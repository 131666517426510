import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-assortment-search',
  templateUrl: './assortment-search.component.html',
  styleUrls: ['./assortment-search.component.scss']
})
export class AssortmentSearchComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;

  public department = "Assortment";
  public disable: any = [];
  public cart = false;

  constructor() { }

  ngOnInit() {
    let departmentName = [];
    departmentName.push(this.department);
    this.disable.push("department");
    this.query = {
      // facetFilters: ["department:" + this.department],
      disjunctiveFacetsRefinements: {
        department: departmentName
      }
    };
  }
}
