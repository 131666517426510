import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NavParams,
  ModalController,
  ToastController,
  Events,
  LoadingController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";
import * as moment from "moment";

@Component({
  selector: 'app-manage-external-inventory-stones',
  templateUrl: './manage-external-inventory-stones.page.html',
  styleUrls: ['./manage-external-inventory-stones.page.scss'],
})
export class ManageExternalInventoryStonesPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  public id: any;
  public selected = []
  public companyDetails = []
  public customerDetails = []
  public flagOpenDoc = 'stoneDetail';
  public actionName: any;
  public page = 'manageExternalStone'
  public count;
  public columns = []
  public columnsForTransfer = []
  public issueStoneList = []
  public requestList = []
  public flagForReturn = false;
  public requestJson = {}
  public segment: any;
  public DCList = []
  public voucherNo: any;
  public isSelect: Boolean = false;
  public transType: any;
  public selectedStoneList = [];
  public deliveryChallanNo: any;
  public actionId: any;
  public status = "External"
  public voucherBookId: any;
  public fxRate: any;
  public returnCSSPList = [];
  public optionPO: any;
  public columnsPO = []
  public transporterDetails = [];
  public isNext = true;
  public listOfActions = []
  public userData: any;
  public toRole: any;
  public nameOfPersonReceivingGood: any;
  public flag: any;
  public terms: any;
  public disc1: any;
  public disc2: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController
  ) { }

  async ngOnInit() {
    await this.getUserData()
    await this.getAllActions()
    this.columns = [
      { name: "Sr No", prop: "" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Rate per carat", prop: "requestPrice" },
      { name: "Offer Price", prop: "offerPrice" },
      { name: "Ct.", prop: "stoneCts" },
    ];
    this.columnsPO = [
      { name: "Sr No", prop: "" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "Rate per carat", prop: "requestPrice" },
      { name: "Ct.", prop: "stoneCts" },
    ];
    this.columnsForTransfer = [
      { name: "Sr No", prop: "" },
      { name: "CSSP", prop: "stoneId" },
      { name: "Issued Ct.", prop: "stoneCts" },
      { name: "Return Ct.", prop: "stoneCts" },
    ]
    let item = await this.navParams.get("data");
    this.actionName = await this.navParams.get("actionName");
    this.segment = await this.navParams.get("title");
    this.flag = await this.navParams.get('flag')
    console.log(this.actionName)
    this.id = item.id;
    console.log(item.id)
    this.deliveryChallanNo = item.bookSerialNumber;
    this.voucherNo = item.shortCode + "-" + item.bookSerialNumber
    await this.getCompanyDetail();
    if (item.partyMasterId) {
      await this.getCustomerDetails(item.partyMasterId);
    } else if (item.toLocation) {
      await this.getCompanyDetailByName(item.toLocation)
      this.nameOfPersonReceivingGood = item.username;
    }

    if (this.segment != 'PO') {
      await this.findTransferRequestStoneByVoucherId();
      await this.getTransporterDetail('Transport');
      await this.findAllTransferRequestedByVoucherIdForDC();
      await this.getLatestRateByCurrencyName()
    } else {
      await this.getAllPOStonesByVoucherId();
    }
  }

  //To company detail for recut and observation 

  async getCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getUserData() {
    this.userData = await this.authService.userData();
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data[0];
      }
      console.log("transporterDetails", this.transporterDetails);
    }
  }

  async getAllActions() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(userData.userId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
    }
  }

  async getDefaultSetting(actionName, actionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(
      this.userData.userId,
      actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
      console.log(this.toRole);
      console.log(this.voucherBookId);
    }
  }

  async findTransferRequestStoneByVoucherId() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.findTransferRequestStoneByVoucherId(this.id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.issueStoneList = data.data;
      if (this.issueStoneList.length > 0) {
        this.transType = this.issueStoneList[0].transType;
        this.issueStoneList.forEach(el => {
          el.offerPrice = el.requestPrice
        });
      }
      this.count = this.issueStoneList.length;
      console.log(this.issueStoneList)

    }
  }

  //currency detail

  async getLatestRateByCurrencyName() {
    let res: any;
    res = await this.httpApiService.getLatestRateByCurrencyName('US Dollar');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      this.fxRate = currencyDetail.rate;
    }
  }

  async findAllTransferRequestedByVoucherIdForDC() {
    let res: any;
    res = await this.httpApiService.getTransferRequestedByVoucherIdForDC(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.DCList = data.data;
      if (this.DCList.length > 0) {
        this.transType = this.DCList[0].transType;
        this.terms = !!this.DCList[0].termName ? this.DCList[0].termName : 0;
        this.disc1 = !!this.DCList[0].disc1 ? this.DCList[0].disc1 : 0;
        this.disc2 = !!this.DCList[0].disc2 ? this.DCList[0].disc2 : 0;
      }
      console.log(this.DCList)
    }
  }

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async previewDocument(view, po) {
    this.flagOpenDoc = view;
    if (po) {
      this.optionPO = po;
    }
  }

  async printDocument() {
    await this.configService.print();
  }

  async generatePo() {
    await this.getVoucherBook('PO');
    if (!!this.voucherBookId) {
      this.selected.forEach((el, i) => {
        this.requestJson[el.id] = el.offerPrice ? el.offerPrice.toString() : null
      });
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        voucherBookId: this.voucherBookId,
        status: "POConfirmed",
        type: "PO Confirmed",
        mapOfStonePrice: this.requestJson,
      }
      console.log(jsonObj)
      res = await this.httpApiService.markStoneAsConfirmed(jsonObj);
      let data = res.json()
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }

  async selectAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.stoneId)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = []
    }
  }

  onSelect(ev, itemList) {
    this.selected = ev.selected;
    this.selectedStoneList = []
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.stoneId)

    });
    if (this.selected.length == itemList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async getAllPOStonesByVoucherId() {
    let res: any;
    res = await this.httpApiService.getAllConfirmedStonesWithStatus(this.id, 'POConfirmed');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.issueStoneList = data.data.content;
      console.log(this.issueStoneList, data)
      this.count = this.issueStoneList.length;
      this.issueStoneList.forEach(el => {
        this.selectedStoneList.push(el.stoneId)
      });
      if (this.issueStoneList.length > 0) {
        this.terms = !!this.issueStoneList[0].termName ? this.issueStoneList[0].termName : 0;
        this.disc1 = !!this.issueStoneList[0].disc1 ? this.issueStoneList[0].disc1 : 0;
        this.disc2 = !!this.issueStoneList[0].disc2 ? this.issueStoneList[0].disc2 : 0;
      }
    }
  }

  async markAsSold() {
    await this.getVoucherBook('Offer');
    if (!!this.voucherBookId) {
      await this.configService.showLoader();
      let dataObj = {
        setOfValues: this.selectedStoneList,
        voucherBookId: this.voucherBookId,
        remarks: "Offer Stone",
        status: "OfferConfirmed",
        type: 'Offer',
        voucherDate: moment().format("YYYY-MM-DD"),
      };
      console.log(dataObj);
      let res = await this.httpApiService.markStoneAsConfirmed(dataObj);
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 200) {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag);
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }

  openReturn() {
    this.returnCSSPList = []
    this.flagForReturn = true;
    let arr = []
    this.returnCSSPList = arr.concat(this.selected);
    this.returnCSSPList.forEach(el => {
      el.size = parseFloat(el.stoneCts);
    })
    console.log(this.returnCSSPList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', cell, rowIndex, event.target.value)

    this.returnCSSPList[rowIndex][cell] = parseFloat(event.target.value);

    this.returnCSSPList = [...this.returnCSSPList];
    if (this.returnCSSPList[rowIndex][cell] > this.returnCSSPList[rowIndex]['stoneCts'] ||
      this.returnCSSPList[rowIndex][cell] == 0 ||
      this.returnCSSPList[rowIndex][cell] == "") {
      this.returnCSSPList[rowIndex]['errorCt'] = true;
    } else {
      this.returnCSSPList[rowIndex]['errorCt'] = false;
    }
    if (!this.returnCSSPList[rowIndex][cell]) {
      this.returnCSSPList[rowIndex][cell] = 0
    }
    for (let i = 0; i < this.returnCSSPList.length; i++) {
      if (
        parseFloat(this.returnCSSPList[i].size) >
        parseFloat(this.returnCSSPList[i].stoneCts) ||
        parseFloat(this.returnCSSPList[i].size) == 0 ||
        this.returnCSSPList[i].size == ""
      ) {
        this.isNext = false;
      } else {
        this.isNext = true;
      }
    }
    console.log(this.isNext, this.returnCSSPList)
  }

  async getVoucherBook(actionName) {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == actionName) {
        return item.actionName;
      }
    });
    console.log(actionArray)
    if (actionArray.length > 0) {
      await this.getDefaultSetting(this.userData.userId, actionArray[0].id);
    }
  }

  async returnCssp() {
    await this.getVoucherBook('Return');
    let setOfStoneIds = []
    this.requestList = []
    this.returnCSSPList.forEach((el) => {
      setOfStoneIds.push(el.id)
      this.requestList.push({
        "stoneId": el.id.toString(),
        "size": el.size ? el.size : el.stoneCts,
      })
    })
    if (!!this.voucherBookId) {
      await this.configService.showLoader()
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        // setOfStoneIds: setOfStoneIds,
        setOfStoneParameter: this.requestList,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
      };
      console.log(jsonObj)
      let res: any;
      res = await this.httpApiService.returnStone(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.flag)
      }
    } else {
      await this.configService.showToast('error', 'Voucher book not found.')
    }

  }


  //update price in cart

  async updatePrice(event, cell, rowIndex) {
    console.log('inline editing rowIndex', event, cell, rowIndex)
    this.issueStoneList[rowIndex][cell] = parseFloat(event.target.value);

    this.issueStoneList = [...this.issueStoneList];
    // this.editing[rowIndex + '-' + cell] = false;
    let errorPrice = 'errorPrice'
    if (!!this.issueStoneList[rowIndex][cell] && this.issueStoneList[rowIndex][cell] > 0
    ) {
      this.issueStoneList[rowIndex][errorPrice] = false;
    } else {
      this.issueStoneList[rowIndex][errorPrice] = true;
      this.issueStoneList[rowIndex][cell] = 0;
    }
    let flag: boolean;
    this.issueStoneList.forEach(item => {
      if (parseFloat(item.offerPrice) > 0 && item.size != "") {
        flag = true;
        this.isNext = true;
      }
    })
    if (flag == false || flag == undefined) {
      this.isNext = false;
    }
    console.log(this.issueStoneList, this.isNext)
  }
}



