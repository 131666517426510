import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, LoadingController } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-upload-stone",
  templateUrl: "./manage-upload-stone.page.html",
  styleUrls: ["./manage-upload-stone.page.scss"]
})
export class ManageUploadStonePage implements OnInit {
  public historyData: any;
  public okRows: any;
  public notOkRows: any;
  public id: any;
  public totalSuccess: any;
  public totalErrors: any;
  public status: any;
  public transferObj: any;
  public type: any;
  public date: any;

  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public configService: ConfigService
  ) {
    let monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    this.historyData = this.navParams.get("data");
    this.transferObj = this.navParams.get("transferObj");

    console.log("this.historyData", this.historyData, this.transferObj);
    let inwardDate = new Date(this.historyData.createdDate)
    let day = inwardDate.getDate()
    let month = monthNames[inwardDate.getMonth()];
    let year = inwardDate.getFullYear();
    this.date = day + "-" + month + "-" + year;
    console.log(this.date)
    this.id = this.historyData.id;
    this.type = this.historyData.type;
    this.totalSuccess = this.historyData.totalSuccess;
    this.totalErrors = this.historyData.totalLines - this.historyData.totalSuccess;
    this.status = this.historyData.status;
  }

  ngOnInit() { }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  async saveData() {
    let validate = await this.validateControls();
    if (!validate) {
      const formData: FormData = new FormData();
      formData.append("myJson", JSON.stringify(this.transferObj));
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.saveOkRows(this.id, formData);
      console.log(res, res.json());
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        if (res.status == 200) {
          await this.configService.showToast("success", data.message);
          this.modalCtrl.dismiss();
        } else {
          await this.configService.showToast("error", data.error);
          this.modalCtrl.dismiss();
        }
      }
    }
  }

  async downloadFile(fileName) {
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(fileName);
  }

  async inwardJsonData() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getDataFromKG(this.date);
    await this.loadingController.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.modalCtrl.dismiss();
      if (res.status == 200) {
        await this.configService.showToast("success", data.message);
      } else {
        await this.configService.showToast("error", data.error);
      }
    }
  }

  async validateControls() {
    console.log(
      this.transferObj.fromDepartment,
      this.transferObj.toDepartment,
      this.transferObj.fromLocation,
      this.transferObj.toLocation,
      this.transferObj.fromUser,
      this.transferObj.toRole,
      this.transferObj.voucherDate
    );
    if (
      !!this.transferObj.fromDepartment &&
      !!this.transferObj.toDepartment &&
      !!this.transferObj.fromLocation &&
      !!this.transferObj.toLocation &&
      !!this.transferObj.fromUser &&
      !!this.transferObj.toRole &&
      !!this.transferObj.voucherDate
    ) {
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }
}
