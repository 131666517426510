import { Component, HostListener, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  AlertController,
  LoadingController,
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";
import { elementEnd } from "@angular/core/src/render3";
import { BORDER_STYLE } from "html2canvas/dist/types/css/property-descriptors/border-style";
import { analyzeAndValidateNgModules } from "@angular/compiler";

@Component({
  selector: "app-manage-search",
  templateUrl: "./manage-search.page.html",
  styleUrls: ["./manage-search.page.scss"]
})
export class ManageSearchPage implements OnInit {
  public data = [];
  public rfid: any = [];
  public stoneID: any;
  public userId: any;
  public actionId: any;
  public fromUser: any;
  public noPagination = true;
  public page = "manageSearch";
  public columns: any;
  public view = "Detail";
  public remarks: any;
  public listOfLabs: any;
  public selectedAction: any;
  public selectedLab: any = [];
  public selectedLabidList: any = [];
  public requestFor = "";
  public voucherBookId: any;
  public toRole: any
  public actions: any;
  public getAllCustomers: any;
  public selectedCustomer: any;
  public getAllCompany: any;
  public selectedCompany: any;
  public usersByCompany: any;
  public selectedUser: any;
  public selectedCustomerList: any = [];
  public selectedCompanyList: any = [];
  public selectedUserList: any = [];
  public actionName: any;
  public getAllContacts: any;
  public selectedContactList: any = [];
  public selectedBoxList: any = [];
  public selectedContact: any;
  public listOfBox: any;
  public selectedBox: any;
  public listOfTags: any;
  public selectedTagIdList: any = [];
  public selectedTag: "";
  public listOfStoneTags: any;
  public items: any;
  public arrowKeyLocation = -1;
  public manageSelectedTag: "";
  public type: any;
  public stoneQtsWiseList = [];
  public isNext: boolean = true;
  public exportAs: any = [];
  public errorMsg = null;
  public errorMsgList = [];
  public successMsgList = [];
  public successMsg = null;
  public msgStatus = false;
  public isDefault = false;
  public defaultTempalateList = [];
  public defaultTemplateId: any;
  public userTemplateId: any;
  public defaultExportList = []
  public userExportList = []
  public count = 0;
  public flagOpenDoc = false;
  public nameOfPersonReceivingGood: any;
  public customerDetails: any;
  public companyDetails = [];
  public salesPersonName: any;
  public status = "Internal";
  public servicesList = []
  public requestList = []
  public transporterDetails: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public deliveryChallanNo: any;
  public fxRate: any;
  public lab: any;
  public userData: any;
  public listOfActions: any;
  public requestVoucherBookId: any;
  public showSelected = true;
  public termList = [];
  public discountPremium: any;
  public error: any;
  public isError: boolean = false;
  public defaultDetail: any;
  public copyValue: any = null;
  public stoneWithPriceArr = [];
  public columnsForCopyData = [];
  public countCopyArr = 0;
  public priceListMasterId: any;
  public totalCts: any;
  public totalAmount = 0;
  public currencyRate: any;
  public spreadRate: any;
  public selectedTermList = [];
  public baseUrl: any;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    public configService: ConfigService,
  ) {
  }

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      let x = document.activeElement.tagName;
      if (x != 'ION-TEXTAREA') {
        if (navigator['clipboard']) {
          navigator['clipboard'].readText().then(data => {
            this.copyValue = data;
            console.log(this.copyValue)
            this.getArray();
          })
        }
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "customer") {
      if (idList.length > 0) {
        this.selectedCustomer = idList[0];
        this.lab = obj[0].partyName;
        this.getAllCustomerContact();
        this.getCustomerDetails(idList[0]);
      } else {
        this.selectedContactList = []
        this.selectedCustomer = null;
        this.selectedContact = null;
      }
    } else if (property == "contacts") {
      if (idList.length > 0) {
        this.selectedContact = idList[0];
        this.nameOfPersonReceivingGood = obj[0].username;
      } else {
        this.selectedContact = null;
      }
    } else if (property == "company") {
      if (idList.length > 0) {
        this.selectedCompany = idList[0];
        let companyName = obj[0].companyName
        this.getCompanyDetailByName(companyName)
        this.findByAppNameAndCompanyId(this.selectedCompany);
      } else {
        this.selectedCompany = null;
        this.selectedUser = null;
        this.selectedUserList = []
        this.usersByCompany = null;
      }
    } else if (property == "users") {
      this.selectedUser = idList[0];
      this.nameOfPersonReceivingGood = obj[0].username;
    } else if (property == "box") {
      this.selectedBox = idList[0];
    } else if (property == "tags") {
      this.selectedTag = idList;
    } else if (property == 'defaultTemplate') {
      this.defaultTemplateId = idList[0];
    } else if (property == 'exportAs') {
      this.userTemplateId = idList[0];
    }
    else if (property == "additionalServices") {
      console.log(idList, obj, masterId)
      if (idList.length > 0) {
        this.data.filter(el => {
          if (el.id == masterId) {
            el.serviceIdList = []
            el.serviceIdList = obj
            delete el.masterId
            el[masterId] = idList;
          }
        })
      } else {
        this.data.filter(el => {
          if (el.id == masterId) {
            el.serviceIdList = []
            delete el.masterId;
          }
        })
      }
      console.log(this.data)
    } else if (property == "term") {
      if (idList.length > 0) {
        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.discountPremium = el.discount
            this.terms = el.termDays;
          }
        })
      } else {
        this.terms = null;
        this.discountPremium = 0;
      }

    }
    console.log(
      "selectedValues",
      idList,
      property,
      this.terms,
      this.selectedCustomer,
      this.selectedLab,
      this.selectedCompany,
      this.selectedUser
    );
  }

  closeModal() {
    this.modalCtrl.dismiss(this.data);
  }

  async ngOnInit() {
    this.getBaseUrl();
    this.actionId = this.navParams.get("actionId");
    this.actionName = this.navParams.get("actionName");
    this.view = this.navParams.get("view");
    this.type = this.navParams.get("type");
    this.priceListMasterId = this.navParams.get('priceListMasterId')
    console.log(this.priceListMasterId)
    console.log(this.columnsForCopyData)
    this.columnsForCopyData = [{ name: 'Sr No', prop: '' }, { name: 'Stone Id', prop: 'stoneId' }, { name: 'Rate per carat', prop: 'price' }]
    await this.getAllLocations()
    await this.getCompanyDetail()
    await this.getLatestRateByCurrencyName();
    let userData = await this.authService.userData();
    this.salesPersonName = userData.fullName;
    this.getAllTemplates();
    this.getAllDefaultTemplates();
    if (this.view == "tags") {
      this.stoneID = this.navParams.get("stoneID");

      this.columns = [
        { name: "Name", prop: "tagName" },
        { name: "Delete", prop: "id" }
      ];
      await this.getStonesTags();
      await this.listAllTags();
    } else {
      this.stoneID = this.navParams.get("stoneID");
      console.log(this.stoneID, this.stoneID.length)
      if (this.type == 'SS') {
        this.columns = [
          { name: "StoneId", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "RAPAPORT", prop: "rapPrice" },
          { name: "Disc %", prop: "discountPercantage" },
          { name: "Rates per cts", prop: "price" },
          { name: "Memo %", prop: "discountPercantage" },
          { name: "Memo Rates per cts", prop: "price" },
          { name: "Memo Price", prop: "offerPrice" },
          { name: "Delete", prop: "id" }
        ];
      } else {
        this.columns = [
          { name: "CSSP", prop: "stoneId" },
          { name: "Color", prop: "color" },
          { name: "Shape", prop: "shape" },
          { name: "Ct.", prop: "stoneCts" },
          { name: "Request Ct.", prop: "size" },
          { name: "Rates per cts", prop: "price" },
          { name: "Memo Rates per cts", prop: "price" },
          { name: "Memo Price", prop: "offerPrice" },
          { name: "Delete", prop: "id" }
        ];
      }
      await this.getAllCartData();
      await this.getDefaultSetting();
      this.getAllTerm();
      console.log("*****", this.flagOpenDoc)
    }
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  //term master 

  async getAllTerm() {
    let res: any;
    res = await this.httpApiService.getAllTerm();
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }
        })
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  //currency detail

  async getLatestRateByCurrencyName() {
    let res: any;
    res = await this.httpApiService.getLatestRateByCurrencyName('US Dollar');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      this.currencyRate = currencyDetail.rate;
      this.spreadRate = currencyDetail.spread;
      this.fxRate = currencyDetail.rate + currencyDetail.spread;
      console.log(currencyDetail, this.fxRate)
    }
  }

  //From Company Details

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  //To company detail for recut and observation 

  async getCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data[0];
      }
      console.log("transporterDetails", this.transporterDetails);
    }
  }

  // Customer Details

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
      if (!!this.customerDetails && this.customerDetails.type == 'Lab') {
        this.data.forEach(el => {
          el.serviceIdList = []
        })
        await this.getAllAdditionalServicesByLabId(this.customerDetails.labReferenceId)
        this.getLatestLabRateByCurrencyName(this.customerDetails.id)
      }
      if (!!this.customerDetails) {
        this.disc1 = this.customerDetails.disc1;
        this.disc2 = this.customerDetails.disc2;
        this.selectedTermList = []
        this.termList.filter(el => {
          if (el.termDays == this.customerDetails.terms) {
            this.selectedTermList.push({ id: el.id, name: el.name })
            this.terms = el.termDays;
          }
        })
      }

      console.log(this.customerDetails)
    }
  }

  //get currency rate for lab 

  async getLatestLabRateByCurrencyName(partyMasterId) {
    let res: any;
    res = await this.httpApiService.getLatestLabRateByCurrencyName(partyMasterId);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      if (currencyDetail.rate) {
        this.fxRate = currencyDetail.rate;
      }
    }
  }

  // Additional lab services by lab id

  async getAllAdditionalServicesByLabId(labId) {
    let res = await this.httpApiService.getAllAdditionalServicesByLabId(labId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.servicesList = data.data;
      // this.items = this.listOfParameter;
      console.log("get All stone param Data", this.servicesList);
      this.data.forEach(el => {
        el.serviceList = this.servicesList;
        // this.servicesList.filter(function (item) {
        //   if (el.stoneCts >= 1) {
        //     if (item.service == 'DG') {
        //       el.serviceIdList = [{ id: item.id, service: item.service }]
        //     }
        //   } else {
        //     if (item.service == 'DOSS') {
        //       el.serviceIdList = [{ id: item.id, service: item.service }]
        //     }
        //   }
        //   el[el.id] = [item.id];
        // })
      })
      console.log("QQQQQQQQQQ", this.data)
    }
  }

  // get all templates for export 

  async getAllTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(this.isDefault)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.exportAs = data.data;
    }
  }

  async getAllDefaultTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(true)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.defaultTempalateList = data.data;
    }
  }

  //add stones to cart

  async addStone() {
    this.stoneID = this.stoneID.split("\n");
    this.stoneID = this.stoneID.filter(function (el) {
      return el != "";
    });
    if (this.stoneID.length > 0) {
      await this.configService.showLoader();
      let objData = {
        setOfStones: this.stoneID,
        transType: this.type,
        type: this.actionName,
        departmentName: 'Sales',
        priceListNameMasterId: this.priceListMasterId,
      };
      console.log(objData);
      let res = await this.httpApiService.addToCart(objData);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);

        await this.getAllCartData();
        await this.changeView('Detail')
      }
    } else {
      await this.configService.showToast("error", "Provide atleast one stone");
    }
  }

  async addStoneWithPrice() {
    if (this.stoneWithPriceArr.length > 0) {
      let mapOfStonePrice = {}
      this.stoneWithPriceArr.forEach(el => {
        mapOfStonePrice[el.stoneId] = el.price;
      })
      await this.configService.showLoader();
      let objData = {
        mapOfStonePrice: mapOfStonePrice,
        transType: this.type,
        priceListNameMasterId: this.priceListMasterId,
        type: this.actionName,
        departmentName: 'Sales'
      };
      console.log(objData);
      let res = await this.httpApiService.addOrUdateWithPrice(objData);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (!!data.data && data.data.listOfErrorsAndStoneId) {
          this.errorMsgList = data.data.listOfErrorsAndStoneId;
          for (let i = 0; i < this.errorMsgList.length; i++) {
            this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
          }
          console.log(this.errorMsgList)
          this.errorMsg = data.message
          this.msgStatus = true;
        } else {
          await this.configService.showToast("error", data.error);
        }
      } else {
        await this.configService.showToast("success", data.message);

        await this.getAllCartData();
        await this.changeView('Detail')
      }
    } else {
      await this.configService.showToast("error", "Provide atleast one stone");
    }
  }

  //get cart data

  async getAllCartData() {
    let res: any;
    res = await this.httpApiService.getCartStone(this.type, this.actionName);
    this.stoneID = "";
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.data = data.data;
      this.count = data.data.length;
      this.stoneQtsWiseList = data.data;
      let cts = 0
      this.totalAmount = 0
      this.data.forEach(el => {
        if (!!el.price && !!el.discountPercantage && !!el.rapPrice) {
          el.requestDisc = el.requestedDiscount ? el.requestedDiscount : el.discountPercantage
          el.requestPrice = el.requestPrice ? el.requestPrice : el.price;
          el.size = el.stoneCts.toFixed(2);
        } else {
          el.requestPrice = el.requestPrice;
          if (!!el.rapPrice) {
            let offerDisc = ((el.rapPrice - el.requestPrice) * 100) / el.rapPrice;
            el.requestDisc = offerDisc.toFixed(2)
          }
          el.size = el.stoneCts;
        }
        this.totalAmount += (el.stoneCts * el.requestPrice);
        cts += el.stoneCts;
        this.totalCts = cts.toFixed(2)
      });
      if (this.data.length == 0) {
        this.totalAmount = 0;
        this.totalCts = null;
      }
      // this.items = this.listOfParameter;
      console.log("All Stone", this.data);
    }
  }

  //remove all stone

  async deleteStone(item) {
    console.log("item.id", item.cartId);
    let res: any;
    res = await this.httpApiService.deleteStoneInCart(item.cartId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.isError = false;
      await this.configService.showToast(
        "success",
        "Stone deleted successfully"
      );
      await this.getAllCartData();
    }
  }

  async getDefaultSetting() {
    console.log("this.requestFor", this.requestFor);
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let resp = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let dataValue = resp.data;
      this.defaultDetail = dataValue.map;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.requestVoucherBook) {
          this.requestVoucherBookId = dataValue.map.requestVoucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.toUser) {
          this.fromUser = dataValue.map.toUser;
        }
        let data1 = [];
        for (var prop in dataValue.map) {
          if (this.actionName == "Mix Search") {
            if (
              prop == "Requisition" ||
              prop == "Return" ||
              prop == "Mixing" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Self Observation" ||
              prop == "Customer Hold" ||
              prop == "Memo Client" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock"
            ) {
              console.log(prop)
              if (prop == 'Recut' || prop == "Observation" || prop == "Self Observation" || prop == "Memo Client" ||
                prop == "Memo Service" || prop == "Customer Hold" ||
                prop == "Consignment In Stock" || prop == "Consignment Confirm" ||
                prop == "Customer Confirm" || prop == "Requisition") {
                let dataJson = JSON.parse(dataValue.map[prop])
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "Single With Me") {
            if (
              prop == "Certification" ||
              prop == "Return" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Customer Hold" ||
              prop == "Memo Client" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock"
            ) {
              if (prop == 'Recut' || prop == "Observation" || prop == "Memo Client" ||
                prop == "Memo Service" || prop == "Customer Hold" ||
                prop == "Consignment In Stock" || prop == "Consignment Confirm" ||
                prop == "Customer Confirm" || prop == "Certification") {
                let dataJson = JSON.parse(dataValue.map[prop])
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });

                }
              }
            }
          } else if (this.actionName == "Mix With Me") {
            if (
              prop == "Return" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Customer Confirm" ||
              prop == "Customer Hold" ||
              prop == "Memo Client" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock"
            ) {
              if (prop == 'Recut' || prop == "Observation" || prop == "Memo Client" ||
                prop == "Memo Service" || prop == "Customer Hold" ||
                prop == "Consignment In Stock" || prop == "Consignment Confirm" ||
                prop == "Customer Confirm") {
                let dataJson = JSON.parse(dataValue.map[prop])
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          } else if (this.actionName == "Single Search") {
            if (
              prop == "Certification" ||
              prop == "Requisition" ||
              prop == "Return" ||
              prop == "Mixing" ||
              prop == "Recut" ||
              prop == "Observation" ||
              prop == "Consignment Confirm" ||
              prop == "Self Observation" ||
              prop == "Customer Hold" ||
              prop == "Customer Confirm" ||
              prop == "Memo Client" ||
              prop == "Memo Service" ||
              prop == "Consignment In Stock"
            ) {
              if (prop == 'Recut' || prop == "Observation" || prop == "Self Observation" || prop == "Memo Client" ||
                prop == "Memo Service" || prop == "Customer Hold" ||
                prop == "Consignment In Stock" || prop == "Consignment Confirm" ||
                prop == "Customer Confirm" || prop == "Certification" || prop == "Requisition") {
                let dataJson = JSON.parse(dataValue.map[prop])
                if (dataJson.enable == true) {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              } else {
                if (dataValue.map[prop] == "true") {
                  let index = Object.keys(dataValue.map).indexOf(prop);
                  data1.push({ id: index + 1, actionName: prop });
                }
              }
            }
          }
        }
        this.actions = data1;
        console.log(this.actions);
      }
    }
  }

  closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  closeErrorMsg() {
    this.error = "";
    this.isError = false;
  }

  // Request Stone

  async requestStone() {
    this.fxRate = this.currencyRate + this.spreadRate;
    // this.flagOpenDoc = true;
    // return
    let setOfStoneIds = []
    this.requestList = []
    this.data.forEach((el, i) => {
      setOfStoneIds.push(el.id)
      this.requestList.push({
        "stoneId": el.id.toString(),
        "size": !!el.size ? el.size : el.stoneCts,
        "requestPrice": !!el.requestPrice ? el.requestPrice.toString() : (!!el.price ? el.price.toString() : "0"),
        "listOfServices": this.data[i][el.id],
      })
    })
    console.log("StoneList", this.requestList);
    // this.flagOpenDoc = true;
    // setTimeout(function () { window.print() }, 1000)
    // return
    let validate = await this.validateControls();
    console.log(validate)
    if (validate) {
      await this.configService.showLoader();
      let res: any, jsonObj;
      if (this.requestFor == "Certification") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          exgRate: this.fxRate,
          requestFor: this.requestFor,
          partyMasterId: this.selectedCustomer
        };
      } else if (this.requestFor == "Requisition") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.requestFor,
          customerContactId: this.selectedContact
        };
        console.log(jsonObj);
      } else if (
        this.requestFor == "Self Observation"
      ) {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          requestFor: this.requestFor,
          fromRole: this.toRole,
          partyMasterId: this.selectedCustomer
        };
      } else if (
        this.requestFor == "Recut" ||
        this.requestFor == "Observation"
      ) {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          exgRate: this.fxRate,
          requestFor: this.requestFor,
          remarks: this.remarks,
          userId: this.selectedUser
        };
      } else if (this.requestFor == "Return") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfStoneIds: setOfStoneIds,
          voucherBookId: this.voucherBookId,
          toRole: this.toRole,
          removeFromCart: true
        };
      } else if (this.requestFor == "Consignment Confirm" ||
        this.requestFor == "Customer Confirm" ||
        this.requestFor == "Memo Client" ||
        this.requestFor == "Customer Hold" ||
        this.requestFor == "Consignment In Stock") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.requestFor,
          customerContactId: this.selectedContact,
          termName: this.terms,
          termDiscount: this.discountPremium,
          exgRate: this.fxRate,
          disc1: !!this.disc1 ? this.disc1.toFixed(2) : '0.00',
          disc2: !!this.disc2 ? this.disc2.toFixed(2) : '0.00',
        };
      } else if (
        this.requestFor == "Memo Service") {
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          setOfStoneParameter: this.requestList,
          fromRole: this.toRole,
          requestFor: this.requestFor,
          exgRate: this.fxRate,
          customerContactId: this.selectedContact,
        };
      } else if (this.requestFor == "Mixing") {
        jsonObj = {
          boxId: this.selectedBox,
          setOfStoneParameter: this.requestList,
          removeFromCart: true
        };
      }
      console.log(jsonObj);
      if (this.requestFor == "Return") {
        res = await this.httpApiService.stoneIssue(jsonObj);

      } else if (this.requestFor == "Mixing") {
        res = await this.httpApiService.addStoneToBox(jsonObj);
      } else {
        res = await this.httpApiService.stoneRequest(jsonObj);
      }
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        // await this.configService.showToast("error", data.error);
        if (this.requestFor == "Return") {
          if (data.data) {
            this.errorMsgList = data.data;
            for (let i = 0; i < this.errorMsgList.length; i++) {
              this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
            }
            console.log(this.errorMsgList)
            this.errorMsg = data.message
            this.msgStatus = true;
          } else {
            await this.configService.showToast("error", data.error);
          }
        } else {
          if (data.data) {
            this.errorMsgList = data.data;
            for (let i = 0; i < this.errorMsgList.length; i++) {
              this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
            }
            console.log(this.errorMsgList)
            this.errorMsg = data.message
            this.msgStatus = true;
          } else {
            await this.configService.showToast("error", data.error);
          }
        }

      } else {
        console.log(data.data, this.disc2)
        if (data.data && data.data.bookSerialNumber) {
          this.deliveryChallanNo = data.data.shortCode + '-' + data.data.bookSerialNumber
        }
        if (!!data.data && !!data.data.url) {
          await this.httpApiService.downloadPackingList(data.data.url)
        }
        await this.configService.showToast("success", data.message);
        if (this.requestFor == 'Certification' || this.requestFor == 'Memo Service' || this.requestFor == 'Memo Client' || this.requestFor == 'Customer Confirm' || this.requestFor == 'Recut' || this.requestFor == 'Observation' || this.requestFor == 'Consignment Confirm' || this.requestFor == 'Consignment In Stock') {
          this.flagOpenDoc = true;
        } else {
          await this.modalCtrl.dismiss();
        }
      }
    }
  }

  //change view

  async changeView(view) {
    this.terms = null;
    this.disc1 = null;
    this.disc2 = null;
    this.countCopyArr = 0;
    this.stoneID = null;
    this.stoneWithPriceArr = []
    this.view = view;
    if (this.view != 'Form') {
      this.selectedContact = null;
      this.selectedCustomer = null;

    }
    if (this.view == 'Detail') {
      this.actions.forEach(element => {
        element.isSelected = false;
      });
      this.msgStatus = false;
      this.usersByCompany = null;
      this.getAllCompany = null;
      this.getAllCustomers = null;
      this.getAllContacts = null;
      this.listOfLabs = null;
    }
  }

  async backToCart(view) {
    this.selectedCustomer = null;
    this.view = view;
    this.flagOpenDoc = false;
    if (this.view == 'Detail') {
      this.actions.forEach(element => {
        element.isSelected = false;
      });
      this.msgStatus = false;
    }
    this.data = []
    this.count = 0
  }

  //Open form for different actions

  async selectedParameterValue(stones, index) {
    this.selectedCustomerList = []
    this.selectedCustomer = null;
    console.log(stones, index, this.voucherBookId);
    if (!!this.voucherBookId) {
      let listOfStoneIds = []
      this.data.forEach(el => {
        listOfStoneIds.push(el.id)
      })
      let res: any;
      let jsonObj = {
        voucherBookId: this.voucherBookId,
        setOfStoneIds: listOfStoneIds
      }
      console.log(jsonObj)
      res = await this.httpApiService.checkValidationForRequest(jsonObj)
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        this.error = data.error
        this.isError = true;
      } else {
        this.error = '';
        this.isError = false;
        await this.getLatestRateByCurrencyName();
        stones.forEach(element => {
          element.isSelected = false;
        });
        stones[index].isSelected = true;
        this.requestFor = stones[index].actionName;
        await this.getAllLocations()
        if (this.requestFor == "Certification") {
          let dataJson = JSON.parse(this.defaultDetail['Certification'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
          await this.getTransporterDetail('Transport');
        } else if (this.requestFor == "Recut" || this.requestFor == "Observation") {
          await this.getTransporterDetail('Transport');
        } else if (this.requestFor == "Requisition") {
          let dataJson = JSON.parse(this.defaultDetail['Requisition'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Customer Confirm") {
          let dataJson = JSON.parse(this.defaultDetail['Customer Confirm'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Consignment In Stock") {
          let dataJson = JSON.parse(this.defaultDetail['Consignment In Stock'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Consignment Confirm") {
          let dataJson = JSON.parse(this.defaultDetail['Consignment Confirm'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Memo Client") {
          let dataJson = JSON.parse(this.defaultDetail['Memo Client'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Customer Hold") {
          let dataJson = JSON.parse(this.defaultDetail['Customer Hold'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        } else if (this.requestFor == "Memo Service") {
          let dataJson = JSON.parse(this.defaultDetail['Memo Service'])
          if (!!dataJson.partyType && dataJson.partyType.length > 0) {
            await this.getAllCustomer(dataJson.partyType);
          }
        }
        for (var prop in this.defaultDetail) {
          let dataJson = JSON.parse(this.defaultDetail[prop])
          if (this.requestFor == 'Recut') {
            if (prop == 'Recut') {
              if (dataJson.enable == true) {
                if (!!dataJson.toLocation) {
                  this.selectedCompanyList = []
                  this.getAllCompany.forEach(item => {
                    if (item.id == dataJson.toLocation) {
                      this.selectedCompany = dataJson.toLocation
                      this.selectedCompanyList.push({ id: item.id, companyName: item.companyName })
                      let companyName = item.companyName
                      this.getCompanyDetailByName(companyName)
                      this.findByAppNameAndCompanyId(this.selectedCompany);
                    }
                  })
                }
              }
            }
          } else if (this.requestFor == 'Observation') {
            if (prop == 'Observation') {
              if (dataJson.enable == true) {
                if (!!dataJson.toLocation) {
                  this.selectedCompanyList = []
                  this.getAllCompany.forEach(item => {
                    if (item.id == dataJson.toLocation) {
                      this.selectedCompany = dataJson.toLocation
                      this.selectedCompanyList.push({ id: item.id, companyName: item.companyName })
                      let companyName = item.companyName
                      this.getCompanyDetailByName(companyName)
                      this.findByAppNameAndCompanyId(this.selectedCompany);
                    }
                  })
                }
              }
            }
          } else if (this.requestFor == 'Self Observation') {
            if (prop == 'Self Observation') {
              if (dataJson.enable == true) {
                if (!!dataJson.partyMaster) {
                  this.selectedCustomerList = []
                  let dataJson = JSON.parse(this.defaultDetail['Self Observation'])
                  if (!!dataJson.partyType && dataJson.partyType.length > 0) {
                    await this.getAllCustomer(dataJson.partyType);
                  }
                  if (this.getAllCustomers.length > 0) {
                    this.getAllCustomers.forEach(item => {
                      if (item.id == dataJson.partyMaster) {
                        this.selectedCustomer = dataJson.partyMaster
                        this.selectedCustomerList.push({ id: item.id, partyName: item.partyName })
                      }
                    })
                  }
                }
              }
            }
          }
        }
        this.changeView("Form");
      }
    } else {
      await this.configService.showToast('error', 'VoucherBook not found')
    }


  }

  async getAllCustomer(type) {
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data;
      console.log("getAllCustomers", this.getAllCustomers);

    }
  }

  // get location for recut and observation

  async getAllLocations() {
    let res: any;
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCompany = data.data;
      // this.items = this.listOfParameter;
      console.log("getAllCompany", this.getAllCompany);
    }
  }

  async findByAppNameAndCompanyId(company) {
    let res: any;
    res = await this.httpApiService.findByAppNameAndCompanyId(company);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.usersByCompany = data.data;
      // this.items = this.listOfParameter;
      console.log("findByAppNameAndCompanyId", this.usersByCompany);
    }
  }

  //validation 

  async validateControls() {
    console.log(this.data.length, this.selectedCustomer, this.selectedLab, this.voucherBookId);
    if (!!this.voucherBookId) {
      if (this.requestFor == "Certification") {
        if (
          this.data.length > 0 &&
          !!this.selectedCustomer
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Recut" || this.requestFor == "Observation") {
        if (
          this.data.length > 0 &&
          !!this.selectedCompany &&
          !!this.selectedUser
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Requisition") {
        if (
          this.data.length > 0 &&
          !!this.selectedContact
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Self Observation") {
        if (
          this.data.length > 0 &&
          !!this.selectedCustomer
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Return") {
        if (
          this.data.length > 0
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Mixing") {
        if (
          this.data.length > 0 &&
          !!this.selectedBox
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      } else if (this.requestFor == "Consignment Confirm" ||
        this.requestFor == "Customer Confirm" ||
        this.requestFor == "Memo Client" ||
        this.requestFor == "Memo Service" ||
        this.requestFor == "Consignment In Stock") {
        if (
          this.data.length > 0 &&
          !!this.selectedContact
        ) {
          return true;
        } else {
          await this.configService.showToast("error", "Please fill all details");
          return false;
        }
      }
    } else {
      console.log("*****")
      await this.configService.showToast('error', 'Voucherbook not found.')
      return false;
    }

  }

  //get customer contact detail

  async getAllCustomerContact() {
    // await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCustomerContactList(
      this.selectedCustomer
    );
    let data = res.json();
    // await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {

      this.getAllContacts = data.data;
      if (this.getAllContacts.length == 1) {
        this.selectedContactList = []
        this.selectedContactList.push({ id: this.getAllContacts[0].id, name: this.getAllContacts[0].name });
        this.selectedContact = this.getAllContacts[0].id;
        this.nameOfPersonReceivingGood = this.getAllContacts[0].name;
      } else {
        this.selectedContactList = []
      }
      // this.items = this.listOfParameter;
      console.log("getAllContacts", this.getAllContacts);
    }
  }

  async findBoxByUser() {
    let res: any;
    res = await this.httpApiService.findBoxByUser();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBox = data.data;
      console.log("listOfBox", this.listOfBox);
    }
  }

  async getStonesTags() {
    let res: any;
    res = await this.httpApiService.getStoneTags(this.stoneID);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfStoneTags = data.data;
      console.log("listOfStoneTags", this.listOfStoneTags);
    }
  }

  async addTags() {
    if (this.manageSelectedTag != "") {
      let setOfTagId = [],
        setOfTagName = [],
        objData;
      this.listOfTags.forEach(tag => {
        if (tag.tagName == this.manageSelectedTag) {
          setOfTagId.push(tag.id);
        }
      });
      if (setOfTagId && setOfTagId.length > 0) {
        objData = {
          stoneId: this.stoneID,
          setOfTagId: setOfTagId
        };
      } else {
        setOfTagName.push(this.manageSelectedTag);
        objData = {
          stoneId: this.stoneID,
          setOfTagName: setOfTagName
        };
      }
      let res = await this.httpApiService.addStoneTag(objData);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.listAllTags();
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", "Select Tag");
    }
    await this.getStonesTags();
  }

  async listAllTags() {
    let res: any;
    res = await this.httpApiService.getAllTags();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTags = data.data;
      this.setItems()
      console.log("listOfTags", this.listOfTags);
    }
  }

  async deleteTag(event) {
    console.log("event", event);
    let res = await this.httpApiService.deleteTag(event.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.getStonesTags();
      await this.configService.showToast("success", "Tag deleted successfully");
    }
  }

  setItems() {
    this.items = this.listOfTags;
  }

  async filterItems(ev: any) {
    // this.selectedTag = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.tagName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.setItems();
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedTag = this.items[this.arrowKeyLocation].tagName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedTag = this.items[this.arrowKeyLocation].tagName;
            break;
          } else {
            break;
          }
        }
    }
  }

  async setTag(tagName) {
    this.manageSelectedTag = tagName;
    this.items = [];
  }

  //export functionality

  async userExport() {
    let ids = [];
    if (this.userTemplateId) {
      this.stoneQtsWiseList.forEach(element => {
        ids.push(element.id);
      });
      let jsonObj: any;
      jsonObj = {
        setOfStoneIds: ids,
        priceListMasterId: this.priceListMasterId,
        templateId: this.userTemplateId
      }
      await this.configService.showLoader()
      let res: any;
      res = await this.httpApiService.exportTemplate(jsonObj);
      let data = res.json()
      await this.loadingController.dismiss()
      console.log(data)
      window.open(this.baseUrl + data.data.filePath.substr(1), '')
      this.userExportList = []
      this.userTemplateId = null
    }
    else {
      await this.configService.showToast('error', 'Please select template')
    }
  }

  async defaultExport() {
    let ids = [];
    if (this.defaultTemplateId) {
      this.stoneQtsWiseList.forEach(element => {
        ids.push(element.id);
      });
      let jsonObj: any;
      jsonObj = {
        setOfStoneIds: ids,
        priceListMasterId: this.priceListMasterId,
        templateId: this.defaultTemplateId
      }
      let res: any;
      await this.configService.showLoader()
      res = await this.httpApiService.exportTemplate(jsonObj);
      let data = res.json()
      await this.loadingController.dismiss()
      console.log(data)
      window.open(this.baseUrl + data.data.filePath.substr(1), '')
      this.defaultExportList = []
      this.defaultTemplateId = null
    }
    else {
      await this.configService.showToast('error', 'Please select template')
    }
  }

  //Start Remove From Cart 

  async confirmRemoveAll() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to remove all stones from cart ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteFromCart();
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteFromCart() {
    this.isError = false;
    let res: any;
    res = await this.httpApiService.deleteAllFromCart();
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllCartData();
    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', event, cell, rowIndex)
    this.data[rowIndex][cell] = parseFloat(event.target.value);

    this.data = [...this.data];
    // this.editing[rowIndex + '-' + cell] = false;
    if (cell == 'size') {
      if (this.data[rowIndex][cell] > this.data[rowIndex]['stoneCts'] ||
        this.data[rowIndex][cell] == 0 ||
        this.data[rowIndex][cell] == "") {
        this.data[rowIndex]['errorCt'] = true;
      } else {
        this.data[rowIndex]['errorCt'] = false;
      }
      if (!this.data[rowIndex][cell]) {
        this.data[rowIndex][cell] = 0
      }
      let flag: boolean;
      this.data.forEach(item => {
        if (parseFloat(item.size) > parseFloat(item.stoneCts) || parseFloat(item.size) == 0 || item.size == "") {
          flag = true;
          this.isNext = false;
        }
      })
      if (flag == false || flag == undefined) {
        this.isNext = true;
      }
    } else if (cell == 'requestPrice') {
      let errorPrice = 'errorPrice'
      if (!!this.data[rowIndex][cell] && this.data[rowIndex][cell] > 0
      ) {
        this.data[rowIndex][errorPrice] = false;
      } else {
        this.data[rowIndex][errorPrice] = true;
        this.data[rowIndex][cell] = 0;
      }
      if (!!this.data[rowIndex].rapPrice) {
        let offerPer = ((this.data[rowIndex].rapPrice - this.data[rowIndex]['requestPrice']) * 100) / this.data[rowIndex].rapPrice;
        this.data[rowIndex]['requestDisc'] = offerPer.toFixed(2);
      } else {
        this.data[rowIndex]['requestDisc'] = 0
      }
      // let flag: boolean;
      // this.data.forEach(item => {
      //   if (!!parseFloat(item.requestPrice) &&
      //     parseFloat(item.requestPrice) > 0) {
      //     flag = true;
      //     this.isNext = false;
      //   }
      // })
      // if (flag == false || flag == undefined) {
      //   this.isNext = true;
      // }
      if (!!this.data[rowIndex]['requestPrice'] &&
        parseFloat(this.data[rowIndex]['requestPrice']) > 0
      ) {
        this.isNext = true;
        let jsonObj = {
          requestPrice: this.data[rowIndex]['requestPrice'],
          requestDisc: Number.parseFloat(this.data[rowIndex]['requestDisc']).toFixed(2)
        }
        let res = await this.httpApiService.updateCartData(this.data[rowIndex]['cartId'], jsonObj)
        console.log("*****")
      } else {
        this.isNext = false;;
      }
    } else if (cell == 'requestDisc') {
      if (!!this.data[rowIndex].rapPrice) {
        this.data[rowIndex].requestPrice = this.data[rowIndex].rapPrice - (this.data[rowIndex].rapPrice * this.data[rowIndex].requestDisc) / 100;
      } else {
        this.data[rowIndex].requestPrice = (this.data[rowIndex].price * this.data[rowIndex].requestDisc) / 100 - (this.data[rowIndex].price * this.data[rowIndex].requestDisc) / 100;
      }
      this.isNext = true;
      let jsonObj = {
        requestPrice: this.data[rowIndex]['requestPrice'],
        requestDisc: this.data[rowIndex]['requestDisc']
      }
      console.log(jsonObj)
      let res = await this.httpApiService.updateCartData(this.data[rowIndex]['cartId'], jsonObj)

    }
    let cts = 0
    this.totalAmount = 0
    this.data.forEach(el => {
      console.log(el)
      this.totalAmount += ((!!el.size ? el.size : el.stoneCts) * el.requestPrice);
      cts += !!el.size ? parseFloat(el.size) : el.stoneCts;
      this.totalCts = cts.toFixed(2)
    })
    console.log(this.data)
  }

  //stoneId

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  async printPreveiw() {
    await this.configService.print()
  }

  // Return with request

  async returnWithRequest() {
    this.fxRate = this.currencyRate + this.spreadRate;
    // this.flagOpenDoc = true;
    // return
    let setOfStoneIds = []
    this.requestList = []
    this.data.forEach((el, i) => {
      setOfStoneIds.push(el.id)
      this.requestList.push({
        "stoneId": el.id.toString(),
        "size": el.size ? el.size : el.stoneCts,
        "requestPrice": el.requestPrice.toString() ? el.requestPrice.toString() : el.price.toString(),
        "listOfServices": this.data[i][el.id],
      })
    })

    let stoneRequestData = {}
    console.log("StoneList", this.requestList);
    let isReady = true;
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any, jsonObj;
      if (this.requestFor == "Return") {
        isReady = true;
        jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          setOfStoneIds: setOfStoneIds,
          voucherBookId: this.voucherBookId,
          toRole: this.toRole,
          removeFromCart: true
        };
      } else {
        if (!!this.requestVoucherBookId) {
          isReady = true;
          if (this.requestFor == "Certification") {
            stoneRequestData = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneParameter: this.requestList,
              voucherBookId: this.requestVoucherBookId,
              requestFor: this.requestFor,
              exgRate: this.fxRate,
              fromRole: this.toRole,
              partyMasterId: this.selectedCustomer
            }
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: stoneRequestData
            };
          } else if (this.requestFor == "Requisition") {
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              voucherBookId: this.voucherBookId,
              setOfStoneParameter: this.requestVoucherBookId,
              fromRole: this.toRole,
              requestFor: this.requestFor,
              customerContactId: this.selectedContact
            };
          } else if (
            this.requestFor == "Recut" ||
            this.requestFor == "Observation"
          ) {
            stoneRequestData = {
              voucherDate: moment().format("YYYY-MM-DD"),
              voucherBookId: this.requestVoucherBookId,
              setOfStoneParameter: this.requestList,
              fromRole: this.toRole,
              exgRate: this.fxRate,
              remarks: this.remarks,
              requestFor: this.requestFor,
              userId: this.selectedUser
            }
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: stoneRequestData
            };
          } else if (this.requestFor == "Consignment Confirm" ||
            this.requestFor == "Customer Confirm" ||
            this.requestFor == "Memo Client" ||
            this.requestFor == "Customer Hold" ||
            this.requestFor == "Consignment In Stock") {
            stoneRequestData = {
              voucherDate: moment().format("YYYY-MM-DD"),
              voucherBookId: this.requestVoucherBookId,
              setOfStoneParameter: this.requestList,
              fromRole: this.toRole,
              requestFor: this.requestFor,
              customerContactId: this.selectedContact,
              termName: this.terms,
              termDiscount: this.discountPremium,
              exgRate: this.fxRate,
              disc1: !!this.disc1 ? this.disc1.toFixed(2) : '0.00',
              disc2: !!this.disc2 ? this.disc2.toFixed(2) : '0.00',
            }
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: stoneRequestData
            };
          } else if (
            this.requestFor == "Memo Service") {
            stoneRequestData = {
              voucherDate: moment().format("YYYY-MM-DD"),
              voucherBookId: this.requestVoucherBookId,
              setOfStoneParameter: this.requestList,
              fromRole: this.toRole,
              requestFor: this.requestFor,
              exgRate: this.fxRate,
              customerContactId: this.selectedContact,
            }
            jsonObj = {
              voucherDate: moment().format("YYYY-MM-DD"),
              setOfStoneIds: setOfStoneIds,
              voucherBookId: this.voucherBookId,
              toRole: this.toRole,
              removeFromCart: true,
              stoneRequestData: stoneRequestData
            };
          } else if (this.requestFor == "Mixing") {
            jsonObj = {
              boxId: this.selectedBox,
              setOfStoneParameter: this.requestList,
              removeFromCart: true
            };
          }
        } else {
          isReady = false;
          await this.configService.showToast("error", 'Voucher book not found');
          await this.loadingController.dismiss();
        }
      }
      console.log(jsonObj, isReady);
      if (isReady == true) {
        if (this.requestFor == "Mixing") {
          res = await this.httpApiService.addStoneToBox(jsonObj);
        } else {
          res = await this.httpApiService.stoneIssue(jsonObj);
        }
        await this.loadingController.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          if (data.data) {
            this.errorMsgList = data.data;
            for (let i = 0; i < this.errorMsgList.length; i++) {
              this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
            }
            console.log(this.errorMsgList)
            this.errorMsg = data.message
            this.msgStatus = true;
          } else {
            await this.configService.showToast("error", data.error);
          }
        } else {
          console.log(data.data)
          if (data.data && data.data.bookSerialNumber) {
            this.deliveryChallanNo = data.data.shortCode + '-' + data.data.bookSerialNumber
          }
          if (!!data.data && !!data.data.url) {
            await this.httpApiService.downloadPackingList(data.data.url)
          }
          await this.configService.showToast("success", data.message);
          if (this.requestFor == 'Certification' || this.requestFor == 'Memo Service' || this.requestFor == 'Memo Client' || this.requestFor == 'Customer Confirm' || this.requestFor == 'Recut' || this.requestFor == 'Observation' || this.requestFor == 'Consignment Confirm' || this.requestFor == 'Consignment In Stock') {
            this.flagOpenDoc = true;
          } else {
            await this.modalCtrl.dismiss();
          }
        }
      }
    }
  }

  getArray() {
    let records = this.copyValue.split('\n');
    this.stoneWithPriceArr = []
    for (let i = 1; i < records.length; i++) {
      if (records[i]) {
        let rowArray = records[i].trim().split('\t')
        this.stoneWithPriceArr.push({ stoneId: rowArray[0], price: rowArray[1] })
      }
    }
    this.countCopyArr = this.stoneWithPriceArr.length;
    console.log(this.stoneWithPriceArr)
  }

}
