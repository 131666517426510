import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { NgAisModule } from "angular-instantsearch";
import { ManageSearchPage } from "../manage-search/manage-search.page";
import { Angular2CsvComponent } from "angular2-csv";
import * as XLSX from "xlsx";
import instantsearch from "instantsearch.js";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { connectRefinementList } from "instantsearch.js/es/connectors";
import * as algoliasearch from "node_modules/algoliasearch";
import searchBox from "instantsearch.js/es/widgets";
import { hits } from "instantsearch.js/es/widgets";
import algoliasearchHelper from "algoliasearch-helper";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: "app-stone-search",
  templateUrl: "./stone-search.component.html",
  styleUrls: ["./stone-search.component.scss"]
})
export class StoneSearchComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() query: any;
  @Input() disable: any;
  @Input() type: any;
  @Input() cart: Boolean;
  @ViewChild("dataTable") public dataTable: any;

  algoliaConfig = {
    apiKey: "c428d99b1b938d5bb665765a24887456",
    appId: "SGTTZIO938",
    indexName: "test1"
  };
  public selectedStone = [];
  public stoneList: any = [];
  public RFList = [];
  public newStoneList: any = [];
  public voucherType: any = "sales_request";
  public resultColumns: any = [];
  public showMoreLess = "less";
  public exportAs: any = [];
  public selectedExportedFormat: any;
  public compareStones: any = [];
  public view: any;
  public options: any;
  public rows: any[];
  public ratePerCts: boolean = false;
  public culetSizeCode: any = "Add";
  public exportedFormatList: any = [];
  public totalStones: any;
  public shapeCode: boolean = true;
  public clarityCode: boolean = true;
  public colorCode: boolean = true;
  public cutCode: boolean = true;
  public cts: boolean = true;
  public polishCode: boolean = true;
  public symmetryCode: boolean = false;
  public fluorescenceCode: boolean = false;
  public department: boolean = false;
  public location: boolean = false;
  public username: boolean = false;
  public totDepth: boolean = false;
  public internalStatusCode: boolean = true
  public tableValue: boolean = false;
  public lOrW: boolean = false;
  public search: any;
  public index: any;
  public modifiedHits: any;
  public searches = { query: "", facetFilters: [] };
  public states: any;
  public searchHistory: any = [];
  public tagName: any;
  public counter = 0;
  public searchHistoryList: any = [];
  public selectedSearch: any;
  public selectedSearchParameters: any;
  public showSaveSearch = false;
  public flagDepartment = true;
  public ids = [];
  public isDefault = false;
  public actionId: any;
  public userId: any;
  public allPriceNameList = [];
  public flagOpenDoc = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public ConfigService: ConfigService,
    public loadingController: LoadingController,
    public event: Events
  ) {
    console.log("calling constructor");
  }

  async ngOnInit() {
    this.actionId = this.data.actionId;
    console.log(this.cart, this.disable)
    if (this.disable) {
      this.flagDepartment = this.disable.includes('department')
    }

    console.log("***************88", this.disable, this.flagDepartment)
    console.log("test ngOnInit", this.data, this);
    this.getAllTemplates();
    // this.exportAs = [{ id: 1, name: "csv" }, { id: 2, name: "xlsx" }];
    await this.generateColumns();
    await this.getAllSearches();
    // let client = algoliasearch("SGTTZIO938","c428d99b1b938d5bb665765a24887456");
    // this.index  = client.initIndex('test1');
    // this.index.search({query: 'ROUND SI1',attributesToRetrieve: ['stoneName','totDepthmm','shapeCode','clarityCode','colorCode','cutCode','cts','polishCode','symmetryCode','fluorescenceCode','department','location','username','totDepth','tableValue','lOrW','culetSizeCode'] }, (err, content) => {
    //   if (err) {
    //     console.log(err);
    //     console.log(err.debugData);
    //     return;
    //   }
    //   this.modifiedHits = content.hits;
    //   console.log(content.hits);
    // });
    let filters = [];
    filters.push("shapeCode:ROUND");
    filters.push("clarityCode:SI1");
    // this.query = { query: "VGOOD", filters: "shapeCode:ROUND OR shapeCode:OVAL AND clarityCode:SI1" };
    // this.index.searchForFacetValues({
    //   facetName: 'shapeCode',
    //   facetQuery: 'ROUND',
    //   facetFilters: '["shapeCode:ROUND"]'
    // }, function(err, content) {
    //   if (err) throw err;
    //   this.modifiedHits = content.facetHits;
    //   console.log(content.facetHits);
    // });
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "exportAs") {
      this.selectedExportedFormat = idList[0];
    } else if (property == "searchHistory") {
      this.selectedSearch = idList[0];
    }
  }
  async getDefaultSetting() {
    await this.getAllPriceListName();
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    console.log(this.actionId)
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATA", dataValue);
      //for VoucherBook
      if (!!dataValue.map) {
        if (!!dataValue.map.priceListName) {
          this.allPriceNameList.filter(el => {
            if (el.id == dataValue.map.priceListName) {
              this.resultColumns.push({ name: "RAPNET", prop: this.allPriceNameList[0].name })
              this.resultColumns.push({ name: "Disc %", prop: 'DiscountPercent' })
              this.resultColumns.push({ name: "Rates per carat", prop: el.name.split(' ').join('') + 'percarat' })
              this.resultColumns.push({ name: "Price", prop: el.name.split(' ').join('') })
            }
          })
        }
      }

      console.log("resultColumns", this.resultColumns)
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async getAllTemplates() {
    let res: any;
    res = await this.httpApiService.findAllTemplate(this.isDefault)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.exportAs = data.data;
    }
  }

  shapeTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  internalStatusTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  clarityTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  polishTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  symmetryTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  fluorescenceTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  departmentTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  locationTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  userTranslate(items) {
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  translate(items) {
    // if(this.stoneList==undefined) {
    //   this.stoneList = [];
    // }
    // console.log("before push this.stoneList",this.stoneList);
    // items.forEach(element => {
    //   if(this.stoneList.indexOf(element)===-1){
    //     this.stoneList.push(element);
    //   }
    // });
    // console.log("this.stoneList",this.stoneList, this.selectedStone)
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }
  async setData(data) {
    // hits.forEach(element => {
    //   if(this.stoneList.indexOf(element)===-1){
    //     this.stoneList.push(element)
    //   }
    // });
    console.log("set data", data, this.dataTable);
    if (data.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      data.event.stopPropagation();
    } else {
      await this.compare(data.row);
      console.log("data", data, this.selectedStone.length);
      let test = false;
      if (this.selectedStone.length <= 0) {
        this.selectedStone.push(data.row);
        this.RFList.push(data.row.rfid);
        data.row.isSelected = true;
        console.log(
          "this.stoneList",
          this.stoneList,
          this.RFList,
          this.selectedStone
        );
      } else {
        this.selectedStone.forEach(el => {
          if (el.id == data.row.id) {
            test = true;
          }
        });
        if (test) {
          let index = this.selectedStone.indexOf(data.row);
          let index2 = this.selectedStone.indexOf(data.row.rfid);
          if (index > -1) {
            this.selectedStone.splice(index, 1);
            this.RFList.splice(index2, 1);
            data.row.isSelected = false;
          }
        } else {
          this.selectedStone.push(data.row);
          this.RFList.push(data.row.rfid);
          data.row.isSelected = true;
          console.log("this.stoneList", this.stoneList, this.selectedStone);
        }
      }
    }
  }
  async testLoop() {
    console.log("test");
    if (this.selectedStone.length > 0) {
      this.selectedStone.forEach(element => {
        element.isSelected = true;
      });
    }
  }
  async cartData() {
    console.log("cart data", this.selectedStone);
  }
  async openStoneModal() {
    console.log("selectedStone", this.selectedStone);
    if (this.selectedStone.length > 0) {
      this.ids = [];
      this.selectedStone.forEach(element => {
        this.ids.push(element.id);
      });
      let objData;
      console.log("************", this.type)
      // if (this.type == undefined) {
      //   objData = {
      //     setOfStoneIds: ids
      //   };
      // } else {
      objData = {
        setOfStoneIds: this.ids,
        transType: this.type
      };
      // }
      console.log(objData)
      // API call to add stone to cart & then open manageSearchPage
      let res = await this.httpApiService.addToCart(objData);
      console.log("res", res.status, res.json().error);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        await this.ConfigService.showToast("success", data.message);
      }
    }
    console.log(this.dataTable)
    if (this.dataTable) {
      this.dataTable.selected = [];
    }
    this.selectedStone = [];
    const modal = await this.modalCtrl.create({
      component: ManageSearchPage,
      cssClass: "extraLargePage",
      backdropDismiss: false,
      componentProps: {
        actionId: this.data.actionId,
        actionName: this.data.desc,
        view: "Detail",
        stoneID: this.ids,
        type: this.type
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    this.RFList = [];
    // this.selectedStone.forEach(element => {
    //   element.isSelected = true;
    //   this.RFList.push(element.rfid);
    // });

    // if(d.data.add){

    //   this.RFList = d.data.add.split("\n");
    // }
    console.log("RFList", this.RFList);
  }

  async validateControls() {
    if (this.RFList.length > 0) {
      return false;
    } else if (
      this.voucherType != "" &&
      this.voucherType != undefined &&
      this.voucherType != null
    ) {
      await this.ConfigService.showToast("error", "Select stone");
      return true;
    } else {
      await this.ConfigService.showToast("error", "Select type");
      return true;
    }
  }

  async generateColumns() {
    this.resultColumns = [
      { name: "Stone Name", prop: "stoneName" },
      { name: "Color", prop: "ColorCode" },
      { name: "Cut", prop: "CutCode" },
      { name: "Clarity", prop: "ClarityCode" },
      { name: "Shape", prop: "ShapeCode" },
      { name: "Carat", prop: "Cts" },
      { name: "Depth", prop: "TotDepthMm" },
      { name: "Polish", prop: "PolishCode" },
      { name: "Internal Status", prop: "internalStatus" }
    ];
    await this.getDefaultSetting();
  }

  async addRemoveColumn(col, name) {
    if (col == "RatePerCts") {
      if (this.ratePerCts == true) {
        this.ratePerCts = false;
      } else {
        this.ratePerCts = true;
      }
    } else if (col == "CuletSize") {
      if (this.culetSizeCode == "Add") {
        this.culetSizeCode = "Remove";
      } else {
        this.culetSizeCode = "Add";
      }
    } else if (col == "ShapeCode") {
      if (this.shapeCode == true) {
        this.shapeCode = false;
      } else {
        this.shapeCode = true;
      }
    } else if (col == "ClarityCode") {
      if (this.clarityCode == true) {
        this.clarityCode = false;
      } else {
        this.clarityCode = true;
      }
    } else if (col == "ColorCode") {
      if (this.colorCode == true) {
        this.colorCode = false;
      } else {
        this.colorCode = true;
      }
    } else if (col == "CutCode") {
      if (this.cutCode == true) {
        this.cutCode = false;
      } else {
        this.cutCode = true;
      }
    } else if (col == "Cts") {
      if (this.cts == true) {
        this.cts = false;
      } else {
        this.cts = true;
      }
    } else if (col == "internalStatusCode") {
      if (this.internalStatusCode == true) {
        this.internalStatusCode = false;
      } else {
        this.internalStatusCode = true;
      }
    } else if (col == "PolishCode") {
      if (this.polishCode == true) {
        this.polishCode = false;
      } else {
        this.polishCode = true;
      }
    } else if (col == "SymmetryCode") {
      if (this.symmetryCode == true) {
        this.symmetryCode = false;
      } else {
        this.symmetryCode = true;
      }
    } else if (col == "FluorescenceCode") {
      if (this.fluorescenceCode == true) {
        this.fluorescenceCode = false;
      } else {
        this.fluorescenceCode = true;
      }
    } else if (col == "department") {
      if (this.department == true) {
        this.department = false;
      } else {
        this.department = true;
      }
    } else if (col == "location") {
      if (this.location == true) {
        this.location = false;
      } else {
        this.location = true;
      }
    } else if (col == "username") {
      if (this.username == true) {
        this.username = false;
      } else {
        this.username = true;
      }
    } else if (col == "TotDepth") {
      if (this.totDepth == true) {
        this.totDepth = false;
      } else {
        this.totDepth = true;
      }
    } else if (col == "tableValue") {
      if (this.tableValue == true) {
        this.tableValue = false;
      } else {
        this.tableValue = true;
      }
    } else if (col == "L_W") {
      if (this.lOrW == true) {
        this.lOrW = false;
      } else {
        this.lOrW = true;
      }
    }
    let i = this.resultColumns.findIndex(function (item, i) {
      return item.name === name;
    });
    console.log("i", i, "col", col);
    if (i == -1) {
      this.resultColumns.push({ name: name, prop: col });
    } else if (i > -1) {
      this.resultColumns.splice(i, 1);
    }
  }

  async show(value) {
    console.log("searchHistoryList", this.searchHistoryList);
    this.showMoreLess = value;
    // await this.clearAll(2000);
  }

  async clearAll(seconds) {
    let element: HTMLElement = document.getElementsByClassName(
      "ais-ClearRefinements-button"
    )[0] as HTMLElement;
    setTimeout(function () {
      console.log("element", element);
      element.click();
    }, seconds);
  }

  onSearchChange({ results, state }: { results: any; state: any }) {
    if (results != undefined) {
      this.totalStones = results.nbHits;
      this.searches.query = results.query;
      this.searches.facetFilters = state.disjunctiveFacetsRefinements;
    }
    this.states = state;
    console.log(
      "results",
      results,
      "state",
      state,
      this.totalStones,
      this.states
    );
  }

  // async export() {
  //   let exportFormat;
  //   await this.exportAs.forEach(format => {
  //     if (format.id == this.selectedExportedFormat) {
  //     }
  //   });
  //   console.log("exported", this.dataTable, exportFormat);
  //   if (exportFormat != undefined) {
  //     const columns: any[] =
  //       this.dataTable.columns || this.dataTable._internalColumns;
  //     const headers = columns.map((column: any) => column.name).filter(e => e); // remove column without name (i.e. falsy value)

  //     this.rows = this.dataTable.rows.map(row => {
  //       let r = {};
  //       columns.forEach(column => {
  //         if (!column.name) {
  //           return;
  //         } // ignore column without name
  //         if (column.prop) {
  //           let prop = column.prop;
  //           r[prop] =
  //             typeof row[prop] === "boolean"
  //               ? row[prop]
  //                 ? "Yes"
  //                 : "No"
  //               : row[prop];
  //         } else {
  //           // special cases handled here
  //         }
  //       });
  //       return r;
  //     });
  //     console.log("headers", headers, "rows", this.rows);
  //     if (exportFormat == "csv") {
  //       this.options = {
  //         fieldSeparator: ",",
  //         quoteStrings: '"',
  //         decimalseparator: ".",
  //         showLabels: true,
  //         headers: headers,
  //         showTitle: false,
  //         title: "Report",
  //         useBom: true
  //       };
  //       let csv = new Angular2CsvComponent();
  //       csv.data = this.rows;
  //       csv.filename = "stones_" + new Date().getTime();
  //       csv.options = this.options;
  //       csv.generateCsv();
  //     } else if (exportFormat == "xlsx") {
  //       const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
  //       const workbook: XLSX.WorkBook = {
  //         Sheets: { data: worksheet },
  //         SheetNames: ["data"]
  //       };
  //       XLSX.writeFile(workbook, "stones_" + new Date().getTime() + ".xlsx");
  //     }
  //   } else {
  //     await this.ConfigService.showToast("error", "Select format type");
  //     return true;
  //   }
  // }

  async compare(row) {
    if (this.compareStones.indexOf(row) == -1) {
      this.compareStones.push(row);
    } else if (this.compareStones.indexOf(row) >= -1) {
      let i = this.compareStones.indexOf(row);
      this.compareStones.splice(i, 1);
    }
    console.log("row", row, this.compareStones);
  }

  async segmentChanged(event) {
    console.log("segment", event.target.value);
    this.view = event.target.value;
  }

  async bookmark() {
    console.log("selectedStone", this.selectedStone);
  }

  currentRefinementsTranslate(items) {
    console.log("items", items);
    return items.map(item => ({
      ...item,
      highlighted: item.highlighted
    }));
  }

  async openImageVideo(value) {
    console.log("value", value);
    window.open(value, "_blank");
  }

  async saveSearches() {
    // this.showSaveSearch = false;
    // setTimeout(()=>{
    //   this.showSaveSearch = true;
    // },1000);
    if (this.tagName != null && this.tagName != "") {
      let objData = {
        searchTitle: this.tagName,
        searchString: this.states.toString()
      };
      // API call to add stone to cart & then open manageSearchPage
      let res = await this.httpApiService.addSavedSearches(objData);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        await this.ConfigService.showToast("success", data.message);
        this.tagName = "";
        await this.getAllSearches();
      }
    } else {
      await this.ConfigService.showToast("error", "Please add tag name");
    }
    // this.searchHistory.push({id: ++this.counter, name:this.tagName,state: this.states});
    console.log("search history", this.searchHistory);
  }

  async fetchSaveSearches() {
    this.searchHistory.forEach(search => {
      if (search.id == this.selectedSearch) {
        this.selectedSearchParameters = JSON.parse(search.searchString);
        let tmpArr = [];
        tmpArr.push(search);
        this.searchHistoryList = tmpArr;
      }
    });
    let jsonObj = JSON.parse(JSON.stringify(this.selectedSearchParameters));
    this.query = jsonObj;
    this.showMoreLess = "less";
    /*let searchParam = {"facets":this.selectedSearchParameters.disjunctiveFacets,"facetFilters": facetFilters,"query": this.selectedSearchParameters.query};
    this.index.search({state: this.selectedSearchParameters}, (err, content) => {
      if (err) {
        console.log(err);
        console.log(err.debugData);
        return;
      }
      this.modifiedHits = content.hits;
      console.log(content.hits);
    }); */
    // this.search = instantsearch({
    //   indexName: 'test1',
    //   searchClient: algoliasearch(
    //     'SGTTZIO938',
    //     'c428d99b1b938d5bb665765a24887456'
    //   ),
    //   searchParameters: this.selectedSearchParameters,
    //   searchFunction(helper) {
    //     if (helper.state.query) {
    //       helper.search();
    //     }
    //     console.log("helper",helper);
    //   }
    // });
    // const hits1 = hits({
    //   container: 'ngx-datatable',
    //   templates: {
    //     empty: 'No results were found for the query: . Try to remove some filters or change the search query.',
    //     item: `
    //     <ngx-datatable #dataTable id="myTableElementId" *ngIf="view=='all' && modifiedHits==undefined"
    //     [rows]="hits"
    //     [sortType]="'multi'"
    //     [headerHeight]="50"
    //     [footerHeight]="50"
    //     [rowHeight]="35"
    //     [offset]="0"
    //     [scrollbarH]="true"
    //     [selectionType]="'multiClick'"
    //     (activate)="setData($event)">
    //       <ngx-datatable-column *ngFor="let col of resultColumns; let i = index" [name]="col.name" [flexGrow]="0.7" [prop]="col.prop" [width]="130"
    //       [resizeable]="true"
    //       [sortable]="true"
    //       [draggable]="true">
    //         <!-- <ng-template ngx-datatable-header-template>
    //           {{ col.name }}
    //         </ng-template> -->
    //         <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
    //           {{ value }}
    //         </ng-template>
    //       </ngx-datatable-column>
    //       <ngx-datatable-column name="Action" [flexGrow]="0.7" prop="imageVidUrl" [width]="130">
    //         <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
    //           <a (click)="openImageVideo(row.imageVidUrl)"><ion-icon name="eye"></ion-icon></a>
    //           &nbsp;<a (click)="viewTags(row.id)"><ion-icon name="pricetags"></ion-icon></a>
    //         </ng-template>
    //       </ngx-datatable-column>
    //     </ngx-datatable>
    //     `
    //   },
    //   transformItems: items => items.map(item => item),
    //   transformData(items) {
    //     console.log("items",items);
    //   }
    // });
    // this.search.addWidget(hits1);
    // console.log("hits",hits1, this.search,hits1.getConfiguration());
    // this.search.start();
    // this.search.on('search', (data) => {
    //   // Do something on search
    //   console.log("search data",data);
    // });
    // this.search.on('render', (data) => {
    //   // Do something on render
    //   console.log("render data",data);
    // });
  }

  async viewTags(value) {
    console.log("value", value);
    const modal = await this.modalCtrl.create({
      component: ManageSearchPage,
      backdropDismiss: false,
      cssClass: "largePage",
      componentProps: {
        actionId: this.data.actionId,
        actionName: this.data.desc,
        view: "tags",
        stoneID: value
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async getAllSearches() {
    let res: any;
    res = await this.httpApiService.findAllSearch(this.data.actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      // this.showSaveSearch = true;
      this.searchHistory = data.data;
      console.log("search history", this.searchHistory);
    }
  }

  async deleteSaveSearches() {
    console.log("this.selectedSearch", this.selectedSearch);
    let res: any;
    res = await this.httpApiService.deleteSearch(this.selectedSearch);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      console.log("search history", data);
      this.searchHistoryList = [];
      this.showMoreLess = "less";
      await this.getAllSearches();
      await this.ConfigService.showToast("success", data.message);
    }
  }


  async export1() {
    this.ids = [];
    if (this.selectedExportedFormat) {
      if (this.selectedStone.length > 0) {
        this.selectedStone.forEach(element => {
          this.ids.push(element.id);
        });
        let jsonObj = {
          setOfStoneIds: this.ids,
          templateId: this.selectedExportedFormat
        }
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
      } else {
        await this.ConfigService.showToast('error', 'Please select stones')
      }
    } else {
      await this.ConfigService.showToast('error', 'Please select template')
    }
  }

  async export() {
    this.ids = [];
    if (this.selectedExportedFormat) {
      if (this.selectedStone.length > 0) {
        this.selectedStone.forEach(element => {
          this.ids.push(element.id);
        });
        let jsonObj = {
          setOfStoneIds: this.ids,
          templateId: this.selectedExportedFormat
        }
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
      } else {
        console.log(this.states)
        let mapOfSearchCriteria: any;
        let mapOfNumericCriteria: any;
        if (this.states && this.states.disjunctiveFacetsRefinements) {
          mapOfSearchCriteria = this.states.disjunctiveFacetsRefinements;
        }
        if (this.states && this.states.numericRefinements) {
          mapOfNumericCriteria = this.states.numericRefinements;
        }
        let jsonObj = {
          mapOfSearchCriteria: mapOfSearchCriteria,
          mapOfNumericCriteria: mapOfNumericCriteria,
          templateId: this.selectedExportedFormat
        }
        let res: any;
        res = await this.httpApiService.exportTemplate(jsonObj);
      }
    } else {
      await this.ConfigService.showToast('error', 'Please select template')
    }
  }


}
