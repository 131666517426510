import { Component, Input, ViewChild } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-update-rfid',
  templateUrl: './update-rfid.component.html',
  styleUrls: ['./update-rfid.component.scss']
})
export class UpdateRfidComponent implements ProcessActionComponent {
  @Input() data: any;

  public boardBaseUrl: any;
  public rfid: any;
  public stoneId: any;

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.getUserData()
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  async scanRfidToAssign() {
    let res: any;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      await this.loadingController.dismiss();
      if (data.type == "error") {
        await this.configService.showToast("error", data.type);
      } else {
        if (data.result.length > 0) {
          if (data.result.length == 1) {
            this.rfid = data.result[0];
          } else {
            await this.configService.showToast("error", "Multiple RFIDs found. Please set anyone and try again.");
          }
        } else {
          await this.configService.showToast("error", "RFID not found.");
        }
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found.");
    }
  }

  async confirmUpdateRfidStatus() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: " Are you sure you want to update? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.checkRfidStatus();
          }
        }
      ]
    });

    await alert.present();
  }

  async checkRfidStatus() {
    if (!!this.rfid) {
      let jsonObj = {
        rfid: this.rfid,
        stoneId: this.stoneId,
        isUpdate: false
      }
      await this.configService.showLoader()
      let res: any;
      res = await this.httpApiService.assignRfidToStone(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.confirmUpdateRfid(data.error)
      } else {
        await this.configService.showToast("success", data.message);

      }
    } else {
      await this.configService.showToast("error", 'Please scan RFID or add manually to check status.');
    }
  }

  async confirmUpdateRfid(message) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: message + " Are you sure you want to change RFID ? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.assignRfidToStone();
          }
        }
      ]
    });

    await alert.present();
  }

  async assignRfidToStone() {
    if (!!this.stoneId) {
      let res: any;
      let jsonObj = {
        rfid: this.rfid,
        stoneId: this.stoneId,
        isUpdate: true
      }
      res = await this.httpApiService.assignRfidToStone(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.stoneId = null;
        this.rfid = null;
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", 'Please enter stoneId');
    }
  }

}