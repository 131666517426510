import { Component, OnInit, Input, HostListener } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ManageCertificationPage } from "../manage-certification/manage-certification.page";
import { ManageExternalInventoryStonesPage } from "../manage-external-inventory-stones/manage-external-inventory-stones.page"

import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";

@Component({
  selector: "app-issue-received",
  templateUrl: "./issue-received.component.html",
  styleUrls: ["./issue-received.component.scss"]
})
export class IssueReceivedComponent implements OnInit {
  @Input() actionFor: any;
  @Input() getActionName: any;

  public setActionIdForSDIX = "SDIX";
  public setActionIdForCertification = "Certification";
  public setActionIdForConsignmentInStock = "Consignment In Stock";
  public setActionIdForConsignmentConfirm = "Consignment Confirm";
  public setActionIdForCustomerConfirm = "Customer Confirm";
  public setActionIdForMemoClient = "Memo Client";
  public setActionIdForMemoService = "Memo Service";
  public setActionIdForObservation = "Observation";
  public setActionIdForRecut = "Recut";
  public setActionIdForCustomerHold = "Customer Hold";

  public segmentList = [];
  public pageNumber = 0;
  public noOfRecords = 10000;
  public certPageNumber = 0;
  public certNoOfRecords = 10000;
  public selectStone: any;
  public columns: any;
  public columns1: any;
  public columns2: any;
  public columns3: any;
  public voucherColumn: any;
  public columnsCommon: any;
  public columnsInHouse: any;
  public columnCertifiactionIncomingReceived: any;
  public receivedColumnCommon: any;
  public voucherColumnForCertifiaction: any;
  public columnsCertHistory: any;
  public requestStoneList: any;
  public uploadedStoneList: any;
  public receivedStoneList: any;
  public poVoucherList: any = [];
  public soldStoneList: any;
  public labList: any;
  public items: any;
  public count: number = 0;
  public totalPages: any;
  public tab = "Issue"
  public data: any;
  public consignmentTab = "Issue";
  public actionName: any;
  public historyData = [];
  public countCert: number = 0;
  public option = "TRANSACTION WISE"
  public totalPagesCert: any
  public page = "certification";
  public optionList = []
  public labReferenceId: any;
  public listOfCustomers: any;
  public selected = [];
  public selectedCustomer: any;
  public listOfActions = [];
  public userData: any;
  public voucherBookId: any;
  public partyType = []
  public selectedCustomerList = []
  //view variable

  public keyFun: any = true;
  public openView: any = false;

  constructor(
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public authService: AuthenticationService,
    public alertController: AlertController,
    public configService: ConfigService,
    public event: Events
  ) { }

  async ngOnInit() {
    await this.getUserData();
    await this.getAllActions();
    console.log(this.getActionName)
    this.getIdByActionName(this.getActionName)
    this.voucherColumn = [
      { name: "Voucher No", prop: "" },
      { name: "Ct.", prop: "totalCarat" },
      { name: "Customer Name", prop: "partyName" },
      { name: "By", prop: "createdBy" },
      { name: "Date & Time", prop: "modifiedDate" }

    ];
    this.voucherColumnForCertifiaction = [
      { name: "Voucher No", prop: "" },
      { name: "Ct.", prop: "totalCarat" },
      { name: "Lab", prop: "partyName" },
      { name: "By", prop: "createdBy" },
      { name: "Date & Time", prop: "modifiedDate" }

    ];
    this.columns3 = [
      { name: "Voucher No", prop: "bookSerialNumber" },
      { name: "Ct", prop: "totalCarat" },
      { name: "Party Name", prop: "partyName" },
      { name: "Created By", prop: "createdBy" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    this.columns2 = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "Consignment No", prop: "voucherLabel" },
      { name: "Customer", prop: "partyName" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    this.columnsCommon = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "Customer", prop: "partyName" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    this.receivedColumnCommon = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    this.columnCertifiactionIncomingReceived = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "Lab", prop: "partyName" },
      { name: "Date & Time", prop: "createdDate" }
    ]


    this.columnsInHouse = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "User", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];

    this.columns1 = [
      { name: "Sr No", prop: "" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Ct", prop: "stoneCts" },
      { name: "Lab", prop: "partyName" },
      { name: "Date & Time", prop: "createdDate" }
    ];



    this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: true }, { id: 2, name: 'STONE/CSSP WISE', isSelected: false }]

    this.event.subscribe(this.getActionName, (item) => {
      this.pageNumber = 0;
      this.noOfRecords = 10000;
      this.certNoOfRecords = 10000;
      this.certPageNumber = 0;
      this.viewDataByOption(this.optionList)
      if (this.tab == "LabResultUploaded") {
        this.columns = this.columns1;
        this.getAllUploadedStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == "Incoming") {
        if (this.getActionName == this.setActionIdForSDIX) {
          this.columns = this.columnCertifiactionIncomingReceived;
        } else if (this.getActionName == this.setActionIdForCertification) {
          this.columns = this.columnCertifiactionIncomingReceived;
        } else {
          this.columns = this.columnsCommon;
        }
        this.getAllInComingStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == "received") {
        if (this.getActionName == this.setActionIdForSDIX) {
          this.columns = this.columnCertifiactionIncomingReceived;
        } else if (this.getActionName == this.setActionIdForCertification) {
          this.columns = this.columnCertifiactionIncomingReceived;
        } else {
          this.columns = this.receivedColumnCommon;
        }
        this.getReceivedAllStone(this.pageNumber, this.noOfRecords);
      } else if (this.tab == "PO") {
        this.columns = this.voucherColumn;
        this.getAllPOVoucher(this.pageNumber, this.noOfRecords, null);
      } else if (this.tab == "Offer") {
        this.columns = this.columns3;
        this.getAllSoldStone(this.pageNumber, this.noOfRecords);
      }
    })

    if (this.getActionName == this.setActionIdForSDIX) {
      this.columns = this.columns1;
      this.actionName = "SDIX";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "Offer", title: "Offer" }
      ];
    } else if (this.getActionName == this.setActionIdForCertification) {
      this.columns = this.columns1;
      this.actionName = "certification";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "LabResultUploaded", title: "Lab Result Uploaded" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
      ];
      console.log("data Empty");
    } else if (this.getActionName == this.setActionIdForConsignmentInStock) {
      this.columns = this.columns2;
      this.actionName = "Consignment In Stock";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "PO", title: "PO" },
        { name: "Offer", title: "Offer" }
      ];
    } else if (this.getActionName == this.setActionIdForConsignmentConfirm) {
      this.columns = this.columns2;
      this.actionName = "Consignment Confirm";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "PO", title: "PO" },
        { name: "Offer", title: "Offer" }
      ];
    } else if (this.getActionName == this.setActionIdForCustomerConfirm) {
      this.columns = this.columnsCommon;
      this.actionName = "Customer Confirm";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "PO", title: "PO" },
        { name: "Offer", title: "Offer" }
      ];
    } else if (this.getActionName == this.setActionIdForMemoClient) {
      this.columns = this.columnsCommon;
      this.actionName = "Memo Client";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "PO", title: "PO" },
        { name: "Offer", title: "Offer" }
      ];
    } else if (this.getActionName == this.setActionIdForMemoService) {
      this.columns = this.columnsCommon;
      this.actionName = "Memo Service";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" }
      ];
    } else if (this.getActionName == this.setActionIdForObservation) {
      this.columns = this.columnsInHouse;
      this.actionName = "Observation";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" }
      ];
    } else if (this.getActionName == this.setActionIdForRecut) {
      this.columns = this.columnsInHouse;
      this.actionName = "Recut";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" }
      ];
    } else if (this.getActionName == this.setActionIdForCustomerHold) {
      this.columns = this.columnsCommon;
      this.actionName = "Customer Hold";
      this.segmentList = [
        { name: "Issue", title: "Issue" },
        { name: "Incoming", title: "Incoming" },
        { name: "received", title: "Received" },
        { name: "PO", title: "PO" },
        { name: "Offer", title: "Offer" }
      ];
    }
    if (this.consignmentTab == "Issue") {
      if (this.option == 'TRANSACTION WISE') {
        if (this.getActionName == this.setActionIdForSDIX) {
          this.columns = this.voucherColumnForCertifiaction;
        } else if (this.getActionName == this.setActionIdForCertification) {
          this.columns = this.voucherColumnForCertifiaction;
        } else {
          this.columns = this.voucherColumn;
        }
        this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null)
      } else if (this.option == 'STONE/CSSP WISE') {
        console.log(this.getActionName, this.setActionIdForCertification)
        if (this.getActionName == this.setActionIdForSDIX) {
          this.columns = this.columns1;
        } else if (this.getActionName == this.setActionIdForCertification) {
          this.columns = this.columns1;
        } else if (this.getActionName == this.setActionIdForConsignmentInStock) {
          this.columns = this.columns2;
        } else if (this.getActionName == this.setActionIdForConsignmentConfirm) {
          this.columns = this.columns2;
        } else if (this.getActionName == this.setActionIdForCustomerConfirm) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForMemoClient) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForMemoService) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForRecut) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForCustomerHold) {
          this.columns = this.columnsCommon;
        }
        else if (this.getActionName == this.setActionIdForObservation) {
          this.columns = this.columnsCommon;
        }
        await this.getAllRequestStone(this.pageNumber, this.noOfRecords);
      }
    }

    this.consignmentTab = "Issue";
    this.tab = "Issue";
  }


  async getIdByActionName(actionName) {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == actionName) {
        return item.actionName;
      }
    });
    console.log(actionArray)
    if (actionArray.length > 0) {
      await this.getDefaultSetting(actionArray[0].id);
    }

  }

  ngOnDestroy() {
    this.event.unsubscribe(this.getActionName);
  }


  async closeViewFun() {
    this.openView = false;
  }

  async viewDataByOption(optionList) {
    this.selectedCustomerList = []
    optionList.forEach(el => {
      if (el.isSelected == true) {
        this.option = el.name;
      }
    })
    console.log(this.option)
    this.pageNumber = 0;
    this.noOfRecords = 10000;
    if (this.option == 'TRANSACTION WISE') {
      if (this.tab == 'Issue') {
        this.columns = this.voucherColumn;
        this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null)
      }

    } else if (this.option == 'STONE/CSSP WISE') {
      if (this.tab == 'Issue') {
        if (this.getActionName == this.setActionIdForSDIX) {
          this.columns = this.columns1;
        } else if (this.getActionName == this.setActionIdForCertification) {
          this.columns = this.columns1;
        } else if (this.getActionName == this.setActionIdForConsignmentInStock) {
          this.columns = this.columns2;
        } else if (this.getActionName == this.setActionIdForConsignmentConfirm) {
          this.columns = this.columns2;
        } else if (this.getActionName == this.setActionIdForCustomerConfirm) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForMemoClient) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForMemoService) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForRecut) {
          this.columns = this.columnsCommon;
        } else if (this.getActionName == this.setActionIdForCustomerHold) {
          this.columns = this.columnsCommon;
        }
        else if (this.getActionName == this.setActionIdForObservation) {
          this.columns = this.columnsCommon;
        }
        await this.getAllRequestStone(this.pageNumber, this.noOfRecords);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(this.keyFun)
    if (this.keyFun) {
      if (this.tab == 'Issue' && this.setActionIdForMemoClient == "Memo Client") {
        if (event.keyCode == 86) {
          this.openView = true;
        }
      }
    }
  }

  async changeSegment(event) {
    console.log(event.detail.value);
    this.option = "TRANSACTION WISE"
    this.pageNumber = 0;
    this.selected = []
    this.selectedCustomerList = []
    this.noOfRecords = 10000;
    this.certNoOfRecords = 10000;
    this.certPageNumber = 0;
    this.tab = event.detail.value;
    if (event.detail.value == "Issue") {
      this.selectedCustomer = null;
      if (this.getActionName == this.setActionIdForSDIX) {
        this.columns = this.voucherColumnForCertifiaction;
      } else if (this.getActionName == this.setActionIdForCertification) {
        this.columns = this.voucherColumnForCertifiaction;
      } else if (this.getActionName == this.setActionIdForConsignmentInStock) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForConsignmentConfirm) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForCustomerConfirm) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForRecut) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForCustomerHold) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForObservation) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForMemoClient) {
        this.columns = this.voucherColumn;
      } else if (this.getActionName == this.setActionIdForMemoService) {
        this.columns = this.voucherColumn;
      }
      await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null);
    } else if (event.detail.value == "LabResultUploaded") {
      this.columns = this.columns1;
      await this.getAllUploadedStone(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "Incoming") {
      if (this.getActionName == this.setActionIdForSDIX) {
        this.columns = this.columnCertifiactionIncomingReceived;
      } else if (this.getActionName == this.setActionIdForCertification) {
        this.columns = this.columnCertifiactionIncomingReceived;
      } else {
        this.columns = this.columnsCommon;
      }
      await this.getAllInComingStone(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "received") {
      if (this.getActionName == this.setActionIdForSDIX) {
        this.columns = this.columnCertifiactionIncomingReceived;
      } else if (this.getActionName == this.setActionIdForCertification) {
        this.columns = this.columnCertifiactionIncomingReceived;
      } else {
        this.columns = this.receivedColumnCommon;
      }
      await this.getReceivedAllStone(this.pageNumber, this.noOfRecords);
    } else if (event.detail.value == "PO") {
      this.selectedCustomer = null;
      this.columns = this.voucherColumn;
      await this.getAllPOVoucher(this.pageNumber, this.noOfRecords, null);
    } else if (event.detail.value == "Offer") {
      this.columns = this.columns3;
      this.getAllSoldStone(this.pageNumber, this.noOfRecords);
    }
  }



  //tab data
  async getAllRequestStone(pageNumber, noOfRecords) {
    await this.configService.showLoader()
    let res = await this.httpApiService.getAllRequest(
      this.actionName,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestStoneList = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      console.log("get All stone", this.requestStoneList);
      this.setData(this.requestStoneList);
    }
  }

  //Voucher wise incoming list
  async getAllRequestVoucherWise(pageNumber, noOfRecords, partyMasterId) {
    let res: any;
    await this.configService.showLoader()
    if (!!partyMasterId) {
      res = await this.httpApiService.findAllRequestVoucherWiseByPartyMasterId(
        this.actionName,
        pageNumber,
        noOfRecords,
        partyMasterId
      );
    } else {
      res = await this.httpApiService.findAllRequestVoucherWise(
        this.actionName,
        pageNumber,
        noOfRecords
      );
    }
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestStoneList = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      console.log("get All stone", this.requestStoneList);
      this.setData(this.requestStoneList);
    }
  }


  async getAllInComingStone(pageNumber, noOfRecords) {
    let res = await this.httpApiService.getAllInComingStone(
      this.actionName,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestStoneList = data.data.content;
      // this.items = this.listOfParameter;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      console.log("get All stone", this.requestStoneList);
      this.setData(this.requestStoneList);
    }
  }

  async getAllUploadedStone(pageNumber, noOfRecords) {
    let res = await this.httpApiService.getAllResultUploadedStone(
      this.actionName,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.uploadedStoneList = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      // this.items = this.listOfParameter;
      console.log("get uploadedStoneList", this.uploadedStoneList);
      this.setData(this.uploadedStoneList);
    }
  }

  async getAllSoldStone(pageNumber, noOfRecords) {
    let res = await this.httpApiService.getAllConfirmedVoucherWithStatus(
      this.actionName,
      'OfferConfirmed',
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.soldStoneList = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      console.log("get All stone", this.soldStoneList);
      this.setData(this.soldStoneList);
    }
  }

  async getAllPOVoucher(pageNumber, noOfRecords, partyMasterId) {
    let res: any;
    if (!!partyMasterId) {
      res = await this.httpApiService.getAllConfirmedVoucherWithStatusUsingPartyMasterId(
        this.actionName,
        'POConfirmed',
        partyMasterId,
        pageNumber,
        noOfRecords
      );
    } else {
      res = await this.httpApiService.getAllConfirmedVoucherWithStatus(
        this.actionName,
        'POConfirmed',
        pageNumber,
        noOfRecords
      );
    }
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.poVoucherList = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      this.setData(this.poVoucherList);
      console.log("get All PO Voucher", this.poVoucherList);
    }
  }

  async getReceivedAllStone(pageNumber, noOfRecords) {
    let res = await this.httpApiService.getReceivedAllStone(
      this.actionName,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.receivedStoneList = data.data.content;
      // this.items = this.listOfParameter;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      console.log("get All receivedStoneList stone", this.receivedStoneList);
      this.setData(this.receivedStoneList);
    }
  }

  async setPage(currentPage, segmentName) {
    this.pageNumber = currentPage.offset;
    if (segmentName == 'Issue') {
      if (this.option == 'STONE/CSSP WISE') {
        await this.getAllRequestStone(this.pageNumber, this.noOfRecords);
      } else {
        await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null)
      }
    } else if (segmentName == 'Incoming') {
      await this.getAllInComingStone(currentPage.offset, this.noOfRecords);
    } else if (segmentName == 'Received') {
      await this.getReceivedAllStone(currentPage.offset, this.noOfRecords);
    } else if (segmentName == 'Offer') {
      await this.getAllSoldStone(currentPage.offset, this.noOfRecords);
    } else if (segmentName == 'LabResultUploaded') {
      await this.getAllUploadedStone(currentPage.offset, this.noOfRecords);
    }
  }

  async setData(value) {
    this.items = []
    this.items = await value;
  }

  async filterItems(ev: any) {
    this.setData(this.requestStoneList);
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (
          item.stoneId &&
          item.stoneId
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.stoneCts &&
          item.stoneCts
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.voucherLabel &&
          item.voucherLabel
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.customer &&
          item.customer.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      this.setData(this.requestStoneList);
    }
  }

  async openModal(flag, item, title) {
    console.log("this.consignmentTab", this.consignmentTab, flag, title, item);
    if (
      this.consignmentTab == "LabResultUploaded" ||
      title == "upload" ||
      title == "return" || title == "Offer" || title == "labExpense"
    ) {
      if (item && item.type == "mouseenter") {
        //Stop event propagation and let onSelect() work
        item.event.stopPropagation();
      } else {
        if (!this.openView) {
          this.keyFun = false;
          if (!item) {
            item = undefined;
          } else {
            let labId: any;
            await this.getLabId(item.row.partyMasterId)
            item = { itemData: item.row, actionId: this.actionFor, labId: this.labReferenceId };
          }
          let css;
          if (flag == 2) {
            css = "requestPage";
          } else {
            css = "largePage";
          }
          console.log(item);
          let modalPage = await this.modalCtrl.create({
            component: ManageCertificationPage,
            backdropDismiss: false,
            componentProps: { type: flag, data: item, title: title, partyType: this.partyType },
            cssClass: css
          });
          modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
          return modalPage.present();
        } else {
          this.keyFun = true;
        }
      }
    } else if (this.consignmentTab == "Issue" && title == "Offer") {
      console.log("***")
      if (item && item.type == "mouseenter") {
        //Stop event propagation and let onSelect() work
        item.event.stopPropagation();
      } else {
        if (!this.openView) {
          this.keyFun = false;
          let modalPage = await this.modalCtrl.create({
            component: ManageCertificationPage,
            backdropDismiss: false,
            componentProps: { type: 4, data: item.row, title: title, partyType: this.partyType },
            cssClass: "extraLargePage"
          });
          modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
          return modalPage.present();
        } else {
          this.keyFun = true;
        }
      }
    } else {
    }
  }

  async getLabId(labReferenceId) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(labReferenceId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (!!data.data && data.data.labReferenceId) {
        this.labReferenceId = data.data.labReferenceId;
      }

      // this.items = this.listOfParameter;
      console.log("labList", this.labList);
    }

  }

  async handleModalDismiss(d) {
    this.keyFun = true;
    console.log(d);
    this.selected = [];
    if (d.data == 1 || d.data == 3) {
      if (this.tab == 'PO') {
        await this.getAllPOVoucher(this.pageNumber, this.noOfRecords, null);
      } else {
        await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null);
      }
    } else if (d.data == 2) {
      await this.getAllUploadedStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 4) {
      await this.getAllSoldStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 5) {
      await this.getAllUploadedStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 9) {
      await this.getAllInComingStone(this.pageNumber, this.noOfRecords);
    } else if (d.data == 10) {
      if (this.tab == 'PO') {
        await this.getAllPOVoucher(this.pageNumber, this.noOfRecords, null);
      } else {
        await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null);
      }
    }

    // await this.searchStoneMaster(this.selectedMaster);
  }

  async getStoneDetailByVocuherModal(flag, item, title) {
    console.log("item ", item);
    if (item && item.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      if (!this.openView) {
        this.keyFun = false;
        let modalPage = await this.modalCtrl.create({
          component: ManageExternalInventoryStonesPage,
          backdropDismiss: false,
          componentProps: { data: item.row, flag: flag, title: title, actionName: this.actionName },
          cssClass: "requestPage"
        });
        modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return modalPage.present();
      } else {
        this.keyFun = true;
      }
    }
  }

  async getAllCustomer(type) {
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCustomers = data.data;
      console.log("getAllCustomers", this.listOfCustomers);
    }
  }

  async dropDownSelection(ev) {
    console.log(ev);
    this.selected = [];
    if (this.option == 'STONE/CSSP WISE') {
      this.option = "TRANSACTION WISE"
      this.optionList = [{ id: 1, name: 'TRANSACTION WISE', isSelected: true }, { id: 2, name: 'STONE/CSSP WISE', isSelected: false }]
    }
    if (this.getActionName == this.setActionIdForSDIX) {
      this.columns = this.voucherColumnForCertifiaction;
    } else if (this.getActionName == this.setActionIdForCertification) {
      this.columns = this.voucherColumnForCertifiaction;
    } else if (this.getActionName == this.setActionIdForConsignmentInStock) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForConsignmentConfirm) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForCustomerConfirm) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForRecut) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForCustomerHold) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForObservation) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForMemoClient) {
      this.columns = this.voucherColumn;
    } else if (this.getActionName == this.setActionIdForMemoService) {
      this.columns = this.voucherColumn;
    }
    if (this.tab == 'Issue') {
      if (!!ev.idList && ev.idList.length > 0) {
        this.selectedCustomer = ev.idList[0];
        await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, ev.idList[0]);
      } else {
        this.selectedCustomer = null;
        await this.getAllRequestVoucherWise(this.pageNumber, this.noOfRecords, null);
      }
    } else if (this.tab == 'PO') {
      if (!!ev.idList && ev.idList.length > 0) {
        this.selectedCustomer = ev.idList[0];
        await this.getAllPOVoucher(this.pageNumber, this.noOfRecords, ev.idList[0]);
      } else {
        this.selectedCustomer = null;
        await this.getAllPOVoucher(this.pageNumber, this.noOfRecords, null);
      }
    }
  }

  onSelect(ev, itemList) {
    this.selected = ev.selected;
    console.log("this.selected ", this.selected);
  }

  async getStoneDetailByVoucherModal(flag, row, title) {
    console.log("getStoneDetailByVoucherModal ", flag, row, title);
    if (!this.openView) {
      this.keyFun = false;
      let modalPage = await this.modalCtrl.create({
        component: ManageExternalInventoryStonesPage,
        backdropDismiss: false,
        componentProps: { data: row, flag: flag, title: title, actionName: this.actionName },
        cssClass: "requestPage"
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return modalPage.present();
    } else {
      this.keyFun = true;
    }
  }

  async generatePO() {
    console.log("generate PO of ", this.selected);
    await this.getVoucherBook('PO');
    if (!!this.voucherBookId) {
      let setOfVoucherIds = [];
      this.selected.forEach(entry => {
        setOfVoucherIds.push(entry.id);
      });
      if (setOfVoucherIds.length > 0 && !!this.selectedCustomer) {
        await this.configService.showLoader();
        let res: any;
        let jsonObj = {
          voucherDate: moment().format("YYYY-MM-DD"),
          voucherBookId: this.voucherBookId,
          status: "POConfirmed",
          type: "PO Confirmed",
          setOfVoucherIds: setOfVoucherIds,
          partyMasterId: this.selectedCustomer,
          transferOnRequestStatus: "Pending"
        }
        console.log(jsonObj)
        res = await this.httpApiService.markMultipleStoneAsConfirmed(jsonObj);
        let data = res.json()
        await this.loadingController.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          await this.configService.showToast("success", data.message);
          let d = { data: 10 };
          await this.handleModalDismiss(d);
        }
      } else {
        await this.configService.showToast("error", 'Please select customer and vouchers.');
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }
  async markAsSold() {
    console.log("generate PO of ", this.selected);
    await this.getVoucherBook('Offer');
    if (!!this.voucherBookId) {
      let setOfVoucherIds = [];
      this.selected.forEach(entry => {
        setOfVoucherIds.push(entry.id);
      });
      if (setOfVoucherIds.length > 0 && !!this.selectedCustomer) {
        await this.configService.showLoader();
        let dataObj = {
          setOfVoucherIds: setOfVoucherIds,
          partyMasterId: this.selectedCustomer,
          voucherBookId: this.voucherBookId,
          remarks: "Offer Stone",
          status: "OfferConfirmed",
          type: 'Offer',
          voucherDate: moment().format("YYYY-MM-DD"),
          transferOnRequestStatus: "POConfirmed"
        };
        console.log(dataObj);
        let res = await this.httpApiService.markMultipleStoneAsConfirmed(dataObj);
        await this.loadingController.dismiss();
        let data = res.json();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 200) {
          await this.configService.showToast("success", data.message);
          let d = { data: 10 };
          await this.handleModalDismiss(d);
        }
      } else {
        await this.configService.showToast("error", 'Please select customer and vouchers.');
      }
    } else {
      await this.configService.showToast("error", 'Voucherbook not found');
    }
  }
  async getAllActions() {
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
    }
  }

  async getUserData() {
    this.userData = await this.authService.userData();
  }

  async getVoucherBook(actionName) {
    let actionArray = this.listOfActions.filter(function (item) {
      if (item.actionName == actionName) {
        return item.actionName;
      }
    });
    console.log(actionArray)
    if (actionArray.length > 0) {
      await this.getDefaultSetting(actionArray[0].id);
    }
  }

  async getDefaultSetting(actionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(
      this.userData.userId,
      actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.partyType) {
          this.partyType = JSON.parse(dataValue.map.partyType)
          if (this.partyType.length > 0) {
            await this.getAllCustomer(this.partyType);
          }
        }
      }
      console.log(this.voucherBookId);
    }
  }
}
