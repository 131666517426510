import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageTemplatesPage } from "../../../components/actions/manage-templates/manage-templates.page";

@Component({
  selector: 'app-external-inventory',
  templateUrl: './external-inventory.component.html',
  styleUrls: ['./external-inventory.component.scss']
})
export class ExternalInventoryComponent implements ProcessActionComponent {
  @Input() data: any;

  public userData: any;
  public listOfActions = [];
  public flag = "externalInventory";
  public actionName: any;
  public actionId: any;

  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public event: Events) { }

  async ngOnInit() {
    await this.getAllActions()
    this.event.subscribe('External Inventory', (item) => {
      if (this.flag == 'externalInventory') {
        this.getAllActions();
      } else {
        this.event.publish(this.actionName, this.actionName);
      }
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('External Inventory')
  }



  async getAllActions() {
    this.listOfActions = []
    await this.configService.showLoader()
    let res: any;
    this.userData = await this.authService.userData();
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        dataValue.forEach(el => {
          if (el.actionName.toLowerCase() == 'consignment confirm' || el.actionName.toLowerCase() == 'consignment in stock' || el.actionName.toLowerCase() == 'customer confirm' || el.actionName.toLowerCase() == 'memo client'
            || el.actionName.toLowerCase() == 'memo service'
            || el.actionName.toLowerCase() == 'customer hold' || el.actionName.toLowerCase() == 'sdix' || el.actionName.toLowerCase() == 'certification' || el.actionName.toLowerCase() == 'observation'
            || el.actionName.toLowerCase() == 'recut') {
            this.listOfActions.push(el)
          }
        })
        await this.listOfActions.sort(function (a, b) {
          let nameA = a.actionName.toLowerCase()
          let nameB = b.actionName.toLowerCase()
          if (nameA < nameB) //sort string ascending
            return -1
          if (nameA > nameB)
            return 1
          return 0 //default return value (no sorting)
        })
      }
      console.log("list of actions", this.listOfActions);
    }
  }

  async changeViewType(type, item) {
    console.log(item)
    this.flag = type;
    console.log(this.flag)
    if (item != 0) {
      this.actionId = item.id;
      this.actionName = item.actionName;
    }
  }
}
