import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageConversionRateMasterPage } from "../manage-conversion-rate-master/manage-conversion-rate-master.page";

@Component({
  selector: 'app-conversion-rate-master',
  templateUrl: './conversion-rate-master.component.html',
  styleUrls: ['./conversion-rate-master.component.scss']
})
export class ConversionRateMasterComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() type: any;

  public columns = [];
  public page = 'conversionRate';
  public listOfConversionRate = [];
  public count = 0;
  public items = [];
  public currencyId: any;
  public currencyList = []
  public currencyIdList = []
  public manageSelectedConversionRate = '';

  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public event: Events, ) { }


  ngOnInit() {
    this.getAllCurrency();
    if (this.type == 'lab') {
      this.columns = [{ name: "Sr No", prop: "" }, { name: "From Date", prop: "fromDate" }, { name: "From Currency", prop: "fromCurrencyName" }, { name: "To Currency", prop: "toCurrencyName" }, { name: "Rate", prop: "rate" }, { name: "Lab", prop: "lab" }, { name: "Action", prop: "" },]
      this.labWiseConversionRate();
      this.event.subscribe('Lab Forex Rate Updater', (item) => {
        this.labWiseConversionRate();
      })
    } else {
      this.type = 'master'
      this.columns = [{ name: "Sr No", prop: "" }, { name: "From Date", prop: "fromDate" }, { name: "From Currency", prop: "fromCurrencyName" }, { name: "To Currency", prop: "toCurrencyName" }, { name: "Rate", prop: "rate" }, { name: "Spread", prop: "spread" }, { name: "Action", prop: "" },]
      this.getAllConversionRate();
      this.event.subscribe('Forex Rate Updater', (item) => {
        this.getAllConversionRate();
      })
    }
  }

  ngOnDestroy() {
    if (this.type == 'lab') {
      this.event.unsubscribe('Lab Forex Rate Updater')
    } else {
      this.event.unsubscribe('Forex Rate Updater')
    }
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'currencyName') {
      if (idList.length > 0) {
        this.currencyId = idList[0];
      } else {
        this.currencyId = null
      }
    }
  }

  setItems() {
    this.items = this.listOfConversionRate;
    this.count = this.listOfConversionRate.length;
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.type && item.type.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.count = this.items.length;
    } else {
      await this.setItems();
    }
  }

  async getAllCurrency() {
    this.currencyIdList = [];
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getCurrencyList();
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.currencyList = data.data;
      if (this.currencyId) {
        this.currencyList.filter(el => {
          if (el.id == this.currencyId) {
            this.currencyIdList = []
            this.currencyId = el.id;
            this.currencyIdList.push({ id: el.id, name: el.name })
          }
        })
      }
    }
  }

  async getAllConversionRate() {
    let res: any;
    res = await this.httpApiService.getConversionRateList();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfConversionRate = data.data;
      this.setItems();
      this.count = this.listOfConversionRate.length;
    }
  }

  async labWiseConversionRate() {
    let res: any;
    res = await this.httpApiService.getLabConversionRate();
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfConversionRate = data.data;
      this.setItems();
      this.count = this.listOfConversionRate.length;
    }
  }

  async manageConversionRate(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      let modal = await this.modalCtrl.create({
        component: ManageConversionRateMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, type: this.type }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    console.log(d.data)
    if (d.data == 'addCurrency' || d.data == 'updateCurrency') {
      await this.getAllCurrency();
      if (this.type == 'lab') {
        await this.labWiseConversionRate();
      } else {
        await this.getAllConversionRate();
      }
    } else if (d.data == 'addConversionRate' || d.data == 'updateConversionRate') {
      if (this.type == 'lab') {
        await this.labWiseConversionRate();
      } else {
        await this.getAllConversionRate();
      }
    }
  }

  //Start Delete Policy 

  async confirmDeleteConversionRate(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Conversion Rate ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteConversionRate(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteConversionRate(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deleteConversionRate(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      if (this.type == 'lab') {
        this.labWiseConversionRate();
      } else {
        this.getAllConversionRate();
      }
    }
  }

  //END

  //Start Delete Block

}
