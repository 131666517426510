import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, LoadingController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-price-name-master",
  templateUrl: "./price-name-master.page.html",
  styleUrls: ["./price-name-master.page.scss"]
})
export class PriceNameMasterPage implements OnInit {
  public name = "";
  public id: any;
  public isModal: any;
  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) {
    this.isModal = this.navParams.get('isModal');
    if (this.isModal == 'update') {
      let dataValue = this.navParams.get('item');
      this.name = dataValue.name;
      this.id = dataValue.id;
    }
  }

  ngOnInit() { }
  async addName() {
    let validate = await this.validateControls();
    console.log(validate)
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name
      };
      console.log(jsonObj)
      res = await this.httpApiService.AddPriceNameMaster(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.name = '';
        this.modalCtrl.dismiss();
      }
    }
  }

  async updateName() {
    let validate = await this.validateControls();
    console.log(validate)
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        name: this.name
      };
      console.log(jsonObj)
      res = await this.httpApiService.updatePriceNameMaster(this.id, jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.name = '';
        this.modalCtrl.dismiss();
      }
    }
  }

  validateControls() {
    console.log(this.name)
    if (this.name != null && this.name != undefined && this.name != "") {
      return true;
    } else {
      return false;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }
}
