import { Component, OnInit } from "@angular/core";
import { ModalController, LoadingController, NavParams } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";



@Component({
  selector: "app-pd-assort-details",
  templateUrl: "./pd-assort-details.page.html",
  styleUrls: ["./pd-assort-details.page.scss"]
})
export class PdAssortDetailsPage implements OnInit {
  public rowData: any;
  public assormentData = [];
  public columns = [];
  public headers = [];
  public page = 'AssortPDResult';
  public count = 0;
  public defaultVotesValue = [];

  constructor(public modalCtrl: ModalController, public navParams: NavParams, public httpApiService: HttpApiService, private authService: AuthenticationService, public configService: ConfigService,
  ) {
    this.rowData = this.navParams.get("rowData");
  }

  ngOnInit() {
    this.pdAssortData();
  }

  async pdAssortData() {
    let id = this.rowData.id
    let res = await this.httpApiService.findResultOfPDAssort(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.headers = dataValue.header;
      this.defaultVotesValue = dataValue.defaultVotesValue;
      for (let i = 0; i < this.headers.length; i++) {
        this.columns.push({ name: this.headers[i], prop: this.headers[i] })
      }
      this.assormentData = dataValue.listOfParamValue;
      this.count = this.assormentData.length;

      console.log("assortment data is",this.defaultVotesValue, this.columns, this.headers, this.assormentData);
    }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
