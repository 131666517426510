import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { FixedPolicyMatrixPage } from '../../actions/fixed-policy-matrix/fixed-policy-matrix.page';

@Component({
  selector: "app-manage-policy-generator",
  templateUrl: "./manage-policy-generator.page.html",
  styleUrls: ["./manage-policy-generator.page.scss"]
})
export class ManagePolicyGeneratorPage implements OnInit {
  public id: any;
  public isModal: any;
  public policyName: any;
  public selectedFile: File = null;
  public allPriceNameList = [];
  public priceListNameMasterId: any;
  public name = "";
  public nameIdList = [];
  public pricePolicyMasterId: any;
  public remark: any
  public status: any;
  public createdDate: any;
  public modifiedDate: any;
  public includeLabExpenses: boolean = false;
  public type: any;
  public defaultPrice: Number;
  public replacePrice: Number;
  public blockOrder: any;
  public jsonMatrix: any = {};
  public flagRef = false;
  public replace: boolean = false;
  public default: boolean = false;
  public errorMsgList = [];
  public msgStatus: any;
  public fileName: any;
  public pricingParameterEffect: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  ngOnInit() {
    this.isModal = this.navParams.get("flgModal");
    console.log(this.isModal)
    if (this.isModal == 'addPolicy') {
      this.getAllPriceListName();
      this.blockOrder = this.navParams.get('blockOrder')
    } else if (this.isModal == 'updatePolicy') {
      let rowData = this.navParams.get("item");
      console.log(rowData)
      this.name = rowData.name;
      this.replacePrice = rowData.replacePrice;
      if (this.replacePrice != null) {
        this.replace = true;
      }
      this.includeLabExpenses = rowData.includeLabExpenses;
      this.defaultPrice = rowData.defaultPrice;
      if (this.defaultPrice != null) {
        this.default = true;
      }
      this.id = rowData.id;
    } else if (this.isModal == 'updateBlock') {
      let rowData = this.navParams.get("item");
      this.name = rowData.name;
      this.pricingParameterEffect = rowData.findToReplace;
      if (!this.pricingParameterEffect) {
        this.pricingParameterEffect = false
      }
      this.id = rowData.id;
      this.pricePolicyMasterId = this.navParams.get("pricePolicyMasterId");
    } else if (this.isModal == 'viewBlock') {
      let rowData = this.navParams.get("item");
      this.remark = rowData.remark;
      if (!!rowData.fileName) {
        this.fileName = rowData.fileName;
      }
      this.status = rowData.status;
      this.createdDate = rowData.createdDate;
      this.modifiedDate = rowData.modifiedDate;
    } else {
      this.pricePolicyMasterId = this.navParams.get("pricePolicyMasterId");
      console.log("@@@", this.pricePolicyMasterId)
    }
    // this.id = this.navParams.get("id");
    // console.log("inside ngOnInit", this.isModal, this.id);
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedPriceName") {
      if (idList.length > 0) {
        this.priceListNameMasterId = idList[0];
      } else {
        this.priceListNameMasterId = null;
      }
    }
  }

  closeModal() {
    if (this.flagRef) {
      this.modalCtrl.dismiss(this.isModal)
    } else {
      this.modalCtrl.dismiss('doNotRefresh');
    }
  }

  fileObject(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async AddPolicy() {
    let validate = await this.validateControls();
    if (validate) {
      let res: any;
      if (this.default == false) {
        this.defaultPrice = null;
      }
      if (this.replace == false) {
        this.replacePrice = null;
      }
      let jsonObj = {
        name: this.name,
        includeLabExpenses: this.includeLabExpenses,
        defaultPrice: this.defaultPrice,
        replacePrice: this.replacePrice
        // priceListNameMasterId: this.priceListNameMasterId
      }
      console.log(jsonObj)
      res = await this.httpApiService.addPolicy(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.nameIdList = [];
        this.name = '';
        this.priceListNameMasterId = null;
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.isModal)
      }
    }
  }

  async updatePolicy() {
    let validate = await this.validateControls();
    if (validate) {
      let res: any;
      if (this.default == false) {
        this.defaultPrice = null;
      }
      if (this.replace == false) {
        this.replacePrice = null;
      }
      let jsonObj = {
        name: this.name,
        includeLabExpenses: this.includeLabExpenses,
        defaultPrice: this.defaultPrice,
        replacePrice: this.replacePrice
        // priceListNameMasterId: this.priceListNameMasterId
      }
      console.log(this.id)
      res = await this.httpApiService.updatePolicy(this.id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.nameIdList = [];
        this.name = '';
        this.priceListNameMasterId = null;
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss(this.isModal)
      }
    }
  }

  async addBlocktoPolicy() {
    let validate = await this.validateBlockControl();
    console.log(validate)
    if (validate) {
      if (this.selectedFile) {
        let myJson = {
          name: this.name,
          blockOrder: this.blockOrder,
          pricePolicyMasterId: this.pricePolicyMasterId,
          findToReplace: this.pricingParameterEffect
        };
        console.log(myJson);
        const formData: FormData = new FormData();
        // if (this.selectedFile) {
        formData.append("file", this.selectedFile);
        // }
        formData.append("dataJson", JSON.stringify(myJson));
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.addBlocks(formData);
        console.log(res.json());
        await this.loadingController.dismiss();
        let uploadData = res.json();
        if (uploadData.status == 400) {
          await this.configService.showToast("error", uploadData.error);
        } else if (res.status == 500) {
          await this.configService.showToast("error", uploadData.error);
        } else {
          await this.configService.showToast("success", uploadData.message);
          this.selectedFile = null;
          this.pricePolicyMasterId = null;
          this.name = ''
          this.modalCtrl.dismiss(this.isModal);
        }
      } else {
        if (Object.keys(this.jsonMatrix).length > 0) {
          this.jsonMatrix.name = this.name;
          this.jsonMatrix.pricePolicyMasterId = this.pricePolicyMasterId;
          console.log(this.jsonMatrix);
          await this.configService.showLoader();
          let res: any;
          res = await this.httpApiService.addFixedPolicy(this.jsonMatrix);
          console.log(res.json());
          await this.loadingController.dismiss();
          let uploadData = res.json();
          if (uploadData.status == 400) {
            await this.configService.showToast("error", uploadData.error);
          } else if (res.status == 500) {
            await this.configService.showToast("error", uploadData.error);
          } else {
            await this.configService.showToast("success", uploadData.message);
            console.log(uploadData.data)
            if (!!uploadData.data) {
              this.errorMsgList = uploadData.data;
              this.msgStatus = true;
              this.flagRef = true;
            } else {
              this.selectedFile = null;
              this.pricePolicyMasterId = null;
              this.name = '';
              this.jsonMatrix = null;
              this.modalCtrl.dismiss(this.isModal);
            }
          }
        } else {
          await this.configService.showToast("error", 'Please upload File OR upload Matrix')
        }
      }
    }
  }

  async closeDiv() {
    this.msgStatus = false;
  }


  async updateBlockWithFile() {
    let validate = await this.validateUpdateBlock();
    console.log(validate)
    if (validate) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.updateBlockWithFile(this.id, this.name, formData, this.pricingParameterEffect);
      console.log(res.json());
      await this.loadingController.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.pricePolicyMasterId = null;
        this.name = ''
        this.modalCtrl.dismiss(this.isModal);
      }

    }
  }

  async updateBlocktoPolicy() {
    let validate = await this.validateUpdateBlock();
    console.log(validate)
    if (validate) {
      let myJson = {
        name: this.name,
        pricePolicyMasterId: this.pricePolicyMasterId
      };
      console.log(myJson)
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.updateBlocks(this.id, myJson);
      console.log(res.json());
      await this.loadingController.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.pricePolicyMasterId = null;
        this.name = ''
        this.modalCtrl.dismiss(this.isModal);
      }
    }
  }

  async validateControls() {
    console.log(this.name)
    if (this.name != undefined && this.name != '' && this.name != null) {
      return true;
    } else {
      await this.configService.showToast("error", "Please insert/select detail");
      return false;

    }
  }

  async validateUpdateBlock() {
    if (this.pricePolicyMasterId != null && this.pricePolicyMasterId != undefined
      && this.pricePolicyMasterId != '' && this.name != null && this.name != '' && this.name != undefined) {
      return true;
    } else {
      this.jsonMatrix = {}
      await this.configService.showToast("error", "Please insert detail");
      return false;

    }
  }

  async validateBlockControl() {
    console.log(this.pricePolicyMasterId, this.name)
    if (!!this.type) {
      if (this.pricePolicyMasterId != null && this.pricePolicyMasterId != undefined
        && this.pricePolicyMasterId != '' && this.name != null && this.name != '' && this.name != undefined) {
        return true;
      } else {
        this.jsonMatrix = {}
        await this.configService.showToast("error", "Please insert detail");
        return false;

      }
    } else {
      await this.configService.showToast("error", "Please select type");
      return false;
    }

  }

  async openPriceMatrix() {
    let modalPage = await this.modalCtrl.create({
      component: FixedPolicyMatrixPage,
      backdropDismiss: false,
      componentProps: { matrix: this.jsonMatrix },
      cssClass: "requestPage"
    })
    modalPage.onDidDismiss().then((d: any) => this.handleMatrixModalDismiss(d));
    await modalPage.present();
  }

  async handleMatrixModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.jsonMatrix = d.data;
    }
  }
}
