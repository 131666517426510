import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RolesComponent } from './actions/roles/roles.component';
import { SearchComponent } from './actions/search/search.component';
import { ManageRolesPage } from './actions/manage-roles/manage-roles.page';
import { StoneInwardComponent } from './actions/stone-inward/stone-inward.component';
import { ManageActionComponent } from './actions/manage-action/manage-action.component';
import { UserComponent } from './actions/user/user.component';
import { ManageUserPage } from './actions/manage-user/manage-user.page';
import { UploadStoneComponent } from './actions/upload-stone/upload-stone.component';
import { ManageUploadStonePage } from './actions/manage-upload-stone/manage-upload-stone.page';
import { MasterComponent } from './actions/master/master.component';
import { ManageMasterPage } from './actions/manage-master/manage-master.page';
import { FileUploadComponent } from './fileUpload/file-upload.component'
import { MomentPipe } from '../pipes/moment/moment.pipe';
import { StoneSearchComponent } from './actions/stone-search/stone-search.component';
import { ManageStoneInwardPage } from './actions/manage-stone-inward/manage-stone-inward.page';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AssortmentComponent } from './actions/assortment/assortment.component';
import { ManageAssortmentPage } from './actions/manage-assortment/manage-assortment.page';
import { InventoryManagerComponent } from './actions/inventory-manager/inventory-manager.component';
import { ManageInventoryManagerPage } from './actions/manage-inventory-manager/manage-inventory-manager.page';
import { BoxMasterComponent } from './actions/box-master/box-master.component';
import { ManageBoxMasterPage } from './actions/manage-box-master/manage-box-master.page';
import { ScanRFIDPage } from './actions/scan-rfid/scan-rfid.page';
import { TransferToManufactureComponent } from './actions/transfer-to-manufacture/transfer-to-manufacture.component'
import { NgAisModule } from 'angular-instantsearch';
import { InventoryComponent } from './actions/inventory/inventory.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { InventoryBoxComponent } from './actions/inventory-box/inventory-box.component';
import { InventoryErrorComponent } from './actions/inventory-error/inventory-error.component';
import { SortComponent } from './actions/sort/sort.component';
import { ManageSearchPage } from './actions/manage-search/manage-search.page';
import { SelectionOnSortPage } from './actions/selection-on-sort/selection-on-sort.page';
import { SSParameterComponent } from './actions/ss-parameter/ss-parameter.component';
import { CertificationComponent } from './actions/certification/certification.component';
import { ManageCertificationPage } from './actions/manage-certification/manage-certification.page'
import { ManageSsParameterPage } from './actions/manage-ss-parameter/manage-ss-parameter.page'
import { VoucherBookComponent } from './actions/voucher-book/voucher-book.component';
import { ManageVoucherBookPage } from './actions/manage-voucher-book/manage-voucher-book.page';
import { StoneInfoComponent } from './stone-info/stone-info.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SingleMultiDropdownComponent } from './actions/single-multi-dropdown/single-multi-dropdown.component';
import { ManageRequestsPage } from './actions/manage-requests/manage-requests.page';
import { CustomerComponent } from './actions/customer/customer.component';
import { ManageCustomerPage } from './actions/manage-customer/manage-customer.page';
import { ConsignmentComponent } from './actions/consignment/consignment.component';
import { IssueReceivedComponent } from './actions/issue-received/issue-received.component'
import { AccordionModule } from "ngx-accordion";
import { StonesWithMeComponent } from './actions/stones-with-me/stones-with-me.component';
import { PriceListUploaderComponent } from './actions/price-list-uploader/price-list-uploader.component';
import { TransferStoneComponent } from './actions/transfer-stone/transfer-stone.component';
import { TransferToSalesSingleComponent } from './actions/transfer-to-sales-single/transfer-to-sales-single.component';
import { TransferToSalesMixComponent } from './actions/transfer-to-sales-mix/transfer-to-sales-mix.component'
import { PricingPolicy } from './actions/pricing-policy/pricing-policy.component'
import { ManagePolicyGeneratorPage } from "./actions/manage-policy-generator/manage-policy-generator.page";
import { AssortParameterConfigComponent } from './actions/assort-parameter-config/assort-parameter-config.component';
import { SearchSingleStoneComponent } from './actions/search-single-stone/search-single-stone.component';
import { SearchMixStoneComponent } from './actions/search-mix-stone/search-mix-stone.component';
import { PdAssortDetailsPage } from "./actions/pd-assort-details/pd-assort-details.page";
import { StoneWithMeSingleComponent } from './actions/stone-with-me-single/stone-with-me-single.component';
import { StoneWithMeMixComponent } from './actions/stone-with-me-mix/stone-with-me-mix.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PriceNameMasterPage } from './actions/price-name-master/price-name-master.page';
import { ApplyPolicyComponent } from './actions/apply-policy/apply-policy.component';
import { ManageRulesPage } from './actions/manage-rules/manage-rules.page';
import { AssortmentSearchComponent } from './actions/assortment-search/assortment-search.component';
import { ClearAssortmentStockComponent } from './actions/clear-assortment-stock/clear-assortment-stock.component';
import { ExportTemplatesComponent } from './actions/export-templates/export-templates.component';
import { ManageTemplatesPage } from './actions/manage-templates/manage-templates.page';
import { ProgrammeComponent } from './actions/programme/programme.component';
import { ManageProgrammePage } from "./actions/manage-programme/manage-programme.page";
import { DefaultTemplatesComponent } from './actions/default-templates/default-templates.component';
import { PolicyTrackerComponent } from './actions/policy-tracker/policy-tracker.component';
import { PDComponent } from './actions/pd/pd.component';
import { SdixComponent } from './actions/sdix/sdix.component';
import { FixedPolicyMatrixPage } from './actions/fixed-policy-matrix/fixed-policy-matrix.page';
import { StatusMasterComponent } from './actions/status-master/status-master.component';
import { ManageStatusPage } from './actions/manage-status/manage-status.page';
import { ParameterMappingMasterComponent } from './actions/parameter-mapping-master/parameter-mapping-master.component';
import { ManageParameterMappingMasterPage } from './actions/manage-parameter-mapping-master/manage-parameter-mapping-master.page';
import { UpdateParameterComponent } from './actions/update-parameter/update-parameter.component';
import { CustomerConfirmComponent } from './actions/customer-confirm/customer-confirm.component';
import { MemoServiceComponent } from './actions/memo-service/memo-service.component';
import { ConsignmentConfirmComponent } from './actions/consignment-confirm/consignment-confirm.component';
import { ConsignmentInStockComponent } from './actions/consignment-in-stock/consignment-in-stock.component';
import { MemoClientComponent } from './actions/memo-client/memo-client.component';
import { LabExpensesComponent } from './actions/lab-expenses/lab-expenses.component';
import { ExternalInventoryComponent } from './actions/external-inventory/external-inventory.component';
import { RecutComponent } from './actions/recut/recut.component';
import { ObservationComponent } from './actions/observation/observation.component';
import { PricingSizeMasterComponent } from './actions/pricing-size-master/pricing-size-master.component';
import { ManagePricingSizeMasterPage } from './actions/manage-pricing-size-master/manage-pricing-size-master.page';
import { ConversionRateMasterComponent } from './actions/conversion-rate-master/conversion-rate-master.component';
import { ManageConversionRateMasterPage } from './actions/manage-conversion-rate-master/manage-conversion-rate-master.page';
import { LabConversionRateMasterComponent } from './actions/lab-conversion-rate-master/lab-conversion-rate-master.component';
import { ViewComponent } from './actions/view/view.component';
import { CompanyComponent } from './actions/company/company.component';
import { ManageCompanyPage } from './actions/manage-company/manage-company.page';
import { UpdateRfidComponent } from './actions/update-rfid/update-rfid.component';
import { DeliveryChallanComponent } from './delivery-challan/delivery-challan.component';
import { ManageExternalInventoryStonesPage } from './actions/manage-external-inventory-stones/manage-external-inventory-stones.page';
import { GeneratePOComponent } from './actions/generate-po/generate-po.component';
import { InterCSSPTransferComponent } from './actions/inter-cssptransfer/inter-cssptransfer.component';
import { AuditCSSPComponent } from './actions/audit-cssp/audit-cssp.component';
import { ApprovalMemoComponent } from './actions/approval-memo/approval-memo.component';
import { InterBoxTransferPage } from './actions/inter-box-transfer/inter-box-transfer.page';
import { BoxTransferComponent } from './actions/box-transfer/box-transfer.component';
import { SingleToMixComponent } from './actions/single-to-mix/single-to-mix.component';
import { SingleToMixTransferComponent } from './actions/single-to-mix-transfer/single-to-mix-transfer.component';
import { PriceClartiyMappingMasterComponent } from './actions/price-clartiy-mapping-master/price-clartiy-mapping-master.component';
import { ManagePriceClartiyMappingMasterPage } from './actions/manage-price-clartiy-mapping-master/manage-price-clartiy-mapping-master.page';
import { UploadBulkToSinglePage } from './actions/upload-bulk-to-single/upload-bulk-to-single.page';
import { MixToSingleTransferComponent } from './actions/mix-to-single-transfer/mix-to-single-transfer.component';
import { PurchaseHistoryComponent } from './actions/purchase-history/purchase-history.component';
import { RapaportMappingComponent } from './actions/rapaport-mapping/rapaport-mapping.component';
import { RapaportParameterMappingMasterPage } from './actions/rapaport-parameter-mapping-master/rapaport-parameter-mapping-master.page';
import { ManageFilterComponent } from './actions/manage-filter/manage-filter.component';
import { TermMasterComponent } from './actions/term-master/term-master.component';
import { ManageTermMasterPage } from './actions/manage-term-master/manage-term-master.page';
import { RapaportPriceListUploaderComponent } from './actions/rapaport-price-list-uploader/rapaport-price-list-uploader.component';
import { PolicyChainComponent } from './actions/policy-chain/policy-chain.component';
import { ManagePolicyChainPage } from './actions/manage-policy-chain/manage-policy-chain.page';
import { MixToSingleComponent } from './actions/mix-to-single/mix-to-single.component';
import { PurchaseToSingleComponent } from './actions/purchase-to-single/purchase-to-single.component';
import { PurchaseInwardComponent } from './actions/purchase-inward/purchase-inward.component';
import { ManagePurchaseInwardPage } from './actions/manage-purchase-inward/manage-purchase-inward.page';
import { SalesInventoryComponent } from './actions/sales-inventory/sales-inventory.component';
import { PartyParameterMappingComponent } from './actions/party-parameter-mapping/party-parameter-mapping.component';
import { PurchaseToMixComponent } from './actions/purchase-to-mix/purchase-to-mix.component';
import { UpdateStoneIdComponent } from './actions/update-stone-id/update-stone-id.component';
import { InventoryManagerSSComponent } from './actions/inventory-manager-ss/inventory-manager-ss.component';
import { InventoryManagerMIXComponent } from './actions/inventory-manager-mix/inventory-manager-mix.component';
import { UpdateBarcodeComponent } from './actions/update-barcode/update-barcode.component';
import { CustomerHoldComponent } from './actions/customer-hold/customer-hold.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DragulaModule,
    NgxDatatableModule,
    NgAisModule,
    NgMultiSelectDropDownModule,
    AccordionModule,
    NgxBarcodeModule,
  ],
  declarations: [
    RolesComponent,
    SearchComponent,
    ManageRolesPage,
    StoneInwardComponent,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    UploadStoneComponent,
    ManageUploadStonePage,
    MasterComponent,
    ManageMasterPage,
    FileUploadComponent,
    MomentPipe,
    StoneSearchComponent,
    ManageStoneInwardPage,
    AssortmentComponent,
    ManageAssortmentPage,
    InventoryManagerComponent,
    ManageInventoryManagerPage,
    BoxMasterComponent,
    ManageBoxMasterPage,
    ScanRFIDPage,
    TransferToManufactureComponent,
    InventoryComponent,
    InventoryBoxComponent,
    InventoryErrorComponent,
    SortComponent,
    ManageSearchPage,
    SelectionOnSortPage,
    SSParameterComponent,
    CertificationComponent,
    ManageCertificationPage,
    ManageSsParameterPage,
    VoucherBookComponent,
    ManageVoucherBookPage,
    StoneInfoComponent,
    SingleMultiDropdownComponent,
    ManageRequestsPage,
    CustomerComponent,
    ManageCustomerPage,
    ConsignmentComponent,
    IssueReceivedComponent,
    StonesWithMeComponent,
    PriceListUploaderComponent,
    TransferStoneComponent,
    TransferToSalesSingleComponent,
    TransferToSalesMixComponent,
    PricingPolicy,
    ManagePolicyGeneratorPage,
    AssortParameterConfigComponent,
    SearchSingleStoneComponent,
    SearchMixStoneComponent,
    PdAssortDetailsPage,
    StoneWithMeSingleComponent,
    StoneWithMeMixComponent,
    PriceNameMasterPage,
    ApplyPolicyComponent,
    ManageRulesPage,
    AssortmentSearchComponent,
    ClearAssortmentStockComponent,
    ExportTemplatesComponent,
    ManageTemplatesPage,
    ProgrammeComponent,
    ManageProgrammePage,
    DefaultTemplatesComponent,
    PolicyTrackerComponent,
    PDComponent,
    SdixComponent,
    FixedPolicyMatrixPage,
    StatusMasterComponent,
    ManageStatusPage,
    ParameterMappingMasterComponent,
    ManageParameterMappingMasterPage,
    UpdateParameterComponent,
    CustomerConfirmComponent,
    MemoServiceComponent,
    ConsignmentConfirmComponent,
    ConsignmentInStockComponent,
    MemoClientComponent,
    LabExpensesComponent,
    ExternalInventoryComponent,
    RecutComponent,
    ObservationComponent,
    PricingSizeMasterComponent,
    ManagePricingSizeMasterPage,
    ConversionRateMasterComponent,
    ManageConversionRateMasterPage,
    LabConversionRateMasterComponent,
    ViewComponent,
    CompanyComponent,
    ManageCompanyPage,
    UpdateRfidComponent,
    DeliveryChallanComponent,
    ManageExternalInventoryStonesPage,
    GeneratePOComponent,
    InterCSSPTransferComponent,
    AuditCSSPComponent,
    ApprovalMemoComponent,
    InterBoxTransferPage,
    BoxTransferComponent,
    SingleToMixComponent,
    SingleToMixTransferComponent,
    PriceClartiyMappingMasterComponent,
    ManagePriceClartiyMappingMasterPage,
    UploadBulkToSinglePage,
    MixToSingleTransferComponent,
    PurchaseHistoryComponent,
    RapaportMappingComponent,
    RapaportParameterMappingMasterPage,
    ManageFilterComponent,
    TermMasterComponent,
    ManageTermMasterPage,
    RapaportPriceListUploaderComponent,
    PolicyChainComponent,
    ManagePolicyChainPage,
    MixToSingleComponent,
    PurchaseToSingleComponent,
    PurchaseInwardComponent,
    ManagePurchaseInwardPage,
    SalesInventoryComponent,
    SalesInventoryComponent,
    PartyParameterMappingComponent,
    PurchaseToMixComponent,
    UpdateStoneIdComponent,
    InventoryManagerSSComponent,
    InventoryManagerMIXComponent,
    UpdateBarcodeComponent,
    CustomerHoldComponent
  ],
  entryComponents: [
    RolesComponent,
    SearchComponent,
    ManageRolesPage,
    StoneInwardComponent,
    ManageActionComponent,
    UserComponent,
    ManageUserPage,
    UploadStoneComponent,
    ManageUploadStonePage,
    MasterComponent,
    ManageMasterPage,
    StoneSearchComponent,
    ManageStoneInwardPage,
    AssortmentComponent,
    ManageAssortmentPage,
    InventoryManagerComponent,
    ManageInventoryManagerPage,
    BoxMasterComponent,
    ManageBoxMasterPage,
    ScanRFIDPage,
    TransferToManufactureComponent,
    InventoryComponent,
    InventoryBoxComponent,
    SortComponent,
    ManageSearchPage,
    SelectionOnSortPage,
    SSParameterComponent,
    CertificationComponent,
    ManageCertificationPage,
    ManageSsParameterPage,
    VoucherBookComponent,
    ManageVoucherBookPage,
    ManageRequestsPage,
    CustomerComponent,
    ManageCustomerPage,
    ConsignmentComponent,
    IssueReceivedComponent,
    StonesWithMeComponent,
    PriceListUploaderComponent,
    TransferStoneComponent,
    TransferToSalesSingleComponent,
    TransferToSalesMixComponent,
    PricingPolicy,
    ManagePolicyGeneratorPage,
    AssortParameterConfigComponent,
    SearchSingleStoneComponent,
    SearchMixStoneComponent,
    PdAssortDetailsPage,
    StoneWithMeSingleComponent,
    StoneWithMeMixComponent,
    PriceNameMasterPage,
    ApplyPolicyComponent,
    ManageRulesPage,
    AssortmentSearchComponent,
    ClearAssortmentStockComponent,
    ExportTemplatesComponent,
    ManageTemplatesPage,
    ProgrammeComponent,
    ManageProgrammePage,
    DefaultTemplatesComponent,
    PolicyTrackerComponent,
    PDComponent,
    SdixComponent,
    FixedPolicyMatrixPage,
    StatusMasterComponent,
    ManageStatusPage,
    ParameterMappingMasterComponent,
    ManageParameterMappingMasterPage,
    UpdateParameterComponent,
    CustomerConfirmComponent,
    MemoServiceComponent,
    ConsignmentConfirmComponent,
    ConsignmentInStockComponent,
    MemoClientComponent,
    LabExpensesComponent,
    ExternalInventoryComponent,
    RecutComponent,
    ObservationComponent,
    PricingSizeMasterComponent,
    ManagePricingSizeMasterPage,
    ConversionRateMasterComponent,
    ManageConversionRateMasterPage,
    LabConversionRateMasterComponent,
    ViewComponent,
    CompanyComponent,
    ManageCompanyPage,
    UpdateRfidComponent,
    DeliveryChallanComponent,
    ManageExternalInventoryStonesPage,
    GeneratePOComponent,
    InterCSSPTransferComponent,
    AuditCSSPComponent,
    ApprovalMemoComponent,
    InterBoxTransferPage,
    BoxTransferComponent,
    SingleToMixComponent,
    SingleToMixTransferComponent,
    PriceClartiyMappingMasterComponent,
    ManagePriceClartiyMappingMasterPage,
    UploadBulkToSinglePage,
    MixToSingleTransferComponent,
    PurchaseHistoryComponent,
    RapaportMappingComponent,
    RapaportParameterMappingMasterPage,
    ManageFilterComponent,
    TermMasterComponent,
    ManageTermMasterPage,
    RapaportPriceListUploaderComponent,
    PolicyChainComponent,
    ManagePolicyChainPage,
    MixToSingleComponent,
    PurchaseToSingleComponent,
    PurchaseInwardComponent,
    ManagePurchaseInwardPage,
    SalesInventoryComponent,
    PurchaseToMixComponent,
    UpdateStoneIdComponent,
    InventoryManagerSSComponent,
    InventoryManagerMIXComponent,
    UpdateBarcodeComponent,
    CustomerHoldComponent
  ],
  providers: [MomentPipe, DragulaService]
})
export class ComponentsModule { }
