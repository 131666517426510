import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-clear-assortment-stock',
  templateUrl: './clear-assortment-stock.component.html',
  styleUrls: ['./clear-assortment-stock.component.scss']
})
export class ClearAssortmentStockComponent implements ProcessActionComponent {
  @Input() data: any;
  public assorterList = [];
  public selectedAssorter = [];
  public assorterIdList = [];

  constructor(
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public event: Events,
  ) { }

  ngOnInit() {
    this.getAllAssorters();
    this.event.subscribe('Clear Assortment Stock', (item) => {
      this.getAllAssorters();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Clear Assortment Stock')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "clearAssorter") {
      if (idList.length > 0) {
        this.selectedAssorter = idList;
      }
      else {
        this.selectedAssorter = []
      }
    }
  }

  async getAllAssorters() {
    await this.configService.showLoader();
    let location = await this.configService.getFromLocation()
    let res = await this.httpApiService.getAllAssorters(
      location
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.assorterList = data.data;
    }
  }

  async confirmDisableAssorter() {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to clear Assorter stock?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.disableAssorter();
          }
        }
      ]
    });
    await alert.present();
  }

  async disableAssorter() {
    await this.configService.showLoader();
    let res: any;
    let jsonObj = {
      setOfVoterIds: this.selectedAssorter
    }
    res = await this.httpApiService.disableAssorter(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.assorterIdList = [];
      this.selectedAssorter = [];
      await this.configService.showToast("success", data.message);
    }
  }

}
