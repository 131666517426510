import { Injectable, ɵConsole } from '@angular/core';
import { Storage } from "@ionic/storage";
import { ConfigService } from '../config/config.service';
import { Headers, Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class HttpApiService {
  baseUrl: string;
  appName: string;
  headers: any;

  constructor(public http: Http,
    private storage: Storage,
    public configService: ConfigService) {
    this.getBaseUrl();
    this.getAppName();
    // this.getApiHeader();
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  async getAppName() {
    this.appName = await this.configService.getAppName();
  }

  async getApiHeader() {
    this.headers = await this.configService.getAPIHeader();
  }

  async getAllActionsByUserID(userId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/action/findAllActionsByUserId?userId=" + userId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllActions() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/action/findAll?appName=" + this.appName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllActionPermissionById(ActionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/action/findById?id=" + ActionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllRoles() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/role/findAll?appName=" + this.appName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRole(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/role/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addRolePermission(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/role_permission/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateRole(roleId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "user-service/api/role/update/" + roleId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRole(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "user-service/api/role/delete/" + roleId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRolePermission(roleId, actionPermissionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "user-service/api/role_permission/removeByRoleIdAndActionPermissionId/" + roleId + "/" + actionPermissionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRoleDetailsById(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/role/findById?id=" + roleId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  //Start Manage-User

  async getAllUser(fromLocation) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findAll?appName=" + this.appName + "&fromLocation=" + fromLocation, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUserDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findById?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUserDetailByIdForChangePasssword(id, tokenObj) {
    try {
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findById?id=" + id, { headers: new Headers(tokenObj) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async createUser(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/user/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteUserRole(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "user-service/api/user_role/removeRoleFromUser/" + jsonData.userId + "/" + jsonData.roleId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveRoleForUser(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/user_role/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async changePassword(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/user/resetPassword", jsonData).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async forgotPassword(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/user/forgotPassword", jsonData).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateUser(userId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "user-service/api/user/update/" + userId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  //Master CRUD 

  async getAllStoneMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-name/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneMasterDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-value/findByStoneParameterNameId?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStoneMasterDetailByName(paramName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-value/findByStoneParameterName?stoneParameterName=" + paramName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneParameterValue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone-parameter-value/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addStoneParameterName(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone-parameter-name/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteStoneParamValue(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/stone-parameter-value/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async UpdateStoneParameterValue(userId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/stone-parameter-value/update/" + userId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async uploadFile(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/uploadStonesIfNoErrors", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async downloadUploadedFile(fileId) {
    try {
      let url = this.baseUrl + "master-service/api/stone/csvDownload?fileName=" + fileId;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

  async saveOkRows(id, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/uploadStonesIfNoErrors?id=" + id
        , formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getUploadHistory(type, pageNumber, noOfRecords, search = '') {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/csv_upload_history/findByType?type=" + type + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords + "&search=" + search, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async getInwardUploadHistory(type, jsonInward, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/csv_upload_history/findByType?type=" + type + "&type=" + jsonInward + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllDepartments() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/department/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllLocations(fromLocation) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/company/findAll?fromLocation=" + fromLocation, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllUsersByCompany(appName, companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findByAppNameAndCompanyId?appName=" + appName + "&companyId=" + companyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDefaultSetting(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user_action_default/findByUserIdAndActionIdUsingDTO?userId=" + userId + "&actionId=" + actionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async transferStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/transferStone"
        , jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingStoneInward(voucherType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/pendingToReceive?voucherType=" + voucherType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPendingToReceiveByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPurchaseInStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPurchaseInStockByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async receivePendingStoneConfirmation(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/receive", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllAssignAssortment(pageNumber, noOfRecords) {
    // get all Assign Assortment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/assignedAssortment?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingAssortment(pageNumber, noOfRecords) {
    // get all Pending Assortment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/pendingForAssignment?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAssorters(location) {
    // get all Assorter list
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "/user-service/api/user/findByLocationAndRole?appName=lattice&roleName=assorter&location=" + location, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdPendingAssignment(voucherId) {
    // get all Pending (stone) Assignment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPendingForAssignmentByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async assignStoneAndAssorter(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/assortment_vote/createVoters", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async findAssignedAssortmentByVoucherId(voucherId) {
    // get all stone & assorter (stone) Assignment
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAssignedAssortmentByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteAssignment(voucherId) {
    // delete assortment_vote
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/assortment_vote/deleteAssignmentByVoucher/" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateVotersAndStone(voucherId, jsonData) {
    // update Voters & stone (Assorters)
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/assortment_vote/updateVoters/" + voucherId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllDepartment(appName) {
    // get All department detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/department/findAllByAppName?appName=" + appName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllDataFor(name) {
    // get all Shape/Color/Clarity detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-value/findByStoneParameterName?stoneParameterName=" + name, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBox(jsonData) {
    // Add Box
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/box/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async findByDepartmentId(deptId, pageNumber, noOfRecords) {
    // get all findByDepartmentId detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByDepartmentOrRoleId?departmentId=" + deptId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteBox(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/box/delete/" + boxId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findBoxById(boxId) {
    // get all Clarity detail
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findById?id=" + boxId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateBox(id, jsonData) {
    // update Box
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/box/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async pendingStones(pageNumber, noOfRecords) {
    // get pendingStones
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findInComming?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdOfPendingAssortmentStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPendingToReceiveByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async receivedStones(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/receivedForBoxing?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async findByVoucherIdOfReceivedAssortmentStocks(voucherId, boxType, boxLevel) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findByVoucherIdOfReceivedForBoxing?voucherId=" + voucherId + "&boxType=" + boxType + "&boxLevel=" + boxLevel, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async getBoxedStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/boxed?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addStoneToBox(jsonData) {
    // get addRFId
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/box/addStones", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByVoucherIdOfBoxedStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findByVoucherIdOfBoxedStocks?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoing(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findOutGoing?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findDelivered(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findDelivered?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async stoneIssue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/issue", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findUserActionDefaultsByUserIdAndActionIdUsingDTO(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user_action_default/findByUserIdAndActionId?userId=" + userId + "&actionId=" + actionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addUserDefault(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/user_action_default/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllCompany() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/company/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async updateUserDefault(jsonData) {
    // update updateUserDefault
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http.put(this.baseUrl + "user-service/api/user_action_default/update", jsonData, { headers: new Headers(this.headers) }).toPromise();
    return await response;
  }


  async calculateAssortmentResult(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/receiveWithCalculateAssortmentResult", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteUserDefault(userId, actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "user-service/api/user_action_default/deleteByUserIdAndActionId/" + userId + "/" + actionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async stoneRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/request", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async pickStoneFromBox(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/pickup", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanByBoard(boardBaseUrl) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(boardBaseUrl + "SmartBoard/Scan", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDefaultStoneParamMaster(fromLocation, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/company_sspd_settings/findAllByCompany?companyName=" + fromLocation + "&transType=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSsPdSetting(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/company_sspd_settings/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteSsPdSetting(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/company_sspd_settings/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addToCart(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/cart/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addOrUdateWithPrice(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/cart/addOrUdateWithPrice", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }


  async getCartStone(transType, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAllInCart?transType=" + transType + "&type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async deleteStoneInCart(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/cart/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findOutGoingStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findOutGoingStockByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findDeliveredStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findDeliveredStockByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllTypesForVoucher() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/voucher_book/getBookType", {
          headers: new Headers(this.headers)
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addVoucherBook(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .post(this.baseUrl + "master-service/api/voucher_book/add", jsonData, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getVoucherBookDetailbyId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(this.baseUrl + "master-service/api/voucher_book/findById?id=" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async getAllVouchersForSelectedType(selectedType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .get(
          this.baseUrl +
          "master-service/api/voucher_book/findByType?type=" +
          selectedType,
          {
            headers: new Headers(this.headers)
          }
        )
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteVoucher(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http
        .delete(this.baseUrl + "master-service/api/voucher_book/delete/" + id, {
          headers: new Headers(this.headers)
        })
        .toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateVoucher(id, jsonData) {
    this.headers = await this.configService.getAPIHeader();
    const response = await this.http
      .put(
        this.baseUrl + "master-service/api/voucher_book/update?id=" + id,
        jsonData,
        {
          headers: new Headers(this.headers)
        }
      )
      .toPromise();
    return await response;
  }

  async findRequestedStone(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findRequested?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateTolerance(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/company_sspd_settings/updateTolerance/?id=" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findOutRequest(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findOutRequest?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findRequestedStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findRequestedStockByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByVoucherIdOfOutRequestStocks(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findByVoucherIdOfOutRequestStocks?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllBoxType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findAllBoxType", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStoneListByRequest(action, id, kamId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findRequestedByactionAndValue?action=" + action + "&value=" + id + (!!kamId ? ("&requestUserId=" + kamId) : ''), { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async certificateOrConsignmentRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/TransferRequested", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async getAllRequest(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestStones?action=" + action + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllRequestVoucherWise(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findTransferRequested?requestAction=" + action + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllRequestVoucherWiseByPartyMasterId(action, pageNumber, noOfRecords, partyMasterId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findTransferRequested?requestAction=" + action + "&partyMasterId=" + partyMasterId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findTransferRequestStoneByVoucherId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestedByVoucherId?voucherId=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async labStoneReportUpload(jsonData, labId) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/uploadLabCertificationCSV?partyMasterId=" + labId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async getAllResultUploadedStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestUploaded?action=" + action + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getAllCustomers() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllCustomersByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findByType?type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findByAppNameAndCompanyId(companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findByAppNameAndCompanyId?appName=" + this.appName + "&companyId=" + companyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async getAllAdditionalServices() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/certification_service/all", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllAdditionalServicesByLabId(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/certification_service/findByLabId?labId=" + labId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  certification_service

  async getDataForComparison(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getDefaultAndLabParameters?stoneId=" + id + "&action=Certification", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async issueRequisition(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/issuePickedUp", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getReceivedAllStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestReceived?action=" + action + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllInComingStone(action, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestInComing?action=" + action + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  // async receivedStoneFromCertificateLab(jsonData){
  //   try{
  //     this.headers =await this.configService.getAPIHeader();
  //     const response = await this.http.post(this.baseUrl + "master-service/api/stone/receiveFromCertification",jsonData,{ headers: new Headers(this.headers)}).toPromise();
  //     return await response;
  //   }catch(error){
  //     return await error;
  //   }
  // }
  async returnStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/initiateTansferReceive", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async certificateRecheckRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "/master-service/api/stone/certificateRecheckRequest", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  // ============================
  async getAllCustomerList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllCustomerBypage(partyName, pageNumber, noOfRecords) {
    try {
      let name = !!partyName ? partyName : ''
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findAll?partyName=" + name + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }



  async getCustomerDetails(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findById/?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getCustomerContactList(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/customer-contact/findByCustomerMasterId?customerMasterId=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getCustomerContactDetails(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/customer-contact/findById/?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }
  async getDepartmentWiseKamPersonList(dept) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/user/findByDepartment?departmentName=" + dept + "", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }



  async addCustomer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/party_master/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addCustomerContact(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/customer-contact/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCustomer(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/party_master/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCustomerContact(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/customer-contact/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }





  // ==========================
  async getAllboxesByDepartmentID(departmentID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByDepartmentId?departmentId=" + departmentID, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findStonesByBoxID(boxID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findByBoxId?boxId=" + boxID, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findStonesByboxIdWithAvailableStock(boxID) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findByBoxId?boxId=" + boxID + "&availableStock=true", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findByLED(boardBaseUrl, obj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(boardBaseUrl + "Smartboard/LedOn", obj, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findBoxByUser() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByUser", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllTags() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/tags/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStoneTags(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stoneTag/findByStoneId?stoneId=" + stoneId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addStoneTag(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stoneTag/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanBox(obj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/box/scan", obj, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByDepartmentIdAndUser(departmentID, isAvailable) {
    console.log(isAvailable)
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByDepartmentIdAndUser?departmentId=" + departmentID + " &availableStock=" + isAvailable, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findByDepartmentOrRoleIdByRole(roleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByDepartmentOrRoleId?roleId=" + roleId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAllErrorByDepartmentAndUser(departmentID, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/boxScanError/findAllByDepartmentAndUser?departmentId=" + departmentID + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }
  async soldStoneList(obj) {
    try {
      this.headers = await this.configService.getAPIHeader();
      // const response =await this.http.post(this.baseUrl + "master-service/api/box/scan",obj,{headers: new Headers(this.headers)}).toPromise();
      // return await response;
    } catch (error) {
      return await error;
    }
  }

  async scanWithLightOn(boardBaseUrl, Rfids) {
    try {
      let xhttp = new XMLHttpRequest();
      await xhttp.open(
        "POST",
        boardBaseUrl + "Smartboard/LedOn",
        true
      );
      xhttp.onreadystatechange = function (onEvent) {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            return JSON.parse(xhttp.responseText);
          } else if (xhttp.status === 500) {
            return xhttp.responseText;
          } else if (xhttp.status === 0) {
            return xhttp.responseText;
          } else {
            return xhttp.statusText;
          }
        }
      };
      await xhttp.setRequestHeader("Content-type", "application/json");
      await xhttp.send(JSON.stringify(Rfids));
    }
    catch (error) {
      return await error;
    }

  }

  async scanLedOff(boardBaseUrl, rfidList) {
    try {
      var xhttp = new XMLHttpRequest();
      await xhttp.open(
        "POST",
        boardBaseUrl + "Smartboard/LedOff",
        true
      );
      xhttp.onreadystatechange = function (onEvent) {
        if (xhttp.readyState === 4) {
          if (xhttp.status === 200) {
            return JSON.parse(xhttp.responseText);
          } else if (xhttp.status === 500) {
            return xhttp.responseText;
          } else if (xhttp.status === 0) {
            return xhttp.responseText;
          } else {
            return xhttp.statusText;
          }
        }
      };
      await xhttp.setRequestHeader("Content-type", "application/json");
      await xhttp.send(JSON.stringify(rfidList));
    }
    catch (error) {
      return await error;
    }
  }

  async deleteTag(stoneTagId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/stoneTag/delete/" + stoneTagId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addSavedSearches(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/savedSearches/save", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateSavedSearches(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/savedSearches/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteSearch(searchId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/savedSearches/delete/" + searchId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllSearch(actionId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/savedSearches/findAll?actionId=" + actionId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAllTransType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/StoneTransType/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findResultOfPDAssort(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/assortment_vote_details/findByStoneId?stoneId=" + stoneId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBoxCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/box_criteria/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getBoxCriteriaById(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box_criteria/findById?id=" + boxId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateCriteriaById(criteriaId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/box_criteria/update/" + criteriaId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteCriteria(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/box_criteria/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllMixStones() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAllMixStonesForTransferToSales", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async uploadPriceList(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_list/uploadCsvData", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async AddPriceNameMaster(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_list_name_master/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllPriceName() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_list_name_master/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async acceptAssortmentWithoutAssortAndPD(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/assortment_vote/acceptAssortmentWithoutAssortAndPD", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deletePolicy(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/price_policy/delete/" + policyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPolicy() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_policy/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateOrder(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/price_policy_block/updateOrder", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async markStoneAsSold(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/markStoneAsSold", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllConfirmedVoucherWithStatus(action, status, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findRequestConfirmedWithStatus?requestAction=" + action + "&status=" + status + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfirmedVoucherWithStatusUsingPartyMasterId(action, status, partyMasterId, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findRequestConfirmedWithStatus?requestAction=" + action + "&status=" + status + "&partyMasterId=" + partyMasterId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllConfirmedStonesWithStatus(voucherId, status) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findTransferRequestWithStatus?voucherId=" + voucherId + "&status=" + status, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async addBlocks(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy_block/add", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addRuleToBlock(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/block_rule/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllBlockByPolicyId(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_policy_block/findByPricePolicyMasterId?pricePolicyMasterId=" + policyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getBlockDataById(blockId, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/block_rule/findByBlockId?blockId=" + blockId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async applyPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy/applyPolicy", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async AssortmentIssue(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/getStoneDetailsForAssortmentIssue", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getInwardStatus(voucherType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getStoneCountByVoucherType?voucherType=" + voucherType, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async disableAssorter(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/assortment_vote/disableAssorters", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findAllTemplate(isDefault) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/template/findAll?isDefault=" + isDefault, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findTemplateById(templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/template/findTemplateById?id=" + templateId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async saveTemplate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/template/save", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteTemplate(templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/template/delete/" + templateId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateTemplate(jsonData, templateId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/template/update?id=" + templateId, jsonData, { headers: new Headers(this.headers) }).toPromise()
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async savePolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy/savePolicy", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async findStonesListOfNotAppliedPolicy(policyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findStonesListOfNotAppliedPolicy?id=" + policyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async addProgram(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/program_master/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllProgram(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/program_master/findAll?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getProgramById(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/program_master/findById?id=" + programId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateProgram(programId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/program_master/update/" + programId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteProgram(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/program_master/delete/" + programId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async exportTemplate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/template/exportFileUsingTemplate", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getRuleDataById(ruleId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/block_rule/findById?id=" + ruleId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPendingStonesProgramWise(voucherType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/program_master/pendingToReceive?voucherType=" + voucherType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findPendingToReceiveByProgramId(programId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPendingToReceiveByProgramId?programId=" + programId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteBlock(blockId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/price_policy_block/delete/" + blockId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateActionDefault(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "user-service/api/action_default/update/", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStonePriceAndRulesFronStoneId(stoneId, priceListNameId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getStonePriceAndRulesFromPriceList?stoneId=" + stoneId + "&priceListNameId=" + priceListNameId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updatePolicy(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/price_policy/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateBlocks(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/price_policy_block/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateBlockWithFile(id, name, formData, findToReplace) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy_block/updateData/" + id + "?blockName=" + name + "&findToReplace=" + findToReplace, formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getDataFromKG(date) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/getDataFromKG?queryDate=" + date, {}, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updatePriceNameMaster(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/price_list_name_master/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAllAppliedPolicies(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_policy/findAllAppliedPolicies?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllParametersForSingleStone(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/company_sspd_settings/findAllByCompany?transType=" +
        type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStockStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findInStockByUserId", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getCountForIncomingAndOutReuqest() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findIncomingAndOutRequestCount", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async savePdResult(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/assortment_vote/updateStonePDParameters", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getStatus() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/statusMaster/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addMasterStatus(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/statusMaster/save", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateMasterStatus(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/statusMaster/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteMasterStatus(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/statusMaster/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }


  async getSizeMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/sizeMaster/getSizeMaster", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async  SDIXsendTransferedToConsignment(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/sendTransferedToConsignment", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addLabParameterMapping(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/lab_parameter_mapping/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllParameterMapping(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/lab_parameter_mapping/findAll?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getParamMappingById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/lab_parameter_mapping/findById?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateLabParameterMapping(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/lab_parameter_mapping/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async searchLabParameterMapping(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/lab_parameter_mapping/search?searchValue=" + encodeURIComponent(search) + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteLabParameterMapping(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/lab_parameter_mapping/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllParameterOfStones() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/lab_parameter_mapping/getAllStoneParameterName", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getlabExpenseUPloadHistory(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/lab_expenses/findAll?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStoneDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone_details/findByStoneId?stoneId=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async downloadCertificationUploadedFile(fileId) {
    try {
      let url = this.baseUrl + "master-service/api/stone/certificationCsvDownload?fileName=" + fileId;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

  async uploadForCetificateAction(labId, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/uploadCsvForCerificationRecheck?labId=" + labId, formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getPricingSizeMaster(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/size_master/findAll?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addPricingSizeMaster(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/size_master/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updatePricingSizeMaster(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/size_master/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deletePricingSizeMaster(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/size_master/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async searchPricingSizeMaster(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/size_master/search?searchValue=" + encodeURIComponent(search) + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addFixedPolicy(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy_block/addFixedPolicy", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addLabExpenses(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/lab_expenses/uploadCsv", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async labExpenseCsvDownload(fileId) {
    try {
      let url = this.baseUrl + "master-service/api/lab_expenses/labExpenseCsvDownload?fileName=" + fileId;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

  async deletelabExpense(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/lab_expenses/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addToCartByFilterCriteria(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/cart/addToCartWithSearchCriteria", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getConversionRateList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/conversation_rate/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addConversionRate(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/conversation_rate/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateConversionRate(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/conversation_rate/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteConversionRate(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/conversation_rate/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getCurrencyList() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/currency_master/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteCurrency(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/currency_master/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addCurrency(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/currency_master/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateCurrency(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/currency_master/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getCurrencyDetailById(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/currency_master/findById?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getLabConversionRate() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/conversation_rate/findAllLabRate", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getLabConversionRateById(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/conversation_rate/findByLabId?labId=" + labId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAllTypeForPricing() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/size_master/findAllType", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findParametersForMixSizeMaster() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-value/findColorClarityShapeIds", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async uploadSizeMasterCSV(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/size_master/uploadSizeMasterCSV", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllAvailableStockBox() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findAllMixBoxesForTransferToSales", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllAvailableCSSPInStockBox(boxId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAllMixStonesOfBoxForTransferToSales?boxId=" + boxId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async downloadCertificationComparisonFile(fileId) {
    try {
      let url = this.baseUrl + "master-service/lab_certifiaction_result/fileDownload?csvUploadHistoryId=" + fileId;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

  async findIncomingByVoucherTypeAndTransType(pageNumber, noOfRecords, voucherType, transType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findIncomingByVoucherTypeAndTransType?voucherType=" + voucherType + "&transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findReceivedByVoucherTypeAndTransType(pageNumber, noOfRecords, voucherType, transType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findReceivedByVoucherTypeAndTransType?voucherType=" + voucherType + "&transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getStone(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findInCommingByTransTypeForSales?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getIncomingStoneToSales(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findInCommingByTransTypeForSales?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getReceivedStoneToSales(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findRecievedStockByTransTypeForSales?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllCompanies() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/company/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findDetailById(companyId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/company/findById?id=" + companyId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateCompany(companyId, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "user-service/api/company/update/" + companyId, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addCompany(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "user-service/api/company/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findPendingForAssignmentByTransType(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findPendingForAssignmentByTransType?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAssignedStockByTransType(transType, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAssignedStockByTransType?transType=" + transType + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async assignRfidToStone(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/updateStoneRFID", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findOutRequestStocksByTransType(str) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + str, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getCompanyDetailByName(companyName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "user-service/api/company/findByName?name=" + companyName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAvailableStock() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findAvailableStock", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findCriteriaByBoxId(boxId, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box_criteria/findCriteriaByBoxId?id=" + boxId + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getTransferRequestedByVoucherIdForDC(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findAllTransferRequestedByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }


  async markStoneAsConfirmed(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/markStoneAsConfirmed", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async markMultipleStoneAsConfirmed(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/markMultipleStoneAsConfirmed", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findStoneByCssp(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/findStoneByCssp", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findCsspOrCreateIfNotExist(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/findStoneByCssp?createIfNotExists=true", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async saveAuditCssp(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/auditCssp", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteAllFromCart() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/cart/deleteAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateCartData(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/cart/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllPartyMasterType() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/party_master/findAllType", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getLatestRateByCurrencyName(currencyName) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/currency_master/getLatestRateByCurrencyName?currencyName=" + currencyName, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getLatestLabRateByCurrencyName(labId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/currency_master/getLatestRateByLabId?partyMasterId=" + labId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async stoneInterCsspTransfer(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/stoneInterCsspTransfer", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async downloadPackingList(queryParam) {
    try {
      let url = this.baseUrl + queryParam;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

  async uploadLabActualExpenseCSV(labId, formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/uploadLabActualExpenseCSV?partyMasterId=" + labId, formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async transferToBox(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/box/transferCssp", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addRapaprtMapping(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_list_parameter_mapping/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async RAPAPORTMappingUpdate(jsonData, id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/price_list_parameter_mapping/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async searchRapoParameterMapping(search, pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_list_parameter_mapping/search?param=" + encodeURIComponent(search) + "&pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllRapoParameterMapping(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/price_list_parameter_mapping/findAll?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteRapoParameterMapping(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/price_list_parameter_mapping/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findUnconfirmPurchaseData() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllUnconfirmPurchaseStone", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async confirmPurchaseData(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/confirmPurchaseStone", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async purchaseToSingle(formData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/purchaseToSingle", formData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findShapeByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/size_master/findDistinctShapeByType?type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findSizeByShape(shpaeId, type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/size_master/findByTypeShape?shape=" + shpaeId + "&type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async autoBoxing(departmentName, boxLevel) {
    try {
      this.headers = await this.configService.getAPIHeader();
      console.log(this.headers)
      const response = await this.http.post(this.baseUrl + "master-service/api/box/autoBoxing?department=" + departmentName + "&boxLevel=" + boxLevel, {}, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getBoxByType(departmentId, boxType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByDepartmentIdAndType?departmentId=" + departmentId + "&boxType=" + boxType, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findBoxByUserAndType(boxType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findByUserAndType?boxType=" + boxType, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findGroupNames() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/company_sspd_settings/findGroupNames", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findGroupValue() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone-parameter-value/findGroupNames", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findOutGoingPickedVouchers(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/findOutGoingPickedVouchers?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findOutGoingPickedStockByVoucherId(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/findOutGoingPickedStockByVoucherId?voucherId=" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async findAllMixBoxesForTransferToSalesByType(type) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/box/findAllMixBoxesForTransferToSalesByType?type=" + type, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addTermMaster(jsonData) {
    try {
      this.headers = await this.configService.fileAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/termMaster/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async updateTermMaster(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/termMaster/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async getAllTerm() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/termMaster/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async deleteTermMaster(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/termMaster/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deleteRequest(voucherId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/stone/deleteRequest/" + voucherId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async checkValidationForRequest(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/validationForRequest", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateStoneParameterFromKG(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/updateStoneParameterFromKG?stoneId=" + stoneId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    }
    catch (error) {
      return await error;
    }
  }

  async addPolicyChain(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/policy_chain/add", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updatePolicyChain(id, jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.put(this.baseUrl + "master-service/api/policy_chain/update/" + id, jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async deletePolicyChain(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.delete(this.baseUrl + "master-service/api/policy_chain/delete/" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPolicyChain() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/policy_chain/findAll", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getDetailByPolicyChainId(id) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/policy_chain/findById?id=" + id, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getPdParamsByStoneId(stoneId) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/assortment_vote/getPdParamsByStoneId?stoneId=" + stoneId, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async applyPolicyChain(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/price_policy/applyPolicyChain", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }


  async getAllConvertedSingleStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllSingleStoneConvertFromMix", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllPurchaseConfirmedStone() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllPurchaseConfirmedStone", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async convertPurchaseToBulk(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/purchaseToBulk", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async returnAndReceive(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/returnAndReceive", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async getAllSingleStoneIssuedForMixing() {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllSingleStoneIssued", { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async convertSingleToMix(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/convertSingleToMix", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async changeStoneId(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/updateStoneId", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async purchaseInward(pageNumber, noOfRecords) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/voucher/purchaseInward?pageNumber=" + pageNumber + "&noOfRecords=" + noOfRecords, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async findByListOfPartyType(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/party_master/findByListOfType", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  // async getMixinghistoryDataByType(type) {
  //   try {
  //     this.headers = await this.configService.getAPIHeader();
  //     const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllConvertedStone?transType=" + type, { headers: new Headers(this.headers) }).toPromise();
  //     return await response;
  //   } catch (error) {
  //     return await error;
  //   }
  // }

  async getMixinghistoryDataByType(fromTransType, toTransType) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.get(this.baseUrl + "master-service/api/stone/getAllTransferStone?fromTransType=" + fromTransType + "&toTransType=" + toTransType, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async updateBarcode(jsonData) {
    try {
      this.headers = await this.configService.getAPIHeader();
      const response = await this.http.post(this.baseUrl + "master-service/api/stone/updateBarcode", jsonData, { headers: new Headers(this.headers) }).toPromise();
      return await response;
    } catch (error) {
      return await error;
    }
  }

  async downloadActualLabExpenseFile(fileId, fileType) {
    try {
      let url = this.baseUrl + "master-service/api/stone/csvDownload?fileName=" + fileId + "&fileType=" + fileType;
      window.open(url, "_blank")
    } catch (error) {
      return await error;
    }
  }

}
