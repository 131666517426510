import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageMasterPage } from "../manage-master/manage-master.page";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-mix-to-single',
  templateUrl: './mix-to-single.component.html',
  styleUrls: ['./mix-to-single.component.scss']
})
export class MixToSingleComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public sizeIdList = []
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public csspList = [];
  public sizeList = [];
  public sizeId: any;
  public fromCts: any;
  public toCts: any;
  public selectedFlag = false;
  public selected = [];
  public selectedCsspList = [];
  public columns = []
  public count = 0;
  public historyData = [];
  public pageNumber = 0;
  public noOfRecords = 10;
  public selectedFile: any;
  public historyColumns = []
  public historyCount = 0;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events,
  ) { }

  ngOnInit() {
    this.historyColumns = [
      { name: "File Name", prop: "fileName" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    this.pageNumber = 0;
    this.noOfRecords = 10;
    this.loadUploadHistory(this.pageNumber, this.noOfRecords)
    this.selectedFlag = false;
    this.columns = [{ name: "Sr No", prop: "" }, { name: "CSSP", prop: "stoneId" }, { name: "cts", prop: "size" }]
    this.findShapeByType();
    this.findParametersForMixSizeMaster();
    this.event.subscribe('Mix To Single', (item) => {
      this.historyColumns = [
        { name: "File Name", prop: "fileName" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.selectedFlag = false;
      this.loadUploadHistory(this.pageNumber, this.noOfRecords)
      this.findShapeByType();
      this.findParametersForMixSizeMaster();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix To Single')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        console.log(idList, idList[0])
        this.sizeIdList = []
        this.findSizeByShape(idList[0])
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeId = idList[0];
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeId = null
        this.sizeIdList = []
      }
    }
  }

  changeType() {
    this.colorIdList = [];
    this.shapeIdList = [];
    this.clarityIdList = [];
    this.shapeId = [];
    this.clarityId = [];
    this.colorId = [];
    this.fromCts = null;
    this.toCts = null;
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType('Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts
    }
    res = await this.httpApiService.findStoneByCssp(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      this.selectedFlag = false;
      this.selectedCsspList = [];
      // this.csspList = [{ id: 1, stoneId: 'FUP/R/0.39-0.449/3A', cts: '15.24' }, { id: 2, stoneId: 'FUP/R/0.39-0.449/2', cts: '10.24' }]
      this.count = this.csspList.length
      if (this.csspList.length > 0) {
        this.count = data.data.length;
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }
      // if (this.csspList.length > 0) {
      //   if (this.type == 'from') {
      //     this.fromCsspList = data.data;
      //     this.fromCsspList.forEach(el => {
      //       el.isSelected = false;
      //       el.transferCts = el.size;
      //     })
      //   } else {
      //     this.toCsspList = data.data;
      //     this.fromCsspList.forEach(el => {
      //       el.isSelected = false;
      //     })
      //   }

      // } else {
      //   await this.configService.showToast('error', 'Searched Cssp not found.')
      // }
    }
    console.log(this.csspList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  //On file Select

  fileObject(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  // BULK TO BULK  SELECTION

  doCsspSelection(ev) {
    this.selected = ev.selected;
    this.selectedCsspList = [];
    this.selected.forEach(el => {
      this.selectedCsspList.push(el);
    })
    console.log(this.selectedCsspList)

  }

  onSelectedCssp() {
    this.selectedFlag = true;
    this.count = this.selectedCsspList.length
  }


  // BULK TO SINGLE FILE UPLOAD

  async convertPurchaseToSingle() {
    if (this.selectedFile) {
      let jsonObj = {
        csspMixStoneId: this.selectedCsspList[0].id,
      };
      console.log(jsonObj);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("myJson", JSON.stringify(jsonObj));
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.purchaseToSingle(formData);
      console.log(res.json());
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        this.resetData()
        await this.configService.showToast("success", data.message);
        await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }


  async loadUploadHistory(pageNumber, noOfRecords) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getUploadHistory(
      "Purchase To Single",
      pageNumber,
      noOfRecords
    );
    await this.loadingController.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue);
      this.historyData = dataValue.content;
      this.historyCount = dataValue.totalElements;
    }
  }

  resetData() {
    this.selectedFlag = false;
    this.selectedCsspList = [];
    this.selected = []
    this.csspList = []
    this.colorId = []
    this.colorIdList = [];
    this.shapeId = [];
    this.shapeIdList = [];
    this.fromCts = null;
    this.toCts = null;
    this.clarityIdList = [];
    this.clarityId = []
    this.sizeIdList = []
  }

  //download

  async downloadUploadedFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(file.id);
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.loadUploadHistory(currentPage.offset, this.noOfRecords);
  }

}
