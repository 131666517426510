import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-generate-po',
  templateUrl: './generate-po.component.html',
  styleUrls: ['./generate-po.component.scss']
})
export class GeneratePOComponent implements OnInit {

  @Input() companyDetails: any;
  @Input() customerDetails: any;
  @Input() stoneDetails: any = [];
  @Input() transtype: any;
  @Input() optionPO: any;
  @Input() terms: any;
  @Input() disc1: any;
  @Input() disc2: any;

  public salesPersonName: any;
  public totalCarat = 0;
  public pricePerCts = 0;
  public totalPricePerCts = 0
  public totalAmount = 0;

  public date: any;
  public numbers = []
  constructor() { }

  ngOnInit() {
    // this.numbers = Array(200).fill(10);
    console.log(this.numbers)
    this.date = moment().format('DD/MM/YYYY');
    this.totalPricePerCts = 0
    for (let i = 0; i < this.stoneDetails.length; i++) {
      this.totalCarat += this.stoneDetails[i].stoneCts;
      this.totalPricePerCts = this.totalPricePerCts + (!!this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price);
      this.totalAmount += ((!!this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price) * this.stoneDetails[i].stoneCts);
    }
    console.log(this.totalAmount, this.totalPricePerCts)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

}
