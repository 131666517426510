import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Router } from '@angular/router';
import { ThemeService } from './services/theme/theme.service';
const themes = {
  autumn: {
    primary: '#F78154',
    secondary: '#4D9078',
    tertiary: '#B4436C',
    light: '#FDE8DF',
    medium: '#FCD0A2',
    dark: '#B89876'
  },
  night: {
    primary: '#8CBA80',
    secondary: '#FCFF6C',
    tertiary: '#FE5F55',
    medium: '#BCC2C7',
    dark: '#F7F7FF',
    light: '#495867'
  },
  neon: {
    primary: '#39BFBD',
    secondary: '#4CE0B3',
    tertiary: '#FF5E79',
    light: '#F4EDF2',
    medium: '#B682A5',
    dark: '#34162A'
  },
  dark : {
    primary: '#eeae15',
    secondary: '#007ACC',
    medium: '#f43e00',
    light: '#bdc3c7',
    dark: '#d35400',
    tertiary: '#F39C12',
  },
   defaults: {
    primary: '#3880ff',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#ffffff'
  }
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public changePasswordPage = false;
  themes: Array<{title: string, theme: string}>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private theme: ThemeService
  ) {
    this.initializeApp();
    //used to change the theme of the app, commented to keep the default
    this.changeTheme('defaults');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.authenticationService.authenticationState.subscribe(state => {
      this.changePasswordPage = false;
      if(window && "location" in window
      && "protocol" in window.location
      && "pathname" in window.location
      && "host" in window.location) {
        if(window.location.href.toString().includes('changePassword')) {
          this.changePasswordPage = true;
        }
      }
      if (state && !this.changePasswordPage) {
        this.router.navigate(['app', 'dashboard']);
      } else if(!this.changePasswordPage) {
        this.router.navigate(['login']);
      }
    });
  }

  changeTheme(name) {
    this.theme.setTheme(themes[name]);
  }

  changeSpeed(val) {
    this.theme.setVariable('--speed', `${val}ms`);
  }
}
