import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";
import { InterBoxTransferPage } from "../../../components/actions/inter-box-transfer/inter-box-transfer.page";

@Component({
  selector: "app-transfer-to-sales-mix",
  templateUrl: "./transfer-to-sales-mix.component.html",
  styleUrls: ["./transfer-to-sales-mix.component.scss"]
})
export class TransferToSalesMixComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public listOfMixStones: any;
  public stoneId = [];
  public size: number;
  public maxSize: number;
  public criteriaId: any;
  public userId: any;
  public actionId: any;
  public boxId: any;
  public toRole: any;
  public voucherBookId: any;
  public errorMsgList = [];
  public successMsgList = [];
  public successMsg: any;
  public errorMsg: any;
  public msgStatus = false;
  public stoneIdList: any;
  public flag = "TRANSFER MIX STOCK";
  public boxName: any;
  public columns = [];
  public listOfBoxes: any = [];
  public listOfCSSP = [];
  public isSelect: Boolean = false;
  public selected = [];
  public selectedCSSPList = []
  public selectedCSSPIdList = []
  public fromRole: any;
  public baseUrl: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events
  ) { }

  ngOnInit() {
    this.getBaseUrl();
    this.actionId = this.data.actionId;
    this.columns = [{ name: "Sr No", prop: "" },
    { name: "CSSP", prop: "stoneId" },
    { name: "Ct", prop: "size" },
    ];
    this.getDefaultSetting();
    this.getAllAvailableStockBox();
    this.event.subscribe('Mix Transfer To Sales', (item) => {
      this.getDefaultSetting();
      this.getAllAvailableStockBox();

    })
  }

  async getBaseUrl() {
    this.baseUrl = await this.configService.getBaseUrl();
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix Transfer To Sales');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedStone") {
      if (idList.length > 0) {
        this.stoneId = idList;
      } else {
        this.size = 0;
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
        if (!!dataValue.map.fromRole) {
          this.fromRole = dataValue.map.fromRole
        }
      }
    }
  }

  async getAllAvailableStockBox() {
    await this.configService.showLoader();
    let res = await this.httpApiService.findAllMixBoxesForTransferToSalesByType('Parcel');
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBoxes = data.data;
    }
  }
  async getAllAvailableStockBoxwithoutLoader() {
    let res = await this.httpApiService.findAllMixBoxesForTransferToSalesByType('Parcel');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfBoxes = data.data;
    }
  }

  async getAllAvailableCSSPInStockBox(boxId) {
    await this.configService.showLoader();
    let res = await this.httpApiService.getAllAvailableCSSPInStockBox(boxId);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCSSP = data.data;
      console.log(this.listOfCSSP)
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  async changeViewType(type, item) {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
    this.selected = [];
    this.selectedCSSPList = [];
    this.selectedCSSPIdList = []
    this.isSelect = false;
    console.log(item)
    this.flag = type;
    if (item != 0) {
      this.boxId = item.id;
      this.boxName = item.label;
      await this.getAllAvailableCSSPInStockBox(this.boxId)
    }
  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  async issueMixStones() {
    let validate = await this.validateControls();
    let res: any;
    if (validate) {
      await this.configService.showLoader();
      let jsonObj = {
        voucherDate: moment().format("YYYY-MM-DD"),
        setOfStoneIds: this.selectedCSSPIdList,
        voucherBookId: this.voucherBookId,
        toRole: this.toRole,
        fromRole: this.fromRole,
        generateCsvForMapping: true,
        transType: "MIX",
      };
      console.log(jsonObj)
      res = await this.httpApiService.stoneIssue(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        if (data.data) {
          this.errorMsgList = data.data;
          for (let i = 0; i < this.errorMsgList.length; i++) {
            this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
          }
          console.log(this.errorMsgList);
          this.errorMsg = data.message;
          this.msgStatus = true;
        } else {
          await this.configService.showToast("error", data.error);
        }
      } else {
        this.size = 0;
        this.stoneIdList = []
        this.successMsgList = data.data.content;
        for (let i = 0; i < this.successMsgList.length; i++) {
          this.successMsgList[i].ids = this.successMsgList[i].StoneId.join(", ");
        }
        console.log(this.successMsgList);
        this.successMsg = data.message;
        console.log(data)
        if (!!data.data.url) {
          window.open(this.baseUrl + data.data.url)
        }
        this.msgStatus = true;
        this.flag = 'TRANSFER MIX STOCK';
        this.getAllAvailableStockBox();
      }
    }
  }

  async validateControls() {
    console.log(this.selectedCSSPList, this.size)
    if (this.selectedCSSPList.length > 0) {
      return true;
    } else {
      await this.configService.showToast("error", "Please select stone");
      return false;
    }
  }

  onSelect(ev) {
    this.selected = ev.selected;
    console.log(this.selected)
    console.log(this.selected)
    this.selectedCSSPList = [];
    this.selectedCSSPIdList = []
    this.selected.forEach(el => {
      this.selectedCSSPIdList.push(parseInt(el.id));
      this.selectedCSSPList.push(el)
    })
    console.log(this.isSelect, this.selectedCSSPList, this.selectedCSSPList.length, this.listOfCSSP.length)
    if (this.selected.length == this.listOfCSSP.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }


  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.listOfCSSP)
      this.isSelect = true;
      this.selectedCSSPList = [];
      this.selected.forEach(el => {
        this.selectedCSSPIdList.push(parseInt(el.id));
        this.selectedCSSPList.push(el)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedCSSPList = [];
      this.selectedCSSPIdList = []
    }
    console.log(this.selectedCSSPList)
  }

  async interBoxTransfer() {
    let modalPage = await this.modalCtrl.create({
      component: InterBoxTransferPage,
      backdropDismiss: false,
      componentProps: { stoneList: this.selectedCSSPList, createNew: false },
      cssClass: "requestPage"
    })
    modalPage
      .onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.selected = []
      this.isSelect = false;
      await this.getAllAvailableCSSPInStockBox(this.boxId)
      await this.getAllAvailableStockBoxwithoutLoader()
    }
  }

}
