import { Component, OnInit, HostListener } from "@angular/core";
import { NavParams, ModalController, LoadingController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-fixed-policy-matrix',
  templateUrl: './fixed-policy-matrix.page.html',
  styleUrls: ['./fixed-policy-matrix.page.scss'],
})

export class FixedPolicyMatrixPage implements OnInit {

  public Xparam = []
  public Yparam = []
  public getAllStoneParam = [];
  public data = [];
  public paramData = [];
  public fixedValueList = [];
  public shapeList = []
  public colorList = []
  public shape: any;
  public color: any;
  public transTypeList = []
  public transType: any;
  public XIdList = []
  public YIdList = []
  public YparamName: any;
  public XparamName: any;
  public copyValue = ''
  public shapeIdList = [];
  public colorIdList = [];
  public typeList = []
  public type: any;
  public typeIdList = []

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public loadingCtrl: LoadingController,
    public configService: ConfigService,
    public event: Events) {
  }

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      if (navigator['clipboard']) {
        navigator['clipboard'].readText().then(data => {
          this.copyValue = data;
          console.log(this.copyValue)
          this.getMatrix();
        })
      }
    }
  }

  async ngOnInit() {
    await this.findAllTypeForPricing();
    await this.getAllStoneMaster();
    await this.getStoneMasterDetailByName('color', 'colorValue')
    await this.getStoneMasterDetailByName('shape', 'shapeValue')
    let dataValue = this.navParams.get('matrix');
    if (Object.keys(dataValue).length > 0) {
      this.data = dataValue.rules;
      this.shape = dataValue.shape;
      this.color = dataValue.color;
      this.shapeList.filter(el => {
        if (el.name == dataValue.shape) {
          this.shapeIdList = []
          this.shapeIdList.push({ id: el.id, name: el.name })
        }
      })
      this.colorList.filter(el => {
        if (el.name == dataValue.color) {
          this.colorIdList = []
          this.colorIdList.push({ id: el.id, name: el.name })
        }
      })
      this.typeList.filter(el => {
        if (el.name == dataValue.type) {
          this.typeIdList = []
          this.typeIdList.push({ id: el.id, name: el.name })
        }
      })
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "XParameter") {
      if (idList.length > 0) {
        // this.getSizeMaster();

      } else {
        this.Xparam = []
        this.data = [];
        this.paramData = []
      }
    } else if (property == 'YParameter') {
      if (idList.length > 0) {
        this.YparamName = obj[0].masterName;
        this.getAllValueByParameterId(idList[0], property)
      } else {
        this.data = []
        this.Yparam = []
        this.paramData = []
      }
    } else if (property == 'shapeValue') {
      if (idList.length > 0) {
        this.shape = obj[0].name;
      } else {
        this.shape = null;
      }
    } else if (property == 'colorValue') {
      if (idList.length > 0) {
        this.color = obj[0].name;
      } else {
        this.color = null;
      }
    } else if (property == 'typeId') {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    }
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
      console.log(this.typeList)
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllStoneParam = data.data;
    }
  }

  async getStoneMasterDetailByName(paramName, property) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailByName(paramName);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data)
      let dataValue = []
      if (property == 'shapeValue') {
        if (data.data.length > 0) {
          this.shapeList = data.data
        }
      } else if (property == 'colorValue') {
        if (data.data.length > 0) {
          data.data.filter(el => {
            if (!el.transTypeName || el.transTypeName == 'MIX') {
              dataValue.push(el);
            }
          })
        }
        console.log(this.colorList)
        this.colorList = dataValue;
      }
    }
  }

  async getAllValueByParameterId(id, property) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (property == 'YParameter') {
        this.Yparam = data.data;
      }
      console.log(this.Xparam.length, this.Yparam.length)
      if (this.Xparam.length > 0 && this.Yparam.length > 0) {
        await this.configService.showLoader()
        this.data = [];

        for (let i = 0; i < this.Yparam.length; i++) {
          this.paramData = []
          for (let j = 0; j < this.Xparam.length; j++) {
            this.paramData.push({ paramName: 'size', paramValue: this.Xparam[j].code, value: '' })
          }
          this.data.push({ paramName: this.YparamName, paramValue: this.Yparam[i].code, sizeArray: this.paramData })
        }
        await this.loadingCtrl.dismiss();
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  getData() {
    let jsonObj = {
      color: this.color,
      shape: this.shape,
      rules: this.data,
      type: this.type,
      ruleType: 2
    }
    this.modalCtrl.dismiss(jsonObj);
    console.log(jsonObj)
  }

  async onFileSelected(event) {
    this.data = []
    readXlsxFile(event.target.files[0]).then((rows) => {
      rows[0].forEach(el => {
        if (!!el) {
          this.shape = el;
        }
      });
      rows[1].forEach(el => {
        if (!!el) {
          this.color = el;
        }
      });
      this.shapeList.filter(el => {
        if (el.name == this.shape) {
          this.shapeIdList = []
          this.shapeIdList.push({ id: el.id, name: el.name })
        }
      })
      this.colorList.filter(el => {
        if (el.name == this.color) {
          this.colorIdList = []
          this.colorIdList.push({ id: el.id, name: el.name })
        }
      })
      let xArray = rows[2];
      this.Xparam = xArray.slice(2);
      for (let i = 2; i < rows.length; i++) {
        if (i != 2) {
          this.paramData = []
          for (let j = 0; j < this.Xparam.length; j++) {
            if (this.Xparam[j]) {
              this.paramData.push({ size: this.Xparam[j].toString(), value: rows[i][j + 2] })
            }
          }
          this.data.push({ claritySS: rows[i][0], clarity: rows[i][1], sizeArray: this.paramData })
        }
      }
      console.log(this.data)
    })
  }

  async getMatrix() {
    this.data = []
    let records = this.copyValue.split('\n');
    let rows = []
    for (let i = 0; i < records.length; i++) {
      if (records[i]) {
        rows.push(records[i].trim().split('\t'))
      }
    }
    rows[0].forEach(el => {
      if (!!el) {
        this.shape = el;
      }
    });
    rows[1].forEach(el => {
      if (!!el) {
        this.color = el;
      }
    });
    this.shapeList.filter(el => {
      if (el.name == this.shape) {
        this.shapeIdList = []
        this.shapeIdList.push({ id: el.id, name: el.name })
      }
    })
    this.colorList.filter(el => {
      if (el.name == this.color) {
        this.colorIdList = []
        this.colorIdList.push({ id: el.id, name: el.name })
      }
    })
    let xArray = rows[2];
    this.Xparam = xArray.slice(2);
    for (let i = 2; i < rows.length; i++) {
      if (i != 2) {
        this.paramData = []
        for (let j = 0; j < this.Xparam.length; j++) {
          if (this.Xparam[j]) {
            this.paramData.push({ size: this.Xparam[j].toString(), value: rows[i][j + 2] })
          }
        }
        this.data.push({ claritySS: rows[i][0], clarity: rows[i][1], sizeArray: this.paramData })
      }
    }
    console.log(this.data)
  }
}
