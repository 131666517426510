import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageProgrammePage } from '../../actions/manage-programme/manage-programme.page';

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.scss']
})
export class ProgrammeComponent implements ProcessActionComponent {

  public columns = []
  public page = 'Programme';
  public pageNumber = 0;
  public noOfRecords = 20;
  public count: Number;
  public userId: any;
  public listOfProgram = [];
  public items = []
  public totalPages;

  @Input() data: any;
  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public events: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "Sr No", prop: "" },
      { name: "Name", prop: "progName" },
      { name: "Customer Name", prop: "customerName" },
      { name: "isActive", prop: "isActive" },
      { name: "Delete", prop: "Delete" }
    ];
    this.getAllProgram(this.pageNumber, this.noOfRecords);
  }

  async getAllProgram(pageNumber, noOfRecords) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.getAllProgram(pageNumber, noOfRecords);
    let data = res.json();
    await this.loadingController.dismiss();
    if (data.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfProgram = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      this.setItems();
      this.count = this.listOfProgram.length;
    }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.getAllProgram(currentPage.offset, this.noOfRecords);
  }

  async setItems() {
    this.items = this.listOfProgram;
  }


  async filterItems(ev: any) {
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (
          item.progName &&
          item.progName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.customerName &&
          item.customerName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      this.setItems();
    }
  }

  async deleteConfirm(item) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Program ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteProgram(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteProgram(item) {
    let res: any;
    res = await this.httpApiService.deleteProgram(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllProgram(this.pageNumber, this.noOfRecords);
    }
  }

  async manageProgramme(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modal = await this.modalCtrl.create({
        component: ManageProgrammePage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllProgram(this.pageNumber, this.noOfRecords);
    }
  }
}
