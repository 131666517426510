import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ModalController, LoadingController, Events, AlertController } from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"]
})
export class RolesComponent implements ProcessActionComponent {
  @Input() data: any;

  public listOfRoles: any;
  public selectedRole = "";
  public items: any;
  public itemsActions: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationAction = -1;
  public manageSelectedRole = "";
  public listOfActions: any;
  public listOfActionPermissions: any;
  public description: "";
  public flagButton = "";
  public roleName = "";
  public id: any;
  public searchPermission: string = "";
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;
  public roleId: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.getAllRoles();
    this.event.subscribe('Roles', (item) => {
      this.getAllRoles();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Roles');
  }

  async getAllRoles() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfRoles = data.data;
      this.setItems();
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  setItems() {
    this.items = this.listOfRoles;
  }

  setActions() {
    this.itemsActions = this.listOfActions;
  }

  selectRole(event, item) {
    this.selectedRole = item.roleName;
    console.log("this.selectedRole", this.selectedRole);
    this.searchRole(item.roleName);
    // this.items = [];
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        console.log("item", item, typeof item);
        return item.roleName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      // this.items = [];
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedRole = this.items[
              this.arrowKeyLocation
            ].roleName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedRole = this.items[
              this.arrowKeyLocation
            ].roleName;
            break;
          } else {
            break;
          }
        }
    }
    console.log(this.arrowKeyLocation);
  }

  async filterActions(ev: any) {
    this.setActions();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.itemsActions = await this.itemsActions.filter(function (item) {
        console.log("item", item, typeof item);
        return item.actionName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationAction = -1;
    } else {
      this.arrowKeyLocationAction = -1;
      // this.itemsActions = [];
    }
  }

  keyDownActions(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowKeyLocationAction != 0) {
          this.arrowKeyLocationAction--;
          break;
        } else {
          break;
        }
      case 40: // this is the ascii of arrow down
        if (this.itemsActions.length != this.arrowKeyLocationAction + 1) {
          this.arrowKeyLocationAction++;
        }
        if (this.itemsActions.length > this.arrowKeyLocationAction) {
          // this.manageSelectedRole = this.items[this.arrowKeyLocation].roleName;
          break;
        } else {
          break;
        }
    }
    console.log(this.arrowKeyLocationAction);
  }

  async searchRole(roleName) {
    await this.configService.showLoader();
    let tmpRoleName = "";
    this.items = this.listOfRoles;
    if (roleName != undefined) {
      tmpRoleName = roleName;
      this.selectedRole = roleName;
      this.manageSelectedRole = roleName;
    } else {
      this.selectedRole = this.manageSelectedRole;
      tmpRoleName = this.manageSelectedRole;
    }
    console.log("roleName", roleName, tmpRoleName);
    let selectedRoleId = this.items.filter(function (search) {
      if (search.roleName == tmpRoleName) {
        return search.id;
      }
    });
    console.log("selectedRoleId", selectedRoleId);
    if (selectedRoleId.length > 0) {
      this.roleId = selectedRoleId[0].id;
      let res: any;
      res = await this.httpApiService.findRoleDetailsById(this.roleId);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        console.log("res", res, data);
        let roleData = data.data;
        this.flagButton = ""
        this.listOfActions = roleData.listOfActions;
        this.selectedRole = roleData.roleName;
        this.id = roleData.id;
        this.roleName = this.selectedRole;
        this.description = roleData.description;
        this.setActions();
        // await this.filterJson(roleData);
        let index = this.items.findIndex(
          x => x.roleName == selectedRoleId[0].roleName
        );
        this.arrowKeyLocation = index;
        console.log(
          "this.listOfActions",
          this.listOfActions,
          this.selectedRole
        );
      }
    } else {
      await this.loadingController.dismiss();
      this.selectedRole = "";
      this.roleName = "";
      this.description = "";
      this.listOfActions = [];
    }
  }

  async filterJson(roleData) {
    let tmpArray: any = [];
    roleData.listOfActions.filter(role => {
      role.listOfActionPermissions.filter(roleActionPermission => {
        roleActionPermission.actionName = role.actionName;
        roleActionPermission.roleId = roleData.id;
        tmpArray.push(roleActionPermission);
      });
    });
    this.listOfActionPermissions = tmpArray;
    this.setActions();
    console.log("list of action permissions", this.listOfActionPermissions);
    return await this.listOfActionPermissions;
  }

  async addRoleActionPermission(selectedRole) {
    let role = this.listOfRoles.filter(item => {
      if (item.roleName == selectedRole) {
        return item;
      }
    });
    console.log("role", role);
    const modal = await this.modalCtrl.create({
      component: ManageRolesPage,
      backdropDismiss: false,
      componentProps: { role: role, type: 1 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async addRole() {
    const modal = await this.modalCtrl.create({
      component: ManageRolesPage,
      backdropDismiss: false,
      componentProps: { role: "", type: 2 }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }
  async handleModalDismiss(d) {
    console.log("d", d);
    if (d.data != 'doNotRefresh') {
      this.getAllRoles();
      this.searchRole(this.selectedRole);
    }
  }

  ionModalDidDismiss() {
    console.log("inside ionModalDidDismiss");
    this.getAllRoles();
    this.searchRole(this.selectedRole);
  }


  async confirmDeletePermission(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Permission ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteRolePermission(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteRolePermission(rolePermission) {
    let res = await this.httpApiService.deleteRolePermission(
      this.roleId,
      rolePermission.id,

    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("delete role permission response", data);
      this.searchRole(this.selectedRole);
      await this.configService.showToast("success", data.message);
      this.searchPermission = "";
    }
  }

  async showButton(name) {
    if (name == "roleName") {
      this.flagButton = "roleName";
    } else if (name == "description") {
      this.flagButton = "description";
    }
  }

  async updateRole() {
    let validate = await this.validateControls();
    let appName = await this.configService.getAppName();
    if (!validate) {
      let jsonObj = {
        roleName: this.roleName,
        appName: appName,
        description: this.description
      };
      let res: any;
      let id = this.id;
      res = await this.httpApiService.updateRole(id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.flagButton = "";
        await this.getAllRoles();
        await this.searchRole(this.roleName);
        await this.configService.showToast(
          "success",
          "Role updated successfully"
        );
      }
    }
  }

  async validateControls() {
    if (this.roleName != "" && this.description != "") {
      return false;
    } else {
      await this.configService.showToast("error", "Please enter the details");
      return true;
    }
  }
}
