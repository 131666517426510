import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";
import { bypassSanitizationTrustResourceUrl } from "@angular/core/src/sanitization/bypass";

@Component({
  selector: 'app-manage-parameter-mapping-master',
  templateUrl: './manage-parameter-mapping-master.page.html',
  styleUrls: ['./manage-parameter-mapping-master.page.scss'],
})
export class ManageParameterMappingMasterPage implements OnInit {

  public copy = [];
  public name: any;
  public flagAddUpdate: any;
  public item: any;
  public id: any;
  public isDefault: any;
  public listOfOption = [];
  public type: any;
  public labId: any;
  public paramId: any;
  public paramValueId: any;
  public masterParamValue: any;
  public masterName: any;

  public listOfLabs = [];
  public listOfParam = [];
  public listOfParamValue = [];
  public paramValueIdList = [];
  public labIdList = [];
  public optionIdList = [];
  public paramIdList = [];
  public listOfStoneParameter = [];
  public masterParamValueOutMapping = "";
  public masterNameOut = "";
  public masterNameIn: any;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
    this.listOfOption = [{ id: 1, name: 'Parameter Name' }, { id: 2, name: 'Parameter Value' }]
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  async ngOnInit() {
    let data = this.navParams.get('item');
    this.isDefault = this.navParams.get('isDefault');
    if (data != 0) {
      this.item = data.row;
      this.id = this.item.id
    }
    console.log(this.item)
    this.flagAddUpdate = this.navParams.get('flag');
    await this.getAllLabs();
    await this.getAllStoneMaster();
    await this.getAllParameterOfStones();
    if (this.flagAddUpdate == 'update') {
      this.labIdList = []
      this.optionIdList = []
      this.paramValueIdList = []
      this.listOfLabs.filter(el => {
        if (el.id == this.item.labId) {
          this.labIdList.push({ id: el.id, name: el.name })
        }
      })
      this.listOfOption.filter(el => {
        if (el.name == this.item.mappingType) {
          this.optionIdList.push({ id: el.id, name: el.name })
        }
      })
      this.type = this.item.mappingType;
      this.labId = this.item.labId;
      if (this.item.mappingType == 'Parameter Name') {
        this.paramId = this.item.masterId
        this.listOfStoneParameter.filter(el => {
          if (el.masterName == this.item.masterName) {
            this.paramIdList = []
            this.paramIdList.push({ id: el.id, masterName: el.masterName })
          }
        })
        this.masterName = this.item.masterName;
        console.log(this.masterName)
        this.masterNameIn = this.item.inMapValue
        this.masterNameOut = this.item.outMapValue
      } else {
        await this.getAllValueByParameterId(this.item.stoneParameterNameId);
        this.paramIdList = []
        this.listOfParam.filter(el => {
          if (el.id == this.item.stoneParameterNameId) {
            this.paramId = el.id;
            this.paramIdList.push({ id: el.id, masterName: el.masterName })
          }
        })
        console.log(this.paramIdList)
        this.paramValueId = this.item.masterId;
        this.masterParamValue = this.item.inMapValue;
        this.masterParamValueOutMapping = this.item.outMapValue;
        this.listOfParamValue.filter(el => {
          if (el.id == this.item.masterId) {
            this.paramValueIdList.push({ id: el.id, name: el.name })
          }
        })
      }
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'optionList') {
      if (idList.length > 0) {
        this.paramIdList = []
        this.paramId = null;
        this.type = obj[0].name
      } else {
        this.type = null;
        this.paramId = null;
        this.masterName = null;
        this.masterParamValue = null;
      }
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.optionIdList = []
        this.type = null;
        this.labId = idList[0]
      } else {
        this.type = null;
        this.labId = null;
        this.masterName = null;
        this.masterParamValue = null;
      }
    } else if (property == 'selectedParamForName') {
      if (idList.length > 0) {
        this.masterName = obj[0].masterName;
      } else {
        this.masterName = null;

      }
    } else if (property == 'selectedParamForValue') {
      if (idList.length > 0) {
        this.masterParamValue = ''
        this.paramValueIdList = [];
        this.paramValueId = null;
        this.paramId = idList[0];
        this.getAllValueByParameterId(idList[0])
      } else {
        this.paramId = null;

      }
    } else if (property == 'selectedParamValue') {
      if (idList.length > 0) {
        this.paramValueId = idList[0];
        this.masterParamValue = null;
      } else {
        this.paramValueId = null;

      }
    }
  }

  async getAllValueByParameterId(id) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParamValue = data.data;
    }
  }

  async getAllParameterOfStones() {
    let res: any;
    res = await this.httpApiService.getAllParameterOfStones();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfStoneParameter = data.data;
    }
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParam = data.data;
    }
  }

  async addMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let jsonObj;
      if (this.type == 'Parameter Name') {
        jsonObj = {
          labId: this.labId,
          mappingType: this.type,
          inMapValue: this.masterNameIn,
          outMapValue: this.masterNameOut,
          masterName: this.masterName
        }
      } else {
        jsonObj = {
          labId: this.labId,
          mappingType: this.type,
          inMapValue: this.masterParamValue,
          outMapValue: this.masterParamValueOutMapping,
          masterId: this.paramValueId
        }
      }
      console.log(jsonObj)
      let res: any;
      res = await this.httpApiService.addLabParameterMapping(jsonObj)
      let data = res.json()
      await this.loadingController.dismiss()
      if (data.status == 401) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async updateMapping() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let jsonObj;
      if (this.type == 'Parameter Name') {
        jsonObj = {
          labId: this.labId,
          mappingType: this.type,
          masterName: this.masterName,
          inMapValue: this.masterNameIn,
          outMapValue: this.masterNameOut
        }
      } else {
        jsonObj = {
          labId: this.labId,
          mappingType: this.type,
          masterId: this.paramValueId,
          inMapValue: this.masterParamValue,
          outMapValue: this.masterParamValueOutMapping
        }
      }
      console.log(jsonObj)
      let res: any;
      res = await this.httpApiService.updateLabParameterMapping(jsonObj, this.id)
      let data = res.json()
      await this.loadingController.dismiss()
      if (data.status == 401) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async validateControls() {
    console.log(this.labId, this.masterParamValue, this.masterName, this.paramValueId, this.masterParamValue)
    if (this.type == 'Parameter Name') {
      if (this.labId != null && this.labId != undefined && this.labId != '' && this.masterName != null && this.masterName != undefined && this.masterName != '') {
        return true;
      } else {
        await this.configService.showToast('error', 'Please insert the details');
        return false;
      }
    } else if (this.type == 'Parameter Value') {
      if (this.labId != null && this.labId != undefined && this.labId != '' && this.masterParamValue != null && this.masterParamValue != undefined && this.masterParamValue != '') {
        return true;
      } else {
        await this.configService.showToast('error', 'Please insert the details');
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please select the detail');
      return false;
    }
  }

}
