import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";
import * as converter from "number-to-words"

@Component({
  selector: 'app-approval-memo',
  templateUrl: './approval-memo.component.html',
  styleUrls: ['./approval-memo.component.scss']
})
export class ApprovalMemoComponent implements OnInit {

  public date = moment().format('DD-MM-YYYY')

  @Input() companyDetails: any;
  @Input() customerDetails: any;
  @Input() stoneDetails: any = [];
  @Input() lab: any;
  @Input() transporterDetails: any;
  @Input() fxRate: any;
  @Input() deliveryChallanNo: any;

  public totalCarat = 0;
  public pricePerCts = 0;
  public numbers = []
  public totalPricePerCts = 0
  public unitPrice = 0;
  public totalAmount = 0;
  public AmountInRupees = 0;
  public AmountInWords = "";

  constructor() { }

  ngOnInit() {
    this.numbers = Array(1).fill(10);
    console.log(this.customerDetails, this.companyDetails, this.stoneDetails)
    for (let i = 0; i < this.stoneDetails.length; i++) {
      let data = []
      this.totalCarat += this.stoneDetails[i].stoneCts;
      if (this.stoneDetails[i].serviceIdList) {
        this.stoneDetails[i].serviceIdList.forEach(item => {
          data.push(item.service)
        })
        this.stoneDetails[i].services = data.toString();
      } else {
        this.stoneDetails[i].services = !!this.stoneDetails[i].services ? this.stoneDetails[i].services.toString() : '';
      }
      if (this.stoneDetails[i].requestPrice) {
        this.totalPricePerCts += this.stoneDetails[i].requestPrice;
        this.totalAmount += (this.stoneDetails[i].requestPrice * this.stoneDetails[i].stoneCts);
      }
    }

    this.unitPrice = this.totalAmount / this.totalCarat;

    this.AmountInRupees = this.totalAmount * this.fxRate
    if (!!this.AmountInRupees) {
      this.AmountInWords = converter.toWords(this.AmountInRupees)
    }
    console.log(this.stoneDetails)
  }
}
