import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-single-to-mix-transfer',
  templateUrl: './single-to-mix-transfer.component.html',
  styleUrls: ['./single-to-mix-transfer.component.scss']
})
export class SingleToMixTransferComponent implements ProcessActionComponent {
  @Input() data: any;

  public actionId: any;
  public transType = "SS";
  public process = 'issueWithRole';
  public issueWithTransType = 'YES'

  constructor() { }

  ngOnInit() {
    this.actionId = this.data.actionId;
  }

}
