import { Component, Input, ViewChild } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageMasterPage } from "../manage-master/manage-master.page";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-purchase-to-single',
  templateUrl: './purchase-to-single.component.html',
  styleUrls: ['./purchase-to-single.component.scss']
})
export class PurchaseToSingleComponent implements ProcessActionComponent {
  @Input() data: any;

  public listOfLabs = []
  public historyData = []
  public historyCount = 0;
  public historyColumns = [];
  public pageNumber = 0;
  public noOfRecords = 15;
  public selectedFile = null;
  public labId: any;

  constructor(public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events, ) { }

  ngOnInit() {
    this.historyColumns = [
      { name: "File Name", prop: "fileName" },
      { name: "Date & Time", prop: "createdDate" },
      { name: "By", prop: "username" },
      { name: "Status", prop: "status" }
    ];
    this.getAllCustomer('Lab');
    this.pageNumber = 0;
    this.noOfRecords = 20;
    this.loadUploadHistory(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Purchase To Single', (item) => {
      this.historyColumns = [
        { name: "File Name", prop: "fileName" },
        { name: "Date & Time", prop: "createdDate" },
        { name: "By", prop: "username" },
        { name: "Status", prop: "status" }
      ];
      this.getAllCustomer('Lab');
      this.loadUploadHistory(this.pageNumber, this.noOfRecords);
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Purchase To Single');
  }

  //dropdown selection

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'labList') {
      if (idList.length > 0) {
        let labId = idList[0];
        this.listOfLabs.filter(el => {
          if (el.id == labId) {
            this.labId = el.labReferenceId;
          }
        })
      } else {
        this.labId = [];
      }
    }
  }


  // get all labs

  async getAllCustomer(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      console.log("getAllCustomers", this.listOfLabs);

    }
  }


  // file object

  fileObject(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  // BULK TO SINGLE FILE UPLOAD

  async convertPurchaseToSingle() {
    console.log(this.selectedFile)
    if (this.selectedFile) {
      let jsonObj = {
        labId: this.labId,
      };
      console.log(jsonObj);
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      formData.append("myJson", JSON.stringify(jsonObj));
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.purchaseToSingle(formData);
      console.log(res.json());
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async loadUploadHistory(pageNumber, noOfRecords) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getUploadHistory(
      "Purchase To Single",
      pageNumber,
      noOfRecords
    );
    await this.loadingController.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue);
      this.historyData = dataValue.content;
      this.historyCount = dataValue.totalElements;
    }
  }

  async setPage(currentPage) {
    console.log(currentPage);
    let res: any;
    this.pageNumber = currentPage.offset;
    await this.loadUploadHistory(this.pageNumber, this.noOfRecords);
  }


  //download

  async downloadUploadedFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.downloadUploadedFile(file.id);
  }

}
