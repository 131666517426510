import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { Headers, Http } from '@angular/http';
import { ConfigService } from '../config/config.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);
  public userActions: string;

  constructor(private storage: Storage,
    private plt: Platform,
    public http: Http,
    public configService: ConfigService) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    });
  }

  async login(username, password, companyName, appName) {
    //await this.storage.set(TOKEN_KEY, 'Bearer 1234567');
    try {
      let baseUrl = await this.configService.getBaseUrl();
      let companyName = await this.configService.getFromLocation();
      let body = {
        username: username,
        password: password,
        companyName: companyName,
        appName: appName
      }
      const response = await this.http.post(baseUrl + "user-service/api/user/login", body).toPromise();
      let responseData = response.json().data;
      console.log(responseData)
      this.userActions = responseData.listOfActions;
      let token = responseData.token_type + " " + responseData.access_token;
      console.log("token received", token);
      await this.storage.set(TOKEN_KEY, token);
      this.storage.set("userData", responseData);
      this.storage.set("username", responseData.username);
      this.storage.set("scanBoardUrl", responseData.scanBoardUrl);
      this.authenticationState.next(true);
      console.log("inside auth service response", response.json());
      return response.json();
    } catch (error) {
      console.log("error", error);
      return await error;
    }
  }

  async logout() {
    await this.storage.remove(TOKEN_KEY);
    await this.storage.remove('userData')
    await this.storage.remove("username");
    await this.storage.remove("scanBoardUrl");
    this.authenticationState.next(false);
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async userData() {
    return await this.storage.get("userData");
  }

  async userName() {
    return await this.storage.get("username");
  }
}
