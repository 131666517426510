import { Component, OnInit, Input } from "@angular/core";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { ManageCustomerPage } from "../../actions/manage-customer/manage-customer.page";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent implements OnInit {
  @Input() data: any;

  public showArrows = true;
  public allCustomer: any;
  public items: any;
  public allCustomerContact: any;
  public contactList = [];
  public manageSelectedCustomer: any;
  public manageSelectedCustomerContact: any;
  public contactData = [];
  public selectedCustomer = "";
  public selectedCustomerContact = "";
  public partyName: any;
  public name: any;
  public city: any;
  public state: any;
  public zipCode: any;
  public customerMobileNo: any;
  public mobileNo: any;
  public gstin: any;
  public customerEmail: any;
  public email: any;
  public customerAddress: any;
  public customerId: any = null;
  public contactId: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationContact = -1;
  public flgBtn = null;
  public selectedKamPerson: any;
  public kamPersonList: any;
  public kamIdList = [];
  public type: any;
  public listOfType = []
  public tab = "partyDetail"
  public typeIdList = []
  public labReferenceId: any;
  public listOfLabs = [];
  public labIdList = []
  public cinNo: any;
  public termList = []
  public selectedTermList = []
  public pageNumber = 0;
  public noOfRecords = 20;
  public count: Number = 0;

  public pan: any;
  public tel: any;
  public fax: any;
  public address: any;
  public stateCode: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public country: any;

  constructor(
    public configService: ConfigService,
    public authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events
  ) { }

  async ngOnInit() {
    await this.getAllCustomer('', this.pageNumber, this.noOfRecords);
    await this.getAllType()
    await this.getAllLabs()
    await this.getAllTerm()
    await this.getDepartmentWiseKamPersonList();
    this.event.subscribe('Party Master', (item) => {
      this.getAllCustomer('', this.pageNumber, this.noOfRecords);
      this.getAllType()
      this.getAllTerm()
      this.getAllLabs()
      this.getDepartmentWiseKamPersonList();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Party Master')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'typeList') {
      if (idList.length > 0) {
        this.type = obj[0].type
      } else {
        this.type = null;
        this.typeIdList = [];
      }
    } else if (property == 'labList') {
      if (idList.length > 0) {
        this.labReferenceId = idList[0]
      } else {
        this.labReferenceId = null;
      }
    } else if (property == 'kamPerson') {
      if (idList.length > 0) {
        this.selectedKamPerson = idList[0]
      } else {
        this.selectedKamPerson = null;
      }
    } else if (property == "term") {
      if (idList.length > 0) {
        this.termList.filter(el => {
          if (el.id == idList[0]) {
            this.terms = el.termDays;
          }
        })
      } else {
        this.terms = null;
      }
    }
  }

  //term master 

  async getAllTerm() {
    let res: any;
    res = await this.httpApiService.getAllTerm();
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValueList = data.data;
      let data1 = []
      if (!!dataValueList && dataValueList.length > 0) {
        dataValueList.forEach(el => {
          if (el.isActive == true) {
            data1.push(el)
          }
        })
        this.termList = data1;
      }
      console.log("termList ", this.termList);
    }
  }

  async getAllLabs() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("lab");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfLabs = data.data;
      // this.items = this.listOfParameter;
    }
  }

  async getAllType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.listOfType.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
  }

  async getDepartmentWiseKamPersonList() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList("KAM Sales");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.kamPersonList = data.data;
      console.log("get All Customer", this.kamPersonList);
    }
  }

  async getAllCustomerContact(id) {
    let res: any;
    res = await this.httpApiService.getCustomerContactList(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allCustomerContact = data.data;
      this.contactList = this.allCustomerContact;
      console.log("get All allCustomerContact", this.allCustomerContact);
    }
  }

  getCustomerDetail(event, partyName) {
    console.log(partyName)
    this.searchCustomer(partyName);
  }

  changeSegment(event) {
    this.tab = event.detail.value;
    if (event.detail.value == "Pending") {

    } else {

    }
  }

  async searchCustomer(partyName) {
    console.log(partyName)
    this.contactData = [];
    this.labReferenceId = null;
    this.selectedCustomerContact = "";
    this.manageSelectedCustomerContact = "";
    this.contactList = [];
    this.labIdList = [];
    this.typeIdList = [];
    let tmpPartyName = "";
    let kgreferenceCode;
    this.items = this.allCustomer;
    if (partyName != undefined) {
      tmpPartyName = partyName.partyName;
      kgreferenceCode = partyName.kgreferenceCode;
      this.manageSelectedCustomer = partyName.partyName;
      this.selectedCustomer = tmpPartyName;
    } else {
      tmpPartyName = this.selectedCustomer;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (!!search.kgreferenceCode) {
        console.log("********")
        if (search.kgreferenceCode == kgreferenceCode) {
          return search;
        }
      } else {
        if (search.partyName == tmpPartyName) {
          return search;
        }
      }
    });
    console.log(selectedActionId)
    if (!!selectedActionId && selectedActionId.length > 0) {
      await this.configService.showLoader();
      this.customerId = selectedActionId[0].id;
      let res: any;
      res = await this.httpApiService.getCustomerDetails(this.customerId);
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let dataValue = data.data;
        console.log("Customer detail", dataValue);
        this.partyName = dataValue.partyName;
        this.customerAddress = dataValue.address;
        this.zipCode = dataValue.zipcode;
        this.city = dataValue.city;
        this.state = dataValue.state;
        this.pan = dataValue.pan;
        this.state = dataValue.state;
        this.fax = dataValue.fax;
        this.stateCode = dataValue.stateCode;
        this.state = dataValue.state;
        this.gstin = dataValue.gstin;
        this.customerMobileNo = dataValue.contactNo;
        this.terms = dataValue.terms;
        this.disc1 = dataValue.disc1;
        this.disc2 = dataValue.disc2;
        this.country = dataValue.country;
        this.customerEmail = dataValue.email;
        this.type = dataValue.type;
        this.selectedTermList = []
        this.termList.filter(el => {
          if (el.termDays == dataValue.terms) {
            this.selectedTermList.push({ id: el.id, name: el.name })
          }
        })
        console.log(this.selectedTermList)
        this.kamIdList = [];
        let data1 = {
          id: dataValue.kamPersonId,
          username: dataValue.kamPerson
        };
        this.kamIdList.push(data1);
        this.selectedKamPerson = dataValue.kamPersonId;
        this.listOfType.filter(el => {
          if (el.type == dataValue.type) {
            this.typeIdList = []
            this.typeIdList.push({ id: el.id, type: el.type })
          }
        })
        if (dataValue.type == "Lab") {
          this.labReferenceId = dataValue.labReferenceId;
          this.labIdList = []
          this.listOfLabs.filter(el => {
            if (el.id == dataValue.labReferenceId) {
              this.labIdList = []
              this.labIdList.push({ id: el.id, name: el.name })
            }
          })
        }

        console.log(this.selectedKamPerson, this.kamIdList, this.labIdList, this.typeIdList);
      }
    } else {
      this.pan = "";
      this.gstin = "";
      this.fax = "";
      this.labIdList = []
      this.labReferenceId = null;
      this.typeIdList = []
      this.labIdList = []
      this.tel = "";
      this.state = "";
      this.stateCode = "";
      this.address = "";
      this.terms = "";
      this.disc1 = "";
      this.type = ""
      this.disc2 = "";
    }
    await this.getAllCustomerContact(this.customerId);
  }

  async getAllCustomer(name, pageNumber, noOfRecords) {
    let res: any;
    if (!!name) {
      this.pageNumber = 0;
    }
    res = await this.httpApiService.getAllCustomerBypage(name, pageNumber, noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allCustomer = data.data.content;
      this.count = data.data.totalElements;
      this.arrowKeyLocation = -1;
      console.log("get All Customer", this.allCustomer);
    }
  }

  getCustomerContactDetail(event, partyName) {
    this.searchContact(partyName);
  }

  async searchContact(partyName) {
    let tmpPartyName = "";
    this.contactList = this.allCustomerContact;
    if (partyName != undefined) {
      tmpPartyName = partyName.contactNo;
      this.manageSelectedCustomerContact = partyName.name;
      this.selectedCustomerContact = tmpPartyName;
    } else {
      tmpPartyName = this.selectedCustomerContact;
    }
    let selectedActionId = this.contactList.filter(function (search) {
      if (search.contactNo == tmpPartyName) {
        return search;
      }
    });
    console.log("selectedActionId", selectedActionId);
    if (selectedActionId.length > 0) {
      await this.configService.showLoader();
      this.contactId = selectedActionId[0].id;
      let res: any;
      res = await this.httpApiService.getCustomerContactDetails(this.contactId);
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let dataValue = data.data;
        console.log("Contact detail", dataValue);
        this.name = dataValue.name;
        this.mobileNo = dataValue.contactNo;
        this.email = dataValue.email;
      }
    } else {
      this.name = "";
      this.customerMobileNo = "";
      this.email = "";
      this.customerAddress = ""
    }
  }

  async validateControls() {
    if (
      !!this.partyName &&
      !!this.customerEmail &&
      !!this.customerAddress &&
      !!this.zipCode &&
      !!this.city &&
      !!this.state
    ) {
      if (!(await this.configService.isValidEmail(this.customerEmail))) {
        await this.configService.showToast(
          "error",
          "Please enter correct email address"
        );
        return true;
      }
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }

  async validateControlsForContact() {
    if (
      this.name != "" &&
      this.mobileNo != "" &&
      this.email != "" &&
      this.selectedKamPerson != ""
    ) {
      if (!(await this.configService.isValidEmail(this.email))) {
        await this.configService.showToast(
          "error",
          "Please enter correct email address"
        );
        return true;
      }
      return false;
    } else {
      await this.configService.showToast("error", "Please insert the detail");
      return true;
    }
  }

  async updateCustomer() {
    let validate = await this.validateControls();
    console.log(this.selectedKamPerson)
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj = {
        partyName: this.partyName,
        address: this.customerAddress,
        zipcode: this.zipCode,
        city: this.city,
        state: this.state,
        gstin: this.gstin,
        pan: this.pan,
        fax: this.fax,
        terms: this.terms,
        country: this.country,
        disc1: this.disc1,
        cinNo: this.cinNo,
        disc2: this.disc2,
        stateCode: this.stateCode,
        labReferenceId: this.labReferenceId,
        type: this.type,
        contactNo: this.customerMobileNo,
        email: this.customerEmail,
        kamPersonId: this.selectedKamPerson
      };
      if (this.labReferenceId) {
        jsonObj.labReferenceId = this.labReferenceId;
      }
      let res: any;
      let id = this.customerId;
      console.log("jsonObj", jsonObj);
      res = await this.httpApiService.updateCustomer(id, jsonObj);
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let userData = data.data;
        console.log(userData);

        this.flgBtn = "";
        await this.getAllCustomer(this.manageSelectedCustomer, this.pageNumber, this.noOfRecords);
        await this.configService.showToast(
          "success",
          "Customer updated successfully"
        );
      }
    }
  }

  async updateCustomerContact() {
    let validate = await this.validateControlsForContact();

    if (!validate) {
      await this.configService.showLoader();
      let jsonObj = {
        name: this.name,
        contactNo: this.mobileNo,
        email: this.email,
        customerMasterId: this.customerId
      };
      let res: any;
      let id = this.contactId;
      console.log("jsonObj", jsonObj);
      res = await this.httpApiService.updateCustomerContact(id, jsonObj);
      await this.loadingController.dismiss();
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let userData = data.data;
        console.log(userData);

        this.flgBtn = "";
        await this.getAllCustomer(this.manageSelectedCustomer, this.pageNumber, this.noOfRecords);
        await this.configService.showToast(
          "success",
          "Customer updated successfully"
        );
      }
    }
  }

  async setItemsContact() {
    this.items = this.allCustomer;
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.allCustomer && this.allCustomer.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedCustomer = this.allCustomer[
              this.arrowKeyLocation
            ].partyName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.allCustomer && this.allCustomer.length > 0) {
          if (this.allCustomer.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.allCustomer.length > this.arrowKeyLocation) {
            this.manageSelectedCustomer = this.allCustomer[
              this.arrowKeyLocation
            ].partyName;
            break;
          } else {
            break;
          }
        }
    }
  }

  contactKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.contactList && this.contactList.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedCustomerContact = this.contactList[
              this.arrowKeyLocation
            ].name;
            this.selectedCustomerContact = this.contactList[
              this.arrowKeyLocation
            ].mobileNumber;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.contactList && this.contactList.length > 0) {
          if (this.contactList.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.contactList.length > this.arrowKeyLocation) {
            this.manageSelectedCustomerContact = this.contactList[
              this.arrowKeyLocation
            ].name;
            this.selectedCustomerContact = this.contactList[
              this.arrowKeyLocation
            ].mobileNumber;
            break;
          } else {
            break;
          }
        }
    }
  }
  async filterItems(ev: any) {
    this.selectedCustomer = "";
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      await this.getAllCustomer(val, this.pageNumber, this.noOfRecords);
      this.arrowKeyLocation = -1;
    } else {
      await this.getAllCustomer('', this.pageNumber, this.noOfRecords);
      this.arrowKeyLocation = -1;
    }
  }

  async filterContactItems(ev: any) {
    this.selectedCustomerContact = "";
    this.setItemsContact();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.contactList = await this.contactList.filter(function (item) {
        return item.name.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocationContact = -1;
    } else {
      this.arrowKeyLocationContact = -1;
      this.contactList = this.allCustomerContact;
    }
  }

  async openModal(id, iModal) {
    if (iModal == "addCustomerContact" && this.customerId == null) {
      await this.configService.showToast("error", "Select Customer");
    } else {
      let modalPage = await this.modalCtrl.create({
        component: ManageCustomerPage,
        backdropDismiss: false,
        componentProps: { flag: iModal, customerId: this.customerId }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modalPage.present();
    }
  }

  async handleModalDismiss(d) {
    console.log(d.data);
    if (d.data == "addCustomer") {
      await this.getAllCustomer(this.manageSelectedCustomer, this.pageNumber, this.noOfRecords);
    } else {
      await this.getAllCustomerContact(this.customerId);
    }
  }

  doInfiniteNew(infiniteScroll) {
    this.pageNumber += 1;
    console.log("infiniteScroll", infiniteScroll);
    setTimeout(() => {
      this.loadDatawithScroll(infiniteScroll);
      infiniteScroll.target.complete();
    }, 500);
  }

  async loadDatawithScroll(infiniteScroll) {
    let res: any;
    res = await this.httpApiService.getAllCustomerBypage(name, this.pageNumber, this.noOfRecords);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.arrowKeyLocation = -1;
      console.log("get All Customer", this.allCustomer);
      let allData = data.data.content;
      if (allData.length == 0) {
        infiniteScroll.target.disabled = true;
      } else {
        if (infiniteScroll) {
          Array.prototype.push.apply(this.allCustomer, allData);
          infiniteScroll.target.complete();
          console.log(
            "this.inComingStone in infinite scroll",
            this.allCustomer
          );
        }
      }
      if (this.allCustomer.length == 4000) {
        infiniteScroll.target.disabled = true;
      }

    }
  }
}
