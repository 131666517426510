import { Component, OnInit } from "@angular/core";
import {
  NavParams,
  LoadingController,
  ModalController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-manage-box-master",
  templateUrl: "./manage-box-master.page.html",
  styleUrls: ["./manage-box-master.page.scss"]
})
export class ManageBoxMasterPage implements OnInit {
  public item: any;
  public flag: any;
  public deptList: any;
  public shapeList: any;
  public clarityList: any;
  public colorList: any;
  public colorIds = [];
  public department: any;
  public label: any;
  public RFId: any;
  public type: any;
  public shapeIds = [];
  public selectedDept: any = ["Select Department"];
  public ctsFromSize: any;
  public ctsToSize: any;
  public clarityIds = [];
  public remark: any;
  public capacity: number;
  public position: any;
  public userId: any;
  public userSelect: any;
  public getUser: any = [];
  public getAllUserData: any;
  public boxTypeList = [];
  public selectedItems: any;
  public shapeIdList: any;
  public colorIdList: any;
  public clarityIdList: any;
  public userIdList: any;
  public typeIdList: any;
  public currentStoneCount: any;
  public departmentName: any;
  public transTypeId: any = [];
  public transTypeName = "";
  public listOfTransType = [];
  public name: any;
  public criteriaId: any;
  public boxId: any;
  public selectionType: any;
  public allRoles = []
  public roleId: any;
  public hideButton: boolean = false;
  public boardBaseUrl: any;

  constructor(
    public navParams: NavParams,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events
  ) {
    this.item = this.navParams.get("item");
    this.flag = this.navParams.get("flag");
    this.boxId = this.navParams.get("boxId");
    this.transTypeName = this.navParams.get("transType");
    console.log(this.item, this.flag, this.boxId, this.transTypeName);
  }

  async ngOnInit() {
    this.getUserData()
    if (this.transTypeName == "SS") {
      this.selectionType = false;
    } else if (this.transTypeName == "MIX") {
      this.selectionType = true;
    } else {
      this.selectionType = false;
      this.hideButton = true;
    }
    console.log("************", this.hideButton)
    await this.getAllRole()
    if (this.flag == 0) {
      await this.getAllUser();
      await this.getBoxType();
      await this.getAllTransType();
    } else {
      await this.getAllShape();
      await this.getAllClarity();
      await this.getAllColour();
    }
    if (this.flag == 2) {
      this.criteriaId = this.item.id;
      await this.getBoxCriteriaById();
      console.log(this.criteriaId)
    }
  }

  async getUserData() {
    let userData = await this.authService.userData();
    if (userData.scanBoardUrl) {
      this.boardBaseUrl = userData.scanBoardUrl;
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "color") {
      this.colorIds = idList;
    } else if (property == "shape") {
      this.shapeIds = idList;
    } else if (property == "clarity") {
      this.clarityIds = idList;
    } else if (property == "user") {
      this.userSelect = idList[0];
    } else if (property == "boxType") {
      this.type = idList[0];
    } else if (property == "transType") {
      this.transTypeId = idList[0];
    } else if (property == "roleForBox") {
      this.roleId = idList[0];
    }
  }

  async getAllRole() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allRoles = data.data;
    }
  }

  async getBoxCriteriaById() {
    let res: any;
    res = await this.httpApiService.getBoxCriteriaById(this.criteriaId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      let val = data.data;
      this.ctsFromSize = parseFloat(val.ctsFromSize);
      this.ctsToSize = parseFloat(val.ctsToSize);
      this.name = val.name;
      if (val.listOfShapes) {
        this.shapeIdList = [];
        val.listOfShapes.forEach(element => {
          let data = { id: element.shapeId, name: element.shapeName };
          this.shapeIdList.push(data);
          this.shapeIds.push(element.shapeId);
        });
      }
      if (val.listOfColors) {
        this.colorIdList = [];
        val.listOfColors.forEach(element => {
          let data = { id: element.colorId, name: element.colorName };
          this.colorIdList.push(data);
          this.colorIds.push(element.colorId);
        });
      }
      if (val.listOfClarities) {
        this.clarityIdList = [];
        val.listOfClarities.forEach(element => {
          let data = { id: element.clarityId, name: element.clarityCode };
          this.clarityIdList.push(data);
          this.clarityIds.push(element.clarityId);
        });
      }
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      this.listOfTransType = data.data;
    }
  }

  async getBoxType() {
    await this.getDepartment();
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let list = [];
      let dataValue = data.data;
      dataValue.forEach(el => {
        if (this.departmentName == "Assortment") {
          list.push(el);
        } else {
          if (el.type == "Regular") list.push(el);
        }
      });
      this.boxTypeList = list;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async getAllUser() {
    let res: any;
    let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllUser(fromLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllUserData = data.data;
      console.log("get All User Data", this.getAllUserData);
      let data1 = await this.authService.userData();
      this.userId = data1.userId;
      this.getUser = [];
      this.getAllUserData.forEach(element => {
        if (element.id == this.userId) {
          this.getUser.push(element);
        }
      });
      console.log("QWQW", this.getUser);
    }
  }

  async getDepartment() {
    let userData = await this.authService.userData();
    this.departmentName = userData.departmentName;
    this.department = userData.departmentId;
  }

  async getAllShape() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("shape");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.shapeList = data.data;
    }
    // console.log("shapeList", this.shapeList);
  }

  async getAllColour() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("color");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.colorList = data.data;
      // console.log("colorList", this.colorList);
    }
  }

  async getAllClarity() {
    let res: any;
    res = await this.httpApiService.getAllDataFor("clarity");
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.clarityList = data.data;
      // console.log("clarityList", this.clarityList);
    }
  }

  async addBox() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj = {
        departmentId: this.department,
        transTypeId: this.transTypeId,
        userId: this.userSelect,
        label: this.label,
        rfid: this.RFId,
        maxCapacity: this.capacity,
        currentStoneCount: 0,
        position: this.position,
        roleId: this.roleId,
        remarks: this.remark,
        boxTypeId: this.type
      };
      let res: any;
      console.log(jsonObj);
      res = await this.httpApiService.addBox(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss();
      }
    }
  }

  // Add Criteria

  async addCriteria() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObjForCriteria = {
        name: this.name,
        boxId: this.boxId,
        ctsFromSize: parseFloat(this.ctsFromSize),
        ctsToSize: parseFloat(this.ctsToSize),
        shapeIds: this.shapeIds,
        clarityIds: this.clarityIds,
        colorIds: this.colorIds
      };
      console.log(jsonObjForCriteria);
      res = await this.httpApiService.addBoxCriteria(jsonObjForCriteria);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Please fill all details");
    }
  }

  async updateCriteria() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObjForCriteria = {
        name: this.name,
        boxId: this.boxId,
        ctsFromSize: parseFloat(this.ctsFromSize),
        ctsToSize: parseFloat(this.ctsToSize),
        shapeIds: this.shapeIds,
        clarityIds: this.clarityIds,
        colorIds: this.colorIds
      };
      console.log(jsonObjForCriteria);
      res = await this.httpApiService.updateCriteriaById(
        this.criteriaId,
        jsonObjForCriteria
      );
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Please fill all details");
    }
  }

  async scanHere() {
    let res;
    if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
      await this.configService.showLoader();
      res = await this.httpApiService.scanByBoard(this.boardBaseUrl);
      let data = res.json();
      console.log(data);
      if (data.type == "error") {
        this.loadingController.dismiss();
        await this.configService.showToast("error", data.type);
        this.RFId = null;
      } else {
        if (data.result.length > 0) {
          if (data.result.length > 1) {
            await this.configService.showToast("error", "More than one RFID found!");
          } else {
            this.RFId = data.result;
          }
        } else {
          this.RFId = null;
        }
        this.loadingController.dismiss();
      }
    } else {
      await this.configService.showToast("error", "Scanner Board Url not found");
    }
  }

  async validateControls() {
    console.log(
      this.department,
      this.label,
      this.RFId,
      this.capacity,
      this.position,
      this.type
    );
    if (this.flag == 0) {
      if (
        this.department != "" &&
        this.department != null &&
        this.department != undefined &&
        this.label != "" &&
        this.label != null &&
        this.label != undefined &&
        this.RFId != "" &&
        this.RFId != null &&
        this.RFId != undefined &&
        this.capacity != null &&
        this.transTypeId != null &&
        this.transTypeId != undefined &&
        this.transTypeId != "" &&
        this.capacity != undefined &&
        this.position != "" &&
        this.position != null &&
        this.position != undefined &&
        this.remark != "" &&
        this.remark != null &&
        this.remark != undefined &&
        this.type != "" &&
        this.type != null &&
        this.type != undefined
      ) {
        if (this.capacity > 0) {
          return false;
        } else {
          await this.configService.showToast("error", "Please enter valid value");
          return true;
        }
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return true;
      }
    } else if (this.flag == 1) {
      if (
        this.name != null &&
        this.name != undefined &&
        this.name != "" &&
        this.ctsFromSize != null &&
        this.ctsFromSize != undefined &&
        this.ctsToSize != null &&
        this.ctsToSize != undefined &&
        this.boxId != undefined &&
        this.clarityIds.length > 0 &&
        this.shapeIds.length > 0 &&
        this.colorIds.length > 0
      ) {
        return false;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return true;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }
}
