import { Component, OnInit, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";

@Component({
  selector: 'app-lab-conversion-rate-master',
  templateUrl: './lab-conversion-rate-master.component.html',
  styleUrls: ['./lab-conversion-rate-master.component.scss']
})
export class LabConversionRateMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public type = "lab"

  constructor() { }

  ngOnInit() {
  }

}
