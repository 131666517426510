import { Component, OnInit, Input } from '@angular/core';
import * as moment from "moment";

@Component({
  selector: 'app-delivery-challan',
  templateUrl: './delivery-challan.component.html',
  styleUrls: ['./delivery-challan.component.scss']
})
export class DeliveryChallanComponent implements OnInit {

  @Input() companyDetails: any;
  @Input() customerDetails: any;
  @Input() stoneDetails: any = [];
  @Input() transType: any;
  @Input() nameOfPersonReceivingGood: any;
  @Input() deliveryChallanNo: any;
  @Input() requestAction: any;
  @Input() status: any;
  @Input() salesPersonName: any;
  @Input() terms: number;
  @Input() disc1: number;
  @Input() disc2: number;
  @Input() fxRate: number;
  @Input() transferBy: any;
  @Input() remarks: any;

  public totalCarat: Number = 0;
  public pricePerCts = 0;
  public numbers = []
  public totalPricePerCts = 0
  public totalAmount = 0;
  public AmountInRupees = 0

  public deliveryDate: any;
  constructor() { }

  ngOnInit() {
    this.totalCarat = 0
    this.totalAmount = 0;
    this.AmountInRupees = 0;
    console.log(this.terms, this.salesPersonName, this.requestAction, this.transferBy, this.stoneDetails, this.nameOfPersonReceivingGood, this.disc2)
    this.numbers = Array(75).fill(10);
    this.deliveryDate = moment().format('DD/MM/YYYY')
    if (this.status == 'Internal') {
      for (let i = 0; i < this.stoneDetails.length; i++) {
        this.totalCarat = this.totalCarat + (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts);
        if (this.stoneDetails[i].requestPrice) {
          this.totalPricePerCts += this.stoneDetails[i].requestPrice;
          this.totalAmount = this.totalAmount + (((this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price) * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts)));
        }
      }
      this.AmountInRupees = this.totalAmount * this.fxRate
    } else {
      for (let i = 0; i < this.stoneDetails.length; i++) {
        this.salesPersonName = this.stoneDetails[0].requestUser;
        this.totalCarat = this.totalCarat + (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts);
        if (this.stoneDetails[i].requestPrice || this.stoneDetails[i].price) {
          this.totalPricePerCts += this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price;
          this.totalAmount = this.totalAmount + (((this.stoneDetails[i].requestPrice ? this.stoneDetails[i].requestPrice : this.stoneDetails[i].price) * (!!this.stoneDetails[i].size ? parseFloat(this.stoneDetails[i].size) : this.stoneDetails[i].stoneCts)));
        }
      }
      this.AmountInRupees = this.totalAmount * this.fxRate
    }

    console.log(this.totalCarat, this.totalAmount, this.totalPricePerCts)
  }

  //stoneId

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

}
