import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  Content,
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { Storage } from "@ionic/storage";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { DragulaService } from "ng2-dragula";
import * as moment from "moment";
import { multisort } from "./multisort";
import { SelectionOnSortPage } from "../../../components/actions/selection-on-sort/selection-on-sort.page";

@Component({
  selector: "app-sort",
  templateUrl: "./sort.component.html",
  styleUrls: ["./sort.component.scss"]
})
export class SortComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild(Content) content: Content;

  displayLoader: boolean = true;
  sorting: boolean = true;
  myInput = "";
  matched = [];
  displayReturn: boolean = true;
  scanned: any = [];
  mainJson: any = [];
  listOfStone: any = [];
  Shape = [];
  ShapeSelect = [];
  Color = [];
  ColorSelect = [];
  Clarity = [];
  ClaritySelect = [];
  loadFirstData: any = [
    {
      title: "Shape",
      value: false
    },
    {
      title: "Cts",
      value: false
    },
    {
      title: "Color",
      value: true
    },
    {
      title: "Clarity",
      value: true
    }
  ];
  ShapeCurrent = "";
  ColorCurrent = "";
  ClarityCurrent = "";
  current = 0;
  requestData = [];
  Repeat = false;
  group = ["Shape", "Cts", "Color", "Clarity"];
  singleValue4 = 0.75;
  min = 0;
  max = 0;
  array = [{ start: 0 }, { start: 1 }, { start: 2 }, { start: 3 }];

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    private storage: Storage,
    private dragulaService: DragulaService,
  ) {
    this.dragulaServiceModel();
    let Shape = (this.Shape = []);
    let Color = (this.Color = []);
    let Clarity = (this.Clarity = []);
    let max = this.max;
    this.mainJson.forEach(function(key) {
      if (Shape.indexOf(key.Shape) == -1) {
        Shape.push(key.Shape);
      }
      if (Color.indexOf(key.Color) == -1) {
        Color.push(key.Color);
      }
      if (Clarity.indexOf(key.Clarity) == -1) {
        Clarity.push(key.Clarity);
      }
      if (key.Cts > max) {
        max = key.Cts;
      }
    });

    this.max = max;
    this.ShapeSelect = this.Shape = Shape;
    this.ColorSelect = this.Color = Color;
    this.ClaritySelect = this.Clarity = Clarity;

    this.ResetRecord();
  }

  ngOnInit() {
  }

  dragulaServiceModel() {
    this.storage.get("loadFirstData").then(loadFirstData => {
      if (loadFirstData) {
        this.loadFirstData = loadFirstData;
      }
    });

    this.storage.get("array").then(array => {
      if (array) {
        this.array = array;
      }
    });
    let selector = "bag" + Math.random();
    console.log("selector");
    this.dragulaService.createGroup(selector, {
      moves: function(el, container, handle) {
        return handle.className.indexOf("handle") > -1;
      },
      mirrorContainer: document.getElementsByTagName("ion-app")[0]
    });

    this.dragulaService.dropModel("bag").subscribe(({ item }) => {
      console.log(item);
      console.log(this.loadFirstData);
      this.storage.set("loadFirstData", this.loadFirstData);
      this.ResetRecord();
    });
  }

  groupBy(array, f) {
    var groups = {};
    array.forEach(function(o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(group => {
      return groups[group];
    });
  }

  findAllMatches() {
    if (!!this.Repeat) {
      return this.mainJson;
    } else {
      let newJSon = this.mainJson;
      let finaleJson = [];
      let loadFirstData = this.loadFirstData;
      let singleValue4 = this.singleValue4;
      let all = this.array;
      // console.log(all);
      newJSon.forEach(function(key) {
        var newArray = [];
        for (let i = 0; i <= 3; i++) {
          if (loadFirstData[i].value) {
            if (loadFirstData[i].title == "Cts") {
              let className: any = "";
              for (let length = all.length; length != 0; length--) {
                if (
                  parseFloat(key[loadFirstData[1].title]) <
                  all[length - 1].start
                ) {
                  className = all[length - 1].start;
                }
              }
              if (!className) {
                className = all[all.length - 1].start;
              }

              // let value: number =
              //   parseFloat(key[loadFirstData[1].title]) / singleValue4;
              // let str: string = String(value);
              newArray.push(parseFloat(className));
            } else {
              // console.log(key[loadFirstData[i].title])
              newArray.push(key[loadFirstData[i].title]);
            }
          }
        }
        // console.log(newArray)
        key["className"] = newArray.join("-");
        finaleJson.push(key);
      });
      multisort(finaleJson, this.group);
      this.mainJson = finaleJson;
      this.requestData = this.groupBy(this.mainJson, item => {
        return [item.className];
      });
      console.log( this.requestData)
      this.Repeat = true;
      return finaleJson;
    }
  }

  enable(box) {
    box.value = !box.value;
    this.ResetRecord();
  }
  ResetRecord() {
    this.group = [];
    for (let i = 0; i <= 3; i++) {
      if (this.loadFirstData[i].value) {
        this.group.push(this.loadFirstData[i].title);
      }
    }
    this.Repeat = false;
  }

  // ledOnDirect(rfid) {
  //   this._RfidScannerProvider.ledOn(rfid);
  // }

  ledOn(what) {
    console.log(what)
    switch (what) {
      case "current":
        break;
      case "next":
        this.current++;

        break;
      case "prev":
        this.current--;
        break;
    }
    console.log( this.requestData)
    this.matched = [];
    this.requestData[this.current].forEach(value => {
      this.matched.push(value.RFID);
    });

    let id = setInterval(() => {
      let position = 0;
      if (document.getElementById(this.matched[0])) {
        let newClassObj = document.getElementById(this.matched[0]);
        if (newClassObj) {
          position = newClassObj.offsetTop;
        }
      }
      position -= 75;
      // this.content.scrollTo(position, position);
      // this.ledOnDirect(this.matched);
      clearInterval(id);
    });
  }

  select(rfid, matched, defaultMatch = [], addRefid: boolean = false) {
    if (addRefid) {
      let index = matched.indexOf(rfid);
      if (index === -1) {
        matched.push(rfid);
      } else {
        matched.splice(index, 1);
      }
    } else {
      let index = defaultMatch.indexOf(rfid);
      if (index !== -1) {
        let index = matched.indexOf(rfid);
        if (index === -1) {
          matched.push(rfid);
        }
        defaultMatch.splice(index, 1);
      }
      return matched.indexOf(rfid) !== -1;
    }
  }

 async ledOnDirect(match, RFID: string = "", newMatch = []) {
    let response = [];
    if (!!RFID) {
      response = [];
      match.forEach(item => {
        response.push(item[RFID]);
        if (newMatch) {
          newMatch.push(item[RFID]);
        }
      });
    } else {
      response = match;
    }
    console.log(match,response)
    if (response.length !== 0) {
      await this.configService.showToast('success','Record Selected');
      // let alert = this.alertCtrl.create({
      //   title: "RFID LED ON",
      //   subTitle: "This RFID Scannes : " + response.length,
      //   enableBackdropDismiss: false
      // });
      // alert.present();
      // let alertCtrl = this.alertCtrl;
      // let _DatabaseProvider = this._DatabaseProvider;
      // this._DatabaseProvider.scanWithLightOn(
      //   response,
      //   function(success) {
      //     alert.dismiss();
      //     console.log(success);
      //     alert = alertCtrl.create({
      //       title: "RFID LED ON",
      //       subTitle: success.result,
      //       enableBackdropDismiss: false,
      //       buttons: [
      //         {
      //           text: "Cancel",
      //           role: "Dismiss",
      //           handler: () => {
      //             _DatabaseProvider.LedOff();
      //           }
      //         }
      //       ]
      //     });
      //     alert.present();
      //   },
      //   function(error) {
      //     alert.dismiss();
      //     console.log(error);
      //   }
      // );
    } else {
      await this.configService.showToast('error','Please Select One Record');
    }
  }


  async openModel() {
    const modal = await this.modalCtrl.create({
      component: SelectionOnSortPage,
      backdropDismiss: false,
      componentProps: {
        array : this.array
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    console.log(d)
    this.array = d.data;
        this.Repeat = false;
  }


  async scan() {
    const loading = await this.loadingController.create({
      message: "Please Wait..",
      duration: 2000
    });
    await loading.present();
    this.listOfStone = [
      {
        Stone_id: "WFWO-8155",
        Cts: "1.7",
        Shape: "ROUND",
        Color: "E",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725476169"
      },
      {
        Stone_id: "WFSL-0342",
        Cts: "2.03",
        Shape: "ROUND",
        Color: "D",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720858998"
      },
      {
        Stone_id: "WRWS-10916",
        Cts: "1.5",
        Shape: "ROUND",
        Color: "L",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725968984"
      },
      {
        Stone_id: "WFSO-4230",
        Cts: "1",
        Shape: "ROUND",
        Color: "E",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100724985402"
      },
      {
        Stone_id: "WFSU-7649",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100724944756"
      },
      {
        Stone_id: "WFWS-0747",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "VGood",
        RFID: "100720832772"
      },
      {
        Stone_id: "WFWS-4526",
        Cts: "2.01",
        Shape: "ROUND",
        Color: "K",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100722982516"
      },
      {
        Stone_id: "WFWW-1532",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720668332"
      },
      {
        Stone_id: "WFWO-1466",
        Cts: "1.04",
        Shape: "ROUND",
        Color: "K",
        Clarity: "IF",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100721055592"
      },
      {
        Stone_id: "WUSO-1312",
        Cts: "1.5",
        Shape: "ROUND",
        Color: "D",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100723318129"
      },
      {
        Stone_id: "WUSO-2565",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "K",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727843220"
      },
      {
        Stone_id: "WUSO-9271-FE",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "D",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720501209"
      },
      {
        Stone_id: "WUSW-4196",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "K",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725517815"
      },
      {
        Stone_id: "WFWO-3503",
        Cts: "1.71",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720626775"
      },
      {
        Stone_id: "WFWO-2659",
        Cts: "1.19",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725428997"
      },
      {
        Stone_id: "WFSF-1297",
        Cts: "1.7",
        Shape: "ROUND",
        Color: "D",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100724014442"
      },
      {
        Stone_id: "WUSO-1262",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "H",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100723319696"
      },
      {
        Stone_id: "WUSW-4555",
        Cts: "1.33",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725506727"
      },
      {
        Stone_id: "WUSW-4577",
        Cts: "1.02",
        Shape: "ROUND",
        Color: "L",
        Clarity: "IF",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720491955"
      },
      {
        Stone_id: "WUSW-8215-JA",
        Cts: "2.56",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100723890853"
      },
      {
        Stone_id: "WUSO-3499",
        Cts: "1.71",
        Shape: "ROUND",
        Color: "K",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727841777"
      },
      {
        Stone_id: "WFSF-1241",
        Cts: "1.7",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100724937908"
      },
      {
        Stone_id: "WUSN-9656",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100725936434"
      },
      {
        Stone_id: "WUSN-1951",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725680730"
      },
      {
        Stone_id: "WUSN-4909",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725721367"
      },
      {
        Stone_id: "WUSN-10816",
        Cts: "1.05",
        Shape: "ROUND",
        Color: "K",
        Clarity: "IF",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728053302"
      },
      {
        Stone_id: "WUSD-8789",
        Cts: "2.04",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726049731"
      },
      {
        Stone_id: "WUSN-9985",
        Cts: "1",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728044254"
      },
      {
        Stone_id: "WUSN-8085",
        Cts: "1",
        Shape: "ROUND",
        Color: "H",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726022203"
      },
      {
        Stone_id: "WUSN-11468",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "F",
        Clarity: "VVS1",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100728034744"
      },
      {
        Stone_id: "WUSN-10823",
        Cts: "1.71",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728053413"
      },
      {
        Stone_id: "WUSN-0074",
        Cts: "1.4",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725902802"
      },
      {
        Stone_id: "WUSO-9720",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "E",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726043562"
      },
      {
        Stone_id: "WUSN-3539",
        Cts: "0.91",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720761541"
      },
      {
        Stone_id: "WUSD-4583",
        Cts: "0.8",
        Shape: "ROUND",
        Color: "K",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726047938"
      },
      {
        Stone_id: "WUSN-8169",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100720854858"
      },
      {
        Stone_id: "WUSO-0636",
        Cts: "1.02",
        Shape: "ROUND",
        Color: "E",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725995220"
      },
      {
        Stone_id: "WUSO-10455",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725650323"
      },
      {
        Stone_id: "WUSN-9243",
        Cts: "1.75",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727725049"
      },
      {
        Stone_id: "WUSD-7741",
        Cts: "1",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100726046730"
      },
      {
        Stone_id: "WUSE-3015",
        Cts: "1.71",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728033516"
      },
      {
        Stone_id: "WUSD-7065",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726027686"
      },
      {
        Stone_id: "WUSE-0318",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725983505"
      },
      {
        Stone_id: "WUSD-0834",
        Cts: "1",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725949580"
      },
      {
        Stone_id: "WUSN-7178",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100726030007"
      },
      {
        Stone_id: "WUSD-2968",
        Cts: "1",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726014532"
      },
      {
        Stone_id: "WUSE-1650",
        Cts: "1.7",
        Shape: "ROUND",
        Color: "M",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726008615"
      },
      {
        Stone_id: "WUSE-0555",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "L",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726027259"
      },
      {
        Stone_id: "WUSD-2402",
        Cts: "0.84",
        Shape: "ROUND",
        Color: "K",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726009070"
      },
      {
        Stone_id: "WUSD-0085",
        Cts: "1.7",
        Shape: "ROUND",
        Color: "N",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726021356"
      },
      {
        Stone_id: "WUSD-8176",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726037218"
      },
      {
        Stone_id: "WUSD-0845",
        Cts: "1",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726008385"
      },
      {
        Stone_id: "WUSD-2891",
        Cts: "0.81",
        Shape: "ROUND",
        Color: "H",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100725984051"
      },
      {
        Stone_id: "WUSD-1704",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726046904"
      },
      {
        Stone_id: "WUSD-1604",
        Cts: "1.22",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726013658"
      },
      {
        Stone_id: "WUSD-4385",
        Cts: "1.02",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726037477"
      },
      {
        Stone_id: "WUSD-7540",
        Cts: "0.8",
        Shape: "ROUND",
        Color: "K",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726036531"
      },
      {
        Stone_id: "WUSE-0586",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726022364"
      },
      {
        Stone_id: "WUSE-1619",
        Cts: "1.85",
        Shape: "ROUND",
        Color: "E",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725989128"
      },
      {
        Stone_id: "WUSE-1647",
        Cts: "1.6",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725987609"
      },
      {
        Stone_id: "WUSE-0694",
        Cts: "0.91",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726035072"
      },
      {
        Stone_id: "WUSD-2534",
        Cts: "1.2",
        Shape: "ROUND",
        Color: "H",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726041813"
      },
      {
        Stone_id: "WUSD-9567",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "F",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726020705"
      },
      {
        Stone_id: "WUSO-11401",
        Cts: "1.61",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726020655"
      },
      {
        Stone_id: "WUSE-1376",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VVS2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100727671599"
      },
      {
        Stone_id: "WUSN-10289",
        Cts: "1.34",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726013757"
      },
      {
        Stone_id: "WUSN-10806",
        Cts: "1.4",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726040835"
      },
      {
        Stone_id: "WUSN-9935",
        Cts: "1.37",
        Shape: "ROUND",
        Color: "L",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726012673"
      },
      {
        Stone_id: "WUSN-10416",
        Cts: "0.8",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728040211"
      },
      {
        Stone_id: "WUSD-7959",
        Cts: "1",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726046025"
      },
      {
        Stone_id: "WUSD-7370",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "E",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726042597"
      },
      {
        Stone_id: "WUSD-2250",
        Cts: "2",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728044177"
      },
      {
        Stone_id: "WUSE-0766",
        Cts: "1.2",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726022264"
      },
      {
        Stone_id: "WUSD-8254",
        Cts: "0.8",
        Shape: "ROUND",
        Color: "F",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726020887"
      },
      {
        Stone_id: "WUSE-2455",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VS2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100726026577"
      },
      {
        Stone_id: "WUSE-0875",
        Cts: "0.73",
        Shape: "ROUND",
        Color: "G",
        Clarity: "IF",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726013923"
      },
      {
        Stone_id: "WUSE-0809",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "J",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726026718"
      },
      {
        Stone_id: "WUSN-9681",
        Cts: "2.01",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS1",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100725916187"
      },
      {
        Stone_id: "WUSE-1290",
        Cts: "1.2",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727677156"
      },
      {
        Stone_id: "WUSE-4950",
        Cts: "1.2",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727749666"
      },
      {
        Stone_id: "WUSE-7188",
        Cts: "0.72",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727721141"
      },
      {
        Stone_id: "WUSE-0867",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "VGood",
        RFID: "100725969474"
      },
      {
        Stone_id: "WUSE-7321",
        Cts: "1",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "VGood",
        RFID: "100727718223"
      },
      {
        Stone_id: "WUSE-7139",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727712897"
      },
      {
        Stone_id: "WUSE-8922",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100725767612"
      },
      {
        Stone_id: "WUSE-8955",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI1",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100725796016"
      },
      {
        Stone_id: "WUSE-9532",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "M",
        Clarity: "VS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727717117"
      },
      {
        Stone_id: "WUSD-8036-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728131709"
      },
      {
        Stone_id: "WUSD-8042-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728132992"
      },
      {
        Stone_id: "WUSD-8051-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728134015"
      },
      {
        Stone_id: "WUSD-8091-AP",
        Cts: "0.91",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728131579"
      },
      {
        Stone_id: "WUSD-8092-AP",
        Cts: "0.91",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728131638"
      },
      {
        Stone_id: "WUSD-8096-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728132607"
      },
      {
        Stone_id: "WUSD-8098-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728131759"
      },
      {
        Stone_id: "WUSD-8099-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728131561"
      },
      {
        Stone_id: "WUSD-8100-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728131685"
      },
      {
        Stone_id: "WUSD-8102-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728134281"
      },
      {
        Stone_id: "WUSD-9161-AP",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100728131920"
      },
      {
        Stone_id: "WUSE-0594",
        Cts: "0.77",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725971793"
      },
      {
        Stone_id: "WUSE-7307",
        Cts: "1.01",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VVS1",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100727711244"
      },
      {
        Stone_id: "WUSE-2887",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "G",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728033726"
      },
      {
        Stone_id: "WUSE-3145",
        Cts: "0.37",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726049902"
      },
      {
        Stone_id: "WUSE-7803",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "E",
        Clarity: "VVS2",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727694847"
      },
      {
        Stone_id: "WUSE-3220",
        Cts: "0.37",
        Shape: "ROUND",
        Color: "E",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728032969"
      },
      {
        Stone_id: "WUSE-2842",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "I",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100728033094"
      },
      {
        Stone_id: "WUSE-8384",
        Cts: "0.37",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727681092"
      },
      {
        Stone_id: "WUSE-4788",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727716219"
      },
      {
        Stone_id: "WUSE-7160",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727725432"
      },
      {
        Stone_id: "WUSE-4930",
        Cts: "1.76",
        Shape: "ROUND",
        Color: "F",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727748131"
      },
      {
        Stone_id: "WUSE-2778",
        Cts: "0.61",
        Shape: "ROUND",
        Color: "D",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727669611"
      },
      {
        Stone_id: "WUSE-4785",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "F",
        Clarity: "VS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100727717225"
      },
      {
        Stone_id: "WUSE-4869",
        Cts: "0.9",
        Shape: "ROUND",
        Color: "J",
        Clarity: "VVS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100727719501"
      },
      {
        Stone_id: "WUSE-7175",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "G",
        Clarity: "SI1",
        Cut: "EXCL",
        Polish: "VGood",
        RFID: "100727715035"
      },
      {
        Stone_id: "WUSE-7512",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "F",
        Clarity: "VVS2",
        Cut: "Good",
        Polish: "Excl",
        RFID: "100727718496"
      },
      {
        Stone_id: "WUSE-1715",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VVS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726011914"
      },
      {
        Stone_id: "WUSE-1612",
        Cts: "2.52",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100726034339"
      },
      {
        Stone_id: "WUSE-1086",
        Cts: "0.7",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VS1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726031934"
      },
      {
        Stone_id: "WUSN-7570",
        Cts: "1.2",
        Shape: "ROUND",
        Color: "I",
        Clarity: "VS1",
        Cut: "EXCL",
        Polish: "Excl",
        RFID: "100725336006"
      },
      {
        Stone_id: "WUSE-2357",
        Cts: "0.75",
        Shape: "ROUND",
        Color: "E",
        Clarity: "SI1",
        Cut: "VGood",
        Polish: "Excl",
        RFID: "100726024911"
      },
      {
        Stone_id: "WUSE-0743",
        Cts: "0.6",
        Shape: "ROUND",
        Color: "H",
        Clarity: "VS2",
        Cut: "EXCL",
        Polish: "VGood",
        RFID: "100726021938"
      }
    ];
      this.Repeat = false;
        this.mainJson = this.listOfStone;
    this.findAllMatches();
  }
}
