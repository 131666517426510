import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { PriceNameMasterPage } from "../price-name-master/price-name-master.page";

@Component({
  selector: 'app-policy-tracker',
  templateUrl: './policy-tracker.component.html',
  styleUrls: ['./policy-tracker.component.scss']
})
export class PolicyTrackerComponent implements ProcessActionComponent {
  @Input() data: any;

  public allPriceNameList = [];
  public priceListNameId: any;
  public stoneId: any;
  public columns = [];
  public listOfRules = [];
  public page = 'policyTracker';
  public count = 0;
  public basePrice: any;
  public discountPercent: any;
  public ratePerCarat: any;
  public derivedPrice: any

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events) {
    this.columns = [{ name: "Sr No", prop: "", width: "10" }, { name: "Policy Name", prop: "policyName" }, { name: "Block Name", prop: "blockName" }, { name: "Rules", prop: "name" }, { name: "Disc % / Fixed Price", prop: "discPer" }]
  }

  ngOnInit() {
    this.getAllPriceListName()
    this.event.subscribe('Policy Tracker', (item) => {
      this.getAllPriceListName();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Policy Tracker');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "PriceListName") {
      if (idList.length > 0) {
        this.priceListNameId = idList[0];
        console.log(this.stoneId, this.priceListNameId)
      } else {
        this.priceListNameId = null;
      }
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async trackStone() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getStonePriceAndRulesFronStoneId(this.stoneId, this.priceListNameId)
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.listOfBlockRules) {
        this.listOfRules = dataValue.listOfBlockRules;

        this.count = this.listOfRules.length;
        this.basePrice = dataValue.basePrice;
        this.discountPercent = dataValue.discountPercent;
        this.ratePerCarat = dataValue.price;
        if (dataValue.listOfStoneDetails && dataValue.listOfStoneDetails.length > 0) {
          let stonects = dataValue.listOfStoneDetails[0].cts;
          this.derivedPrice = this.ratePerCarat * stonects
        }

      } else {
        this.listOfRules = []
        this.basePrice = dataValue.basePrice;
        this.discountPercent = dataValue.discountPercent;
        this.ratePerCarat = dataValue.price;
        this.count = 0
        if (dataValue.listOfStoneDetails && dataValue.listOfStoneDetails.length > 0) {
          let stonects = dataValue.listOfStoneDetails[0].cts;
          this.derivedPrice = this.ratePerCarat * stonects
        }
      }
      console.log(dataValue, this.listOfRules)
    }
  }

}
