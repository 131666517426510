import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ValueAccessor } from "@ionic/angular/dist/directives/control-value-accessors/value-accessor";
import { splitAtColon } from "@angular/compiler/src/util";

@Component({
  selector: 'app-manage-rules',
  templateUrl: './manage-rules.page.html',
  styleUrls: ['./manage-rules.page.scss'],
})
export class ManageRulesPage implements OnInit {

  //Parameter stone

  public getAllStoneParam = [];
  public listOfParamValue = [];
  public valueIdList = [];
  public paramIdList = [];
  public listOfOption = [];
  public optionIdList = [];
  public parameter = [];
  public value = [];
  public option = [];
  public array = [];
  public listOfBlockRuleDetails = [];
  public ctsFromSize: any;
  public ctsToSize: any;
  public disc: number;
  public blockId: any;
  public item: any;
  public id: any;
  public flagAddUpdate: any;
  public name: any;

  constructor(
    private authService: AuthenticationService,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.blockId = this.navParams.get('blockId');
    console.log(this.blockId)
    let data = this.navParams.get('item');
    if (data != 0) {
      this.item = data.row;
      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    console.log(this.flagAddUpdate)
    if (this.flagAddUpdate == 'update') {
      this.getRulePamramById(this.id)
    } else {
      this.getAllStoneMaster();
    }
    this.listOfOption = [{ "id": "1", "type": "Is", "status": true }, { "id": "2", "type": "Is Not", "status": false }]
  }

  ngOnInit() {
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "parameter") {
      if (idList.length > 0) {
        this.paramIdList = obj;
        this.getAllValueByParameterId(idList[0])
        this.parameter = idList;
      } else {
        this.parameter = [];
        this.value = []
        this.paramIdList = []
        this.valueIdList = []
      }
    } else if (property == "value") {
      if (idList.length > 0) {
        this.valueIdList = obj;
        this.value = idList;
        console.log(this.paramIdList)
        console.log(this.valueIdList)
      } else {
        this.value = []
      }
    } else if (property == "option") {
      if (idList.length > 0) {
        this.optionIdList = obj;
        this.option = idList;
        console.log(this.optionIdList)
      } else {
        this.option = []
      }
    }
  }

  async getRulePamramById(id) {
    this.getAllStoneMaster();
    let res: any;
    res = await this.httpApiService.getRuleDataById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.name = dataValue.name;
      this.disc = dataValue.discPer;
      console.log(dataValue);
    }
  }

  async getAllValueByParameterId(id) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data);
      this.listOfParamValue = data.data;

    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllStoneParam = data.data;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async addToRule() {
    if (this.array.length > 0) {
      let flag = true;
      this.array.filter(el => {
        if (el.paramName == this.paramIdList[0].masterName) {
          flag = false;
        }
      })
      console.log(flag)
      if (flag) {
        let valueList = []
        this.valueIdList.forEach(el => {
          valueList.push(el.code)
        })
        let option = this.optionIdList[0].type;
        let opretor;
        if (option == 'Is') {
          opretor = 'eq';
        } else {
          opretor = 'neq';
        }
        this.array.push({ paramName: this.paramIdList[0].masterName, value: valueList, option: option, valuelist: valueList.toString() })
        this.listOfBlockRuleDetails.push({ paramName: this.paramIdList[0].masterName, paramValueIds: this.value, opretor: opretor })
      } else {
        this.configService.showToast('error', 'Parameter already exist.')
      }
    } else {
      let valueList = []
      this.valueIdList.forEach(el => {
        valueList.push(el.code)
      })
      let option = this.optionIdList[0].type;
      let opretor;
      if (option == 'Is') {
        opretor = 'eq';
      } else {
        opretor = 'neq';
      }
      this.array.push({ paramName: this.paramIdList[0].masterName, value: valueList, option: option, valuelist: valueList.toString() })
      this.listOfBlockRuleDetails.push({ paramName: this.paramIdList[0].masterName, paramValueIds: this.value, opretor: opretor })
    }
    this.listOfParamValue = []
    this.paramIdList = [];
    this.valueIdList = [];
    this.optionIdList = [];
  }

  async remove(item) {
    this.array.forEach(el => {
      if (item.paramName == el['paramName']) {
        let removeIndex = this.array.map((item) => item['paramName']).indexOf(el['paramName']);
        console.log(removeIndex)
        this.array.splice(removeIndex, 1);
        let removeArrIndex = this.listOfBlockRuleDetails.map((item) => item['paramName']).indexOf(el['paramName']);
        console.log(removeArrIndex)
        this.listOfBlockRuleDetails.splice(removeArrIndex, 1);
      }
    })
  }

  async saveRules() {
    let validate = await this.validateControls();
    if (validate) {
      let data1 = [];
      let data2 = [];
      data1.push({ paramName: 'cts', paramValueCode: this.ctsFromSize.split(), opretor: 'gt' })
      data2.push({ paramName: 'cts', paramValueCode: this.ctsToSize.split(), opretor: 'lt' })
      Array.prototype.push.apply(this.listOfBlockRuleDetails, data1);
      Array.prototype.push.apply(this.listOfBlockRuleDetails, data2);
      let res: any;
      let jsonObj = {
        pricePolicyBlockId: this.blockId,
        discPer: this.disc,
        name: this.name,
        listOfBlockRuleDetails: this.listOfBlockRuleDetails
      }
      console.log(jsonObj)
      res = await this.httpApiService.addRuleToBlock(jsonObj);
      let data = res.json();
      console.log(data)
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (this.ctsFromSize != null && this.ctsFromSize != undefined && this.ctsFromSize != ''
      && this.ctsToSize != null && this.ctsToSize != undefined && this.ctsToSize != '' && this.listOfBlockRuleDetails.length > 0) {
      if (this.disc != 0 && this.disc > 0) {
        return true;
      } else {
        await this.configService.showToast('error', 'Please instert valid Disc %')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please instert the detail')
      return false;

    }
  }
}
