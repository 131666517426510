import { Component, Input, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageTermMasterPage } from "../manage-term-master/manage-term-master.page";

@Component({
  selector: 'app-term-master',
  templateUrl: './term-master.component.html',
  styleUrls: ['./term-master.component.scss']
})
export class TermMasterComponent implements ProcessActionComponent {
  @Input() data: any;
  public termList = [];
  public page = "termMaster";
  public columns = [];
  public items = []
  public count: Number;

  constructor(private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) {
    this.event.subscribe('Term Master', (item) => {
      this.getAllTerm();
    })
  }

  async ngOnInit() {
    this.columns = [{ name: "Sr No", prop: "" }, { name: "Term Name", prop: "name" }, { name: "Term Days", prop: "termDays" }, { name: "Grace Days", prop: "graceDays" }, { name: "Percentage", prop: "percentage" }, { name: "Discount/(-)Premium", prop: "discount" }, { name: "IsActive", prop: "isActive" }, { name: "Delete", prop: "Delete" }];
    await this.getAllTerm();
  }

  ngOnDestroy() {
    this.event.unsubscribe('Term Master');
  }

  setItems() {
    this.items = this.termList;
    this.count = this.termList.length;
  }



  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.termDays && item.termDays.toString().includes(val.toLowerCase())) {
          return true;
        } else if (item.graceDays && item.graceDays.toString().includes(val.toLowerCase())) {
          return true;
        } else if (item.discount && item.discount.toString().includes(val.toLowerCase())) {
          return true;
        } else if (item.percentage && item.percentage.toString().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.count = this.items.length;
    } else {
      await this.setItems();
    }
  }

  async getAllTerm() {
    let res: any;
    res = await this.httpApiService.getAllTerm();
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.termList = data.data;
      if (!!this.termList && this.termList.length > 0) {
        this.count = this.termList.length;
      } else {
        this.count = 0;
      }
      this.setItems()
      console.log("termList ", this.termList);
    }
  }

  async manageTermMaster(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
      item.event.stopPropagation();
    } else {
      let modal = await this.modalCtrl.create({
        component: ManageTermMasterPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllTerm();
    }
  }
  async deleteConfirm(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteTermMaster(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteTermMaster(item) {
    let res: any;
    res = await this.httpApiService.deleteTermMaster(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllTerm();
    }
  }
}
