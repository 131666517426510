import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
@Component({
  selector: 'app-selection-on-sort',
  templateUrl: './selection-on-sort.page.html',
  styleUrls: ['./selection-on-sort.page.scss'],
})
export class SelectionOnSortPage implements OnInit {

  public all: any;

  constructor(   private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    private nav: NavController,
    public httpApiService: HttpApiService) { 
    
    this.all = this.navParams.get("array");
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss(this.all);
  }

  minus(i) {
    this.all.splice(i, 1);
  }
  plus(j) {
    this.all.splice(j + 1, 0, {
      value: this.all.length
    });
  }

  closeModal(){
    this.modalCtrl.dismiss(this.all);
  }
}
