import { Component, ViewChild, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import * as moment from "moment";

@Component({
  selector: 'app-mix-to-single-transfer',
  templateUrl: './mix-to-single-transfer.component.html',
  styleUrls: ['./mix-to-single-transfer.component.scss']
})
export class MixToSingleTransferComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public stoneList = [];
  public columns = [];
  public csspColumns = [];
  public count: Number = 0;
  public selected = [];
  public selectedStoneList = []
  public isSelect: boolean = false;
  public voucherBookId: any;
  public toRole: any;
  public errorMsg = null;
  public successMsg = null;
  public errorMsgList = []
  public msgStatus = false;
  public successMsgList = []
  public items = []

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public event: Events,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "StoneId", prop: "stoneId" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Ct.", prop: "cts" },
      { name: "Clarity", prop: "clarity" },
      { name: "Reference CSSP", prop: "mixStoneId" },
      { name: "Party", prop: "mixStoneId" },
      { name: "Invoice No", prop: "invoiceNo" },
      { name: "Sequence No", prop: "srNo" },
      { name: "Date & Time", prop: "createdDate" },
    ];
    this.getAllConvertedSingleStone()
    this.getDefaultSetting()
    this.event.subscribe('Single Transfer To Assortment', (item) => {
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "cts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Reference CSSP", prop: "mixStoneId" },
        { name: "Party", prop: "mixStoneId" },
        { name: "Invoice No", prop: "invoiceNo" },
        { name: "Sequence No", prop: "srNo" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      this.getAllConvertedSingleStone()
      this.getDefaultSetting()
    })
  }

  setItems() {
    this.items = this.stoneList;
    this.count = this.stoneList.length;
  }


  async filterItems(ev: any) {
    if (this.stoneList.length > 0) {
      let val = ev.target.value;
      console.log(val)
      await this.setItems();
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.stoneId && item.stoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.mixStoneId && item.mixStoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.clarity && item.clarity.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.shape && item.shape.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.invoiceNo && item.invoiceNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.srNo && item.srNo.toString().includes(val.toLowerCase())) {
            return true;
          } else if (item.color && item.color.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.cts && item.cts.toString().includes(val.toLowerCase())) {
            return true;
          }
        });
        this.count = this.items.length;
      } else {
        await this.setItems();
      }
    }

  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }

  ngOnDestroy() {
    this.event.unsubscribe('Mix To Single Transfer')
  }

  async getAllConvertedSingleStone() {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getAllConvertedSingleStone();
    await this.loadingController.dismiss();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneList = data.data;
      this.count = data.data.length;
      this.setItems()
    }
  }

  onSelect(ev) {
    this.selected = ev.selected;
    console.log(this.selected)
    console.log(this.selected)
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.id);
    })
    // console.log(this.isSelect, this.selectedStoneList, this.selectedStoneList.length, this.listOfPendingAssortment.length)
    if (this.selected.length == this.stoneList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.stoneList)
      this.isSelect = true;
      this.selectedStoneList = [];
      this.selected.forEach(el => {
        this.selectedStoneList.push(el.id);
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedStoneList = [];
    }
    console.log(this.selectedStoneList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", data);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.toRole) {
          this.toRole = dataValue.map.toRole
        }
      }
      console.log(this.voucherBookId, this.toRole);
    }
  }


  async issueStones() {
    this.errorMsg = null;
    this.msgStatus = false;
    await this.configService.showLoader();
    let jsonObj = {
      voucherDate: moment().format("YYYY-MM-DD"),
      setOfStoneIds: this.selectedStoneList,
      voucherBookId: this.voucherBookId,
      toRole: this.toRole,
      isTransferToAssortment: true,
      isAssortmentDone: false,
      isReadyForSale: false
    };
    console.log(jsonObj)
    let res: any;
    res = await this.httpApiService.stoneIssue(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      if (data.data) {
        this.errorMsgList = data.data;
        for (let i = 0; i < this.errorMsgList.length; i++) {
          this.errorMsgList[i].ids = this.errorMsgList[i].StoneId.join(", ");
        }
        console.log(this.errorMsgList);
        this.errorMsg = data.message;
        this.msgStatus = true;
      } else {
        await this.configService.showToast("error", data.error);
      }
    } else {
      this.isSelect = false;
      this.selectedStoneList = []
      this.successMsgList = data.data.content;
      for (let i = 0; i < this.successMsgList.length; i++) {
        this.successMsgList[i].ids = this.successMsgList[i].StoneId.join(", ");
      }
      console.log(this.successMsgList);
      this.successMsg = data.message;
      this.msgStatus = true;
      this.selected = []
      this.isSelect = false;
      this.getAllConvertedSingleStone();
    }
  }

}



