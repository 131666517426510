import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManagePurchaseInwardPage } from "../../actions/manage-purchase-inward/manage-purchase-inward.page";

@Component({
  selector: 'app-purchase-inward',
  templateUrl: './purchase-inward.component.html',
  styleUrls: ['./purchase-inward.component.scss']
})
export class PurchaseInwardComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public listOfStoneData = [];
  public count = 0;
  public selected = [];
  public selectedStoneList = [];
  public isSelect = false;
  public pageNumber = 0;
  public noOfRecords = 20;
  public page = "purchaseInward"

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events) { }

  ngOnInit() {
    this.columns = [
      { name: "Voucher No", prop: "" },
      { name: "Party Name", prop: "partyName" },
      { name: "Invoice No", prop: "invoiceNo" },
      { name: "Total Sequence no", prop: "srNo" },
      { name: "Pending Ct.", prop: "pendingCarat" },
      { name: "Date & Time", prop: "modifiedDate" }
    ];
    this.pageNumber = 0;
    this.noOfRecords = 20;
    this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Purchase Inward', (item) => {
      this.columns = [
        { name: "Voucher No", prop: "" },
        { name: "Party Name", prop: "partyName" },
        { name: "Invoice No", prop: "invoiceNo" },
        { name: "Total Sequence no", prop: "srNo" },
        { name: "Pending Ct.", prop: "pendingCarat" },
        { name: "Date & Time", prop: "modifiedDate" }
      ];
      this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Purchase Inward');
  }

  async getAllPendingPurchaseStoneInward(pageNumber, noOfRecords) {
    await this.configService.showLoader();
    let res: any;
    console.log(this.data.voucherType);
    res = await this.httpApiService.purchaseInward(
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.listOfStoneData = data.data.content;
        console.log(this.listOfStoneData);
        this.count = data.data.totalElements;
      }
    }
  }


  async openModal(item, flag) {
    if (item.type == "mouseenter") {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modalPage = await this.modalCtrl.create({
        component: ManagePurchaseInwardPage,
        backdropDismiss: false,
        componentProps: {
          data: item.row,
          flagModal: flag
        },
        cssClass: 'requestPage'
      })
      modalPage.onDidDismiss().then((d: any) => this.handlePurchaseDetail(d))
      return await modalPage.present();
    }
  }

  async handlePurchaseDetail(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllPendingPurchaseStoneInward(this.pageNumber, this.noOfRecords)
    }
  }

}
