import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-master",
  templateUrl: "./manage-master.page.html",
  styleUrls: ["./manage-master.page.scss"]
})
export class ManageMasterPage implements OnInit {
  public isModal: any;
  public code = "";
  public name = "";
  public stoneParameterNameId = "";
  public StoneParamName = "";
  public item: any;
  public id: any;
  public masterName = "";
  public priority: any;
  public listOfTransType: any = [];
  public transType: any = null;
  public typeIdList: any = [];
  public flagNewValueGroup: Boolean = false;
  public nameValueList = [];
  public nameValue: any;
  public nameValueIdList = [];
  public valueGroup: any;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public ConfigService: ConfigService,
    public authService: AuthenticationService,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  async ngOnInit() {
    await this.getAllTransType();
    this.isModal = this.navParams.get("flagModal");
    this.stoneParameterNameId = this.navParams.get("id");
    this.StoneParamName = this.navParams.get("StoneParamName");
    this.item = this.navParams.get("item");
    if (this.isModal == "updateValue") {
      this.code = this.item.code;
      this.name = this.item.name;
      this.priority = this.item.priority,
        this.stoneParameterNameId = this.item.stoneParameterNameId;
      this.id = this.item.id;
      if (this.item.transTypeId && this.item.transTypeName) {
        this.transType = this.item.transTypeId;
        let data = { id: this.item.transTypeId, name: this.item.transTypeName };
        this.typeIdList.push(data);
      }
      await this.findGroupValue();
      if (!!this.item.valueGroup) {
        this.nameValueIdList = []
        this.valueGroup = this.item.valueGroup
        if (this.nameValueList.length > 0) {
          this.nameValueList.filter(el => {
            if (el.name == this.item.valueGroup) {
              this.nameValueIdList.push({ id: el.id, name: el.name })
            }
          })
        }
      }
    } else {
      await this.findGroupValue();
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "transType") {
      if (idList.length > 0) {
        this.transType = idList[0];
      } else {
        this.transType = null;
      }
    } else if (property == "group") {
      if (idList.length > 0) {
        this.valueGroup = obj[0].name;
      } else {
        this.valueGroup = null
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss(0);
  }


  async findGroupValue() {
    let res: any;
    res = await this.httpApiService.findGroupValue();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      let data1 = []
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          data1.push({ id: i + 1, name: dataList[i] })
        }
      } else {
        this.flagNewValueGroup = true;
      }
      this.nameValueList = data1;
      console.log(this.nameValueList)
    }
  }

  addNewValueGroup() {
    this.flagNewValueGroup = true;
  }


  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.listOfTransType = data.data;
    }
  }

  async saveValue() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        stoneParameterNameId: this.stoneParameterNameId,
        code: this.code,
        name: this.name,
        valueGroup: this.valueGroup,
        priority: this.priority,
        transTypeId: this.transType == null ? null : this.transType
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.addStoneParameterValue(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        await this.ConfigService.showToast(
          "success",
          "Stone Parameter value created successfully"
        );
        this.resetForm();
      }
    }
  }

  async updateValue() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        stoneParameterNameId: this.stoneParameterNameId,
        code: this.code,
        name: this.name,
        priority: this.priority,
        valueGroup: this.valueGroup,
        transTypeId: this.transType == null ? null : this.transType
      };
      console.log(jsonObj, this.transType);
      let res: any;
      res = await this.httpApiService.UpdateStoneParameterValue(
        this.id,
        jsonObj
      );
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        await this.ConfigService.showToast(
          "success",
          "Stone Parameter value updated successfully"
        );
      }
    }
  }

  async addNewParam() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        masterName: this.masterName,
        priority: this.priority
      };
      console.log(jsonObj);
      let res: any;
      res = await this.httpApiService.addStoneParameterName(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        await this.ConfigService.showToast(
          "success",
          "Stone Parameter Name added successfully"
        );
        this.resetForm();
      }
    }
  }

  async validateControls() {
    if (this.isModal == "addValue" || this.isModal == "updateValue") {
      if (this.code != "" && this.name != "") {
        return false;
      } else {
        await this.ConfigService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return true;
      }
    } else {
      if (this.masterName != "" && !!this.priority) {
        return false;
      } else {
        await this.ConfigService.showToast(
          "error",
          "Please insert the information"
        );
        return true;
      }
    }
  }

  resetForm() {
    this.code = "";
    this.name = "";
    this.masterName = "";
    this.priority = null;
    this.typeIdList = [];
    this.transType = null;
  }
}
