import { Component, Input } from "@angular/core";
import { ModalController, NavParams, Events, LoadingController } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { id } from "@swimlane/ngx-datatable/release/utils";

@Component({
  selector: "app-manage-action",
  templateUrl: "./manage-action.component.html",
  styleUrls: ["./manage-action.component.scss"]
})
export class ManageActionComponent implements ProcessActionComponent {
  @Input() data: any;

  public items: any;
  public userData: any;
  public manageSelectedAction: "";
  public arrowKeyLocation = -1;
  public listOfActions: any;
  public listOfActionPermission: any;
  public selectedAction: "";
  public description: "";
  public defaultData: any = [];
  public keys: any;
  public actionId: any;

  public toUser: number;
  public toLocation: number;
  public toDepartment: number;
  public fromDepartment: number;
  public usersList = [];
  public listOfVoucherBook: any;
  public voucherBook: any;
  public fromUser: any;
  public dept: any;
  public roleId: any;
  public recut: Boolean = false;
  public observation: Boolean = false;
  public certification: Boolean = false;
  public mixing: Boolean = false;
  public requisition: Boolean = false;
  public consignment: Boolean = false;
  public stoneReturn: Boolean = false;
  public priceListName: any;
  public fromDeptidList = [];
  public toDeptidList = [];
  public toLocationidList = [];
  public voucheridList = [];
  public toUseridList = [];
  public priceidList = []
  public fromUseridList = [];
  public allPriceNameList = [];
  public toLocationObvidList = [];
  public listOfLocations = [];
  public toRoleIdList = []
  public fromRoleIdList = []
  public listOfRoles = []
  public deptList = []
  public voucherType: any;
  public sdix: Boolean = false;
  public consignmentConfirm: Boolean = false;
  public memoClient: Boolean = false;
  public customerConfirm: Boolean = false;
  public memoService: Boolean = false;
  public consignmentInStock: Boolean = false;
  public selfObservationEnable: Boolean = false;
  public memoClientEnable: Boolean = false;
  public customerHoldEnable: Boolean = false;
  public customerConfirmEnable: Boolean = false;
  public memoServiceEnable: Boolean = false;
  public consignmentConfirmEnable: Boolean = false;
  public consignmentInStockEnable: Boolean = false;
  public certificationEnable: Boolean = false;
  public requisitionEnable: Boolean = false;
  public toRole: any;
  public fromRole: any;
  public defaultObjRecut = {};
  public defaultObjSelfObservation = {};
  public defaultObjObservation = {};
  public defaultObjMemoClient = {};
  public defaultObjCustomerHold = {};
  public defaultObjCustomerConfirm = {};
  public defaultObjMemoService = {};
  public defaultObjConsignmentConfirm = {};
  public defaultObjConsignmentInStock = {};
  public defaultObjCertification = {}
  public defaultObjRequisition = {}
  public recutEnable: Boolean = false;
  public observationEnable: Boolean = false;
  public requestVoucherBook: any;
  public requestVoucherIdList = [];
  public getAllCustomers = [];
  public partyMasterIdList = [];
  public typeList = [];
  public typeIdList = [];
  public type: any;
  public boxTypeList = [];
  public boxType: any;
  public boxTypeIdList = [];
  public partyTypeList = [];
  public partyTypeMemoClientIdList = []
  public partyTypeCConfirmIdList = []
  public partyTypeForMemoServiceIdList = []
  public partyTypeForConsignmentConfirmIdList = []
  public partyTypeForConsignmentInStockIdList = []
  public partyTypeSelfObservationIdList = []
  public partyTypeForCertificationIdList = []
  public partyTypeForRequisitionIdList = []
  public partyTypeIdList = []
  public partyType = [];
  public transType: any;
  public transTypeList = [];
  public transTypeIdList = []
  public partyTypeCustomerHoldIdList = []

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.selectedAction = "";
    this.findAllTypeForPricing();
    this.getAllActions();
    this.getAllCompany();
    this.getAllPriceListName();
    this.getAllRoles();
    this.getBoxType();
    this.getAllPartyMasterType();
    this.getAllTransType();
    this.getAllDepartment();
    this.event.subscribe('Manage Actions', (item) => {
      this.typeList = [];
      this.listOfRoles = [];
      this.transTypeList = [];
      this.partyTypeList = [];

      this.fromDeptidList = [];
      this.toDeptidList = [];
      this.toLocationidList = [];
      this.fromUseridList = [];
      this.voucheridList = [];
      this.fromDeptidList = [];
      this.boxTypeIdList = [];
      this.requestVoucherIdList = []
      this.priceidList = []
      this.toRoleIdList = []
      this.fromRoleIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeCConfirmIdList = []
      this.transTypeIdList = []
      this.partyMasterIdList = []
      this.partyTypeForMemoServiceIdList = []
      this.partyTypeForConsignmentConfirmIdList = []
      this.partyTypeForConsignmentInStockIdList = []
      this.partyTypeForCertificationIdList = []
      this.partyTypeIdList = []
      this.partyTypeForRequisitionIdList = []
      this.findAllTypeForPricing();
      this.getAllActions();
      this.getBoxType();
      this.getAllCompany();
      this.getAllTransType()
      this.getAllPriceListName();
      this.getAllRoles();
      this.getAllPartyMasterType();
      this.getAllDepartment();
      this.searchManageActionForPermission(this.selectedAction);
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Manage Actions');
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "roles") {
      this.roleId = idList[0];
    } else if (property == "fromDepartment") {
      this.fromDepartment = idList[0];
    } else if (property == "toDepartment") {
      this.toDepartment = idList[0];
    } else if (property == "toLocation") {
      if (idList.length > 0) {
        this.toUseridList = [];
        this.usersList = [];
        this.toLocation = idList[0];
        this.getAllToUser(this.toLocation);
      } else {
        this.toLocation = null;
        this.toUseridList = [];
        this.usersList = [];
      }
    } else if (property == "voucherBook") {
      this.voucherBook = idList[0];
    } else if (property == "requestVoucherBook") {
      this.requestVoucherBook = idList[0];
    } else if (property == "toUsers") {
      this.toUser = idList[0];
      console.log("toUseridList", this.toUseridList);
    } else if (property == "fromUsers") {
      this.fromUser = idList[0];
    } else if (property == "allDepartment") {
      this.dept = idList[0];
    } else if (property == "price") {
      this.priceListName = idList[0];
    } else if (property == "toRoles") {
      this.toRole = idList[0];
    } else if (property == "fromRoles") {
      this.fromRole = idList[0];
    }
    else if (property == "toLocationForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["toLocation"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["toLocation"];
      }
      console.log(this.defaultObjRecut)
    } else if (property == "toLocationForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["toLocation"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
      console.log(this.defaultObjObservation)
    } else if (property == "partMaster") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyMaster"] = idList[0];
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
    } else if (property == "typeId") {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    } else if (property == "boxType") {
      if (idList.length > 0) {
        this.boxType = obj[0].type;
      } else {
        this.boxType = null;
      }
    } else if (property == "partyTypeForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["partyType"];
      }
    } else if (property == "partyTypeForCustomerHold") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["partyType"];
      }
    }
    else if (property == "partyTypeForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjMemoService["partyType"];
      }
    } else if (property == "partyTypeForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["partyType"];
      }
    } else if (property == "partyTypeForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["partyType"];
      }
    } else if (property == "partyTypeForSelfObservation") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyType"] = obj.map(a => a.type);
        this.getAllCustomer(obj.map(a => a.type))
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjSelfObservation["partyType"];
      }
    } else if (property == "partyTypeForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["partyType"] = obj.map(a => a.type);
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["partyType"];
      }
    } else if (property == "partyTypeForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["partyType"] = obj.map(a => a.type);
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["partyType"];
      }
    } else if (property == "partyType") {
      if (idList.length > 0) {
        this.partyType = obj.map(a => a.type)
      } else {
        this.partyType = []
      }
    } else if (property == "transTypeList") {
      if (idList.length > 0) {
        this.transType = obj[0].name
      } else {
        this.transType = null;
      }
    }
  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.boxTypeList = data.data;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  async getAllPartyMasterType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.partyTypeList.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
    console.log("boxTypeList", this.boxTypeList);
  }

  async getAllCustomer(type) {
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data;
      console.log("getAllCustomers", this.getAllCustomers);

    }
  }

  async getAllCustomerDetail(type, callback) {
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data;
      callback(this.getAllCustomers)
    }
  }

  async getUserData() {
    return await this.authService.userData();
  }

  setItems() {
    this.items = this.listOfActions;
  }

  manageActionForPermission(event, item) {
    this.searchManageActionForPermission(item.actionName);
  }

  async getAllRoles() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfRoles = data.data;
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async getAllDepartment() {
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllDepartment(
      appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
    }
  }

  async getVoucherBookList() {
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedType(
      this.voucherType
    );
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfVoucherBook = data.data;
      console.log(this.listOfVoucherBook);
    }
  }

  async searchManageActionForPermission(aName) {
    await this.configService.showLoader();
    let tmpActionName = "";
    this.items = this.listOfActions;
    if (aName != undefined) {
      tmpActionName = aName;
      this.selectedAction = aName;
      this.manageSelectedAction = aName;
    } else {
      this.selectedAction = this.manageSelectedAction;
      tmpActionName = this.manageSelectedAction;
    }
    let selectedActionId = this.items.filter(function (search) {
      if (search.actionName == tmpActionName) {
        return search.id;
      }
    });
    if (selectedActionId.length > 0) {
      this.actionId = selectedActionId[0].id;
      let res: any;
      res = await this.httpApiService.getAllActionPermissionById(this.actionId);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        let actionData = data.data;
        if (actionData.listOfActionPermissions) {
          this.listOfActionPermission = actionData.listOfActionPermissions;
        }
        this.voucherType = actionData.voucherType
        this.selectedAction = actionData.actionName;
        this.description = actionData.description;
        let index = this.items.findIndex(
          x => x.actionName == selectedActionId[0].actionName
        );
        this.arrowKeyLocation = index;
        await this.getAllVoucherBook();
        if (actionData.listOfActionDefaults) {
          this.defaultData = actionData.listOfActionDefaults;
          this.priceidList = [];
          this.voucheridList = [];
          this.fromDeptidList = [];
          this.toDeptidList = [];
          this.typeIdList = []
          this.toLocationidList = [];
          this.requestVoucherIdList = []
          this.toRoleIdList = []
          this.boxTypeIdList = []
          this.voucheridList = [];
          this.partyTypeMemoClientIdList = []
          this.partyTypeMemoClientIdList = []
          this.partyTypeCConfirmIdList = []
          this.partyMasterIdList = []
          this.partyTypeForMemoServiceIdList = []
          this.partyTypeForConsignmentConfirmIdList = []
          this.partyTypeForConsignmentInStockIdList = []
          this.partyTypeForCertificationIdList = []
          this.partyTypeIdList = []
          this.partyTypeForRequisitionIdList = []
          this.defaultData.filter(el => {
            if (el.paramName == 'priceListName') {
              this.priceListName = el.paramValue;
              this.allPriceNameList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.priceidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'fromDepartment') {
              this.fromDepartment = el.paramValue;
              this.deptList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.fromDeptidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'toDepartment') {
              this.toDepartment = el.paramValue;
              this.deptList.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toDeptidList.push({ id: item.id, name: item.name })
                }
              })
            }
            if (el.paramName == 'partyType') {
              if (!!el.paramValue) {
                this.partyType = JSON.parse(el.paramValue);
                this.partyTypeIdList = []
                console.log(this.partyType)
                if (!!this.partyType && this.partyType.length > 0) {
                  this.partyTypeList.forEach(item => {
                    let flag = this.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
              }
            }
            if (el.paramName == 'toLocation') {
              this.toLocation = el.paramValue;
              this.listOfLocations.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                  this.getAllToUser(item.id)
                }
              })
            }
            if (el.paramName == 'voucherBook') {
              this.voucherBook = el.paramValue;
              this.listOfVoucherBook.forEach(item => {
                if (item.id == el.paramValue) {
                  this.voucheridList.push({ id: item.id, bookName: item.bookName })
                }
              })
            }
            if (el.paramName == 'boxType') {
              this.boxType = el.paramValue;
              this.boxTypeList.forEach(item => {
                if (item.type == el.paramValue) {
                  this.boxTypeIdList.push({ id: item.id, type: item.type })
                }
              })
            }

            if (el.paramName == 'requestVoucherBook') {
              this.requestVoucherBook = el.paramValue;
              this.listOfVoucherBook.forEach(item => {
                if (item.id == el.paramValue) {
                  this.requestVoucherIdList.push({ id: item.id, bookName: item.bookName })
                }
              })
            }
            if (el.paramName == 'toUser') {
              this.toUseridList = [];
              this.toUser = el.paramValue
              this.usersList.forEach(item => {
                if (item.id == parseInt(el.paramValue)) {
                  let data = { id: item.id, username: item.username };
                  this.toUseridList.push(data);
                  console.log(this.toUseridList)
                }
              });
            }
            if (el.paramName == 'toRole') {
              this.toRole = el.paramValue;
              this.toRoleIdList = []
              this.listOfRoles.forEach(item => {
                if (item.id == el.paramValue) {
                  this.toRoleIdList.push({ id: el.id, roleName: item.roleName })
                }
              })
            } if (el.paramName == 'transType') {
              this.transType = el.paramValue;
              this.transTypeIdList = []
              this.transTypeList.forEach(item => {
                if (item.name == el.paramValue) {
                  this.transTypeIdList.push({ id: item.id, name: item.name })
                }
              })
            } if (el.paramName == 'fromRole') {
              this.fromRole = el.paramValue;
              this.fromRoleIdList = []
              this.listOfRoles.forEach(item => {
                if (item.id == el.paramValue) {
                  this.fromRoleIdList.push({ id: el.id, roleName: item.roleName })
                }
              })
            }
            if (el.paramName == 'Return') {
              this.stoneReturn = el.paramValue;
            }
            if (el.paramName == 'type') {
              this.type = el.paramValue
              console.log(this.typeList)
              this.typeList.forEach(item => {
                if (item.name == el.paramValue) {
                  this.typeIdList.push({ id: item.id, name: item.name })
                }
              })
              console.log(this.typeIdList)
            }
            if (el.paramName == 'Recut') {
              this.defaultObjRecut["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.recutEnable = dataJson.enable;
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.defaultObjRecut["toLocation"] = this.toLocation;
                  this.toLocationidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                }
                if (this.recutEnable) {
                  this.defaultObjRecut["enable"] = true;
                } else {
                  this.defaultObjRecut["enable"] = false;
                }
              } else {
                this.recutEnable = false;
              }
              console.log(this.recutEnable)
            }

            if (el.paramName == 'Requisition') {
              this.defaultObjRequisition["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.requisitionEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjRequisition["partyType"] = dataJson.partyType;
                  this.partyTypeForRequisitionIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForRequisitionIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.certificationEnable) {
                  this.defaultObjRequisition["enable"] = true;
                } else {
                  this.defaultObjRequisition["enable"] = false;
                }
              } else {
                this.certificationEnable = false;
              }
              console.log(this.partyTypeForRequisitionIdList, this.certificationEnable)
            }
            if (el.paramName == 'Observation') {
              this.defaultObjObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              if (!!dataJson) {
                this.observationEnable = dataJson.enable;
                if (!!dataJson.toLocation) {
                  this.toLocation = dataJson.toLocation;
                  this.toLocationObvidList = []
                  this.listOfLocations.forEach(item => {
                    if (item.id == this.toLocation) {
                      this.toLocationObvidList.push({ id: item.id, companyName: item.companyName })
                    }
                  })
                  this.defaultObjObservation["toLocation"] = this.toLocation;
                }
                if (this.observationEnable) {
                  this.defaultObjObservation["enable"] = true;
                } else {
                  this.defaultObjObservation["enable"] = false;
                }
              } else {
                this.observationEnable = false;
              }
            }
            if (el.paramName == 'Sdix') {
              this.sdix = el.paramValue;
            }
            if (el.paramName == 'Memo Client') {
              this.defaultObjMemoClient["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.memoClientEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjMemoClient["partyType"] = dataJson.partyType;
                  this.partyTypeMemoClientIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeMemoClientIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.memoClientEnable) {
                  this.defaultObjMemoClient["enable"] = true;
                } else {
                  this.defaultObjMemoClient["enable"] = false;
                }
              } else {
                this.memoClientEnable = false;
              }
              console.log(this.partyTypeMemoClientIdList, this.memoClientEnable)
            }
            if (el.paramName == 'Customer Hold') {
              this.defaultObjCustomerHold["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerHoldEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerHold["partyType"] = dataJson.partyType;
                  this.partyTypeCustomerHoldIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCustomerHoldIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.customerHoldEnable) {
                  this.defaultObjCustomerHold["enable"] = true;
                } else {
                  this.defaultObjCustomerHold["enable"] = false;
                }
              } else {
                this.customerHoldEnable = false;
              }
              console.log(this.partyTypeCustomerHoldIdList, this.customerHoldEnable)
            }
            if (el.paramName == 'Certification') {
              this.defaultObjCertification["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.certificationEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCertification["partyType"] = dataJson.partyType;
                  this.partyTypeForCertificationIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForCertificationIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.certificationEnable) {
                  this.defaultObjCertification["enable"] = true;
                } else {
                  this.defaultObjCertification["enable"] = false;
                }
              } else {
                this.certificationEnable = false;
              }
              console.log(this.partyTypeMemoClientIdList, this.certificationEnable)
            }
            if (el.paramName == 'Consignment In Stock') {
              this.defaultObjConsignmentInStock["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.consignmentInStockEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentInStock["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentInStockIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentInStockIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.consignmentInStockEnable) {
                  this.defaultObjConsignmentInStock["enable"] = true;
                } else {
                  this.defaultObjConsignmentInStock["enable"] = false;
                }
              } else {
                this.consignmentInStockEnable = false;
              }
              console.log('this.partyTypeForConsignmentInStockIdList', this.partyTypeForConsignmentInStockIdList)
            }
            if (el.paramName == 'Consignment Confirm') {
              this.defaultObjConsignmentConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.consignmentConfirmEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjConsignmentConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeForConsignmentConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForConsignmentConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.consignmentConfirmEnable) {
                  this.defaultObjConsignmentConfirm["enable"] = true;
                } else {
                  this.defaultObjConsignmentConfirm["enable"] = false;
                }
              } else {
                this.consignmentConfirmEnable = false;
              }
            }
            if (el.paramName == 'Memo Service') {
              this.defaultObjMemoService["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.memoServiceEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjMemoService["partyType"] = dataJson.partyType;
                  this.partyTypeForMemoServiceIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeForMemoServiceIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.memoServiceEnable) {
                  this.defaultObjMemoService["enable"] = true;
                } else {
                  this.defaultObjMemoService["enable"] = false;
                }
              } else {
                this.memoServiceEnable = false;
              }
            }
            if (el.paramName == 'Customer Confirm') {
              this.defaultObjCustomerConfirm["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log(dataJson)
              if (!!dataJson) {
                this.customerConfirmEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjCustomerConfirm["partyType"] = dataJson.partyType;
                  this.partyTypeCConfirmIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeCConfirmIdList.push({ id: item.id, type: item.type })
                    }
                  })
                }
                if (this.customerConfirmEnable) {
                  this.defaultObjCustomerConfirm["enable"] = true;
                } else {
                  this.defaultObjCustomerConfirm["enable"] = false;
                }
              } else {
                this.customerConfirmEnable = false;
              }
            }
            if (el.paramName == 'Self Observation') {
              this.defaultObjSelfObservation["enable"] = false;
              let dataJson = null
              if (!!el.paramValue) {
                dataJson = JSON.parse(el.paramValue)
              }
              console.log("&&&", dataJson)
              if (!!dataJson) {
                this.selfObservationEnable = dataJson.enable;
                if (!!dataJson.partyType) {
                  this.defaultObjSelfObservation["partyType"] = dataJson.partyType;
                  this.partyTypeSelfObservationIdList = []
                  this.partyTypeList.forEach(item => {
                    let flag = dataJson.partyType.some(element => element == item.type)
                    if (flag) {
                      this.partyTypeSelfObservationIdList.push({ id: item.id, type: item.type })
                    }
                  })
                  var _this = this
                  this.getAllCustomerDetail(dataJson.partyType, function (data, error) {
                    if (!!dataJson.partyMaster) {
                      _this.partyMasterIdList = []
                      if (data.length > 0) {
                        data.forEach(item => {
                          if (item.id == dataJson.partyMaster) {
                            _this.partyMasterIdList.push({ id: item.id, partyName: item.partyName })
                          }
                        })
                      }
                      _this.defaultObjSelfObservation["partyMaster"] = dataJson.partyMaster;
                    }
                  })
                }
                if (this.selfObservationEnable) {
                  this.defaultObjSelfObservation["enable"] = true;
                } else {
                  this.defaultObjSelfObservation["enable"] = false;
                }
                console.log(this.partyMasterIdList)
              } else {
                this.selfObservationEnable = false;
              }

            }
          })
        } else {
          this.defaultData = [];
        }
      }
      console.log(this.actionId)
      let voucherArray = this.listOfActions.forEach(el => {
        if (el.id == this.actionId) {
          return el;
        }
      })
    } else {
      await this.loadingController.dismiss();
      this.selectedAction = "";
      this.description = "";
      this.listOfActionPermission = [];
    }
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
      console.log(this.typeList)
    }
  }


  async getAllVoucherBook() {
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedType(
      this.voucherType
    );
    let data = res.json();
    console.log(data)
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfVoucherBook = data.data;
      console.log(this.listOfVoucherBook);
    }
  }

  async filterItems(ev: any) {
    this.selectedAction = "";
    this.listOfActionPermission = [];
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.actionName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.listOfActions;
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedAction = this.items[
              this.arrowKeyLocation
            ].actionName;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedAction = this.items[
              this.arrowKeyLocation
            ].actionName;
            break;
          } else {
            break;
          }
        }
    }
  }

  async getAllActions() {
    let res: any;
    this.userData = await this.getUserData();
    res = await this.httpApiService.getAllActions();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      this.items = this.listOfActions;
      console.log("list of actions by http", res, this.listOfActions);
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }

  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
      } else {
        this.usersList = [];
      }
    }
  }

  filter(obj, predicate) {
    return Object.keys(obj)
      .filter(key => predicate(key, obj[key]))
      .reduce((res, key) => (res[key] = obj[key], res), {});
  }

  async updateUserDefaultData() {
    let res: any;
    await this.configService.showLoader()
    let jsonObj: any;

    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    jsonObj = {
      actionId: this.actionId,
      fromDepartment: this.fromDepartment,
      toDepartment: this.toDepartment,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      type: this.type,
      toRole: this.toRole,
      fromRole: this.fromRole,
      priceListName: this.priceListName,
      transType: this.transType,
      boxType: this.boxType,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Customer Hold': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType)
      // mixing: this.mixing,
    }
    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);
    console.log(filtered)
    filtered.actionId = this.actionId;
    if (this.partyType.length == 0) {
      delete filtered['partyType'];
    }
    console.log(filtered)
    res = await this.httpApiService.updateActionDefault(filtered)
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.searchManageActionForPermission(this.selectedAction)
    }
  }

  checkRecut() {
    if (this.recutEnable) {
      this.defaultObjRecut["enable"] = true;
    } else {
      this.defaultObjRecut["enable"] = false;
      delete this.defaultObjRecut["toLocation"];
    }
    console.log(this.defaultObjRecut)
  }

  checkObservation() {
    if (this.observationEnable) {
      this.defaultObjObservation["enable"] = true;
    } else {
      this.defaultObjObservation["enable"] = false;
      delete this.defaultObjObservation["toLocation"];
    }
    console.log(this.defaultObjObservation)
  }

  checkSelfObservation() {
    if (this.selfObservationEnable) {
      this.defaultObjSelfObservation["enable"] = true;
    } else {
      this.partyTypeSelfObservationIdList = []
      this.partyMasterIdList = []
      this.getAllCustomers = []
      this.defaultObjSelfObservation["enable"] = false;
      delete this.defaultObjSelfObservation["partyMaster"];
      delete this.defaultObjSelfObservation["partyType"];
    }
    console.log(this.defaultObjSelfObservation)
  }

  checkMemoClient() {
    if (this.memoClientEnable) {
      this.defaultObjMemoClient["enable"] = true;
    } else {
      this.partyTypeMemoClientIdList = []
      this.defaultObjMemoClient["enable"] = false;
      delete this.defaultObjMemoClient["partyType"];
    }
    console.log(this.defaultObjMemoClient)
  }

  checkCustomerHold() {
    if (this.customerHoldEnable) {
      this.defaultObjCustomerHold["enable"] = true;
    } else {
      this.partyTypeCustomerHoldIdList = []
      this.defaultObjCustomerHold["enable"] = false;
      delete this.defaultObjCustomerHold["partyType"];
    }
    console.log(this.defaultObjCustomerHold)
  }

  checkCConfirm() {
    if (this.customerConfirmEnable) {
      this.defaultObjCustomerConfirm["enable"] = true;
    } else {
      this.partyTypeCConfirmIdList = []
      this.defaultObjCustomerConfirm["enable"] = false;
      delete this.defaultObjCustomerConfirm["partyType"];
    }
    console.log(this.defaultObjCustomerConfirm)
  }

  checkMemoService() {
    if (this.memoServiceEnable) {
      this.defaultObjMemoService["enable"] = true;
    } else {
      this.partyTypeForMemoServiceIdList = []
      this.defaultObjMemoService["enable"] = false;
      delete this.defaultObjMemoService["partyType"];
    }
    console.log(this.defaultObjMemoService)
  }

  checkConsignmentConfirm() {
    if (this.consignmentConfirmEnable) {
      this.defaultObjConsignmentConfirm["enable"] = true;
    } else {
      this.partyTypeForConsignmentConfirmIdList = []
      this.defaultObjConsignmentConfirm["enable"] = false;
      delete this.defaultObjConsignmentConfirm["partyType"];
    }
    console.log(this.defaultObjConsignmentConfirm)
  }

  checkConsignmentInStock() {
    if (this.consignmentInStockEnable) {
      this.defaultObjConsignmentInStock["enable"] = true;
    } else {
      this.partyTypeForConsignmentInStockIdList = []
      this.defaultObjConsignmentInStock["enable"] = false;
      delete this.defaultObjConsignmentInStock["partyType"];
    }
    console.log(this.defaultObjConsignmentInStock)
  }

  checkCertification() {
    if (this.certificationEnable) {
      this.defaultObjCertification["enable"] = true;
    } else {
      this.partyTypeForCertificationIdList = []
      this.defaultObjCertification["enable"] = false;
      delete this.defaultObjCertification["partyType"];
    }
    console.log(this.defaultObjCertification)
  }

  checkRequisition() {
    if (this.requisitionEnable) {
      this.defaultObjRequisition["enable"] = true;
    } else {
      this.partyTypeForRequisitionIdList = []
      this.defaultObjRequisition["enable"] = false;
      delete this.defaultObjRequisition["partyType"];
    }
    console.log(this.defaultObjRequisition)
  }

  logout() {
    this.authService.logout();
  }
}
