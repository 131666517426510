import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service'

@Component({
  selector: 'app-sdix',
  templateUrl: './sdix.component.html',
  styleUrls: ['./sdix.component.scss']
})
export class SdixComponent implements OnInit {
  @Input() data: any

  public actionName: any;
  public actionId: any;

  constructor(
    public authService: AuthenticationService
  ) { }

  async ngOnInit() {
    let userData = await this.authService.userData();
    console.log("userData", userData, this.data);
    this.actionName = await this.data.desc
    this.actionId = await this.data.actionId
    console.log(this.actionName, this.actionId)
  }

}
