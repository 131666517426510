import { Component, OnInit, Input } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";
import { bypassSanitizationTrustResourceUrl } from "@angular/core/src/sanitization/bypass";
import { CodegenComponentFactoryResolver } from "@angular/core/src/linker/component_factory_resolver";

@Component({
  selector: 'app-manage-policy-chain',
  templateUrl: './manage-policy-chain.page.html',
  styleUrls: ['./manage-policy-chain.page.scss'],
})
export class ManagePolicyChainPage implements OnInit {

  public policies = []
  public item: any;
  public isActive: any;
  public id: any;
  public flagAddUpdate: any;
  public policyIdList = []
  public chainList = []
  public copy = [];
  public allPriceNameList = []
  public toPriceNameList
  public nameIdList = []
  public priceNameListId: any;
  public startPriceListNameId: any;
  public endPriceListNameId: any;
  public selectedPolicyList = [];
  public fromNameIdList = [];
  public toNameIdList = [];
  public changePosObj = {}

  public name: any;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public dragulaService: DragulaService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) { }

  async  ngOnInit() {
    this.selectedPolicyList = []
    let data = this.navParams.get('item');
    await this.getAllPolicies()
    await this.getAllPriceListName()
    if (data != 0) {
      this.item = data.row;
      this.fromNameIdList = []
      this.toNameIdList = []
      this.policyIdList = []
      console.log(this.item)
      this.id = this.item.id;
      this.name = this.item.name
      this.startPriceListNameId = this.item.startPriceListNameId
      this.endPriceListNameId = this.item.endPriceListNameId
      this.fromNameIdList.push({ id: this.item.startPriceListNameId, name: this.item.startPriceListName })
      this.toNameIdList.push({ id: this.item.endPriceListNameId, name: this.item.endPriceListName })
      if (this.item.pricePolicies.length > 0) {
        this.item.pricePolicies.forEach((el, i) => {
          this.chainList.push({ id: el.pricePolicyMasterId, name: el.pricePolicyName })
          this.policyIdList.push({ id: el.pricePolicyMasterId, name: el.pricePolicyName })
          this.selectedPolicyList.push({ id: el.id, pricePolicyMasterId: el.pricePolicyMasterId, policyOrder: el.policyOrder })
        })
        let i = 1;
        this.item.pricePolicies.map(res => { this.changePosObj[res.pricePolicyMasterId] = res.policyOrder; });
      }
    }
    this.flagAddUpdate = this.navParams.get('flag');

  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }


  changePosition(event) {
    this.chainList = event
    this.changePosObj = {};
    let i = 1;
    this.chainList.map(res => { this.changePosObj[res.id] = i++; });
    console.log(this.changePosObj)
  }

  async getAllPolicies() {
    let res: any;
    res = await this.httpApiService.getAllPolicy();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.policies = data.data;
      console.log("get policies", this.policies);
    }
  }

  dropDownSelection(ev) {
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedPolicy") {
      if (idList.length > 0) {
        this.changePosObj = {}
        this.chainList = []
        idList.forEach(el => {
          this.policies.filter(item => {
            if (item.id == el) {
              this.chainList.push(item)
            }
          })
        })
        let i = 1;
        idList.map(res => { this.changePosObj[res] = i++; });
        this.selectedPolicyList
      } else {
        this.chainList = []
        this.policyIdList = []
      }
    } if (property == "fromPriceListName") {
      if (idList.length > 0) {
        this.startPriceListNameId = idList[0];
        this.getAllToPriceListName(this.startPriceListNameId);
      } else {
        this.endPriceListNameId = null;
      }
    } else if (property == "toPriceListName") {
      if (idList.length > 0) {
        this.endPriceListNameId = idList[0];
      } else {
        this.endPriceListNameId = null;
      }
    }

  }

  async getAllToPriceListName(id) {
    let index = this.allPriceNameList.findIndex(x => x.id == id);
    if (index > -1) {
      this.toPriceNameList.slice(index, 1)
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
      this.toPriceNameList = data.data;
    }
  }

  async addPolicyChain() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader()
      let res: any;
      this.selectedPolicyList = []
      if (this.chainList.length > 0) {
        this.chainList.forEach(el => {
          this.selectedPolicyList.push({ pricePolicyMasterId: el.id, policyOrder: this.changePosObj[el.id] })
        })
      }
      let jsonObj = {
        name: this.name,
        startPriceListNameId: this.startPriceListNameId,
        endPriceListNameId: this.endPriceListNameId,
        pricePolicies: this.selectedPolicyList
      }
      console.log(jsonObj)
      res = await this.httpApiService.addPolicyChain(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }

  }

  async updatePolicyChain() {
    let validate = await this.validateControls();
    if (validate) {
      let res: any;
      if (this.chainList.length > 0) {
        this.chainList.forEach((el, i) => {
          let flag = this.selectedPolicyList.some(item => item.pricePolicyMasterId == el.id);
          if (!flag) {
            this.selectedPolicyList.push({ pricePolicyMasterId: el.id, policyOrder: this.changePosObj[el.id] })
          }
          this.selectedPolicyList.forEach(item => {
            if (el.id == item.pricePolicyMasterId) {
              item.policyOrder = this.changePosObj[el.id]
            }
          })
        })

        this.selectedPolicyList.forEach(el => {
          let flag = this.chainList.some(item => item.id == el.pricePolicyMasterId);
          if (!flag) {
            let removeIndex = this.selectedPolicyList.findIndex(x => x.id == el.id)
            if (removeIndex > -1) {
              this.selectedPolicyList.splice(removeIndex, 1)
            }

          }
        })
      }
      console.log("***", this.selectedPolicyList)
      let jsonObj = {
        name: this.name,
        startPriceListNameId: this.startPriceListNameId,
        endPriceListNameId: this.endPriceListNameId,
        pricePolicies: this.selectedPolicyList
      }
      res = await this.httpApiService.updatePolicyChain(this.id, jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }

  }

  async validateControls() {
    if (this.chainList.length > 0 && !!this.startPriceListNameId && !!this.endPriceListNameId && !!this.name) {
      return true;
    } else {
      await this.configService.showToast('error', 'Please insert details')
      return false;
    }
  }
}
