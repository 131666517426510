import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-manage-price-clartiy-mapping-master',
  templateUrl: './manage-price-clartiy-mapping-master.page.html',
  styleUrls: ['./manage-price-clartiy-mapping-master.page.scss'],
})
export class ManagePriceClartiyMappingMasterPage implements OnInit {

  public fromClarity: any;
  public toClarity: any;
  public fromClarityList = []
  public toClarityList = []
  public fromClarityIdList = [];
  public toClarityIdList = []

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    private toastCtrl: ToastController,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  ngOnInit() {
    this.getStoneMasterDetailByName();
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'fromClarityIdList') {
      if (idList.length > 0) {
        this.fromClarity = idList[0]
      } else {
        this.fromClarity = null;
      }
    } else if (property == 'toClarityIdList') {
      if (idList.length > 0) {
        this.toClarity = idList[0]
      } else {
        this.toClarity = null;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async getStoneMasterDetailByName() {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailByName('clarity');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data)
      let dataList = data.data;
      let data1 = []
      let data2 = []
      if (dataList.length > 0) {
        dataList.forEach(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el)
          } else {
            data2.push(el)
          }
        })
        this.fromClarityList = data1;
        this.toClarityList = data2;
      }
    }
  }

}
