import { Component, OnInit,Input } from '@angular/core';
import { ModalController,LoadingController,ToastController } from '@ionic/angular'
import { ManageCertificationPage } from '../manage-certification/manage-certification.page'
import { HttpApiService } from '../../../services/http-api/http-api.service'
import { AuthenticationService } from '../../../services/authentication/authentication.service'

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {
  
  @Input() data: any;
  
  public certificationTab :any = "IssuedLabResult"
  public filtered:any = [];
  public RFidList = "";
  public openFlag = false;
  public requestStoneList:any;
  public uploadedStoneList:any;
  public pageNumber = 0;
  public noOfRecords = 5;
  public columns:any;
  public count: number = 0;
  public totalPages:any;
  public receivedStoneList:any
  public labList:any;
  public tabFor:any;
  public actionName:any;
  public actionId:any;


  constructor(
    public modalCtrl: ModalController,
    public loadingController:LoadingController,
    public httpApiService:HttpApiService,
    public toastCtrl:ToastController,
    public authService:AuthenticationService

  ) { }

  async ngOnInit() {
    let userData = await this.authService.userData();
    console.log("userData", userData);
    this.actionName = await this.data.desc
    this.actionId = await this.data.actionId
    // this.columns = [
    //   { name: "SrNo", prop: "id" },
    //   { name: "StoneId", prop: "stoneId" },
    //   { name: "Cts", prop: "stoneCts" },
    //   { name :'Lab', prop:"lab"},
    //   { name: "Date & Time", prop: "createdDate" }
    // ];
    // if (this.certificationTab == "IssuedLabResult") {
    //   // await this.getAllRequestStone();
    // } else if(this.certificationTab == "LabResultUploaded")  {
    //   // await this.getAllUploadedStone();
    // }else{
    //   // await this.getReceivedStoneListFromCertificate();
    // }
  }
  // async getReceivedStoneListFromCertificate(){
  //   let res = await this.httpApiService.getReceivedStoneListFromCertificate(this.pageNumber,this.noOfRecords)
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     let toast = await this.toastCtrl.create({
  //       message: res.error,
  //       duration: 2000,
  //       position: "top",
  //       color: "danger"
  //     });
  //     await toast.present();
  //   } else {
  //     console.log(res.json().data);
  //     this.receivedStoneList = res.json().data.content;
  //     // this.items = this.listOfParameter;
  //     this.totalPages = res.json().data.totalPages;
  //       this.count = res.json().data.totalElements;
  //     console.log("get All receivedStoneList stone", this.receivedStoneList);
  //   }
  // }
  // async changeSegment(event) {
  //   // this.pageNumber = 0;
  //   // this.noOfRecords = 5;
  //   console.log(event.detail.value);
  //   if (this.certificationTab == "IssuedLabResult") {
  //     await this.getAllRequestStone();
  //   } else if(this.certificationTab == "LabResultUploaded")  {
  //     await this.getAllUploadedStone();
  //   }else{
  //     await this.getReceivedStoneListFromCertificate();
  //   }
  // }
  // async getAllRequestStone(){
  //   // this.requestStoneList = [
  //   //   {stoneId:"WSWS1515",date:"an hour ago",crt:1.02,lab:"GIA"}
  //   // ]
  //   let res = await this.httpApiService.getAllCertificationRequest(this.pageNumber,this.noOfRecords)
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     let toast = await this.toastCtrl.create({
  //       message: res.error,
  //       duration: 2000,
  //       position: "top",
  //       color: "danger"
  //     });
  //     await toast.present();
  //   } else {
  //     console.log(res.json().data);
  //     this.requestStoneList = res.json().data.content;
  //     // this.items = this.listOfParameter;
  //     this.totalPages = res.json().data.totalPages;
  //       this.count = res.json().data.totalElements;
  //     console.log("get All stone", this.requestStoneList);
  //   }
  // }
  // async getAllUploadedStone(){
    
  //   let res = await this.httpApiService.getAllResultUploadedStone(this.pageNumber,this.noOfRecords)
  //   if (res.status == 401) {
  //     this.authService.logout();
  //   } else if (res.status == 500) {
  //     let toast = await this.toastCtrl.create({
  //       message: res.error,
  //       duration: 2000,
  //       position: "top",
  //       color: "danger"
  //     });
  //     await toast.present();
  //   } else {
  //     console.log(res.json().data);
  //     this.uploadedStoneList = res.json().data.content;
  //     this.totalPages = res.json().data.totalPages;
  //     this.count = res.json().data.totalElements;
  //     // this.items = this.listOfParameter;
  //     console.log("get uploadedStoneList", this.uploadedStoneList);
  //   }
  // }
  // async openModal(flag,item){
    
  //   if (item && item.type == "mouseenter") {
  //     //Stop event propagation and let onSelect() work
  //     item.event.stopPropagation();
  //   } else {
  //     if(!item){
  //       item = undefined
  //     }else{
  //       let labId:any;
  //       await this.getLab();
  //       this.labList.forEach(element => {
  //         if(element.name == item.row.lab){
  //           labId = element.id
  //         }
  //       });
  //       item = {itemData:item.row,actionId:this.data.actionId,labId:labId}
  //     }
  //     let css;
  //     if(flag == 2){
  //       css = "extraLargePage"
  //     }else{
  //       css = "scanRfiDPage"
  //     }
  //     console.log(item)
  //     let modalPage = await this.modalCtrl.create({
  //       component:ManageCertificationPage,
  //       componentProps:{type:flag, data:item},
  //       cssClass: css,
  //     })
  //     modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
  //     return modalPage.present();
  //   }
  // }
  
//   async getLab() {
//     let res = await this.httpApiService.getAllDataFor("lab");
//     if (res.status == 401) {
//       this.authService.logout();
//     } else if (res.status == 500) {
//       let toast = await this.toastCtrl.create({
//         message: res.error,
//         duration: 2000,
//         position: "top",
//         color: "danger"
//       });
//       await toast.present();
//     } else {
//       this.labList = res.json().data;
//       // this.items = this.listOfParameter;
//       console.log("labList", this.labList);
//     }
//   }

// async handleModalDismiss(d) {
//   console.log(d)
//   if(d.data == 1){
//     await this.getAllRequestStone();
//   }else{
//     await this.getAllUploadedStone();
//   }
  
//   // await this.searchStoneMaster(this.selectedMaster);
// }
}
