import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageParameterMappingMasterPage } from "../../actions/manage-parameter-mapping-master/manage-parameter-mapping-master.page"
@Component({
  selector: 'app-parameter-mapping-master',
  templateUrl: './parameter-mapping-master.component.html',
  styleUrls: ['./parameter-mapping-master.component.scss']
})
export class ParameterMappingMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public page = 'status';
  public listOfMappingParams = [];
  public pageNumber = 0;
  public noOfRecords = 20;
  public items = []
  public count = 0;
  public totalPages: Number = 0;
  public searchValue = ""
  public searchParam = ""
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }


  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{ name: "Sr No", prop: "" }, { name: "Lab Name", prop: "lab" }, { name: "Type", prop: "mappingType" }, { name: "KG Parameter Name", prop: "masterName" }, { name: "KG Parameter Value", prop: "valueCode" }, { name: "In Map Value", prop: "inMapValue" }, { name: "Out Map Value", prop: "outMapValue" }]
    if (this.deletePermissionFlag == true) {
      this.columns.push({ name: "Action", prop: "" })
    }
    this.getAllParamMapping(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Lab Parameter Mapping', (item) => {
      this.searchParam = ""
      this.getAllParamMapping(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Parameter Mapping');
  }

  async getAllParamMapping(pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.getAllParameterMapping(pageNumber, noOfRecords);
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfMappingParams = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      this.setItems()
    }
  }

  async setItems() {
    this.items = this.listOfMappingParams;
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;
    if (!!this.searchValue) {
      this.searchLabParameterMapping(this.searchValue, currentPage.offset, this.noOfRecords)
    } else {
      this.getAllParamMapping(currentPage.offset, this.noOfRecords);
    }
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    this.searchValue = val;
    if (val && val.trim() !== "") {
      let res: any;
      res = await this.httpApiService.searchLabParameterMapping(val, this.pageNumber, this.noOfRecords);
      let data = res.json();
      if (data.status == 401) {
        await this.authService.logout()
      } else if (data.status == 500) {
        await this.configService.showToast('error', data.error)
      } else if (data.status == 400) {
        await this.configService.showToast('error', data.error)
      } else {
        this.listOfMappingParams = data.data.content;
        this.totalPages = data.data.totalPages;
        this.count = data.data.totalElements;
        this.setItems()
      }
    } else {
      await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
    }
  }

  async searchLabParameterMapping(val, pageNumber, noOfRecords) {
    let res: any;
    res = await this.httpApiService.searchLabParameterMapping(val, pageNumber, noOfRecords);
    let data = res.json();
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfMappingParams = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
      this.setItems()
    }
  }

  async manageMappingMaster(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      if (flag == 'update' && this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageParameterMappingMasterPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      } if (flag == 'add' && this.addPermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageParameterMappingMasterPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      if (!!this.searchValue) {
        await this.searchLabParameterMapping(this.searchValue, this.pageNumber, this.noOfRecords)
      } else {
        await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
      }
    }
  }

  async deleteConfirm(item) {
    console.log(item)
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Mapping ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteParamMapping(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteParamMapping(item) {
    let res: any;
    res = await this.httpApiService.deleteLabParameterMapping(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllParamMapping(this.pageNumber, this.noOfRecords);
    }
  }

}
