import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import {
  ModalController,
  ToastController,
  LoadingController,
  Events
} from "@ionic/angular";
import { ManageRolesPage } from "../manage-roles/manage-roles.page";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ConfigService } from "../../../services/config/config.service";
import { PriceNameMasterPage } from "../price-name-master/price-name-master.page";
import { ManagePriceClartiyMappingMasterPage } from "../manage-price-clartiy-mapping-master/manage-price-clartiy-mapping-master.page";

@Component({
  selector: 'app-price-clartiy-mapping-master',
  templateUrl: './price-clartiy-mapping-master.component.html',
  styleUrls: ['./price-clartiy-mapping-master.component.scss']
})
export class PriceClartiyMappingMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    public toastCtrl: ToastController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
  }

  async manageMapppingMaster() {
    let modal = await this.modalCtrl.create({
      component: ManagePriceClartiyMappingMasterPage,
      componentProps: [],
      backdropDismiss: false,
      cssClass: 'largePage',
    })
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {

    }
  }
}
