import { Component, OnInit, Input } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
@Component({
  selector: "app-inventory-error",
  templateUrl: "./inventory-error.component.html",
  styleUrls: ["./inventory-error.component.scss"]
})
export class InventoryErrorComponent implements OnInit {
  public page = "inventoryError";
  public columns: any;
  public fromDepartment: any;
  public pageNumber = 0;
  public noOfRecords = 5;
  public count: Number = 0;
  public listOfErrors: any = [];

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "Box Name", prop: "label" },
      { name: "Stone Id", prop: "stoneId" },
      { name: "RFID", prop: "rfid" },
      { name: "Time", prop: "createdDate" }
    ];
    this.getUserData();
    this.event.subscribe('InventoryError', (item) => {
      this.getUserData();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('InventoryError');
  }


  async getUserData() {
    let userData = await this.authService.userData();
    this.fromDepartment = userData.departmentId;
    await this.findAllErrorByDepartmentAndUser(
      this.pageNumber,
      this.noOfRecords
    );
  }

  async findAllErrorByDepartmentAndUser(pageNumber, noOfRecords) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findAllErrorByDepartmentAndUser(
      this.fromDepartment,
      pageNumber,
      noOfRecords
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfErrors = data.data.content;
      this.count = data.data.totalElements;
    }
  }

  async setInventoryErrorPage(currentPage) {
    this.pageNumber = currentPage.offset;
    this.findAllErrorByDepartmentAndUser(currentPage.offset, this.noOfRecords);
  }
}
