import { Component, Input, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { LoadingController, ModalController, AlertController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { ManageStatusPage } from "../manage-status/manage-status.page";

@Component({
  selector: 'app-status-master',
  templateUrl: './status-master.component.html',
  styleUrls: ['./status-master.component.scss']
})
export class StatusMasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public columns = [];
  public page = 'status';
  public listOfStatus = [];
  public count = 0;
  public items = [];
  public manageSelectedStatus = '';
  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) {
  }


  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.columns = [{ name: "Sr No", prop: "" }, { name: "Status Name", prop: "name" }, { name: "Type", prop: "type" }, { name: "Status", prop: "isActive" }];
    if (this.deletePermissionFlag == true) {
      this.columns.push({ name: "Action", prop: "" })
    }
    this.getAllStatus()
    this.event.subscribe('Status Master', (item) => {
      this.getAllStatus()
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Status Master');
  }

  setItems() {
    this.items = this.listOfStatus;
    this.count = this.listOfStatus.length;
  }

  async filterItems(ev: any) {
    let val = ev.target.value;
    console.log(val)
    await this.setItems();
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (item.name && item.name.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (item.type && item.type.toLowerCase().includes(val.toLowerCase())) {
          return true;
        }
      });
      this.count = this.items.length;
    } else {
      await this.setItems();
    }
  }

  async getAllStatus() {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getStatus();
    let data = res.json();
    await this.loadingController.dismiss()
    if (data.status == 401) {
      await this.authService.logout()
    } else if (data.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (data.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.listOfStatus = data.data;
      this.setItems();
      this.count = this.listOfStatus.length;
    }
  }

  async manageStatus(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Action')) {
      item.event.stopPropagation();
    } else {
      if (flag == 'update' && this.updatePermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageStatusPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
      if (flag == 'add' && this.addPermissionFlag == true) {
        let modal = await this.modalCtrl.create({
          component: ManageStatusPage,
          backdropDismiss: false,
          cssClass: "largePage",
          componentProps: { item: item, flag: flag, }
        })
        modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
        return await modal.present();
      }
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllStatus();
    }
  }

  //Start Delete Status 

  async confirmDeleteStatus(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Status ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteStatus(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteStatus(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deleteMasterStatus(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.getAllStatus();
    }
  }

  //END

  //Start Delete Block




}
