import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-company',
  templateUrl: './manage-company.page.html',
  styleUrls: ['./manage-company.page.scss'],
})
export class ManageCompanyPage implements OnInit {

  public gstin: any;
  public cinNo: any;
  public email: any;
  public pan: any;
  public tel: any;
  public fax: any;
  public address: any;
  public companyName: any;
  public state: any;
  public stateCode: any;
  public companyFullName: any;
  public address2: any;


  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh')
  }

  async addCompany() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        gstin: this.gstin,
        pan: this.pan,
        tel: this.tel,
        fax: this.fax,
        state: this.state,
        stateCode: this.stateCode,
        cinNo: this.cinNo,
        email: this.email,
        address: this.address,
        companyFullName: this.companyFullName,
        address2: this.address2
      };
      let res: any;
      res = await this.httpApiService.addCompany(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (this.pan != null && this.pan != undefined && this.pan != '') {
      var panPattern = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
      if (this.pan.search(panPattern) == -1) {
        await this.configService.showToast("error", "Please enter valid PAN");
        return false;
      }
      let gstinPattern = /^(0[1-9]|[1-2][0-9]|3[0-9])([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([a-zA-Z0-9]){1}([a-zA-Z]){1}([a-zA-Z0-9]){1}/;
      if (this.gstin.search(gstinPattern) == -1) {
        await this.configService.showToast("error", "Please enter valid GSTIN");
        return false;
      }
      let email = await this.configService.isValidEmail(this.email)
      if (!email) {
        await this.configService.showToast("error", "Please enter valid Email");
        return false;
      }
      if (this.cinNo != null && this.cinNo != undefined && this.cinNo != '' && this.cinNo.length == 21) {
        if (this.address != null && this.address != '' && this.address != undefined && this.state != null && this.state != undefined && this.state != ''
          && this.stateCode != null && this.stateCode != undefined && this.stateCode != ''
          && this.companyName != null && this.companyName != undefined && this.companyName != ''
          && this.companyFullName != null && this.companyFullName != undefined && this.companyFullName != '') {
          return true;
        } else {
          await this.configService.showToast("error", "Please enter Details");
          return false;
        }
      } else {
        await this.configService.showToast("error", "Please enter valid CIN No");
        return false;
      }
    }
  }

}
