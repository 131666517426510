import { Component, OnInit, Input } from "@angular/core";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ManageVoucherBookPage } from "../manage-voucher-book/manage-voucher-book.page";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-voucher-book",
  templateUrl: "./voucher-book.component.html",
  styleUrls: ["./voucher-book.component.scss"]
})
export class VoucherBookComponent implements ProcessActionComponent {
  @Input() data: any;
  public listOfTypes: any;
  public items: any;
  public selectedType: any;
  public findByType: any = [];
  public searchText: any;
  public allTypes: any = [];
  public arrowKeyLocation = -1;
  public arrowKeyLocationAction = -1;

  constructor(
    public httpApiService: HttpApiService,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.getAllTypes();
    this.event.subscribe('Manage Voucher Book', (item) => {
      this.getAllTypes();
      if (!!this.searchText) {
        this.searchType(this.searchText);
      }
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Manage Voucher Book');
  }

  async getAllTypes() {
    this.allTypes = [];
    let res: any;
    res = await this.httpApiService.getAllTypesForVoucher();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTypes = data.data;
      for (let i = 0; i < this.listOfTypes.length; i++) {
        this.allTypes.push({ id: i + 1, voucherType: this.listOfTypes[i] });
      }
      await this.setItems();
    }
  }

  async selectType(item) {
    await this.searchType(item.voucherType);
  }

  async searchType(selectedType) {
    let type;
    if (selectedType != undefined) {
      type = selectedType;
      this.searchText = selectedType;
    } else {
      type = this.searchText;
    }
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.getAllVouchersForSelectedType(type);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.findByType = data.data;
      console.log(this.findByType);
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.searchText = this.items[this.arrowKeyLocation].voucherType;
            break;
          } else {
            break;
          }
        }
      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.searchText = this.items[this.arrowKeyLocation].voucherType;
            break;
          } else {
            break;
          }
        }
    }
  }

  setItems() {
    this.items = this.allTypes;
  }

  async addVoucherBook() {
    const modal = await this.modalCtrl.create({
      component: ManageVoucherBookPage,
      backdropDismiss: false,
      componentProps: {
        selectedType: this.searchText,
        type: 2,
        typeArray: this.listOfTypes
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllTypes();
      await this.selectType(this.searchText);
    }
  }

  async deleteVoucher(item) {
    let res: any;
    res = await this.httpApiService.deleteVoucher(item.id);
  }

  async updateVoucher(selectedItem) {
    const modal = await this.modalCtrl.create({
      component: ManageVoucherBookPage,
      backdropDismiss: false,
      componentProps: {
        selectedItem: selectedItem,
        type: 1,
        typeArray: this.listOfTypes
      }
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
    // let updateObj = {};
    // let res: any;
    // res = await this.httpApiService.updateVoucher(updateObj);
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.voucherType.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      // this.items = [];
    }
  }
}
