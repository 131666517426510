import { Component, Input } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";

@Component({
  selector: 'app-inter-cssptransfer',
  templateUrl: './inter-cssptransfer.component.html',
  styleUrls: ['./inter-cssptransfer.component.scss']
})
export class InterCSSPTransferComponent implements ProcessActionComponent {
  @Input() data: any;

  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public sizeIdList = []
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public type = 'from';
  public csspList = [];
  public sizeList = [];
  public sizeId: any;
  public fromCsspList = []
  public toCsspList = []
  public fromTransferCsspIdList = []
  public fromTransferCsspList = []

  public toTransferCsspIdList = []
  public toTransferCsspList = []
  public userId: any;
  public showSelected = true;
  public openSelectedFromCSSPFlag = false;
  public openSelectedToCSSPFlag = false;
  public sizeType: any;

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events,
  ) { }

  async ngOnInit() {
    await this.getDefaultSetting()
    this.findShapeByType();
    this.findParametersForMixSizeMaster();
    this.event.subscribe('Inter Cssp Transfer', (item) => {
      this.findShapeByType();
      this.findParametersForMixSizeMaster();
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inter Cssp Transfer')
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.sizeIdList = []
        console.log(idList, idList[0])
        this.sizeIdList = []
        this.findSizeByShape(idList[0])
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeId = idList[0];
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    res = await this.httpApiService.getDefaultSetting(
      this.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.type) {
          this.sizeType = dataValue.map.type
        }
      }
    }
  }


  changeType() {
    this.colorIdList = [];
    this.shapeIdList = [];
    this.clarityIdList = [];
    this.sizeIdList = []
    this.shapeId = [];
    this.clarityId = [];
    this.colorId = [];
    this.sizeList = []
    this.fromCts = null;
    this.toCts = null;
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
    }
  }

  async findShapeByType() {
    let res: any;
    console.log(this.type)
    res = await this.httpApiService.findShapeByType(this.sizeType ? this.sizeType : 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, this.sizeType ? this.sizeType : 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        if (this.type == 'from') {
          this.fromTransferCsspList = []
          this.fromCsspList = data.data;
          this.fromCsspList.forEach(el => {
            el.transferCts = el.size;
            this.fromTransferCsspList.push(el)
          })
          this.openSelectedFromCSSPFlag = true;
        } else {
          this.toTransferCsspList = []
          this.toCsspList = data.data;
          this.toCsspList.forEach(el => {
            this.toTransferCsspList.push(el)
          })
          this.openSelectedToCSSPFlag = true;
        }

      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }
    }
    console.log(this.csspList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  selectedCsspList() {
    if (this.type == 'from') {
      this.openSelectedFromCSSPFlag = true;
    } else {
      this.openSelectedToCSSPFlag = true;
    }
  }

  async transferCSSP() {
    if (!!this.fromTransferCsspList[0].transferCts && this.fromTransferCsspList[0].transferCts > 0) {
      let res: any;
      await this.configService.showLoader()
      let jsonObj = {
        fromCsspId: this.fromTransferCsspList[0].id,
        toCsspId: this.toTransferCsspList[0].id,
        cts: this.fromTransferCsspList[0].transferCts
      }
      console.log(jsonObj)
      res = await this.httpApiService.stoneInterCsspTransfer(jsonObj);
      let data = res.json()
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.fromTransferCsspList = []
        this.toTransferCsspList = []
        this.toCsspList = []
        this.sizeList = []
        this.fromCsspList = []
        this.openSelectedFromCSSPFlag = false;
        this.openSelectedToCSSPFlag = false;
        this.changeType();
      }
    } else {
      await this.configService.showToast('error', 'Please insert valid size value')
    }
  }

}
