import { Component, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
@Component({
  selector: "app-search-single-stone",
  templateUrl: "./search-single-stone.component.html",
  styleUrls: ["./search-single-stone.component.scss"]
})
export class SearchSingleStoneComponent implements ProcessActionComponent {
  @Input() data: any;
  public query: any;
  public type = "SS";
  public department = "Sales";
  public disable: any = [];
  public cart = true;

  constructor() { }

  ngOnInit() {
    let name = [];
    let departmentName = [];
    departmentName.push(this.department);
    name.push(this.type);
    this.disable.push("transType");
    this.disable.push("department");
    this.query = {
      //   facetFilters: ["transType:" + [this.type], "department:" + [this.department]],
      disjunctiveFacetsRefinements: {
        transType: name,
        department: departmentName
      }
    };
  }
}
