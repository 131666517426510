import { Component, OnInit, Input } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-lab-expenses',
  templateUrl: './lab-expenses.component.html',
  styleUrls: ['./lab-expenses.component.scss']
})
export class LabExpensesComponent implements ProcessActionComponent {
  @Input() data: any;

  public pageNumber = 0;
  public noOfRecords = 20;
  public getHistoryData = [];
  public count = 0;
  public totalPages = 0;
  public columns = []
  public selectedFile: any;
  public page = "labExpense";
  public clearFile = ""

  constructor(
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public loadingController: LoadingController,
    public event: Events
  ) {
    this.columns = [
      { name: "File Name", prop: "fileName" },
      { name: "Status", prop: "status" },
      { name: "By", prop: "username" },
      { name: "Date & Time", prop: "createdDate" }
    ];
  }

  ngOnInit() {
    this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords)
    this.event.subscribe('Lab Expenses', (item) => {
      this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords)
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Lab Expenses');
  }

  async getAllLabExpenseFileUploadHistory(pageNumber, noOfRecords) {
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getUploadHistory('Lab Expenses Upload', pageNumber, noOfRecords)
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      await this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast('error', data.error)
    } else if (res.status == 400) {
      await this.configService.showToast('error', data.error)
    } else {
      this.getHistoryData = data.data.content;
      this.totalPages = data.data.totalPages;
      this.count = data.data.totalElements;
    }
  }

  async setPage(currentPage) {
    this.pageNumber = currentPage.offset;;
    await this.getAllLabExpenseFileUploadHistory(currentPage.offset, this.noOfRecords)

  }

  handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {

    }
  }

  fileObject(event) {
    this.clearFile = event.target.value
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  async addLabExpenses() {
    if (this.selectedFile) {
      const formData: FormData = new FormData();
      formData.append("file", this.selectedFile);
      await this.configService.showLoader();
      let res: any;
      res = await this.httpApiService.addLabExpenses(formData);
      console.log(res.json());
      await this.loadingController.dismiss();
      let uploadData = res.json();
      if (uploadData.status == 400) {
        await this.configService.showToast("error", uploadData.error);
      } else if (res.status == 500) {
        await this.configService.showToast("error", uploadData.error);
      } else {
        await this.configService.showToast("success", uploadData.message);
        this.selectedFile = null;
        this.clearFile = null;
        await this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords);
      }
    } else {
      await this.configService.showToast("error", "Please select file");
    }
  }

  async labExpenseCsvDownloadFile(file) {
    console.log(file)
    let res: any;
    res = await this.httpApiService.labExpenseCsvDownload(file.id);
  }


  //Start Delete Policy 

  async confirmDeletedlabExpense(item) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this lab expense file ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deletelabExpense(item.id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deletelabExpense(id) {
    console.log(id)
    let res: any;
    res = await this.httpApiService.deletelabExpense(id);
    let data = res.json();
    if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllLabExpenseFileUploadHistory(this.pageNumber, this.noOfRecords);
    }
  }
}
