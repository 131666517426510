import { Component, OnInit, ɵConsole } from "@angular/core";
import {
  NavParams,
  ModalController,
  ToastController,
  Events,
  AlertController,
  LoadingController
} from "@ionic/angular";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "src/app/services/http-api/http-api.service";

@Component({
  selector: 'app-inter-box-transfer',
  templateUrl: './inter-box-transfer.page.html',
  styleUrls: ['./inter-box-transfer.page.scss'],
})
export class InterBoxTransferPage implements OnInit {

  public columns = [];
  public stoneList = [];
  public parcelBoxList = []
  public departmentId: any;
  public boxId: any;
  public count: number = 0
  public isNext: boolean = true;
  public departmentName: any;
  public userId: any;
  public transTypeId: any;
  public boxTypeId: any;
  public shapeName: any;
  public sizeName: any;
  public createNew: any;
  public actionId: any;
  public fromCts: any;
  public toCts: any;
  public label: any;
  public boxType: any;

  constructor(public navParams: NavParams,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public toastCtrl: ToastController,
    public httpApiService: HttpApiService,
    public alertCtrl: AlertController,
    public authService: AuthenticationService,
    public event: Events,
    public loadingController: LoadingController) { }

  async ngOnInit() {
    this.stoneList = await this.navParams.get('stoneList')
    this.shapeName = await this.navParams.get('shapeName')
    this.actionId = await this.navParams.get('actionId');
    this.boxType = await this.navParams.get('boxType');
    console.log(this.boxType)
    this.sizeName = await this.navParams.get('sizeName')
    this.createNew = await this.navParams.get('createNew')
    this.label = this.shapeName + "-" + this.sizeName + "-"
    await this.getUserData();
    if (this.boxType == 'Parcel') {
      this.createNew = true;
    } else {
      this.createNew = false;
    }
    console.log(this.stoneList, this.createNew)
    if (this.stoneList.length > 0) {
      this.count = this.stoneList.length;
      this.stoneList.forEach(el => {
        el.transferCts = el.size
        el.errorCt = false;
      })
    }
    this.columns = [{ name: "Sr No", prop: "" },
    { name: "CSSP", prop: "stoneId" },
    { name: "Ct.", prop: "size" },]
    if (!!this.boxType && this.boxType == 'Parcel') {
      this.columns.push({ name: "Transfer Ct.", prop: "transferCts" })
    }
    await this.getBoxType();
    await this.getAllTransType()

  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log(data.data);
      let dataValue = data.data;
      if (dataValue.length > 0) {
        dataValue.filter(el => {
          if (el.name == 'MIX') {
            this.transTypeId = el.id;
          }
        })
      }
    }
  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let list = [];
      let dataValue = data.data;
      dataValue.forEach(el => {
        if (el.type == "Parcel") {
          this.boxTypeId = el.id
        }
      });
    }
  }


  async getUserData() {
    let userData = await this.authService.userData();
    this.userId = userData.userId;
    if (userData.departmentId) {
      this.departmentId = userData.departmentId;
      this.departmentName = userData.departmentName;
    } else {
      await this.authService.logout()
    }

    await this.getAllBoxesByDepartmentID();
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'boxList') {
      if (idList.length > 0) {
        this.boxId = idList[0]
      } else {
        this.boxId = null;
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getAllBoxesByDepartmentID() {
    this.parcelBoxList = []
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findBoxByUserAndType(
      !!this.boxType ? this.boxType : 'Parcel'
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.parcelBoxList = data.data;
      console.log("data", data, this.parcelBoxList);
    }
  }

  //stoneId

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  //update price in cart

  async updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', event, cell, rowIndex)
    this.stoneList[rowIndex][cell] = parseFloat(event.target.value);

    this.stoneList = [...this.stoneList];
    if (cell == 'transferCts') {
      if (this.stoneList[rowIndex][cell] > this.stoneList[rowIndex]['size'] ||
        this.stoneList[rowIndex][cell] == 0 ||
        this.stoneList[rowIndex][cell] == "") {
        this.stoneList[rowIndex]['errorCt'] = true;
      } else {
        this.stoneList[rowIndex]['errorCt'] = false;
      }
      let flag: boolean;
      this.stoneList.forEach(item => {
        if (parseFloat(item.transferCts) > parseFloat(item.size) || parseFloat(item.transferCts) == 0 || item.transferCts == "") {
          flag = true;
          this.isNext = false;
        }
      })
      if (flag == false || flag == undefined) {
        this.isNext = true;
      }
    }
    console.log(this.stoneList)
  }

  async confirmCreateNewParcel() {
    const alert = await this.alertCtrl.create({
      header: "Create",
      message: "Creating New Parcel..",
      inputs: [
        {
          name: 'Label',
          placeholder: this.label,
          value: this.label,
        }
      ],
      buttons: [
        {
          text: "Okay",
          handler: (data) => {
            this.label = data.Label
            console.log(data, "Confirm Okay");
            this.createNewParcel()
          }
        }
      ]
    });
    await alert.present();
  }

  async createNewParcel() {
    let jsonObj = {
      departmentId: this.departmentId,
      transTypeId: this.transTypeId,
      userId: this.userId,
      label: this.label,
      maxCapacity: 100000,
      currentStoneCount: 0,
      boxTypeId: this.boxTypeId
    };
    let res: any;
    res = await this.httpApiService.addBox(jsonObj);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      await this.transferToBox(dataValue.id)
    }

  }

  async transferToBox(boxId) {
    await this.configService.showLoader()
    let mapOfStoneCts = {};
    this.stoneList.forEach((el, i) => {
      mapOfStoneCts[el.id] = el.transferCts
    })
    let jsonObj = {
      boxId: !!boxId ? boxId : this.boxId,
      mapOfStoneCts: mapOfStoneCts
    }
    console.log(jsonObj)
    let res: any;
    res = await this.httpApiService.transferToBox(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.modalCtrl.dismiss();
    }
  }
}
