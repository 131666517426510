import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
@Component({
  selector: 'app-manage-programme',
  templateUrl: './manage-programme.page.html',
  styleUrls: ['./manage-programme.page.scss'],
})
export class ManageProgrammePage implements OnInit {

  public listOfCustomers = [];
  public listOfParams = [];
  public listOfParamValue = [];
  public paramIdList = [];
  public parameter = [];
  public valueIdList = [];
  public customerIdList = [];
  public option = [];
  public value = [];
  public progName: any;
  public criteriaList = [];
  public customerId: any;
  public discPer: number
  public listOfParamNamesAndItsValues = [];
  public isActive: Boolean;
  public item: any;
  public flagAddUpdate: any;
  public id: any;
  public buttonName = false;

  constructor(private authService: AuthenticationService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
    let data = this.navParams.get('item');
    if (data != 0) {
      this.item = data.row;
      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      this.getProgramById();
    } else {
      this.getAllCustomer();
    }
  }

  ngOnInit() {
    this.getAllStoneMaster();
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "selectedParameter") {
      if (idList.length > 0) {
        this.paramIdList = obj;
        this.getAllValueByParameterId(idList[0])
        this.parameter = idList[0];
      } else {
        this.parameter = [];
        this.value = []
        this.paramIdList = []
        this.valueIdList = []
      }
    } else if (property == "selectedParamValue") {
      if (idList.length > 0) {
        this.valueIdList = obj;
        this.value = idList;
      } else {
        this.valueIdList = []
        this.value = []
      }
    } else if (property == "selectedCustomer") {
      if (idList.length > 0) {
        this.customerIdList = obj;
        this.customerId = idList[0];
      } else {
        this.customerId = null;
      }
    }
  }

  async getProgramById() {
    await this.getAllCustomer();
    await this.configService.showLoader()
    let res: any;
    res = await this.httpApiService.getProgramById(this.id);
    let data = res.json();
    await this.loadingController.dismiss()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.progName = dataValue.progName;
      this.isActive = dataValue.isActive;
      this.discPer = dataValue.discPer;
      this.customerId = dataValue.customerId;
      if (dataValue.customerId) {
        this.customerIdList = [];
        this.listOfCustomers.filter(el => {
          if (el.id == dataValue.customerId) {
            let data = { id: el.id, customerName: el.customerName }
            this.customerIdList.push(data)
          }
        })
      }
      let criteriaParamValue = dataValue.listOfParamNamesAndItsValues;
      for (let i = 0; i < criteriaParamValue.length; i++) {
        let valueList = []
        this.value = []
        criteriaParamValue[i].paramValuesAndCode.forEach(el => {
          valueList.push(el.code)
          this.value.push(el.id)
        })
        console.log(criteriaParamValue[i])
        this.criteriaList.push({ paramName: criteriaParamValue[i].paramName, value: valueList, valuelist: valueList.toString() });
        this.listOfParamNamesAndItsValues.push({ paramName: criteriaParamValue[i].paramName, paramValues: this.value })
      }
      console.log(this.criteriaList, this.listOfParamNamesAndItsValues, this.customerIdList)
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }


  async getAllCustomer() {
    let res: any;
    res = await this.httpApiService.getAllCustomerList();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfCustomers = data.data;
      console.log(this.listOfCustomers)
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParams = data.data;
    }
  }

  async getAllValueByParameterId(id) {
    let res: any;
    res = await this.httpApiService.getStoneMasterDetailById(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfParamValue = data.data;

    }
  }

  async addProgram() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        progName: this.progName,
        isActive: this.isActive == true ? 1 : 0,
        customerId: this.customerId,
        discPer: this.discPer,
        listOfParamNamesAndValues: this.listOfParamNamesAndItsValues
      }
      res = await this.httpApiService.addProgram(jsonObj)
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async updateProgram() {
    let validate = await this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        progName: this.progName,
        isActive: this.isActive == true ? 1 : 0,
        customerId: this.customerId,
        discPer: this.discPer,
        listOfParamNamesAndValues: this.listOfParamNamesAndItsValues
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateProgram(this.id, jsonObj)
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        await this.modalCtrl.dismiss()
      }
    }
  }

  async validateControls() {
    if (this.progName != null && this.progName != undefined && this.progName != '' && this.customerId != null && this.customerId != undefined && this.customerId != '' && this.listOfParamNamesAndItsValues.length > 0) {
      if (this.discPer != 0 && this.discPer > 0) {
        return true;
      } else {
        await this.configService.showToast('error', 'Please instert valid Disc %')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please instert the detail')
      return false;
    }
  }

  async editParameter(item) {
    this.buttonName = true;
    await this.configService.showLoader();
    let editData: any;
    this.paramIdList = []
    this.listOfParams.filter(el => {
      if (el.masterName == item.paramName) {
        let data = { id: el.id, masterName: el.masterName }
        this.paramIdList.push(data)
        this.parameter = el.id
      }
    })
    if (this.parameter) {
      await this.getAllValueByParameterId(this.parameter);
      this.valueIdList = []
      item.value.forEach(el => {
        this.listOfParamValue.filter(item => {
          if (el == item.code) {
            let data = { id: item.id, code: item.code }
            this.valueIdList.push(data);
            this.value.push(item.id)
          }
        })
      })
    }
    await this.loadingController.dismiss();
  }

  //AddLocal

  async manageCriteria() {
    this.buttonName = false;
    if (this.criteriaList.length > 0) {
      let flag: boolean;
      this.criteriaList.filter(el => {
        if (el.paramName == this.paramIdList[0].masterName) {
          flag = true;
        }
      })
      if (flag == false || flag == undefined) {
        let valueList = []
        this.valueIdList.forEach(el => {
          valueList.push(el.code)
        })
        this.criteriaList.push({ paramName: this.paramIdList[0].masterName, value: valueList, valuelist: valueList.toString() })
        this.listOfParamNamesAndItsValues.push({ paramName: this.paramIdList[0].masterName, paramValues: this.value })
      } else {
        this.criteriaList.forEach(el => {
          if (this.paramIdList[0].masterName == el['paramName']) {
            let removeIndex = this.criteriaList.map((item) => item['paramName']).indexOf(el['paramName']);
            this.criteriaList.splice(removeIndex, 1);
            this.listOfParamNamesAndItsValues.splice(removeIndex, 1)
          }
        })
        // this.configService.showToast('error', 'Parameter already exist.')
        let valueList = []
        this.valueIdList.forEach(el => {
          valueList.push(el.code)
        })
        this.criteriaList.push({ paramName: this.paramIdList[0].masterName, value: valueList, valuelist: valueList.toString() })
        this.listOfParamNamesAndItsValues.push({ paramName: this.paramIdList[0].masterName, paramValues: this.value })
      }
    } else {
      let valueList = []
      this.valueIdList.forEach(el => {
        valueList.push(el.code)
      })
      this.criteriaList.push({ paramName: this.paramIdList[0].masterName, value: valueList, valuelist: valueList.toString() })
      this.listOfParamNamesAndItsValues.push({ paramName: this.paramIdList[0].masterName, paramValues: this.value })
    }
    this.listOfParamValue = [];
    this.paramIdList = [];
    this.valueIdList = [];
  }

  async remove(item) {
    this.criteriaList.forEach(el => {
      if (item.paramName == el['paramName']) {
        let removeIndex = this.criteriaList.map((item) => item['paramName']).indexOf(el['paramName']);
        this.criteriaList.splice(removeIndex, 1);
        this.listOfParamNamesAndItsValues.splice(removeIndex, 1)
      }
    })
  }

}
