import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";

@Component({
  selector: "app-manage-requests",
  templateUrl: "./manage-requests.page.html",
  styleUrls: ["./manage-requests.page.scss"]
})
export class ManageRequestsPage implements OnInit {
  @ViewChild("dataTable") public dataTable: any;

  public data: any;
  public flag: any;
  public memo: any;
  public userData: any;
  public requestAction: any = [];
  public requestIdList: any = [];
  public selectedAction: any;
  public labList: any;
  public filtered = [];
  public allCustomer: any;
  public requestId: any;
  public labId: any;
  public requestActionFlag: any;
  public userId: any;
  public voucherBookId: any;
  public actionId: any;
  public type: any;
  public columns: any;
  public allCustomerContact: any;
  public noPagination = true;
  public stoneForCertificate = [];
  public stoneForCertificateId = [];
  public requestJson: any = {};
  public selected = [];
  public listOfLocations: any;
  public usersList: any;
  public customerId: any;
  public toUser: any;
  public customerIdList = [];
  public allKAMPersonList = [];
  public voucherType: any;
  public isSelect: Boolean;
  public status = "External"
  public custIdList = [];
  public userIdList = [];
  public locationIdList = []
  public columnsForCertification = [];
  public servicesList = []
  public showSelected = true;
  public roleId: any;
  public companyDetails: any;
  public serviceIdList = []
  public salesPersonName: any;
  public flagOpenDoc = false;
  public customerDetails: any;
  public nameOfPersonReceivingGood: any;
  public transType: any;
  public deliveryChallanNo: any;
  public locationId: any;
  public fxRate: any;
  public customerContactId: any;
  public transporterDetails: any;
  public lab: any;
  public boardBaseUrl: any;
  public terms: any;
  public disc1: any;
  public disc2: any;
  public inventoryManagerType: any;
  public partyType: any;
  public kamIdList = []

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
  ) {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");
    this.inventoryManagerType = this.navParams.get("inventoryManagerType")
  }

  async dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "requestAction") {
      this.isSelect = false;
      if (idList.length > 0) {
        this.kamIdList = []
        this.stoneForCertificate = []
        this.requestId = idList[0]
        this.requestActionFlag = obj[0].actionName;
        this.selectedAction = obj[0].actionName;
        //for request 
        if (this.requestActionFlag == "Certification") {
          await this.getDefaultSetting(this.userId, this.requestId);
          this.getAllCustomer(this.partyType);
          this.getTransporterDetail('Transport');
        } else if (this.requestActionFlag == "Recut" || this.requestActionFlag == "Observation") {
          this.userIdList = [];
          this.toUser = null;
          this.locationIdList = []
          this.getAllCompany();
          this.getTransporterDetail('Transport');
          this.getDefaultSetting(this.userId, this.requestId);
        } else if (this.requestActionFlag == 'Requisition') {
          this.getDefaultSetting(this.userId, this.requestId);
          this.getAllKAMPerson();
        } else if (this.requestActionFlag == 'Memo Service') {
          this.customerIdList = []
          this.custIdList = []

          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        } else if (this.requestActionFlag == 'Self Observation') {
          this.customerIdList = []
          this.custIdList = []
          this.getAllKAMPerson();
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        } else if (this.requestActionFlag == 'Memo Client') {
          this.customerIdList = []
          this.custIdList = []
          this.customerId = null
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        } else if (this.requestActionFlag == 'Customer Hold') {
          this.customerIdList = []
          this.custIdList = []
          this.customerId = null
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        } else if (this.requestActionFlag == 'Customer Confirm') {
          this.customerIdList = []
          this.custIdList = []
          this.customerId = null
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        } else if (this.requestActionFlag == 'Consignment Confirm' || this.requestActionFlag == 'Consignment In Stock') {
          this.customerIdList = []
          this.custIdList = []
          this.customerId = null
          await this.getDefaultSetting(this.userId, this.requestId);
          this.allCustomerContact = [];
          this.getAllCustomer(this.partyType);
        }
      } else {
        this.selected = []
        this.customerIdList = []
        this.customerId = []
        this.custIdList = []
        this.locationId = null;
        this.stoneForCertificate = [];
        this.requestActionFlag = "";
      }
      //form data

    } else if (property == "Certification") {
      this.selected = []
      if (idList.length > 0) {
        this.customerId = idList[0];
        this.getCustomerDetails(idList[0])
        this.getRequestedStoneList(this.selectedAction, this.customerId);
      } else {
        this.customerIdList = []
        this.allCustomerContact = [];
        this.customerId = null;
        this.stoneForCertificate = []
      }
    } else if (property == "partyMaster") {
      this.selected = []
      if (idList.length > 0) {
        this.customerId = idList[0]
        this.lab = obj[0].partyName;
        this.getCustomerDetails(this.customerId)
        this.getAllCustomerContact(this.customerId);
      } else {
        this.customerIdList = []
        this.allCustomerContact = [];
        this.customerId = null;
        this.stoneForCertificate = []
      }
    } else if (property == "partyContact") {
      this.selected = []
      this.custIdList = []
      if (idList.length > 0) {
        this.customerContactId = idList[0];
        this.getRequestedStoneList(this.selectedAction, this.customerContactId);
      } else {
        this.customerContactId = null;
      }
    } else if (property == "Requisition") {
      this.selected = []
      this.labId = idList[0];
      if (idList) {
        this.getRequestedStoneList(this.selectedAction, idList);
      }
    } else if (property == "KAMPerson") {
      this.selected = []
      this.toUser = idList[0];
      console.log(this.selectedAction)
      if (this.selectedAction == 'Self Observation') {
        this.getStoneListByKAM(this.selectedAction, this.customerId, idList)
      } else {
        this.getStoneListByKAM(this.selectedAction, idList, null)
      }

    } else if (property == "Recut" || property == "Observation") {
      this.selected = []
      if (idList.length > 0) {
        this.locationId = idList[0]
        this.toCompanyDetailByName(obj[0].companyName)
        this.getAllToUser(this.locationId);
      } else {
        this.locationId = null;
        this.userIdList = [];
        this.toUser = null;
      }
    } else if (property == "User") {
      this.selected = []
      if (idList.length > 0) {
        this.nameOfPersonReceivingGood = obj[0].username;
        console.log(this.nameOfPersonReceivingGood)
        this.toUser = idList[0];
        this.getRequestedStoneList(this.selectedAction, idList);
      } else {
        this.stoneForCertificate = []
      }
    } else if (property == "additionalServices") {
      this.selected = []
      console.log(idList, obj, masterId)
      if (idList.length > 0) {
        this.stoneForCertificate.filter(el => {
          if (el.id == masterId) {
            delete el.masterId
            el[masterId] = idList;
            el.isSelected = true;
          }
        })
      } else {
        this.stoneForCertificate.filter(el => {
          if (el.id == masterId) {
            delete el.masterId;
            el.isSelected = false;
          }
        })
      }
      console.log(this.stoneForCertificate)
    }
  }

  async ngOnInit() {
    await this.getUserData();
    await this.getAllActions();
    await this.getCompanyDetail();
    await this.getLatestRateByCurrencyName();
    this.columns = [
      { name: "Sr No", prop: "id" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Cts", prop: "stoneCts" },
      { name: "Price Per Ct.", prop: "requestPrice" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Purity", prop: "clarity" },
      { name: "By", prop: "requestUser" }
    ];
    this.columnsForCertification = [
      { name: "Sr No", prop: "id" },
      { name: "StoneId", prop: "stoneId" },
      { name: "Services", prop: "" },
      { name: "Cts", prop: "stoneCts" },
      { name: "Price Per Ct.", prop: "requestPrice" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Purity", prop: "clarity" },
      { name: "By", prop: "requestUser" }
    ];

  }

  async backToTransfer() {
    this.flagOpenDoc = false;
    this.selected = [];
    this.isSelect = false;
    this.stoneForCertificateId = []
    this.stoneForCertificate = [];
    this.requestJson = [];
    this.customerContactId = null;
    this.customerIdList = [];
    this.locationIdList = []
    this.locationId = null;
    this.serviceIdList = [];
  }

  //currency detail

  async getLatestRateByCurrencyName() {
    let res: any;
    res = await this.httpApiService.getLatestRateByCurrencyName('US Dollar');
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let currencyDetail = data.data;
      this.fxRate = currencyDetail.rate;
    }
  }

  // transporter detail 

  async getTransporterDetail(type) {
    let res: any;
    res = await this.httpApiService.getAllCustomersByType(type);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      if (data.data.length > 0) {
        this.transporterDetails = data.data[0];
      }
      console.log("transporterDetails", this.transporterDetails);
    }
  }


  async getAllCustomer(type) {
    if (!!type && type.length > 0) {
      let json = {
        listOfType: type
      }
      let res: any;
      res = await this.httpApiService.findByListOfPartyType(json);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.allCustomer = data.data;
        console.log("getAllCustomers", this.allCustomer);
      }
    }
  }

  async getAllKAMPerson() {
    let res: any;
    res = await this.httpApiService.getDepartmentWiseKamPersonList('KAM Sales');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allKAMPersonList = data.data;
      console.log("get All Customer", this.allCustomer);
    }
  }

  async getCompanyDetail() {
    let companyName = await this.configService.getFromLocation()
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.companyDetails = data.data;
    }
  }

  // To company detail

  async toCompanyDetailByName(companyName) {
    let res: any;
    res = await this.httpApiService.getCompanyDetailByName(companyName);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async getCustomerDetails(id) {
    let res: any;
    res = await this.httpApiService.getCustomerDetails(id);
    let data = res.json()
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.customerDetails = data.data;
    }
  }

  async getAllCustomerContact(id) {
    let res: any;
    res = await this.httpApiService.getCustomerContactList(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allCustomerContact = data.data;
      if (this.allCustomerContact.length > 0) {
        this.customerIdList = []
        this.customerIdList.push({ id: this.allCustomerContact[0].id, name: this.allCustomerContact[0].name });
        this.customerContactId = this.allCustomerContact[0].id;
        this.nameOfPersonReceivingGood = this.allCustomerContact[0].name;
        this.getRequestedStoneList(this.requestActionFlag, this.customerContactId);
      } else {
        this.stoneForCertificate = []
        this.customerIdList = []
        this.customerContactId = null;
      }
      console.log("get All allCustomerContact", this.allCustomerContact);
    }
  }

  async getDefaultSetting(userId, ActionId) {
    let res: any;
    res = await this.httpApiService.getDefaultSetting(userId, ActionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log("DATa", dataValue);
      if (!!dataValue.map) {
        if (!!dataValue.map.voucherBook) {
          this.voucherBookId = dataValue.map.voucherBook
        }
        if (!!dataValue.map.partyType) {
          this.partyType = JSON.parse(dataValue.map.partyType)
        }
      }
    }
  }

  async getAllActions() {
    await this.configService.showLoader();
    let res: any;
    this.userData = await this.authService.userData();
    console.log(this.userData);
    res = await this.httpApiService.getAllActionsByUserID(this.userData.userId);
    let resp = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", resp.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", resp.error);
    } else {
      let data = resp.data;
      let data1 = [];
      data.forEach(el => {
        if (
          el.actionName == "Certification" ||
          el.actionName == "Recut" ||
          el.actionName == "Requisition" ||
          el.actionName == "Observation" ||
          el.actionName == "Consignment" ||
          el.actionName == "Customer Hold" ||
          el.actionName == "Memo Service" ||
          el.actionName == "Self Observation" ||
          el.actionName == "Memo Client" ||
          el.actionName == "Customer Confirm" ||
          el.actionName == "Consignment Confirm" ||
          el.actionName == "Consignment In Stock"
          // el.actionName == "Return"
        ) {
          data1.push({ id: el.id, actionName: el.actionName, voucherType: el.voucherType });
        }
      });
      this.requestAction = data1;
      console.log("list of actions by http", this.requestAction);
    }
  }

  async getStoneListByKAM(action, id, kamId) {
    console.log(action, id, kamId)
    let res: any;
    res = await this.httpApiService.getStoneListByRequest(action, id, kamId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneForCertificate = data.data;
      console.log("stoneForCertificate", this.stoneForCertificate);
    }
  }

  //list of stones requested for different actions
  async getRequestedStoneList(action, id) {
    let res: any;
    res = await this.httpApiService.getStoneListByRequest(action, id, null);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneForCertificate = data.data;
      if (this.stoneForCertificate.length > 0) {
        this.roleId = this.stoneForCertificate[0].roleId;
        this.salesPersonName = this.stoneForCertificate[0].requestUser;
        this.transType = this.stoneForCertificate[0].transType;
        this.terms = !!this.stoneForCertificate[0].termName ? this.stoneForCertificate[0].termName : 0;
        this.disc1 = !!this.stoneForCertificate[0].disc1 ? this.stoneForCertificate[0].disc1 : 0;
        this.disc2 = !!this.stoneForCertificate[0].disc2 ? this.stoneForCertificate[0].disc2 : 0;
      }
      console.log("stoneForCertificate", this.stoneForCertificate, this.requestJson);
    }
  }

  // get all company List & user list for recut and observation

  async getAllCompany() {
    let res: any;
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
      console.log("this.listOfLocations", this.listOfLocations);
    }
  }
  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
        console.log("usersList", this.usersList);
      } else {
        this.usersList = [];
      }
    }
  }

  //end

  async getUserData() {
    let userData = await this.authService.userData();
    this.type = userData.departmentName;
    this.userId = userData.userId;
    if (userData.boardBaseUrl) {
      this.boardBaseUrl = userData.boardBaseUrl;
    }
    console.log("userData", userData);
  }

  //close modal

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  //transfer Request 
  async transferRequest() {
    // this.flagOpenDoc = true;
    // await this.getCompanyDetail()
    //  window.print()
    // return
    if (!!this.voucherBookId) {
      let validate = await this.validateControls();
      if (validate) {
        await this.configService.showLoader();
        let res: any;
        let jsonObj;
        if (this.requestActionFlag == "Certification") {
          this.requestJson = {}
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
              "listOfServices": el.setOfServices
            };
          });
          jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            partyMasterId: this.customerId,
            roleId: this.roleId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (
          this.requestActionFlag == "Recut" ||
          this.requestActionFlag == "Observation"
        ) {
          let actionName = this.requestActionFlag;
          let findVoucherType = this.requestAction.filter(function (item) {
            if (item.actionName == actionName) {
              return item.actionName;
            }
          })
          if (findVoucherType.length > 0) {
            this.voucherType = findVoucherType[0].voucherType
          }
          this.requestJson = {}
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
            };
          });
          let jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            remarks: 'stone send by userId' + this.toUser,
            type: this.voucherType,
            roleId: this.roleId,
            userId: this.toUser
          };
          console.log(jsonObj)
          res = await this.httpApiService.certificateOrConsignmentRequest(jsonObj);
        } else if (
          this.requestActionFlag == "Requisition"
        ) {
          let actionName = this.requestActionFlag;
          let findVoucherType = this.requestAction.filter(function (item) {
            if (item.actionName == actionName) {
              return item.actionName;
            }
          })
          if (findVoucherType.length > 0) {
            this.voucherType = findVoucherType[0].voucherType
          }
          let jsonObj = {
            voucherDate: moment().format("YYYY-MM-DD"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            remarks: 'stone send by userId' + this.toUser,
            type: this.voucherType,
            toUser: this.toUser
          };
          console.log(jsonObj)
          res = await this.httpApiService.issueRequisition(jsonObj);
        } else if (
          this.requestActionFlag == "Self Observation"
        ) {
          // let actionName = this.requestActionFlag;
          // let findVoucherType = this.requestAction.filter(function (item) {
          //   if (item.actionName == actionName) {
          //     return item.actionName;
          //   }
          // })
          // if (findVoucherType.length > 0) {
          //   this.voucherType = findVoucherType[0].voucherType
          // }
          let jsonObj = {
            voucherDate: moment().format("YYYY-MM-DD"),
            setOfStoneIds: this.stoneForCertificateId,
            voucherBookId: parseInt(this.voucherBookId),
            toUser: this.toUser
          };
          console.log(jsonObj)
          res = await this.httpApiService.issueRequisition(jsonObj);
        } else if (this.requestActionFlag == "Customer Confirm"
          || this.requestActionFlag == "Consignment Confirm" ||
          this.requestActionFlag == "Consignment In Stock") {
          this.requestJson = {}
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
            };
          });
          jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            customerContactId: this.customerContactId,
            roleId: this.roleId,
            memo: this.memo
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Memo Client") {
          this.requestJson = {}
          console.log(this.selected)
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
            };
          });
          jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Customer Hold") {
          this.requestJson = {}
          console.log(this.selected)
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
            };
          });
          jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        } else if (this.requestActionFlag == "Memo Service") {
          this.requestJson = {}
          this.selected.forEach((el, i) => {
            this.requestJson[el.id] = {
              "requestUserId": el.requestUserId.toString(),
              "requestPrice": el.requestPrice ? el.requestPrice.toString() : null,
            };
          });
          jsonObj = {
            requestDate: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            voucherBookId: parseInt(this.voucherBookId),
            // setOfStoneIds: this.stoneForCertificateId,
            stoneRequestParams: this.requestJson,
            requestAction: this.selectedAction,
            roleId: this.roleId,
            customerContactId: this.customerContactId,
          };
          console.log(jsonObj);
          res = await this.httpApiService.certificateOrConsignmentRequest(
            jsonObj
          );
        }

        let data = res.json();
        await this.loadingController.dismiss();
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          await this.configService.showToast("success", data.message);
          console.log(data.data)
          if (data.data && data.data.bookSerialNumber) {
            this.deliveryChallanNo = data.data.shortCode + '-' + data.data.bookSerialNumber
          }
          if (!!data.data && !!data.data.url) {
            await this.httpApiService.downloadPackingList(data.data.url)
          }
          if (this.requestActionFlag == 'Certification' || this.requestActionFlag == 'Memo Service' || this.requestActionFlag == 'Memo Client' || this.requestActionFlag == 'Customer Confirm' || this.requestActionFlag == 'Recut' || this.requestActionFlag == 'Observation' || this.requestActionFlag == 'Consignment Confirm' || this.requestActionFlag == 'Consignment In Stock') {
            this.flagOpenDoc = true;
          } else {
            await this.modalCtrl.dismiss();
          }
        }
      }
    } else {
      await this.configService.showToast('error', 'Voucher book not found.')
    }
  }

  async validateControls() {
    console.log(this.stoneForCertificateId.length, this.customerContactId)
    if (this.requestActionFlag == "Certification") {
      if (
        this.stoneForCertificateId.length > 0 &&
        !!this.customerId
      ) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (
      this.requestActionFlag == "Recut" ||
      this.requestActionFlag == "Observation" ||
      this.requestActionFlag == "Requisition"
    ) {
      if (
        this.stoneForCertificateId.length > 0 &&
        !!this.toUser
      ) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Customer Confirm"
      || this.requestActionFlag == "Consignment Confirm" ||
      this.requestActionFlag == "Consignment In Stock" ||
      this.requestActionFlag == "Memo Client") {
      if (
        this.stoneForCertificateId.length > 0 &&
        !!this.customerContactId
      ) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Memo Service") {
      if (
        this.stoneForCertificateId.length > 0 &&
        !!this.customerContactId
      ) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    } else if (this.requestActionFlag == "Self Observation") {
      if (
        this.stoneForCertificateId.length > 0 &&
        !!this.toUser && !!this.customerId
      ) {
        return true;
      } else {
        await this.configService.showToast("error", "Please fill all details");
        return false;
      }
    }
  }

  // partial selection on request

  async selectAll(item) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(this.stoneForCertificate)
      this.isSelect = true;
      this.stoneForCertificateId = [];
      this.selected.forEach(el => {
        this.stoneForCertificateId.push(parseInt(el.id));
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.stoneForCertificateId = [];
    }
    console.log(this.stoneForCertificateId)
  }

  onSelect(ev) {
    console.log(ev)
    this.selected = ev.selected;
    this.stoneForCertificateId = [];
    this.selected.forEach(el => {
      this.stoneForCertificateId.push(parseInt(el.id));
    })
    console.log(this.isSelect, this.stoneForCertificateId)
    if (this.stoneForCertificateId.length == this.stoneForCertificate.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  // temp
  async openDoc() {
    this.flagOpenDoc = true;
    await this.getCompanyDetail()
    await this.configService.print()
    // await this.modalCtrl.dismiss();
  }

  //led on 
  async findByLED() {
    if (this.stoneForCertificate.length > 0) {
      let rfidList = []
      this.stoneForCertificate.forEach(el => {
        if (el.rfid) {
          rfidList.push(el.rfid)
        }
      })
      if (this.boardBaseUrl != null && this.boardBaseUrl != undefined && this.boardBaseUrl != '') {
        await this.configService.showLoader();
        let res: any;
        res = await this.httpApiService.scanWithLightOn(this.boardBaseUrl, rfidList);
        console.log(res);
        // let data = res.json();
        console.log("findBy LED", res);
        this.loadingController.dismiss();
      }
      else {
        await this.configService.showToast("error", "Scanner Board Url not found");
      }
    } else {
      await this.configService.showToast("error", "stone not found!");
    }
  }

  showStoneId(stoneId) {
    if (stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }
}
