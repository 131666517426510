import { Component, Input } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  NavParams,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageMasterPage } from "../manage-master/manage-master.page";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-master",
  templateUrl: "./master.component.html",
  styleUrls: ["./master.component.scss"]
})
export class MasterComponent implements ProcessActionComponent {
  @Input() data: any;

  public getAllStoneParam: any;
  public items: any;
  public itemsValue: any;
  public arrowKeyLocation = -1;
  public arrowKeyLocationValue = -1;
  public selectedMaster = "";
  public manageSelectedMaster = "";
  public stoneData: any;
  public listOfValue = [];
  public id: any;
  public valueSearch = "";
  public description = "";

  public readPermissionFlag: Boolean = false;
  public addPermissionFlag: Boolean = false;
  public deletePermissionFlag: Boolean = false;
  public updatePermissionFlag: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public ConfigService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    if (!!this.data.listOfActionPermissions) {
      this.data.listOfActionPermissions.filter(el => {
        if (el.permissionName == 'Read') {
          this.readPermissionFlag = true;
        } if (el.permissionName == 'Add') {
          this.addPermissionFlag = true;
        } if (el.permissionName == 'Update') {
          this.updatePermissionFlag = true;
        } if (el.permissionName == 'Delete') {
          this.deletePermissionFlag = true;
        }
      })
      console.log(this.readPermissionFlag, this.updatePermissionFlag, this.addPermissionFlag, this.deletePermissionFlag)
    }
    this.getAllStoneMaster();
    this.event.subscribe('Parameter Master', (item) => {
      this.getAllStoneMaster();
      if (!!this.selectedMaster) {
        this.searchStoneMaster(this.selectedMaster);
      }
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Parameter Master');
  }

  setItems() {
    this.items = this.getAllStoneParam;
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.getAllStoneParam = data.data;
      this.items = this.getAllStoneParam;
    }
  }

  getStoneMasterDetail(event, name) {
    this.searchStoneMaster(name);
  }

  async searchStoneMaster(name) {
    this.valueSearch = "";
    await this.ConfigService.showLoader();
    let tmpName = "";
    this.items = this.getAllStoneParam;
    if (name != undefined) {
      tmpName = name;

      this.manageSelectedMaster = name;
      this.selectedMaster = name;
    } else {
      this.selectedMaster = this.manageSelectedMaster;
      tmpName = this.manageSelectedMaster;
    }
    let selectedMasterId = this.items.filter(function (search) {
      if (search.masterName == tmpName) {
        return search.id;
      }
    });
    if (selectedMasterId.length > 0) {
      this.id = selectedMasterId[0].id;

      let res: any;
      res = await this.httpApiService.getStoneMasterDetailById(this.id);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.ConfigService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.ConfigService.showToast("error", data.error);
      } else {
        console.log(data);
        this.stoneData = data.data;
        if (this.stoneData && this.stoneData.length > 0) {
          this.listOfValue = this.stoneData;
        } else {
          this.listOfValue = [];
        }
        let index = this.items.findIndex(
          x => x.masterName == selectedMasterId[0].masterName
        );
        this.arrowKeyLocation = index;
        await this.loadingController.dismiss();
      }
    } else {
      await this.loadingController.dismiss();
      this.description = "";
      this.selectedMaster = "";
    }
  }

  async filterItems(ev: any) {
    this.selectedMaster = "";
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        return item.masterName.toLowerCase().includes(val.toLowerCase());
      });
      this.arrowKeyLocation = -1;
    } else {
      this.arrowKeyLocation = -1;
      this.items = this.getAllStoneParam;
    }
  }

  keyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.items && this.items.length > 0) {
          if (this.arrowKeyLocation != 0) {
            this.arrowKeyLocation--;
            this.manageSelectedMaster = this.items[
              this.arrowKeyLocation
            ].masterName;
            break;
          } else {
            break;
          }
        }

      case 40: // this is the ascii of arrow down
        if (this.items && this.items.length > 0) {
          if (this.items.length != this.arrowKeyLocation + 1) {
            this.arrowKeyLocation++;
          }
          if (this.items.length > this.arrowKeyLocation) {
            this.manageSelectedMaster = this.items[
              this.arrowKeyLocation
            ].masterName;
            break;
          } else {
            break;
          }
        }
    }
  }

  //Start Inner Search

  setValues() {
    this.listOfValue = this.stoneData;
  }

  async filterValues(ev: any) {
    this.setValues();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      console.log(val, this.listOfValue);
      this.listOfValue = await this.listOfValue.filter(function (item) {
        if (item.code && item.code.toLowerCase().includes(val.toLowerCase())) {
          return true;
        } else if (
          item.name &&
          item.name.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.transTypeName &&
          item.transTypeName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
      this.arrowKeyLocationValue = -1;
    } else {
      this.listOfValue = this.listOfValue;
      this.arrowKeyLocationValue = -1;
    }
  }

  //ENd Inner Search

  //delete Value

  async confirmDeleteParameterValue(id) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this value ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteStoneParamValue(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteStoneParamValue(id) {
    let res: any;
    res = await this.httpApiService.deleteStoneParamValue(id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.ConfigService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.ConfigService.showToast("error", data.error);
    } else {
      this.valueSearch = "";
      this.searchStoneMaster(this.selectedMaster);
      await this.ConfigService.showToast(
        "success",
        "Stone Parameter value deleted successfully"
      );
    }
  }

  async openModal(item, flagModal) {
    if (flagModal == 'updateValue' && this.updatePermissionFlag == true) {
      let modalPage = await this.modalCtrl.create({
        component: ManageMasterPage,
        backdropDismiss: false,
        cssClass: 'largePage',
        componentProps: {
          id: this.id,
          item: item,
          StoneParamName: this.selectedMaster,
          flagModal: flagModal
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
    if ((flagModal == 'addValue' || flagModal == 'addNewStoneParam') && this.addPermissionFlag == true) {
      let modalPage = await this.modalCtrl.create({
        component: ManageMasterPage,
        backdropDismiss: false,
        cssClass: 'largePage',
        componentProps: {
          id: this.id,
          item: item,
          StoneParamName: this.selectedMaster,
          flagModal: flagModal
        }
      });
      modalPage.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      await modalPage.present();
    }
  }

  async handleModalDismiss(d) {
    await this.getAllStoneMaster();
    await this.searchStoneMaster(this.selectedMaster);
  }
}
