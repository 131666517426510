import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ToastController, LoadingController } from "@ionic/angular";
const TOKEN_KEY = "auth-token";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  public mode = "Dev";
  public appName = "lattice";
  // public fromLocation = "Surat";
  public fromLocation = "Mumbai";

  constructor(
    private storage: Storage,
    public toastCtrl: ToastController,
    public loadingController: LoadingController
  ) { }
  async getBaseUrl() {
    if (this.mode == "Production") {
      return "https://lattice-app.com/";
    } else if (this.mode == "Dev") {
      // return "https://dev.lattice-app.com/"
      return "http://ec2-13-232-62-167.ap-south-1.compute.amazonaws.com:9998/"
      // return "https://lattice-dev.atishae.com/"
    } else {
      return "http://192.168.7.119:9998/";
    }
  }

  async getAPIHeader() {
    var obj = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: await this.storage.get(TOKEN_KEY)
      //"Access-Control-Allow-Origin": "*"
    };

    return await obj;
  }

  async fileAPIHeader() {
    var obj = {
      Authorization: await this.storage.get(TOKEN_KEY)
    };
    return await obj;
  }
  async isValidEmail(email) {
    const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      email
    );

    if (re) {
      return true;
    } else {
      return false;
    }
  }

  async getAppName() {
    return await this.appName;
  }

  async getFromLocation() {
    if (
      window &&
      "location" in window &&
      "protocol" in window.location &&
      "pathname" in window.location &&
      "host" in window.location
    ) {
      this.fromLocation = window.location.hostname;
      console.log("host name", this.fromLocation);
      this.fromLocation = window.location.hostname.split(".")[0];
      return await this.fromLocation;
    }
    // return await this.fromLocation;
  }

  async showToast(status, message) {
    if (status == "success") {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        color: "success"
      });
      await toast.present();
    } else {
      let toast = await this.toastCtrl.create({
        message: message,
        duration: 3000,
        position: "top",
        color: "danger"
      });
      await toast.present();
    }
  }

  async showLoader() {
    const loading = await this.loadingController.create({
      message: "Please Wait.."
    });
    await loading.present();
  }


  async print() {
    let css = '@media print { @page { size: A4; } }';

    if (document.getElementsByTagName("app-delivery-challan").length != 0) {
      css = '@media print { @page { size: A5; } }';
    }
    if (!!document.getElementById("stylePrint")) {
      document.getElementById("stylePrint").innerHTML = css;
    } else {
      let head = document.head || document.getElementsByTagName('head')[0],
        style: any = document.createElement('style');

      head.appendChild(style);

      style.type = 'text/css';
      style.id = 'stylePrint';
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
    console.log("============>", css)
    window.print();
  }
}
