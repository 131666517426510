import { Component, Input, ViewChild, HostListener } from "@angular/core";
import { ProcessActionComponent } from "../../processAction";
import * as moment from "moment";
import { LoadingController, ModalController, Events } from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: 'app-single-to-mix',
  templateUrl: './single-to-mix.component.html',
  styleUrls: ['./single-to-mix.component.scss']
})
export class SingleToMixComponent implements ProcessActionComponent {
  @Input() data: any;
  @ViewChild("dataTable") public dataTable: any;

  public page = "singletoMix";
  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public csspList = [];
  public stoneList = [];
  public columns = [];
  public csspColumns = [];
  public count: Number = 0;
  public openSelectedcsspListFlag = true;
  public selectedCsspList = [];
  public showSelected = true;
  public selected = [];
  public selectedStoneList = []
  public isSelect: boolean = false;
  public sizeList = [];
  public sizeIdList = []
  public items = []
  public countCssp = 0

  constructor(
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public modalCtrl: ModalController,
    public configService: ConfigService,
    public event: Events
  ) { }

  ngOnInit() {
    this.columns = [
      { name: "StoneId", prop: "stoneId" },
      { name: "Color", prop: "color" },
      { name: "Shape", prop: "shape" },
      { name: "Ct.", prop: "cts" },
      { name: "Clarity", prop: "clarity" },
    ];
    this.csspColumns = [
      { name: "CSSP", prop: "stoneId" },
      { name: "Ct.", prop: "size" },
    ];
    this.getAllSingleStoneIssuedForMixing()
    this.findParametersForMixSizeMaster();
    this.findShapeByType()
    this.event.subscribe('Single Mixing', (item) => {
      this.columns = [
        { name: "StoneId", prop: "stoneId" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Ct.", prop: "cts" },
        { name: "Clarity", prop: "clarity" },
        { name: "Date & Time", prop: "createdDate" },
      ];
      this.csspColumns = [
        { name: "CSSP", prop: "stoneId" },
        { name: "Ct.", prop: "size" },
      ];
      this.resetData()
      this.getAllSingleStoneIssuedForMixing()
      this.findParametersForMixSizeMaster();
      this.findShapeByType()

    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Single Mixing');
  }


  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'colorIdList') {
      if (idList.length > 0) {
        this.colorId = idList;
      } else {
        this.colorId = [];
      }
    } else if (property == 'shapeIdList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.sizeIdList = []
        this.findSizeByShape(this.shapeId)
      } else {
        this.shapeId = [];
      }
    } else if (property == 'clarityIdList') {
      if (idList.length > 0) {
        this.clarityId = idList;
      } else {
        this.clarityId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  setItems() {
    this.items = this.stoneList;
    this.count = this.items.length;
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType('Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, 'Sale Bulk');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async getAllSingleStoneIssuedForMixing() {
    let res: any;
    res = await this.httpApiService.getAllSingleStoneIssuedForMixing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.stoneList = data.data;
      this.count = data.data.length;
      this.setItems()
      console.log(this.stoneList)
    }
  }

  async findParametersForMixSizeMaster() {
    let res: any;
    res = await this.httpApiService.findParametersForMixSizeMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.colorList) {
        let data1 = []
        dataValue.colorList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.colorList = data1;
      }
      if (dataValue.clarityList) {
        let data1 = []
        dataValue.clarityList.filter(el => {
          if (!el.transTypeName || el.transTypeName == 'MIX') {
            data1.push(el);
          }
        })
        this.clarityList = data1;
      }
    }
  }

  async searchCSSP() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      colorId: this.colorId,
      shapeId: this.shapeId,
      clarityId: this.clarityId,
      fromCts: this.fromCts,
      toCts: this.toCts
    }
    res = await this.httpApiService.findCsspOrCreateIfNotExist(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        this.countCssp = this.csspList.length
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }

    }
  }

  async convertSingleToMix() {
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      listOfStoneIds: this.selectedStoneList,
      stoneId: this.csspList[0].id
    }
    console.log(jsonObj)
    res = await this.httpApiService.convertSingleToMix(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      this.resetData();
      this.getAllSingleStoneIssuedForMixing()
    }
  }

  resetData() {
    this.csspList = []
    this.selected = []
    this.clarityId = []
    this.colorId = []
    this.sizeList = []
    this.shapeId = [];
    this.shapeIdList = []
    this.clarityIdList = []
    this.colorIdList = []
    this.sizeIdList = []
    this.openSelectedcsspListFlag = false;
  };

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.toString().indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  onSelect(ev) {
    this.selected = ev.selected;
    this.selectedStoneList = [];
    this.selected.forEach(el => {
      this.selectedStoneList.push(el.id);
    })
  }

  async filterItems(ev: any) {
    if (this.stoneList.length > 0) {
      let val = ev.target.value;
      console.log(val)
      await this.setItems();
      if (val && val.trim() !== "") {
        this.items = await this.items.filter(function (item) {
          if (item.stoneId && item.stoneId.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.color && item.color.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.clarity && item.clarity.toLowerCase().includes(val.toLowerCase())) {
            return true;
          } else if (item.shape && item.shape.toLowerCase().includes(val.toLowerCase())) {
            return true;
          }
        });
        this.count = this.items.length;
      } else {
        await this.setItems();
      }
    }

  }

}
