import { Component, Input } from '@angular/core';
import { LoadingController, AlertController, ModalController, Events } from '@ionic/angular';
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ManageTemplatesPage } from "../../../components/actions/manage-templates/manage-templates.page";

@Component({
  selector: 'app-export-templates',
  templateUrl: './export-templates.component.html',
  styleUrls: ['./export-templates.component.scss']
})
export class ExportTemplatesComponent implements ProcessActionComponent {
  @Input() data: any;
  @Input() template: any;
  public columns = []
  public page = 'Templates';
  public pageNumber = 0;
  public noOfRecords = 2000;
  public count: Number;
  public userId: any;
  public listOfTemplates = [];
  public items = [];
  public isDefault: any;
  constructor(public configService: ConfigService,
    public httpApiService: HttpApiService,
    public alertController: AlertController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private authService: AuthenticationService,
    public event: Events, ) { }

  async ngOnInit() {
    console.log(this.template);
    if (!!this.template) {
      this.isDefault = true
      this.event.subscribe('Default Export Template', (item) => {
        this.getAllTemplates();
      })
    } else {
      this.isDefault = false;
      this.event.subscribe('Export Templates', (item) => {
        this.getAllTemplates();
      })
    }
    console.log(this.template);
    this.columns = [
      { name: "Sr No", prop: "" },
      { name: "Name", prop: "templateName" },
      { name: "Format", prop: "fileType" },
      { name: "Delete", prop: "Delete" }
    ];
    await this.getAllTemplates();
  };

  ngOnDestroy() {
    if (!!this.template) {
      this.event.unsubscribe('Default Export Template')
    } else {
      this.event.unsubscribe('Export Template')
    }
  }

  async getAllTemplates() {
    await this.getUserData();
    let res: any;
    res = await this.httpApiService.findAllTemplate(this.isDefault)
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfTemplates = data.data;
      this.setItems();
      if (this.listOfTemplates.length > 0) {
        this.count = this.listOfTemplates.length;
      } else {
        this.count = 0;
      }

    }
  }

  async setItems() {
    this.items = this.listOfTemplates;
    this.count = this.listOfTemplates.length;
  }

  async getUserData() {
    let userData = await this.authService.userData();
    this.userId = userData.userId;
  }

  async manageTemplates(item, flag) {
    if ((item.type == "mouseenter") || (item.column && item.column.name == 'Delete')) {
      //Stop event propagation and let onSelect() work
      item.event.stopPropagation();
    } else {
      let modal = await this.modalCtrl.create({
        component: ManageTemplatesPage,
        backdropDismiss: false,
        cssClass: "largePage",
        componentProps: { item: item, flag: flag, isDefault: this.isDefault }
      })
      modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
      return await modal.present();
    }
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      await this.getAllTemplates();
    }
  }

  async filterItems(ev: any) {
    this.setItems();
    let val = ev.target.value;
    if (val && val.trim() !== "") {
      this.items = await this.items.filter(function (item) {
        if (
          item.templateName &&
          item.templateName.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        } else if (
          item.fileType &&
          item.fileType.toLowerCase().includes(val.toLowerCase())
        ) {
          return true;
        }
      });
    } else {
      await this.setItems();
    }
  }

  async deleteConfirm(item) {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete this Template ?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.deleteTemplate(item);
          }
        }
      ]
    });

    await alert.present();
  }

  async deleteTemplate(item) {
    let res: any;
    res = await this.httpApiService.deleteTemplate(item.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.configService.showToast("success", data.message);
      await this.getAllTemplates();
    }
  }
}


