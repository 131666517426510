import { Component, OnInit } from "@angular/core";
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";

@Component({
  selector: "app-manage-user",
  templateUrl: "./manage-user.page.html",
  styleUrls: ["./manage-user.page.scss"]
})
export class ManageUserPage implements OnInit {
  public allRoles: any = [];
  public roleId: any;
  public listOfActions = [];
  public id: any;
  public isModal: any;
  public name: any;
  public userData: any;
  public voucherType: any;
  public voucherId: any;
  public dropdownList: any;
  public dropdownSettings: any;
  public actionName: any;
  public listOfRoles = []

  //Add new User
  public username = "";
  public password = "";
  public companyName = "";
  public appName = "";
  public fullName = "";
  public mobileNumber = "";
  public email = "";
  public deptList = [];
  public listOfLocations: any;
  public usersList = [];
  public listOfVoucherBook: any;
  public voucherBook: any;

  public toUser: number;
  public toLocation: number;
  public toDept: number;
  public fromDept: number;
  public toActionId: number;
  public items: any;
  public flag: any = 2;
  public picked: any;
  public pickedFlag = false;
  public boxed: any;
  public boxedFlag = false;
  public defaultData: any;
  public fromUser: any;
  public dept: any;
  public recutEnable: Boolean = false;
  public observationEnable: Boolean = false;
  public certification: Boolean = false;
  public mixing: Boolean = false;
  public requisition: Boolean = false;
  public consignment: Boolean = false;
  public stoneReturn: Boolean = false;
  public consignmentConfirm: Boolean = false;
  public memoClient: Boolean = false;
  public customerConfirm: Boolean = false;
  public memoService: Boolean = false;
  public consignmentInStock: Boolean = false;
  public selfObservationEnable: Boolean = false;
  public memoClientEnable: Boolean = false;
  public customerHoldEnable: Boolean = false;
  public customerConfirmEnable: Boolean = false;
  public memoServiceEnable: Boolean = false;
  public consignmentConfirmEnable: Boolean = false;
  public consignmentInStockEnable: Boolean = false;
  public certificationEnable: Boolean = false;
  public requisitionEnable: Boolean = false;
  public toRole: any;
  public fromRole: any;
  public sdix: Boolean = false;
  public priceListName: any;
  public scanBoardUrl: any;

  public fromDeptidList = [];
  public toDeptidList = [];
  public toLocationidList = [];
  public voucheridList = [];
  public toUseridList = [];
  public fromUseridList = [];
  public priceidList = []
  public toRoleIdList = []
  public fromRoleIdList = []
  public requestVoucherBook: any;
  public requestVoucherIdList = []

  public allPriceNameList = [];
  public getAllCustomers = []
  public toLocationObvidList = []
  public partyMasterIdList = []

  public defaultObjRecut = {};
  public defaultObjObservation = {};
  public defaultObjSelfObservation = {}
  public defaultObjMemoClient = {};
  public defaultObjCustomerConfirm = {};
  public defaultObjMemoService = {};
  public defaultObjConsignmentConfirm = {};
  public defaultObjConsignmentInStock = {};
  public defaultObjCertification = {}
  public defaultObjRequisition = {}
  public defaultObjCustomerHold = {};

  public typeList = [];
  public typeIdList = [];
  public type: any;

  public boxTypeList = [];
  public boxType: any;
  public boxTypeIdList = [];
  public partyTypeList = [];
  public partyTypeMemoClientIdList = []
  public partyTypeCConfirmIdList = []
  public partyTypeForMemoServiceIdList = []
  public partyTypeForConsignmentConfirmIdList = []
  public partyTypeForConsignmentInStockIdList = []
  public partyTypeSelfObservationIdList = []
  public partyTypeForCertificationIdList = []
  public partyTypeForRequisitionIdList = []
  public partyTypeIdList = []
  public partyType = [];
  public transType: any;
  public transTypeList = [];
  public transTypeIdList = []
  public partyTypeCustomerHoldIdList = []

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public configService: ConfigService,
    public authService: AuthenticationService,
    public loadingController: LoadingController,
    private nav: NavController,
    public httpApiService: HttpApiService,
    public event: Events
  ) { }

  async ngOnInit() {
    this.getAllDepartment();
    this.getBoxType();
    this.getAllPartyMasterType()
    this.getAllRoles();
    this.getAllTransType();
    await this.getAppName();
    await this.getFromLocation();
    // await this.getAllCompany();

    // this.listOfLocations.forEach(item=>{
    //   if(item.companyName ==  this.companyName.toLowerCase()){
    //     this.toLocation = item.id
    //   }
    // });
    // await this.getAllToUser(this.toLocation);

    this.username = "";
    this.isModal = this.navParams.get("flgModal");
    this.id = this.navParams.get("id");
    if (this.isModal == "addUserRole") {
      await this.getAllRole();
      this.name = this.navParams.get("username");
      this.id = this.navParams.get("id");
    } else if (this.isModal == "updateUserDefault") {
      this.actionName = this.id.actionName;
      this.toActionId = this.id.actionId;
      if (this.id.voucherType) {
        this.voucherType = this.id.voucherType;
        let res: any;
        res = await this.httpApiService.getAllVouchersForSelectedType(
          this.voucherType
        );
        let data = res.json();
        console.log(data)
        if (res.status == 401) {
          this.authService.logout();
        } else if (res.status == 500) {
          await this.configService.showToast("error", data.error);
        } else if (res.status == 400) {
          await this.configService.showToast("error", data.error);
        } else {
          this.listOfVoucherBook = data.data;
          console.log(this.listOfVoucherBook);
        }
      }
      this.id = this.id.id;
      this.getDefaultSetting(this.toActionId, this.id);
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "roles") {
      this.roleId = idList[0];
    } else if (property == "fromDepartment") {
      this.fromDept = idList[0];
    } else if (property == "toDepartment") {
      this.toDept = idList[0];
    } else if (property == "toLocation") {
      if (idList.length > 0) {
        this.toUseridList = [];
        this.usersList = [];
        this.toLocation = idList[0];
        this.getAllToUser(this.toLocation);
      } else {
        this.toLocation = null;
        this.toUseridList = [];
        this.usersList = [];
      }
    } else if (property == "voucherBook") {
      this.voucherBook = idList[0];
    } else if (property == "requestVoucherBook") {
      this.requestVoucherBook = idList[0];
    }
    else if (property == "toUsers") {
      this.toUser = idList[0];
      console.log("toUseridList", this.toUseridList);
    } else if (property == "fromUsers") {
      this.fromUser = idList[0];
    } else if (property == "allDepartment") {
      this.dept = idList[0];
    } else if (property == "price") {
      this.priceListName = idList[0];
    } else if (property == "toRoles") {
      this.toRole = idList[0];
    } else if (property == "fromRoles") {
      this.fromRole = idList[0];
    } else if (property == "toLocationForRecut") {
      if (idList.length > 0) {
        this.defaultObjRecut["toLocation"] = idList[0];
        this.defaultObjRecut["enable"] = true;
      } else {
        delete this.defaultObjRecut["toLocation"];
      }
      console.log(this.defaultObjRecut)
    } else if (property == "toLocationForObservation") {
      if (idList.length > 0) {
        this.defaultObjObservation["toLocation"] = idList[0];
        this.defaultObjObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
      console.log(this.defaultObjObservation)
    } else if (property == "partMaster") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyMaster"] = idList[0];
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjObservation["toLocation"];
      }
    } else if (property == "typeId") {
      if (idList.length > 0) {
        this.type = obj[0].name;
      } else {
        this.type = null;
      }
    } else if (property == "boxType") {
      if (idList.length > 0) {
        this.boxType = obj[0].type;
      } else {
        this.boxType = null;
      }
    } else if (property == "partyTypeForMemoClient") {
      if (idList.length > 0) {
        this.defaultObjMemoClient["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoClient["enable"] = true;
      } else {
        delete this.defaultObjMemoClient["partyType"];
      }
    } else if (property == "partyTypeForCustomerHold") {
      if (idList.length > 0) {
        this.defaultObjCustomerHold["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerHold["enable"] = true;
      } else {
        delete this.defaultObjCustomerHold["partyType"];
      }
    } else if (property == "partyTypeForCConfirm") {
      if (idList.length > 0) {
        this.defaultObjCustomerConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjCustomerConfirm["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForMemoService") {
      if (idList.length > 0) {
        this.defaultObjMemoService["partyType"] = obj.map(a => a.type);
        this.defaultObjMemoService["enable"] = true;
      } else {
        delete this.defaultObjCustomerConfirm["partyType"];
      }
    } else if (property == "partyTypeForConsignmentInStock") {
      if (idList.length > 0) {
        this.defaultObjConsignmentInStock["partyType"] = obj.map(a => a.type);;
        this.defaultObjConsignmentInStock["enable"] = true;
      } else {
        delete this.defaultObjConsignmentInStock["partyType"];
      }
    } else if (property == "partyTypeForConsignmentConfirm") {
      if (idList.length > 0) {
        this.defaultObjConsignmentConfirm["partyType"] = obj.map(a => a.type);
        this.defaultObjConsignmentConfirm["enable"] = true;
      } else {
        delete this.defaultObjConsignmentConfirm["partyType"];
      }
    } else if (property == "partyTypeForSelfObservation") {
      if (idList.length > 0) {
        this.defaultObjSelfObservation["partyType"] = obj.map(a => a.type);
        this.getAllCustomer(obj.map(a => a.type));
        this.defaultObjSelfObservation["enable"] = true;
      } else {
        delete this.defaultObjSelfObservation["partyType"];
      }
    } else if (property == "partyTypeForRequisition") {
      if (idList.length > 0) {
        this.defaultObjRequisition["partyType"] = obj.map(a => a.type);
        this.defaultObjRequisition["enable"] = true;
      } else {
        delete this.defaultObjRequisition["partyType"];
      }
    } else if (property == "partyTypeForCertification") {
      if (idList.length > 0) {
        this.defaultObjCertification["partyType"] = obj.map(a => a.type);
        this.defaultObjCertification["enable"] = true;
      } else {
        delete this.defaultObjCertification["partyType"];
      }
    } else if (property == "partyType") {
      if (idList.length > 0) {
        this.partyType = obj.map(a => a.type)
      } else {
        this.partyType = []
      }
    } else if (property == "transTypeList") {
      if (idList.length > 0) {
        this.transType = obj[0].name
      } else {
        this.transType = null
      }
    }
  }

  async getAllTransType() {
    let res: any;
    res = await this.httpApiService.findAllTransType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.transTypeList = data.data;
    }
  }

  async getAllPartyMasterType() {
    let res: any;
    res = await this.httpApiService.getAllPartyMasterType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataList = data.data;
      console.log(dataList)
      this.partyTypeList = []
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          this.partyTypeList.push({ id: i + 1, type: dataList[i] })
        }
      }
    }
    console.log("boxTypeList", this.boxTypeList);
  }

  async getAllRoles() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfRoles = data.data;
      console.log("getAllRoles res", res, this.listOfRoles);
    }
  }

  async getBoxType() {
    let res;
    res = await this.httpApiService.getAllBoxType();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.boxTypeList = data.data;
      console.log("boxTypeList", this.boxTypeList);
    }
  }

  async UpdateUserDefaultData() {
    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    console.log(mainObj)
    let jsonObj = {
      userId: this.id,
      actionId: this.toActionId,
      fromDepartment: this.fromDept,
      toDepartment: this.toDept,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      toRole: this.toRole,
      fromRole: this.fromRole,
      type: this.type,
      boxType: this.boxType,
      priceListName: this.priceListName,
      transType: this.transType,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Customer Hold': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType)
      // mixing: this.mixing,
    }

    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);
    filtered.actionId = this.toActionId;
    filtered.userId = this.id;

    if (this.defaultObjRecut["enable"] == false) {
      delete filtered['Recut'];
    } if (this.defaultObjCertification["enable"] == false) {
      delete filtered['Certification'];
    } if (this.defaultObjRequisition["enable"] == false) {
      delete filtered['Requisition'];
    } if (this.defaultObjObservation["enable"] == false) {
      delete filtered['Observation'];
    } if (this.defaultObjSelfObservation["enable"] == false) {
      delete filtered['Self Observation'];
    } if (this.defaultObjMemoClient["enable"] == false) {
      delete filtered['Memo Client'];
    } if (this.defaultObjConsignmentInStock["enable"] == false) {
      delete filtered['Consignment In Stock'];
    } if (this.defaultObjMemoService["enable"] == false) {
      delete filtered['Memo Service'];
    } if (this.defaultObjCustomerConfirm["enable"] == false) {
      delete filtered['Customer Confirm'];
    } if (this.defaultObjConsignmentConfirm["enable"] == false) {
      delete filtered['Consignment Confirm'];
    } if (this.partyType.length == 0) {
      delete filtered['partyType'];
    }
    console.log(filtered)
    let res;
    res = await this.httpApiService.updateUserDefault(filtered);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.roleId = "";
      await this.configService.showToast(
        "success",
        "User updated successfully"
      );
      await this.modalCtrl.dismiss();
    }
  }

  async getAllCustomer(type) {
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data;
      console.log("getAllCustomers", this.getAllCustomers);

    }
  }

  async getDefaultSetting(actionId, id) {
    console.log(actionId, id)
    this.defaultData = []
    let res: any;
    res = await this.httpApiService.getAllActionPermissionById(actionId);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      console.log(dataValue)
      this.flag = 1;
      if (dataValue && dataValue.listOfActionDefaults) {
        this.defaultData = dataValue.listOfActionDefaults;
        console.log("******", this.defaultData)
        await this.getDefaultValue(actionId, id);
        // this.defaultData.forEach(element => {

        // if(element.paramName == "Picked"){
        //   this.pickedFlag = true
        //   // console.log("element.picked",element.paramValue)
        // }
        // if (element.paramName == "Boxed") {
        //   this.boxedFlag = true;
        //   // console.log("element.boxed",element.paramValue)
        // }
        // });
      } else {
        this.flag = 2;
      }
    }
  }

  async getAllPriceListName() {
    let res: any;
    res = await this.httpApiService.getAllPriceName();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allPriceNameList = data.data;
    }
  }

  async findAllTypeForPricing() {
    let res: any;
    res = await this.httpApiService.findAllTypeForPricing();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let tempArray = []
      for (let i = 0; i < dataValue.length; i++) {
        tempArray.push({ id: i + 1, name: dataValue[i] })
      }
      this.typeList = tempArray;
      console.log(this.typeList)
    }
  }

  async getDefaultValue(actionId, id) {
    await this.configService.showLoader();
    await this.getAllRole()
    await this.getAllCompany();
    await this.getAllDepartment();
    await this.getAllPriceListName();
    await this.getAllTransType();
    await this.getBoxType();
    await this.findAllTypeForPricing()
    await this.getAllPartyMasterType()
    let res;
    res = await this.httpApiService.findUserActionDefaultsByUserIdAndActionIdUsingDTO(
      id,
      actionId
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.fromDeptidList = [];
      this.toDeptidList = [];
      this.toLocationidList = [];
      this.fromUseridList = [];
      this.voucheridList = [];
      this.fromDeptidList = [];
      this.boxTypeIdList = [];
      this.requestVoucherIdList = []
      this.priceidList = []
      this.toRoleIdList = []
      this.fromRoleIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeMemoClientIdList = []
      this.partyTypeCConfirmIdList = []
      this.transTypeIdList = []
      this.partyMasterIdList = []
      this.partyTypeForMemoServiceIdList = []
      this.partyTypeForConsignmentConfirmIdList = []
      this.partyTypeForConsignmentInStockIdList = []
      this.partyTypeForCertificationIdList = []
      this.partyTypeIdList = []
      this.partyTypeForRequisitionIdList = []
      console.log("QWQ", data.data);
      // let dataValue = data.data;
      let dataValueList = data.data;
      if (dataValueList.length > 0) {
        dataValueList.filter(el => {
          if (el.paramName == 'voucherBook') {
            this.listOfVoucherBook.forEach(element => {
              if (element.id == el.paramValue) {
                let data = { id: element.id, bookName: element.bookName };
                this.voucheridList.push(data);
              }
            });
            this.voucherBook = parseInt(el.paramValue);
          }
          console.log(el.paramName)
          if (el.paramName == 'requestVoucherBook') {
            this.listOfVoucherBook.forEach(element => {
              if (element.id == el.paramValue) {
                let data = { id: element.id, bookName: element.bookName };
                this.requestVoucherIdList.push(data);
              }
            });
            this.requestVoucherBook = parseInt(el.paramValue);
          }
          if (el.paramName == 'fromDepartment') {
            this.deptList.forEach(element => {
              if (element.id == el.paramValue) {
                let data = { id: element.id, name: element.name };
                this.fromDeptidList.push(data);
              }
            });
            this.fromDept = parseInt(el.paramValue);
          }
          if (el.paramName == 'toDepartment') {
            this.deptList.forEach(element => {
              if (element.id == el.paramValue) {
                let data = { id: element.id, name: element.name };
                this.toDeptidList.push(data);
              }
            });
            this.toDept = parseInt(el.paramValue);
          }
          if (el.paramName == 'toLocation') {
            this.listOfLocations.forEach(element => {
              if (element.id == el.paramValue) {
                let data = { id: element.id, companyName: element.companyName };
                this.toLocationidList.push(data);
              }
            });
            this.toLocation = parseInt(el.paramValue);
            this.getAllToUser(this.toLocation);
          }
          if (el.paramName == 'toUser') {
            this.toUseridList = [];
            this.usersList.forEach(element => {
              if (element.id == parseInt(el.paramValue)) {
                let data = { id: element.id, username: element.username };
                this.toUseridList.push(data);
              }
            });
            this.toUser = parseInt(el.paramValue);
          }
          if (el.paramName == 'boxType') {
            this.boxTypeList.forEach(element => {
              if (element.type == el.paramValue) {
                let data = { id: element.id, type: element.type };
                this.boxTypeIdList.push(data);
              }
            });
            this.boxType = el.paramValue;
          }
          if (el.paramName == 'transType') {
            this.transType = el.paramValue;
            this.transTypeIdList = []
            this.transTypeList.forEach(item => {
              if (item.name == el.paramValue) {
                this.transTypeIdList.push({ id: item.id, name: item.name })
              }
            })
            console.log(this.transTypeIdList)
          }
          if (el.paramName == 'fromUser') {
            this.usersList.forEach(element => {
              if (element.id == parseInt(el.paramValue)) {
                let data = { id: element.id, username: element.username };
                this.fromUseridList.push(data);
              }
            });
            this.fromUser = parseInt(el.paramValue);
          }
          if (el.paramName == 'priceListName') {
            this.priceidList = []
            this.allPriceNameList.forEach(element => {
              if (element.id == parseInt(el.paramValue)) {
                let data = { id: element.id, name: element.name };
                this.priceidList.push(data);
              }
            });
            this.priceListName = parseInt(el.paramValue);
          }
          if (el.paramName == 'type') {
            this.typeIdList = []
            this.typeList.forEach(element => {
              if (element.name == el.paramValue) {
                let data = { id: element.id, name: element.name };
                this.typeIdList.push(data);
              }
            });
            this.type = el.paramValue;
          }
          if (el.paramName == 'toRole') {
            this.toRoleIdList = []
            this.allRoles.forEach(element => {
              if (element.id == parseInt(el.paramValue)) {
                let data = { id: element.id, roleName: element.roleName };
                this.toRoleIdList.push(data);
              }
            });
            this.toRole = parseInt(el.paramValue);
          }
          if (el.paramName == 'fromRole') {
            this.fromRoleIdList = []
            this.allRoles.forEach(element => {
              if (element.id == parseInt(el.paramValue)) {
                let data = { id: element.id, roleName: element.roleName };
                this.fromRoleIdList.push(data);
              }
            });
            this.fromRole = parseInt(el.paramValue);
          }
          if (el.paramName == 'Return') {
            if (el.paramValue == 'true') {
              this.stoneReturn = true
            } else {
              this.stoneReturn = false
            }
          }
          if (el.paramName == 'Certification') {
            this.defaultObjCertification["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.certificationEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjCertification["partyType"] = dataJson.partyType;
                this.partyTypeForMemoServiceIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeForCertificationIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.certificationEnable) {
                this.defaultObjCertification["enable"] = true;
              } else {
                this.defaultObjCertification["enable"] = false;
              }
            } else {
              this.defaultObjCertification["enable"] = false;
            }
          }
          if (el.paramName == 'Requisition') {
            this.defaultObjRequisition["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.requisitionEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjRequisition["partyType"] = dataJson.partyType;
                this.partyTypeForRequisitionIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeForRequisitionIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.requisitionEnable) {
                this.defaultObjRequisition["enable"] = true;
              } else {
                this.defaultObjRequisition["enable"] = false;
              }
            } else {
              this.defaultObjRequisition["enable"] = false;
            }
          }
          if (el.paramName == 'Recut') {
            this.defaultObjRecut["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            console.log(dataJson)
            if (!!dataJson) {
              this.recutEnable = dataJson.enable;
              if (!!dataJson.toLocation) {
                this.toLocation = dataJson.toLocation;
                this.defaultObjRecut["toLocation"] = this.toLocation;
                this.toLocationidList = []
                this.listOfLocations.forEach(item => {
                  if (item.id == this.toLocation) {
                    this.toLocationidList.push({ id: item.id, companyName: item.companyName })
                  }
                })
              }
              if (this.recutEnable) {
                this.defaultObjRecut["enable"] = true;
              } else {
                this.defaultObjRecut["enable"] = false;
              }
            } else {
              this.recutEnable = dataJson.enable;
              this.observationEnable = false;
            }
            console.log(this.recutEnable)

          }
          if (el.paramName == 'Observation') {
            this.defaultObjObservation["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.observationEnable = dataJson.enable;
              if (!!dataJson.toLocation) {
                this.toLocation = dataJson.toLocation;
                this.toLocationObvidList = []
                this.listOfLocations.forEach(item => {
                  if (item.id == this.toLocation) {
                    this.toLocationObvidList.push({ id: item.id, companyName: item.companyName })
                  }
                })
                this.defaultObjObservation["toLocation"] = this.toLocation;
              }
              if (this.observationEnable) {
                this.defaultObjObservation["enable"] = true;
              } else {
                this.defaultObjObservation["enable"] = false;
              }
            } else {
              this.defaultObjObservation["enable"] = false;
              this.observationEnable = false;
            }
          }
          if (el.paramName == 'Self Observation') {
            this.defaultObjSelfObservation["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.selfObservationEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjMemoService["partyType"] = dataJson.partyType;
                this.partyTypeSelfObservationIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeSelfObservationIdList.push({ id: item.id, type: item.type })
                  }
                })
                var _this = this
                console.log(dataJson.partyType)
                this.getAllCustomerDetail(dataJson.partyType, function (data, error) {
                  if (!!dataJson.partyMaster) {
                    _this.partyMasterIdList = []
                    if (data.length > 0) {
                      data.forEach(item => {
                        if (item.id == dataJson.partyMaster) {
                          _this.partyMasterIdList.push({ id: item.id, partyName: item.partyName })
                        }
                      })
                    }
                    _this.defaultObjSelfObservation["partyMaster"] = dataJson.partyMaster;
                  }
                })
              }
              if (this.selfObservationEnable) {
                this.defaultObjSelfObservation["enable"] = true;
              } else {
                this.defaultObjSelfObservation["enable"] = false;
              }
            } else {
              this.defaultObjSelfObservation["enable"] = false;
              this.selfObservationEnable = false;
            }

          }
          if (el.paramName == 'Memo Service') {
            this.defaultObjMemoService["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.memoServiceEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjMemoService["partyType"] = dataJson.partyType;
                this.partyTypeForMemoServiceIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeForMemoServiceIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.memoServiceEnable) {
                this.defaultObjMemoService["enable"] = true;
              } else {
                this.defaultObjMemoService["enable"] = false;
              }
            } else {
              this.defaultObjMemoService["enable"] = false;
            }
          }
          if (el.paramName == 'Memo Client') {
            this.defaultObjMemoClient["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.memoClientEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjMemoClient["partyType"] = dataJson.partyType;
                this.partyTypeMemoClientIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeMemoClientIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.memoClientEnable) {
                this.defaultObjMemoClient["enable"] = true;
              } else {
                this.defaultObjMemoClient["enable"] = false;
              }
            } else {
              this.defaultObjMemoClient["enable"] = false;
            }
          }
          if (el.paramName == 'Customer Hold') {
            this.defaultObjCustomerHold["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.customerHoldEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjCustomerHold["partyType"] = dataJson.partyType;
                this.partyTypeCustomerHoldIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeCustomerHoldIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.customerHoldEnable) {
                this.defaultObjCustomerHold["enable"] = true;
              } else {
                this.defaultObjCustomerHold["enable"] = false;
              }
            } else {
              this.defaultObjCustomerHold["enable"] = false;
            }
          }
          if (el.paramName == 'partyType') {
            if (!!el.paramValue) {
              this.partyTypeIdList = []
              this.partyType = JSON.parse(el.paramValue)
              for (let i = 0; i < this.partyTypeList.length; i++) {
                let flag = this.partyType.some(element => element == this.partyTypeList[i].type)
                if (flag) {
                  this.partyTypeIdList.push({ id: this.partyTypeList[i].id, type: this.partyTypeList[i].type })
                }
              }
            } else {
              this.partyType = []
            }
          }
          if (el.paramName == 'SDIX') {
            if (el.paramValue == 'true') {
              this.sdix = true
            } else {
              this.sdix = false
            }
          }
          if (el.paramName == 'Consignment Confirm') {
            this.defaultObjConsignmentConfirm["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.consignmentConfirmEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjConsignmentConfirm["partyType"] = dataJson.partyType;
                this.partyTypeForConsignmentConfirmIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeForConsignmentConfirmIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.consignmentConfirmEnable) {
                this.defaultObjConsignmentConfirm["enable"] = true;
              } else {
                this.defaultObjConsignmentConfirm["enable"] = false;
              }
            } else {
              this.defaultObjConsignmentConfirm["enable"] = false;
            }
          }
          if (el.paramName == 'Consignment In Stock') {
            this.defaultObjConsignmentInStock["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.consignmentInStockEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjConsignmentInStock["partyType"] = dataJson.partyType;
                this.partyTypeForConsignmentInStockIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeForConsignmentInStockIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.consignmentInStockEnable) {
                this.defaultObjConsignmentInStock["enable"] = true;
              } else {
                this.defaultObjConsignmentInStock["enable"] = false;
              }
            } else {
              this.defaultObjConsignmentInStock["enable"] = false;
            }
          }
          if (el.paramName == 'Customer Confirm') {
            this.defaultObjCustomerConfirm["enable"] = false;
            let dataJson = null
            if (!!el.paramValue) {
              dataJson = JSON.parse(el.paramValue)
            }
            if (!!dataJson) {
              this.customerConfirmEnable = dataJson.enable;
              if (!!dataJson.partyType) {
                this.defaultObjCustomerConfirm["partyType"] = dataJson.partyType;
                this.partyTypeCConfirmIdList = []
                this.partyTypeList.forEach(item => {
                  let flag = dataJson.partyType.some(element => element == item.type)
                  if (flag) {
                    this.partyTypeCConfirmIdList.push({ id: item.id, type: item.type })
                  }
                })
              }
              if (this.customerConfirmEnable) {
                this.defaultObjCustomerConfirm["enable"] = true;
              } else {
                this.defaultObjCustomerConfirm["enable"] = false;
              }
            } else {
              this.defaultObjCustomerConfirm["enable"] = false;
            }
          }
        })
      } else {
        this.flag = 0;
      }
    }
  }

  async getAllCompany() {
    let res: any;
    // let fromLocation = await this.configService.getFromLocation();
    res = await this.httpApiService.getAllCompany();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.listOfLocations = dataValue;
    }
  }
  async getAllToUser(toLocation) {
    this.usersList = [];
    this.toUser = null;
    let res: any;
    let appName = await this.configService.appName;
    res = await this.httpApiService.getAllUsersByCompany(appName, toLocation);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (dataValue.length > 0) {
        this.usersList = dataValue;
      } else {
        this.usersList = [];
      }
    }
  }

  async getAllActions() {
    let res: any;
    res = await this.httpApiService.getAllActionsByUserID(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.listOfActions = data.data;
      this.items = this.listOfActions;
    }
  }
  async getAllDepartment() {
    let res: any;
    res = await this.httpApiService.getAllDepartment(
      this.configService.appName
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.deptList = data.data;
    }
  }

  async getAppName() {
    this.appName = await this.configService.getAppName();
  }

  async getFromLocation() {
    this.companyName = await this.configService.getFromLocation();
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  async getAllRole() {
    let res: any;
    res = await this.httpApiService.getAllRoles();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.allRoles = data.data;
    }
  }

  //Create New User

  async createUser() {
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let jsonObj = {
        username: this.username,
        password: this.password,
        companyName: this.companyName,
        appName: this.appName,
        fullName: this.fullName,
        mobileNumber: this.mobileNumber,
        email: this.email,
        departmentId: this.dept,
        scanBoardUrl: this.scanBoardUrl
      };
      let res: any;
      res = await this.httpApiService.createUser(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.allRoles = data;
        await this.configService.showToast(
          "success",
          "User created successfully"
        );
        await this.modalCtrl.dismiss();
        this.resetForm();
      }
    }
  }

  // Save Role for User

  async saveRoleForUser() {
    let validate = await this.validateControls();
    if (!validate) {
      let jsonObj = {
        roleId: this.roleId,
        userId: this.id
      };
      let res: any;
      res = await this.httpApiService.saveRoleForUser(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.roleId = "";
        await this.configService.showToast(
          "success",
          "Role assigned successfully"
        );
        await this.modalCtrl.dismiss()
      }
    }
  }

  async getAllCustomerDetail(type, callback) {
    console.log(type)
    let res: any;
    let json = {
      listOfType: type
    }
    res = await this.httpApiService.findByListOfPartyType(json);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllCustomers = data.data;
      callback(this.getAllCustomers)
    }
  }

  async validateControls() {
    if (this.isModal == "addUserRole") {
      if (
        this.roleId != "" &&
        this.roleId != null &&
        this.roleId != undefined
      ) {
        return false;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return true;
      }
    } else {
      if (
        this.username != "" &&
        this.password != "" &&
        this.appName != "" &&
        this.fullName != "" &&
        this.companyName != ""
      ) {
        return false;
      } else {
        await this.configService.showToast(
          "error",
          "Please select/insert all the information"
        );
        return true;
      }
    }
  }

  filter(obj, predicate) {
    return Object.keys(obj)
      .filter(key => predicate(key, obj[key]))
      .reduce((res, key) => (res[key] = obj[key], res), {});
  }

  async saveUserDefault() {
    let jsonObj: any;
    let mainObj = this.defaultData.map(function (key, value) {
      return key.paramName;
    })
    jsonObj = {
      userId: this.id,
      actionId: this.toActionId,
      fromDepartment: this.fromDept,
      toDepartment: this.toDept,
      toUser: this.toUser,
      toLocation: this.toLocation,
      fromUser: this.fromUser,
      voucherBook: this.voucherBook,
      requestVoucherBook: this.requestVoucherBook,
      toRole: this.toRole,
      fromRole: this.fromRole,
      type: this.type,
      priceListName: this.priceListName,
      boxType: this.boxType,
      transType: this.transType,
      'Return': this.stoneReturn,
      'Recut': Object.keys(this.defaultObjRecut).length > 0 ? JSON.stringify(this.defaultObjRecut) : this.defaultObjRecut["enable"] = false,
      'Observation': Object.keys(this.defaultObjObservation).length > 0 ? JSON.stringify(this.defaultObjObservation) : this.defaultObjObservation["enable"] = false,
      'Self Observation': Object.keys(this.defaultObjSelfObservation).length > 0 ? JSON.stringify(this.defaultObjSelfObservation) : this.defaultObjSelfObservation["enable"] = false,
      'Requisition': Object.keys(this.defaultObjRequisition).length > 0 ? JSON.stringify(this.defaultObjRequisition) : this.defaultObjRequisition["enable"] = false,
      'Certification': Object.keys(this.defaultObjCertification).length > 0 ? JSON.stringify(this.defaultObjCertification) : this.defaultObjCertification["enable"] = false,
      'Consignment Confirm': Object.keys(this.defaultObjConsignmentConfirm).length > 0 ? JSON.stringify(this.defaultObjConsignmentConfirm) : this.defaultObjConsignmentConfirm["enable"] = false,
      'Memo Client': Object.keys(this.defaultObjMemoClient).length > 0 ? JSON.stringify(this.defaultObjMemoClient) : this.defaultObjMemoClient["enable"] = false,
      'Customer Hold': Object.keys(this.defaultObjCustomerHold).length > 0 ? JSON.stringify(this.defaultObjCustomerHold) : this.defaultObjCustomerHold["enable"] = false,
      'Customer Confirm': Object.keys(this.defaultObjCustomerConfirm).length > 0 ? JSON.stringify(this.defaultObjCustomerConfirm) : this.defaultObjCustomerConfirm["enable"] = false,
      'Memo Service': Object.keys(this.defaultObjMemoService).length > 0 ? JSON.stringify(this.defaultObjMemoService) : this.defaultObjMemoService["enable"] = false,
      'Consignment In Stock': Object.keys(this.defaultObjConsignmentInStock).length > 0 ? JSON.stringify(this.defaultObjConsignmentInStock) : this.defaultObjConsignmentInStock["enable"] = false,
      'partyType': JSON.stringify(this.partyType)
      // mixing: this.mixing,
    }
    let filtered: any = this.filter(jsonObj, score => mainObj.indexOf(score) != -1);
    filtered.actionId = this.toActionId;
    filtered.userId = this.id;
    console.log(filtered)
    if (this.defaultObjRecut["enable"] == false) {
      delete filtered['Recut'];
    } if (this.defaultObjObservation["enable"] == false) {
      delete filtered['Observation'];
    } if (this.defaultObjCertification["enable"] == false) {
      delete filtered['Certification'];
    } if (this.defaultObjRequisition["enable"] == false) {
      delete filtered['Requisition'];
    } if (this.defaultObjSelfObservation["enable"] == false) {
      delete filtered['Self Observation'];
    } if (this.defaultObjMemoClient["enable"] == false) {
      delete filtered['Memo Client'];
    } if (this.defaultObjConsignmentInStock["enable"] == false) {
      delete filtered['Consignment In Stock'];
    } if (this.defaultObjMemoService["enable"] == false) {
      delete filtered['Memo Service'];
    } if (this.defaultObjCustomerConfirm["enable"] == false) {
      delete filtered['Customer Confirm'];
    } if (this.defaultObjConsignmentConfirm["enable"] == false) {
      delete filtered['Consignment Confirm'];
    } if (this.partyType.length == 0) {
      delete filtered['partyType'];
    }
    let res;
    res = await this.httpApiService.addUserDefault(filtered);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.roleId = "";
      await this.configService.showToast(
        "success",
        "User default update successfully"
      );
      await this.modalCtrl.dismiss();
    }
  }

  resetForm() {
    this.username = "";
    this.password = "";
    this.mobileNumber = "";
    this.toDeptidList = [];
    this.dept = null
    this.email = "";
    this.fullName = "";
  }

  checkRecut() {
    if (this.recutEnable) {
      this.defaultObjRecut["enable"] = true;
    } else {
      this.defaultObjRecut["enable"] = false;
      delete this.defaultObjRecut["toLocation"];
    }
    console.log(this.defaultObjRecut)
  }

  checkObservation() {
    if (this.recutEnable) {
      this.defaultObjObservation["enable"] = true;
    } else {
      this.defaultObjObservation["enable"] = false;
      delete this.defaultObjObservation["toLocation"];
    }
    console.log(this.defaultObjRecut)
  }

  checkSelfObservation() {
    if (this.selfObservationEnable) {
      this.defaultObjSelfObservation["enable"] = true;
    } else {
      this.partyTypeSelfObservationIdList = []
      this.partyMasterIdList = []
      this.getAllCustomers = []
      this.defaultObjSelfObservation["enable"] = false;
      delete this.defaultObjSelfObservation["partyMaster"];
      delete this.defaultObjSelfObservation["partyType"];
    }
    console.log(this.defaultObjSelfObservation)
  }

  checkMemoClient() {
    if (this.memoClientEnable) {
      this.defaultObjMemoClient["enable"] = true;
    } else {
      this.partyTypeMemoClientIdList = []
      this.defaultObjMemoClient["enable"] = false;
      delete this.defaultObjMemoClient["partyType"];
    }
    console.log(this.defaultObjMemoClient)
  }

  checkCustomerHold() {
    if (this.customerHoldEnable) {
      this.defaultObjCustomerHold["enable"] = true;
    } else {
      this.partyTypeCustomerHoldIdList = []
      this.defaultObjCustomerHold["enable"] = false;
      delete this.defaultObjCustomerHold["partyType"];
    }
    console.log(this.defaultObjCustomerHold)
  }

  checkCConfirm() {
    if (this.customerConfirmEnable) {
      this.defaultObjCustomerConfirm["enable"] = true;
    } else {
      this.partyTypeCConfirmIdList = []
      this.defaultObjCustomerConfirm["enable"] = false;
      delete this.defaultObjCustomerConfirm["partyType"];
    }
    console.log(this.defaultObjCustomerConfirm)
  }

  checkMemoService() {
    if (this.memoServiceEnable) {
      this.defaultObjMemoService["enable"] = true;
    } else {
      this.partyTypeForMemoServiceIdList = []
      this.defaultObjMemoService["enable"] = false;
      delete this.defaultObjMemoService["partyType"];
    }
    console.log(this.defaultObjMemoService)
  }

  checkConsignmentConfirm() {
    if (this.consignmentConfirmEnable) {
      this.defaultObjConsignmentConfirm["enable"] = true;
    } else {
      this.partyTypeForConsignmentConfirmIdList = []
      this.defaultObjConsignmentConfirm["enable"] = false;
      delete this.defaultObjConsignmentConfirm["partyType"];
    }
    console.log(this.defaultObjConsignmentConfirm)
  }

  checkConsignmentInStock() {
    if (this.consignmentInStockEnable) {
      this.defaultObjConsignmentInStock["enable"] = true;
    } else {
      this.partyTypeForConsignmentInStockIdList = []
      this.defaultObjConsignmentInStock["enable"] = false;
      delete this.defaultObjConsignmentInStock["partyType"];
    }
    console.log(this.defaultObjConsignmentInStock)
  }

  checkCertification() {
    if (this.certificationEnable) {
      this.defaultObjCertification["enable"] = true;
    } else {
      this.partyTypeForCertificationIdList = []
      this.defaultObjCertification["enable"] = false;
      delete this.defaultObjCertification["partyType"];
    }
    console.log(this.defaultObjCertification)
  }

  checkRequisition() {
    if (this.requisitionEnable) {
      this.defaultObjRequisition["enable"] = true;
    } else {
      this.partyTypeForRequisitionIdList = []
      this.defaultObjRequisition["enable"] = false;
      delete this.defaultObjRequisition["partyType"];
    }
    console.log(this.defaultObjRequisition)
  }

}
