import { Component, OnInit, Input, ViewChild, HostListener } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { ScanRFIDPage } from "../../actions/scan-rfid/scan-rfid.page";
import * as moment from "moment";
import { PdAssortDetailsPage } from "../../actions/pd-assort-details/pd-assort-details.page";

@Component({
  selector: "app-manage-inventory-manager",
  templateUrl: "./manage-inventory-manager.page.html",
  styleUrls: ["./manage-inventory-manager.page.scss"]
})
export class ManageInventoryManagerPage implements OnInit {
  @Input() dataValue: any;
  @ViewChild("dataTable") public dataTable: any;

  public data: any;
  public flag: any;
  public listOfStones: any = [];
  public transferredList: any = [];
  public pickedList: any = [];
  public deliveredList: any = [];
  public requestedStoneList: any = [];
  public getData: any;
  public boxNo: any = null;
  public inwardStoneList: any;
  public suggestedStoneList: any;
  public selectedStones = [];
  public boxList: any = [];
  public availableSpace: any;
  public totalPieces: number;
  public type: any;
  public sendStoneAction: any;
  public sendStoneList = [];
  public filtered = [];
  public boxType: any;
  public boxLevel: any;
  public columns: any;
  public page = "inManagerPage";
  public isCriteria = false;
  public count: any;
  public noPagination = true;
  public userId: any;
  public userData: any;
  public listOfStoneForLED = [];
  public showParamBox = false;
  public stoneId: any;
  public stoneParams: any;
  public listOfCriteria: any;
  public criteriaId: any;
  public boxTransType: any;
  public currentStoneCount: number;
  public maxCapacity: number;
  public criteriaIdList = [];
  public cssFlag: boolean;
  public role: any;
  public inventoryManagerType: any;
  public transferStoneList = []

  public isSelect = false;
  public selected = []
  public successMsg: any;
  public errorMsg: any;
  public errorMsgList = []
  public successMsgList = []
  public msgStatus = false;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public loadingController: LoadingController,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public event: Events
  ) {
    this.data = this.navParams.get("data");
    this.flag = this.navParams.get("type");
    this.inventoryManagerType = this.navParams.get("inventoryManagerType")
    console.log("data", this.data, this.flag);
    if (this.flag == 2 || this.flag == 3) {
      this.columns = [
        { name: "SrNo", prop: "id" },
        { name: "StoneId", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Purity", prop: "clarity" },
        { name: "Result", prop: "isAssortmentDone" }
      ];
    } else {
      this.columns = [
        { name: "SrNo", prop: "id" },
        { name: "StoneId", prop: "stoneId" },
        { name: "Cts", prop: "stoneCts" },
        { name: "Color", prop: "color" },
        { name: "Shape", prop: "shape" },
        { name: "Purity", prop: "clarity" }
      ];
    }
    if (this.flag == 0) {
      this.findRequestedStoneById(this.data.id);
    } else if (this.flag == 1) {
      this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
    } else if (this.flag == 2) {
      this.findByVoucherIdOfReceivedAssortmentStocks(this.data.id);
    } else if (this.flag == 3) {
      this.findByVoucherIdOfBoxedStocks(this.data.id);
    } else if (this.flag == 4) {
      this.findByVoucherIdOfOutRequestStocks(this.data.id);
    } else if (this.flag == 5) {
      this.findByVoucherIdOfPickedStock(this.data.id);
    } else if (this.flag == 6) {
      this.findByVoucherIdOfDeliveredStock(this.data.id);
    } else if (this.flag == 7) {
      this.findByVoucherIdOfTransferredStock(this.data.id);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode)
    if (event.keyCode == 27) {
      this.modalCtrl.dismiss();
    }
  }

  ngOnInit() {
    this.getUserData();
    console.log(this.flag);
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == "criteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach(el => {
          if (el.criteriaIdList) {
            el.criteriaIdList = []
          }
        })
        this.boxList.filter(el => {
          if (el.id == masterId) {
            el.criteriaIdList = []
            el.listOfCriterias.forEach(item => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name })
              }
            })
          }
        })
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0
        });
        this.criteriaId = null;
      }
    } else if (property == "boxCriteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach(el => {
          if (el.criteriaIdList) {
            el.criteriaIdList = []
          }
        })
        this.boxList.filter(el => {
          if (el.id == masterId) {
            el.criteriaIdList = []
            el.listOfCriterias.forEach(item => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name })
              }
            })
          }
        })
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0
        });
        this.criteriaId = null;
      }
    } else if (property == "outCriteria") {
      if (idList.length > 0) {
        this.criteriaId = idList[0];
        this.boxList.forEach(el => {
          if (el.criteriaIdList) {
            el.criteriaIdList = []
          }
        })
        this.boxList.filter(el => {
          if (el.id == masterId) {
            el.criteriaIdList = []
            el.listOfCriterias.forEach(item => {
              if (item.id == this.criteriaId) {
                el.criteriaIdList.push({ id: item.id, name: item.name })
              }
            })
          }
        })
        this.selectedStoneCriteriaWise(this.criteriaId);
      } else {
        this.event.publish("availableSpace", {
          availableSpace: 0,
          boxNo: 0
        });
        this.criteriaId = null;
      }
    }
  }

  async showResultOfAssortPD(data) {
    console.log("data isss", data);
    if (data.isAssortmentDone) {
      const modal = await this.modalCtrl.create({
        component: PdAssortDetailsPage,
        backdropDismiss: false,
        cssClass: 'largePage',
        componentProps: {
          rowData: data
        },
      });
      modal.onDidDismiss().then((d: any) => this.handleModalResultDismiss(d));
      return await modal.present();
    }
  }

  handleModalResultDismiss(d) {
  }
  async showParameter(item) {
    this.showParamBox = true;
    this.stoneId = item.stoneId;
    let stoneParams = {
      color: "Near Colorless",
      clarity: "VVS2",
      Cut: "EXC",
      Polish: "EXC",
      Lab: "GIA"
    };
    this.stoneParams = Object.entries(stoneParams);
    console.log(this.stoneParams);
  }
  async closeParamBox() {
    this.stoneId = null;
    this.showParamBox = false;
  }
  async getUserData() {
    let userData = await this.authService.userData();
    this.type = userData.departmentName;
    if (userData.roles.length > 0 && userData.roles.includes('Sales Stock Manager - Single')) {
      this.role = 'Sales Stock Manager - Single';
    }
    console.log("userData", userData);
  }

  async findRequestedStoneById(id) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findRequestedStockByVoucherId(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.requestedStoneList = data.data;
      console.log("requestedStoneList", this.requestedStoneList);
    }
  }

  async findByVoucherIdOfPendingAssortmentStocks(id) {
    let res: any;
    await this.configService.showLoader();
    res = await this.httpApiService.findByVoucherIdOfPendingAssortmentStocks(
      id
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.inwardStoneList = data.data;
      this.count = this.inwardStoneList.length;
      console.log("inwardStoneList", this.inwardStoneList);
    }
  }

  async findByVoucherIdOfReceivedAssortmentStocks(id) {
    await this.getUserData();
    let res: any;
    await this.configService.showLoader();
    if (this.type == "KAM Sales") {
      this.boxLevel = "user";
      this.boxType = "Regular";
    } else if (
      this.type == "Assortment" &&
      this.data &&
      this.data.type != "Assortment/PD Return"
    ) {
      this.boxLevel = "department";
      this.boxType = "Regular";
    } else if (this.data.type == "Assortment/PD Return") {
      this.boxLevel = "department";
      this.boxType = "Assortment";
    } else {
      this.boxLevel = "department";
      this.boxType = "Regular";
    }
    res = await this.httpApiService.findByVoucherIdOfReceivedAssortmentStocks(
      id,
      this.boxType,
      this.boxLevel
    );
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      this.suggestedStoneList = dataValue.packets;
      this.boxList = dataValue.boxes;
      this.count = this.suggestedStoneList.length;
      console.log(this.boxList)
      if (this.boxList.length == 1) {
        if (this.boxList[0].transTypeName == 'MIX' && this.boxList[0].listOfCriterias.length > 0) {
          this.boxList[0]['criteriaIdList'] = []
          this.boxList[0].criteriaIdList.push({ id: this.boxList[0].listOfCriterias[0].id, name: this.boxList[0].listOfCriterias[0].name })
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.boxNo = this.boxList[0].listOfCriterias[0].boxId;
          this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.getBoxDetail(this.boxList[0]);
        }
      }
      // dataValue.boxes
      console.log("dataValue", dataValue, this.suggestedStoneList);
    }
  }

  async findByVoucherIdOfBoxedStocks(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findByVoucherIdOfBoxedStocks(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.suggestedStoneList = dataValue.packets;
      this.boxList = dataValue.boxes;
      this.count = this.suggestedStoneList.length;
      if (this.boxList.length == 1) {
        if (this.boxList[0].transTypeName == 'MIX' && this.boxList[0].listOfCriterias.length > 0) {
          this.boxList[0]['criteriaIdList'] = []
          this.boxList[0].criteriaIdList.push({ id: this.boxList[0].listOfCriterias[0].id, name: this.boxList[0].listOfCriterias[0].name })
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.getBoxDetail(this.boxList[0]);
        }
      }
      // dataValue.boxes
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfOutRequestStocks(id) {
    await this.configService.showLoader();
    this.suggestedStoneList = [];
    this.boxList = [];
    let res: any;
    res = await this.httpApiService.findByVoucherIdOfOutRequestStocks(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.suggestedStoneList = dataValue.packets;
      this.boxList = dataValue.boxes;
      if (this.boxList.length > 0) {
        if (this.boxList[0].transTypeName == 'MIX' && this.boxList[0].listOfCriterias.length > 0) {
          this.boxList[0]['criteriaIdList'] = []
          this.boxList[0].criteriaIdList.push({ id: this.boxList[0].listOfCriterias[0].id, name: this.boxList[0].listOfCriterias[0].name })
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.selectedStoneCriteriaWise(this.criteriaId);
        } else {
          this.cssFlag = true;
          this.criteriaId = this.boxList[0].listOfCriterias[0].id;
          this.getBoxDetail(this.boxList[0]);
        }
      } else {
        this.suggestedStoneList.forEach(element => {
          this.selectedStones.push(element.id);
        })
      }
      // dataValue.boxes
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfPickedStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findOutGoingPickedStockByVoucherId(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.pickedList = dataValue;
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfTransferredStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findOutGoingStockByVoucherId(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.transferredList = dataValue;
      console.log("dataValue", dataValue);
    }
  }

  async findByVoucherIdOfDeliveredStock(id) {
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findDeliveredStockByVoucherId(id);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      console.log("data", data.data);
      let dataValue = data.data;
      this.deliveredList = dataValue;
      console.log("dataValue", dataValue);
    }
  }

  getBoxDetail(item) {
    console.log(item);
    // this.criteriaIdList = null
    this.boxTransType = item.transTypeName;
    this.boxNo = item.id;
    this.maxCapacity = item.maxCapacity;
    this.currentStoneCount = item.currentStoneCount

    if (this.boxTransType == 'SS') {
      this.cssFlag = true;
      this.criteriaId = item.criteriaId;
      this.selectedStones = [];
      this.listOfStoneForLED = [];
      this.availableSpace = this.maxCapacity - this.currentStoneCount;
      this.suggestedStoneList.forEach(element => {
        if (
          element.criteriaId == item.criteriaId &&
          this.selectedStones.length < this.availableSpace
        ) {
          this.selectedStones.push(element.id);
          this.listOfStoneForLED.push(element.rfid);
        }
      });
      if (this.selectedStones.length > 0) {
        this.totalPieces = this.selectedStones.length;
      }
      this.event.publish("availableSpace", {
        availableSpace: this.availableSpace,
        boxNo: item.criteriaId
      });
    } else if (this.boxTransType == 'MIX') {
      // this.selectedStones = []
      // this.criteriaId = null
      this.listOfStoneForLED = [];

    } else {
      this.cssFlag = true;
      this.criteriaId = item.criteriaId;
      this.selectedStones = [];
      this.listOfStoneForLED = [];
      this.suggestedStoneList.forEach(element => {
        if (
          element.criteriaId == item.criteriaId
        ) {
          this.selectedStones.push(element.id);
          this.listOfStoneForLED.push(element.rfid);
        }
      });
      if (this.selectedStones.length > 0) {
        this.totalPieces = this.selectedStones.length;
      }
      this.event.publish("availableSpace", {
        availableSpace: 10000000,
        boxNo: item.criteriaId
      });
    }
  }

  async selectedStoneCriteriaWise(criteriaId) {
    this.cssFlag = false;
    this.selectedStones = []
    // this.availableSpace = this.maxCapacity - this.currentStoneCount;
    console.log(this.suggestedStoneList, this.criteriaId)
    this.suggestedStoneList.forEach(element => {
      if (element.criteriaId == criteriaId) {
        this.selectedStones.push(element.id);
        this.listOfStoneForLED.push(element.rfid);
      }
    });
    this.event.publish("availableSpace", {
      availableSpace: 10000000,
      boxNo: criteriaId
    });
  }

  closeModal() {
    this.modalCtrl.dismiss(this.flag);
  }

  async addStoneInBox() {
    console.log(this.selectedStones);
    let validate = await this.validateControls();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        boxId: this.boxNo,
        criteriaId: this.criteriaId,
        setOfStoneIds: this.selectedStones
      };
      console.log(jsonObj);
      res = await this.httpApiService.addStoneToBox(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    }
  }

  async pickStoneFromBox() {
    let validate = await this.validateControlsForPickStone();
    if (!validate) {
      await this.configService.showLoader();
      let res: any;
      let jsonObj = {
        setOfStoneIds: this.selectedStones
      };
      console.log(jsonObj);
      res = await this.httpApiService.pickStoneFromBox(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
        this.modalCtrl.dismiss(this.flag);
      }
    }
  }

  async validateControls() {
    console.log(this.selectedStones, this.boxNo)
    if (
      this.selectedStones.length != 0 &&
      this.boxNo != undefined &&
      this.boxNo != "" &&
      this.boxNo != null
    ) {
      return false;
    } else {
      return true;
    }
  }

  async validateControlsForPickStone() {
    if (this.selectedStones.length != 0 || this.boxList.length == 0) {
      return false;
    } else {
      return true;
    }
  }

  async openLedOnModel() {
    let rfIdList = [];
    this.suggestedStoneList.forEach(el => {
      if (el.rfid) {
        rfIdList.push(el.rfid);
      }
    });
    console.log("**********8", this.selectedStones, rfIdList)
    const modal = await this.modalCtrl.create({
      component: ScanRFIDPage,
      backdropDismiss: false,
      componentProps: {
        type: "scanAndLedOn",
        setOfStoneId: rfIdList
      },
      cssClass: "scanRfiDPage"
    });
    modal.onDidDismiss().then((d: any) => this.handleLedOnModalDismiss(d));
    return await modal.present();
  }

  async handleLedOnModalDismiss(d) {
    console.log("d", d);
  }

  async openScanModel() {
    const modal = await this.modalCtrl.create({
      component: ScanRFIDPage,
      backdropDismiss: false,
      componentProps: {
        type: "receive"
      },
      cssClass: "largePage"
    });
    modal.onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modal.present();
  }

  async handleModalDismiss(d) {
    console.log("d", d);
    this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
  }

  // Received Incoming stones by selection

  async receiveIncomingStones() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";

    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      setOfValues: this.transferStoneList
    };
    res = await this.httpApiService.receivePendingStoneConfirmation(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.type);
    } else if (res.status == 400) {
      if (data.data) {
        this.errorMsgList = data.data;
        for (let i = 0; i < data.data.length; i++) {
          this.errorMsgList[i].ids = data.data[i].StoneId.join(", ");
        }
        console.log(this.errorMsgList)
        this.errorMsg = data.message
        this.msgStatus = true;
      } else {
        await this.configService.showToast("error", data.error);
      }
    } else {
      this.successMsgList = data.data;
      for (let i = 0; i < data.data.length; i++) {
        this.successMsgList[i].ids = data.data[i].StoneId.join(", ");
      }
      console.log(this.successMsgList)
      this.transferStoneList = []
      this.selected = []
      this.isSelect = false;
      this.successMsg = data.message;
      this.msgStatus = true;
      this.findByVoucherIdOfPendingAssortmentStocks(this.data.id);
    }
  }
  //END

  //Selection & select All

  onSelect(ev, itemList) {
    this.selected = ev.selected;
    this.transferStoneList = []
    this.selected.forEach(el => {
      this.transferStoneList.push(el.stoneId)
    });
    if (this.selected.length == itemList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      this.isSelect = true;
      this.transferStoneList = []
      this.selected.forEach(el => {
        this.transferStoneList.push(el.stoneId)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.transferStoneList = []
    }
    console.log(this.transferStoneList)
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  async closeDiv() {
    this.msgStatus = false;
    this.successMsg = "";
    this.errorMsg = "";
  }


  //END
}
