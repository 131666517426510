import { Component, Input } from "@angular/core";
import { ModalController, LoadingController, Events } from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import * as moment from "moment";
import { InterBoxTransferPage } from "../../../components/actions/inter-box-transfer/inter-box-transfer.page";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: 'app-box-transfer',
  templateUrl: './box-transfer.component.html',
  styleUrls: ['./box-transfer.component.scss']
})
export class BoxTransferComponent implements ProcessActionComponent {
  @Input() data: any;

  public fromCts: any;
  public toCts: any;
  public colorList = [];
  public clarityList = [];
  public shapeList = [];
  public colorIdList = [];
  public shapeIdList = [];
  public clarityIdList = [];
  public shapeId = [];
  public clarityId = [];
  public colorId = [];
  public type = 'from';
  public csspList = [];
  public count = 0;
  public selectedcsspList = []
  public columns = [];
  public selected = [];
  public isSelect = false;
  public sizeList = [];
  public sizeIdList = [];
  public sizeName: any;
  public sizeId: any;
  public shapeName: any;
  public boxType: any;

  constructor(public modalCtrl: ModalController,
    private authService: AuthenticationService,
    public configService: ConfigService,
    public httpApiService: HttpApiService,
    public loadingController: LoadingController,
    public event: Events, ) { }

  async ngOnInit() {
    this.columns = [{ name: "Sr No", prop: "" },
    { name: "CSSP", prop: "stoneId" },
    { name: "Box Name", prop: "boxName" },
    { name: "Ct.", prop: "size" }]
    await this.getDefaultSetting()
    await this.findShapeByType()
    this.event.subscribe('Inter Box Transfer', (item) => {
      this.getDefaultSetting()
      this.findShapeByType();

    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Inter Box Transfer')
  }

  async getDefaultSetting() {
    let res: any;
    let userData = await this.authService.userData();
    res = await this.httpApiService.getDefaultSetting(
      userData.userId,
      this.data.actionId
    );
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      if (!!dataValue.map) {
        if (!!dataValue.map.type) {
          this.type = dataValue.map.type;

        }
        if (!!dataValue.map.boxType) {
          this.boxType = dataValue.map.boxType;
        }
      }
      console.log(this.type, this.boxType);
    }
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'shapeList') {
      if (idList.length > 0) {
        this.shapeId = idList;
        this.shapeName = obj[0].name;
        console.log(idList, idList[0])
        this.sizeIdList = []
        this.findSizeByShape(idList[0])
      } else {
        this.sizeIdList = []
        this.shapeId = [];
      }
    } else if (property == 'sizeList') {
      if (idList.length > 0) {
        this.sizeName = obj[0].name;
        this.sizeId = idList[0];
        this.sizeList.filter(el => {
          if (el.id == idList[0]) {
            this.fromCts = el.fromCts;
            this.toCts = el.toCts;
          }
        })
      } else {
        this.sizeIdList = []
      }
    }
  }

  async findShapeByType() {
    let res: any;
    res = await this.httpApiService.findShapeByType(!!this.type ? this.type : 'Assort');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      let dataValue = data.data;
      let data1 = []
      dataValue.forEach(el => {
        data1.push({ id: el.shape, name: el.shapeName })
      })
      this.shapeList = data1;
    }
  }

  async findSizeByShape(shapeId) {
    let res: any;
    res = await this.httpApiService.findSizeByShape(shapeId, !!this.type ? this.type : 'Assort');
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.sizeList = data.data;
      console.log(this.sizeList)
    }
  }

  async searchCSSP() {
    this.selected = []
    let res: any;
    await this.configService.showLoader();
    let jsonObj = {
      shapeId: this.shapeId,
      fromCts: this.fromCts,
      toCts: this.toCts,
      availableStock: true
    }
    res = await this.httpApiService.findStoneByCssp(jsonObj);
    let data = res.json();
    await this.loadingController.dismiss();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.csspList = data.data;
      if (this.csspList.length > 0) {
        this.count = data.data.length;
      } else {
        await this.configService.showToast('error', 'Searched Cssp not found.')
      }
      this.selectedcsspList = []
      console.log(this.csspList)
    }
  }

  showStoneId(stoneId) {
    if (!!stoneId && stoneId.indexOf("_") > -1) {
      return stoneId.slice(0, stoneId.indexOf("_"))
    } else {
      return stoneId
    }
  }

  onSelect(ev) {
    this.selected = ev.selected;
    console.log(this.selected)
    console.log(this.selected)
    this.selectedcsspList = [];
    this.selected.forEach(el => {
      this.selectedcsspList.push(el);
    })
    if (this.selected.length == this.csspList.length) {
      this.isSelect = true;
    } else {
      this.isSelect = false;
    }
  }

  async selectAll(item, itemList) {
    if (item == true) {
      let arr = []
      this.selected = arr.concat(itemList)
      this.isSelect = true;
      this.selectedcsspList = [];
      this.selected.forEach(el => {
        console.log(el)
        this.selectedcsspList.push(el)
      });
    } else {
      this.isSelect = false;
      this.selected = []
      this.selectedcsspList = []
    }
  }

  async interBoxTransfer() {
    let modalPage = await this.modalCtrl.create({
      component: InterBoxTransferPage,
      backdropDismiss: false,
      componentProps: { stoneList: this.selectedcsspList, shapeName: this.shapeName, sizeName: this.sizeName, actionId: this.data.actionId, boxType: this.boxType, createNew: true },
      cssClass: "requestPage"
    })
    modalPage
      .onDidDismiss().then((d: any) => this.handleModalDismiss(d));
    return await modalPage.present();
  }

  async handleModalDismiss(d) {
    if (d.data != 'doNotRefresh') {
      this.isSelect = false;
      this.csspList = [];
      this.selectedcsspList = [];
    }
  }

}
