import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-update-parameter',
  templateUrl: './update-parameter.component.html',
  styleUrls: ['./update-parameter.component.scss']
})
export class UpdateParameterComponent implements ProcessActionComponent {
  @Input() data: any;

  public stoneId: any;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
  }

  async updateStoneParameterFromKG() {
    if (!!this.stoneId) {
      let res: any;
      res = await this.httpApiService.updateStoneParameterFromKG(this.stoneId);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", 'Please enter StoneId');
    }

  }

}
