import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-manager-mix',
  templateUrl: './inventory-manager-mix.component.html',
  styleUrls: ['./inventory-manager-mix.component.scss']
})
export class InventoryManagerMIXComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
