import { Component, Input, ViewChild } from "@angular/core";
import {
  ModalController,
  LoadingController,
  AlertController,
  Events
} from "@ionic/angular";
import { ProcessActionComponent } from "../../processAction";
import { ConfigService } from "../../../services/config/config.service";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-update-barcode',
  templateUrl: './update-barcode.component.html',
  styleUrls: ['./update-barcode.component.scss']
})
export class UpdateBarcodeComponent implements ProcessActionComponent {
  @Input() data: any;
  public stoneId: any;
  public barcode: any;

  constructor(private authService: AuthenticationService,
    public httpApiService: HttpApiService,
    public configService: ConfigService,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public event: Events) { }

  ngOnInit() {
    this.event.subscribe('Update Barcode', (item) => {
      this.stoneId = "";
      this.barcode = "";
    })
  }

  ngOnDestroy() {
    this.event.unsubscribe('Update Barcode');
  }

  async confirmUpdateBarcodeStatus() {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: " Are you sure you want to update? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.checkBarcodeStatus();
          }
        }
      ]
    });

    await alert.present();
  }

  async checkBarcodeStatus() {
    if (!!this.barcode && !!this.stoneId) {
      let jsonObj = {
        barcode: this.barcode,
        stoneId: this.stoneId,
        isUpdate: false
      }
      await this.configService.showLoader()
      let res: any;
      res = await this.httpApiService.updateBarcode(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss()
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.confirmUpdateBarcode(data.error)
      } else {
        await this.configService.showToast("success", data.message);

      }
    } else {
      await this.configService.showToast("error", 'Please scan RFID or add manually to check status.');
    }
  }

  async confirmUpdateBarcode(message) {
    const alert = await this.alertCtrl.create({
      header: "Confirm!",
      message: message + " Are you sure you want to change Barcode ? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: blah => {
            console.log("Confirm Cancel: blah");
          }
        },
        {
          text: "Okay",

          handler: () => {
            console.log("Confirm Okay");
            this.assignBarcodeToStone();
          }
        }
      ]
    });

    await alert.present();
  }

  async assignBarcodeToStone() {
    if (!!this.stoneId) {
      let res: any;
      let jsonObj = {
        barcode: this.barcode,
        stoneId: this.stoneId,
        isUpdate: true
      }
      res = await this.httpApiService.updateBarcode(jsonObj);
      let data = res.json();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        this.stoneId = null;
        this.barcode = null;
        await this.configService.showToast("success", data.message);
      }
    } else {
      await this.configService.showToast("error", 'Please enter stoneId');
    }
  }

}
