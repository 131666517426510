import { Component, OnInit, ɵConsole } from '@angular/core';
import {
  NavController,
  ModalController,
  NavParams,
  LoadingController,
  Events
} from "@ionic/angular";
import { HttpApiService } from "../../../services/http-api/http-api.service";
import { DragulaService } from "ng2-dragula";
import { ConfigService } from "../../../services/config/config.service";
import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: 'app-manage-templates',
  templateUrl: './manage-templates.page.html',
  styleUrls: ['./manage-templates.page.scss'],
})
export class ManageTemplatesPage implements OnInit {

  public getAllStoneParam = [];
  public fileList = [];
  public parameterIdList = [];
  public copy = [];
  public templateName: any;
  public fileType: any;
  public flagAddUpdate: any;
  public item: any;
  public exportIdList = [];
  public id: any;
  public isDefault: any;
  public listOfParameters = [];

  constructor(private authService: AuthenticationService,
    public dragulaService: DragulaService,
    public navParams: NavParams,
    public loadingController: LoadingController,
    public modalCtrl: ModalController,
    public event: Events,
    public httpApiService: HttpApiService,
    public configService: ConfigService) {
    this.fileList = [{ id: 1, name: 'excel' }, { id: 2, name: 'csv' }]

    let data = this.navParams.get('item');
    this.isDefault = this.navParams.get('isDefault');
    if (data != 0) {
      this.item = data.row;
      this.id = this.item.id
    }
    this.flagAddUpdate = this.navParams.get('flag');
    if (this.flagAddUpdate == 'update') {
      this.getTemplateDataById();
    } else {
      this.getAllStoneMaster();
    }
  }

  ngOnInit() {
  }

  dropDownSelection(ev) {
    console.log(ev)
    let property = ev.property;
    let idList = ev.idList;
    let obj = ev.arrayList;
    let masterId = ev.masterId;
    if (property == 'parameterList') {
      if (idList.length > 0) {
        idList.forEach(el => {
          let flag = this.listOfParameters.find(item => item.id == el);
          if (!flag) {
            let obj = this.getAllStoneParam.find(obj => obj.id == el);
            this.listOfParameters.push(obj)
          }
        })
        this.parameterIdList = obj
      } else {
        this.parameterIdList = []
      }
    } else if (property == 'exportFile') {
      if (idList.length > 0) {
        this.fileType = obj[0].name;
      } else {
        this.fileType = null;
      }
    }
  }

  async getTemplateDataById() {
    await this.getAllStoneMaster()
    await this.configService.showLoader();
    let res: any;
    res = await this.httpApiService.findTemplateById(this.id);
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      await this.loadingController.dismiss();
      let dataValue = data.data;
      this.fileType = dataValue.fileType;
      let data1 = [];
      let paramList = dataValue.listOfParameters;
      console.log(paramList)
      this.parameterIdList = []
      if (paramList) {
        this.listOfParameters = []
        paramList.forEach(el => {
          data1.push(parseInt(el.id))
        })
        this.listOfParameters = (paramList)
        console.log(this.listOfParameters)
        data1.forEach(el => {
          this.getAllStoneParam.filter(item => {
            if (item.id == el) {

              let data = { id: item.id, masterName: item.masterName }
              this.parameterIdList.push(data);
            }
          })
        })
      }
      console.log(this.parameterIdList)
      // parameterIdList
      if (this.fileType) {
        this.exportIdList = [];
        this.fileList.forEach(el => {
          if (el.name == dataValue.fileType) {
            let data = { id: el.id, name: el.name };
            console.log(data)
            this.exportIdList.push(data)
          }
        })
      }
      this.templateName = dataValue.templateName;
    }
  }

  async getAllStoneMaster() {
    let res: any;
    res = await this.httpApiService.getAllStoneMaster();
    let data = res.json();
    if (res.status == 401) {
      this.authService.logout();
    } else if (res.status == 500) {
      await this.configService.showToast("error", data.error);
    } else if (res.status == 400) {
      await this.configService.showToast("error", data.error);
    } else {
      this.getAllStoneParam = data.data;
      console.log(this.getAllStoneParam)
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('doNotRefresh');
  }

  dragulaServiceModel() {
    let selector = "bag" + Math.random();
    this.dragulaService.createGroup(selector, {
      moves: function (el, container, handle) {
        return handle.className.indexOf("handle") > -1;
      },
      mirrorContainer: document.getElementsByTagName("ion-app")[0]
    });
    this.dragulaService.dropModel("bag").subscribe(value => {
      var objLength = this.listOfParameters.length;
      var i = 0,
        changePosition = [];
      for (i = 0; i < objLength; i++) {
        if (this.listOfParameters[i].id !== this.copy[i].id) {
          changePosition.push({
            key: this.listOfParameters[i].id,
            value: i
          });
        }
      }
    });
    this.cloneBoxDetails(this.parameterIdList);
    console.log(this.parameterIdList)
  }

  cloneBoxDetails(data) {
    this.listOfParameters = [];
    this.listOfParameters = data;
    this.copy = Object.assign({}, this.listOfParameters);
  }

  async addTemplate() {
    console.log(this.parameterIdList)
    let validate = this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      for (let i = 0; i < this.listOfParameters.length; i++) {
        this.listOfParameters[i].paramOrder = i + 1;
      }
      let jsonObj = {
        fileType: this.fileType,
        templateName: this.templateName,
        isDefault: this.isDefault,
        listOfParameters: this.listOfParameters
      }
      console.log(jsonObj)
      // return
      res = await this.httpApiService.saveTemplate(jsonObj);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async updateTemplate() {
    console.log(this.listOfParameters)
    let validate = this.validateControls();
    if (validate) {
      await this.configService.showLoader();
      let res: any;
      for (let i = 0; i < this.listOfParameters.length; i++) {
        this.listOfParameters[i].paramOrder = i + 1;
      }
      let jsonObj = {
        id: this.id,
        fileType: this.fileType,
        isDefault: this.isDefault,
        templateName: this.templateName,
        listOfParameters: this.listOfParameters
      }
      console.log(jsonObj)
      res = await this.httpApiService.updateTemplate(jsonObj, this.id);
      let data = res.json();
      await this.loadingController.dismiss();
      if (res.status == 401) {
        this.authService.logout();
      } else if (res.status == 500) {
        await this.configService.showToast("error", data.error);
      } else if (res.status == 400) {
        await this.configService.showToast("error", data.error);
      } else {
        await this.configService.showToast('success', data.message);
        await this.modalCtrl.dismiss();
      }
    }
  }

  async validateControls() {
    if (this.fileType != null && this.fileType != undefined && this.fileType != '') {
      if (this.parameterIdList.length > 0) {
        if (this.templateName != null && this.templateName != null && this.templateName != '') {
          return true;
        } else {
          await this.configService.showToast('error', 'Please insert Template name')
          return false;
        }
      } else {
        await this.configService.showToast('error', 'Please select parameter')
        return false;
      }
    } else {
      await this.configService.showToast('error', 'Please select file type')
      return false;
    }
  }
}
